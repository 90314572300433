import Layout from '@root/gatsby-contentful-core/src/components/layout';
import MixpanelDetails from '@root/gatsby-contentful-core/src/components/mixpanel-details';
import PropTypes from '@root/vendor/prop-types';
import Seo from '@root/gatsby-contentful-core/src/components/seo';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveAttribution from '@root/attribution/src/hooks/use-save-attribution';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';

function CorePageTemplateBody({
  analyticsContext,
  children,
  ctaLink,
  ctaText,
  deIndexPage,
  footer,
  includeHotjar,
  includeOptimizely,
  isCtaButtonDisabled,
  isStickyFooterDisabled,
  location,
  mainStyles,
  nav,
  pageCanonical,
  pageDescription,
  pageThumbnail,
  pageTitle,
  isDarkMode,
}) {
  useAnalytics(analyticsContext);
  useSaveAttribution(location);
  useSaveTouchAttributionEffect();

  return (
    <Layout
      ctaLink={ctaLink}
      ctaText={ctaText}
      footer={footer}
      includeHotjar={includeHotjar}
      includeOptimizely={includeOptimizely}
      isCtaButtonDisabled={isCtaButtonDisabled}
      isDarkMode={isDarkMode}
      isStickyFooterDisabled={isStickyFooterDisabled}
      location={location}
      mainStyles={mainStyles}
      nav={nav}
    >
      <Seo
        canonical={pageCanonical}
        deIndexPage={deIndexPage}
        description={pageDescription}
        image={pageThumbnail}
        title={pageTitle}
      />

      {analyticsContext && (
        <MixpanelDetails
          analyticsContext={analyticsContext}
          isPageView={true}
        />
      )}
      {children}
    </Layout>
  );
}

CorePageTemplateBody.propTypes = {
  analyticsContext: PropTypes.string,
  children: PropTypes.any,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  deIndexPage: PropTypes.bool,
  footer: PropTypes.func.isRequired,
  includeHotjar: PropTypes.bool,
  includeOptimizely: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isStickyFooterDisabled: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  mainStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  nav: PropTypes.func.isRequired,
  pageCanonical: PropTypes.string.isRequired,
  pageDescription: PropTypes.string,
  pageThumbnail: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
};

export default function CorePageTemplate({
  analyticsContext,
  children,
  ctaLink,
  ctaText,
  deIndexPage,
  footer,
  includeHotjar,
  includeOptimizely,
  isCtaButtonDisabled,
  isStickyFooterDisabled,
  location,
  mainStyles,
  nav,
  pageCanonical,
  pageDescription,
  pageThumbnail,
  pageTitle,
  isDarkMode,
}) {
  return (
    <CorePageTemplateBody
      analyticsContext={analyticsContext}
      ctaLink={ctaLink}
      ctaText={ctaText}
      deIndexPage={deIndexPage}
      footer={footer}
      includeHotjar={includeHotjar}
      includeOptimizely={includeOptimizely}
      isCtaButtonDisabled={isCtaButtonDisabled}
      isDarkMode={isDarkMode}
      isStickyFooterDisabled={isStickyFooterDisabled}
      location={location}
      mainStyles={mainStyles}
      nav={nav}
      pageCanonical={pageCanonical}
      pageDescription={pageDescription}
      pageThumbnail={pageThumbnail}
      pageTitle={pageTitle}
    >
      {children}
    </CorePageTemplateBody>
  );
}

CorePageTemplate.propTypes = {
  analyticsContext: PropTypes.string,
  children: PropTypes.any,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  deIndexPage: PropTypes.bool,
  footer: PropTypes.func.isRequired,
  includeHotjar: PropTypes.bool,
  includeOptimizely: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isStickyFooterDisabled: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  mainStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  nav: PropTypes.func.isRequired,
  pageCanonical: PropTypes.string.isRequired,
  pageDescription: PropTypes.string,
  pageThumbnail: PropTypes.string,
  pageTitle: PropTypes.string.isRequired,
};
