import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Eyebrow, H2, Subhead } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PressSection({
  anchor,
  logos,
  eyebrow,
  headline,
  sectionSpacing = {},
  subhead,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
}) {
  const hasTitleContent = eyebrow || headline || subhead;

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.pressSection,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(backgroundColor),
      ]}
      id={anchor}
    >
      <div className={'wrap'}>

        {hasTitleContent && (
          <div className={'container_content'}>
            {eyebrow && <Eyebrow className={'eyebrow'}>{eyebrow}</Eyebrow>}
            {headline && <H2 className={'headline'}>{headline}</H2>}
            {subhead && <Subhead className={'subhead'}>{subhead}</Subhead>}
          </div>
        )}

        <div className={'container_logos'}>
          {logos.map((logo, i) => {
            if (logo.link) {
              return (
                <Link
                  className={'link_logo'}
                  key={i}
                  rel={'noopener'}
                  target={'_blank'}
                  to={logo.link}
                >
                  <img
                    alt={logo.alt ?? ''}
                    loading={'lazy'}
                    src={logo.svg}
                  />
                </Link>
              );
            }

            return (
              <span
                className={'link_logo'}
                key={i}
              >
                <img
                  alt={logo.alt ?? ''}
                  loading={'lazy'}
                  src={logo.svg}
                />
              </span>
            );
          })}
        </div>

      </div>
    </section>
  );
}

PressSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
  logos: PropTypes.arrayOf(PropTypes.shape({
    link: PropTypes.string,
    alt: PropTypes.string,
    svg: PropTypes.node,
  })).isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  subhead: PropTypes.string,
};

const styles = StyleSheet.create({
  pressSection: {
    display: 'flex',
    justifyContent: 'center',
    padding: '80px 20px',

    '.wrap': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1200,

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        maxWidth: 580,
        margin: '20px 0 40px',

        '.eyebrow, .headline': {
          marginBottom: 12,
        },
      },

      '.container_logos': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1040,

        '.link_logo': {
          margin: '10px 4%',
          transition: 'opacity 100ms ease-in-out',
        },

        '.link_logo:hover': {
          opacity: 0.6,
        },

        img: {
          display: 'block',
        },

        ...Responsive.sm({
          flexDirection: 'row',
          justifyContent: 'center',
          flexWrap: 'wrap',
        }),
      },
    },
  },
});
