import dayjs from '@root/vendor/dayjs';
export default function getNextTenBusinessDays(operatingHours, holidays) {
  if (!operatingHours) { return []; }

  const tomorrow = dayjs.utc().add(1, 'day');
  const thirtyDays = dayjs.utc().add(31, 'day');

  const diff = thirtyDays.diff(tomorrow, 'day');

  const nextThirtyDays = Array.from(
    { length: diff + 1 }, (_, i) => {
      const date = tomorrow.add(i, 'day');
      const weekdayStr = date.format('dddd');
      const dateStr = date.format('YYYY-MM-DD');
      return [dateStr, weekdayStr];
    }
  );

  const isClosed = (time) => {
    return time.toUpperCase() === 'CLOSED' || time === '';
  };
  const operatingHoursOffDays = Object.keys(operatingHours).filter((day) => isClosed(operatingHours[day].startTime) && isClosed(operatingHours[day].endTime));
  const nextThirtyDaysWithoutOffDays = nextThirtyDays.filter((day) => !operatingHoursOffDays.includes(day[1]));
  const nextThirtyDaysWithoutOffDaysAndHolidays = nextThirtyDaysWithoutOffDays.filter((day) => !holidays || !holidays.includes(day[0]));

  const nextTenFilteredBusinessDays = nextThirtyDaysWithoutOffDaysAndHolidays.slice(0, 10).map((day) => day[0]);

  return nextTenFilteredBusinessDays;
}
