import findOrCreateAbTestForDynamic from '@root/core/src/api/find-or-create-ab-test/find-or-create-ab-test-dynamic';
import useAbTests from '@root/core/src/hooks/use-ab-tests';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useEffect, useRef } from '@root/vendor/react';

export default function useDynamicAbTest(dynamicAbTestConfigId) {
  const [abTests, abTestsDispatch] = useAbTests();
  const calledRef = useRef(false);
  const bucket = abTests[dynamicAbTestConfigId];
  let configuration;
  if (!bucket || !dynamicAbTestConfigId) {
    configuration = findOrCreateAbTestForDynamic(dynamicAbTestConfigId);
  }

  const [makeRequest, isLoading] = useSafeImperativeNetworkRequest(() => configuration);

  useEffect(() => {
    if (calledRef.current) { return; }

    const request = async () => {
      if (!dynamicAbTestConfigId || !configuration) { return; }
      calledRef.current = true;
      const result = await makeRequest();

      abTestsDispatch({
        type: 'SET_BUCKET_FOR_AB_TEST',
        abTestName: dynamicAbTestConfigId,
        bucket: result.data.bucketId,
      });
    };

    request();
  }, [abTestsDispatch, dynamicAbTestConfigId, isLoading, makeRequest, configuration]);

  return [bucket, isLoading];
}
