import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H4, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { secondaryButtonStyles, textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

export default function StatusQuoCard({
  cardHeadline,
  cardBody,
  buttonLink,
  buttonText,
  image,
}) {
  const renderButton = buttonLink && buttonText;

  return (
    <div css={styles.card}>
      <GatsbyImage
        alt={''}
        css={styles.img}
        image={getImage(image)}
      />
      <div css={styles.textContent}>
        <H4 css={textStyles}>{cardHeadline}</H4>
        <P1 css={textStyles}>{cardBody}</P1>
      </div>
      {renderButton &&
        <div css={styles.buttonContainer}>
          <LinkButton
            buttonStyles={secondaryButtonStyles}
            to={buttonLink}
            type={LinkButtonModel.types.SECONDARY}
          >
            {buttonText}
          </LinkButton>
        </div>
      }
    </div>
  );
}

StatusQuoCard.propTypes = {
  buttonLink: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  cardBody: PropTypes.string.isRequired,
  cardHeadline: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 6px',
    width: 'calc(100vw - 60px)',
    maxWidth: 480,
    flexShrink: 0,
    userSelect: 'none',
    scrollSnapAlign: 'center',
    backgroundColor: Colors.nearBlack(),
    borderRadius: 12,
    overflow: 'hidden',
    ...Responsive.md({
      flex: 1,
      margin: '0 10px',
    }),
  },
  img: {
    width: '100%',
    borderRadius: '12px 12px 0 0',
  },
  textContent: {
    padding: '25px 15px',
    textAlign: 'center',
    ...Responsive.md({
      padding: '25px 20px',
    }),
    '& > * + *': {
      marginTop: 8,
    },
  },
  buttonContainer: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-end',
    padding: '0 20px 50px',
  },
});
