import NavBar from '@root/joinroot.com/src/components/nav/nav-bar';
import NavContainer from '@root/joinroot.com/src/components/nav/nav-container';
import RootLogo from '@root/brand/src/components/root-logo';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function LoadingNav() {
  return (
    <NavContainer
      isNavOpen={false}
      isVisible={true}
    >
      <NavBar>
        <a href={'/'}>
          <RootLogo logoStyles={styles.logo} />
        </a>
      </NavBar>
    </NavContainer>
  );
}

const styles = StyleSheet.create({
  logo: {
    display: 'block',
    width: 160,
  },
});
