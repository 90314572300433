import Cookies from '@root/vendor/js-cookie';
import HubSpotValues from '@root/joinroot.com/src/third-party-api/hubspot-values';
import States from '@root/gatsby-contentful-core/src/models/states';
import { URL_REGEX, invalid, isRequired } from '@root/gatsby-contentful-core/src/utils/schema';
import { boolean, object, ref, string } from 'yup';

export default class AffiliateApplicationForm {
  static schema = object()
    .shape({
      firstName: string()
        .required(isRequired),
      lastName: string()
        .required(isRequired),
      email: string()
        .email(invalid)
        .required(isRequired),
      emailConfirm: string()
        .oneOf([ref('email'), null], 'Email addresses must match')
        .required('This field is required.'),
      companyName: string(),
      website: string()
        .matches(URL_REGEX, invalid)
        .required(isRequired),
      streetAddress: string()
        .required(isRequired),
      streetAddress2: string(),
      city: string()
        .required(isRequired),
      state: string()
        .oneOf(States.getKeys()),
      country: string()
        .required(isRequired),
      postalCode: string()
        .label('ZIP code')
        .required(isRequired),
      phoneNumber: string()
        .matches(/(?:\d\s)?\(?(\d{3})\)?-?\s?(\d{3})-?\s?(\d{4})$/i, invalid)
        .required(isRequired),
      confirmation: boolean()
        .oneOf([true], 'Please accept the agreement.'),
    });

  constructor(value) {
    return Object.assign(
      this,
      {
        ...value,
      }
    );
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(value = {}) {
    return Object.assign(
      this,
      new AffiliateApplicationForm(value),
    );
  }

  serialize() {
    return {
      fields: [
        {
          name: 'firstname',
          value: this.firstName,
        },
        {
          name: 'lastname',
          value: this.lastName,
        },
        {
          name: 'email',
          value: this.email,
        },
        {
          name: 'company',
          value: this.companyName,
        },
        {
          name: 'company_website',
          value: this.website,
        },
        {
          name: 'address',
          value: this.streetAddress + (this.streetAddress2 ? ' ' + this.streetAddress2 : ''),
        },
        {
          name: 'city',
          value: this.city,
        },
        {
          name: 'state',
          value: this.state,
        },
        {
          name: 'country',
          value: this.country,
        },
        {
          name: 'zip',
          value: this.postalCode,
        },
        {
          name: 'phone',
          value: this.phoneNumber,
        },
        {
          name: 'affiliate_tos_agreement',
          value: this.confirmation,
        },
      ],
      context: {
        hutk: Cookies.getJSON(HubSpotValues.COOKIE_NAME),
        pageUri: 'joinroot.com/affiliates/application',
        pageName: 'Root Affiliate Program Application',
      },
    };
  }
}
