import ContentfulFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-factory';
import uuid from '@root/vendor/uuid/v4';

export default class ContentfulCustomReactComponentFactory {
  static create(overrides = {}) {
    return {
      id: uuid(),
      sys: ContentfulFactory.createSys('customReactComponent'),
      componentId: 'example-component-id',
      ...overrides,
    };
  }
}
