import Colors, { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';
import Icon from '@root/gatsby-contentful-core/src/components/icon';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import styled from '@root/vendor/@emotion/styled';
import { IntentType } from '@root/gatsby-contentful-core/src/utils/theme';

export class ButtonType {
  static BUTTON = 'button';
  static SUBMIT = 'submit';
  static LINK = 'link';
}

export class ButtonSize {
  static XSMALL = 'xsmall';
  static SMALL = 'small';
  static NORMAL = 'normal';
  static LARGE = 'large';
}

export default class Button extends Component {
  static propTypes = {
    children: PropTypes.node,
    disabled: PropTypes.bool,
    href: PropTypes.string,
    icon: PropTypes.oneOfType([Icon]),
    intent: PropTypes.string,
    rel: PropTypes.string,
    size: PropTypes.string,
    target: PropTypes.string,
    testID: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    size: ButtonSize.NORMAL,
    intent: IntentType.PRIMARY,
    type: ButtonType.BUTTON,
  };

  render() {
    const {
      type, href, target, rel, disabled, children, testID, ...rest
    } = this.props;

    let tagName = 'button';
    let passProps = {
      ...rest,
      type,
      disabled,
    };

    if (type === ButtonType.LINK) {
      tagName = 'a';
      passProps = {
        ...rest,
        href,
        rel,
        target,
      };
    }

    return (
      <StyledButton
        as={tagName}
        className={'Button'}
        {...passProps}
      >
        <span data-testid={testID}>{children}</span>
      </StyledButton>
    );
  }
}

const StyledButton = styled.button(({ size, intent }) => {
  let minHeight;
  let backgroundColor = 'transparent';
  let borderColor = 'transparent';
  let color;

  switch (size) {
  case ButtonSize.LARGE:
    minHeight = 72;
    break;
  case ButtonSize.SMALL:
    minHeight = 48;
    break;
  case ButtonSize.XSMALL:
    minHeight = 36;
    break;
  case ButtonSize.NORMAL:
  default:
    minHeight = 60;
  }

  switch (intent) {
  case IntentType.SECONDARY:
    backgroundColor = Colors.nearBlack();
    color = Colors.white();
    break;
  case IntentType.TERTIARY:
    backgroundColor = Colors.white();
    borderColor = toRgba(Colors.black(), 0.1);
    color = Colors.nearBlack();
    break;
  case IntentType.VALID:
    backgroundColor = Colors.green();
    color = Colors.white();
    break;
  case IntentType.WARN:
    backgroundColor = Colors.yellow();
    color = Colors.nearBlack();
    break;
  case IntentType.INVALID:
    backgroundColor = Colors.invalid();
    color = Colors.white();
    break;
  case IntentType.NONE:
    break;
  case IntentType.PRIMARY:
  default:
    backgroundColor = Colors.rootOrange();
    color = Colors.white();
  }

  return {
    ...Typography.button(),
    backgroundColor,
    borderRadius: 4,
    minHeight,
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 20,
    paddingRight: 20,
    borderColor,
    borderWidth: 1,
    borderStyle: 'solid',
    lineHeight: 1,
    color,
    textDecoration: 'none',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    ':disabled': {
      backgroundColor: toRgba(Colors.greyDark(), 0.8),
      borderColor: 'transparent',
      color: Colors.nearWhite(),
    },

    ':not(:disabled)': {
      cursor: 'pointer',

      '&:active': {
        transform: 'translateY(1px)',
      },
    },
  };
});
