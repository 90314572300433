import AnalyticsService from '@root/core/src/services/analytics-service';
import TrackingService from '@root/attribution/src/services/tracking-service';
import { useEffect } from '@root/vendor/react';

export default function GTMDistinctIdReporter() {
  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  return null;
}
