
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environmentalizeUrl from '@root/gatsby-contentful-core/src/utils/environmentalize-url';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { graphql, navigate, useStaticQuery } from 'gatsby';

const ProductTile = ({ product, marketData }) => {
  const isProductAvailable = marketData[product.productType];

  const { existingHomeownersSlugs } = useStaticQuery(graphql`
  query {
    existingHomeownersSlugs: allSitePage(filter: {path: {regex: "/homeowners-insurance/"}}) {
      nodes {
        path
      }
    }
  }
  `);

  const generateLinkFromMarketName = (prefix, marketFullName) => {
    marketFullName = marketFullName.toLowerCase().split(' ').join('-');

    const pageExistsForMarket = existingHomeownersSlugs.nodes.find(({ path }) => path.includes(marketFullName));

    if (pageExistsForMarket) {
      return `/${prefix}/${marketFullName}`;
    }

    return '/homeowners-insurance/';
  };

  const handleProductTextClick = (selectedMarketData, productData) => {
    const { market } = selectedMarketData;
    const marketName = Market.MAPPING[market];
    let forwardLink;

    if (productData.productType === 'homeowners') {
      forwardLink = generateLinkFromMarketName(productData.prefix, marketName);
      navigate(forwardLink);
    } else {
      forwardLink = product.link;
      forwardLink = environmentalizeUrl(forwardLink);

      // eslint-disable-next-line root/prevent-use-of-window-location
      window.location = forwardLink;
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleProductTextClick(marketData, product);
    }
  };

  const className = `product-${isProductAvailable ? 'available' : 'unavailable'}`;

  return (
    <div
      className={className}
      css={styles.product}
      data-testid={className}
      onKeyPress={handleKeyPress}
      tabIndex={isProductAvailable ? 0 : -1}
    >
      <div
        className={'product-icon'}
        css={styles.icon}
        data-testid={`product-icon-${product.productType}`}
      >
        {product.icon}
      </div>
      <p
        css={styles.title}
        data-testid={'link'}
        onClick={isProductAvailable ? () => handleProductTextClick(marketData, product) : undefined}
      >
        {product.title}
      </p>
    </div>
  );
};

ProductTile.propTypes = {
  marketData: PropTypes.object.isRequired,
  product: PropTypes.shape({
    title: PropTypes.string.isRequired,
    productType: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    link: PropTypes.string,
    prefix: PropTypes.string,
  }),
};

const styles = StyleSheet.create({
  product: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 28,
    alignItems: 'center',
  },
  icon: {
    svg: {
      '.product-available &': {
        fill: Colors.rootOrange(),
      },
      '.product-unavailable &': {
        fill: Colors.gray50(),
      },
    },
  },
  title: {
    lineHeight: '130%',
    letterSpacing: '-0.159174px',
    marginLeft: 15,
    fontWeight: 400,
    fontSize: '20px',
    '.product-available &': {
      color: Colors.nearBlack(),
      textDecoration: 'underline',
      cursor: 'pointer',
      '&:hover': {
        color: Colors.rootOrange(),
      },
      '&:active': {
        color: '#F08E65',
      },
    },
    '.product-unavailable &': {
      color: Colors.gray50(),
      textDecoration: 'none',
    },
  },
});

export default ProductTile;
