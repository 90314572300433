import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';
import { H2 } from '@root/gatsby-contentful-core/src/components/text';

export default function CategorySectionStacked({
  sectionTitle,
  viewAllButtonSlug,
  children,
  backgroundColor = Colors.white(),
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      data-testid={'category-section-stacked'}
      sectionSpacing={sectionSpacing}
    >
      {sectionTitle &&
        <H2
          css={styles.sectionTitle}
          data-testid={'section-title'}
        >
          {sectionTitle}
        </H2>
      }
      <div
        css={styles.tileLayout}
        data-testid={'tile-layout'}
      >
        {children}
      </div>
      {viewAllButtonSlug &&
        <LinkButton
          buttonStyles={styles.viewAllButtonContainer}
          to={viewAllButtonSlug}
          type={LinkButtonModel.types.SECONDARY}
        >
          View all
        </LinkButton>
      }
    </Section>
  );
}

CategorySectionStacked.propTypes = {
  backgroundColor: PropTypes.oneOf([Colors.white(), Colors.nearWhite()]),
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(Object.values(TileLayoutModel)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  sectionTitle: PropTypes.string,
  viewAllButtonSlug: PropTypes.string,
};

const Section = styled.section({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  return {
    ...sectionSpacingStyles,
    backgroundColor,
  };
});

const styles = StyleSheet.create({
  sectionTitle: {
    margin: '0 20px 40px',
    textAlign: 'center',
  },
  tileLayout: {
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 1184, // Visually evaluates to spec'd 1164 with 10px tile margins on either side.
    width: '100%',
  },
  viewAllButtonContainer: {
    margin: '10px 0 0',
  },
});
