import PropTypes from '@root/vendor/prop-types';
import { P1 } from '@root/gatsby-contentful-core/src/components/text';
import { isValidElement } from '@root/vendor/react';

export default function BodyCopy({ content, className }) {
  if (!content) { return null; }

  if (isValidElement(content)) {
    return (
      <div className={className}>
        {content}
      </div>
    );
  }

  return <P1 className={className}>{content}</P1>;
}

BodyCopy.propTypes = {
  className: PropTypes.string,
  content: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};
