module.exports = {
  // https://github.com/contentful/rich-text/tree/master/packages/rich-text-types/src
  RichTextNodeTypes: {
    HEADING_1: 'heading-1',
    HEADING_2: 'heading-2',
    HEADING_3: 'heading-3',
    HEADING_4: 'heading-4',
    HEADING_5: 'heading-5',
    HEADING_6: 'heading-6',
    HYPERLINK: 'hyperlink',
    OL_LIST: 'ordered-list',
    QUOTE: 'blockquote',
    UL_LIST: 'unordered-list',
  },

  RichTextMarkTypes: {
    BOLD: 'bold',
    CODE: 'code',
    ITALIC: 'italic',
    UNDERLINE: 'underline',
  },

  Alignments: {
    LEFT: 'left',
    RIGHT: 'right',
    CENTER: 'center',
  },

  SizesSML: {
    S: 'S',
    M: 'M',
    L: 'L',
  },

  Types: {
    SHORT_TEXT: 'Symbol',
    LONG_TEXT: 'Text',
    INTEGER: 'Integer',
    NUMBER: 'Number',
    DATE: 'Date',
    BOOLEAN: 'Boolean',
    OBJECT: 'Object',
    LOCATION: 'Location',
    RICH_TEXT: 'RichText',
    ARRAY: 'Array',
    LINK: 'Link',
  },

  LinkTypes: {
    ASSET: 'Asset',
    ENTRY: 'Entry',
  },

  WidgetNamespaces: {
    BUILT_IN: 'builtin', // Contentful built-in extension
    EXTENSION: 'extension', // Contentful custom extension
  },

  // https://www.contentful.com/developers/docs/concepts/editor-interfaces/
  EditorInterfaces: {
    BOOLEAN: 'boolean',
    DROPDOWN: 'dropdown',
    SLUG_EDITOR: 'slugEditor',
    URL_EDITOR: 'urlEditor',
    SINGLE_LINE: 'singleLine',
    RICH_TEXT_EDITOR: 'richTextEditor',
  },
};
