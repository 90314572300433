import AbTestBuckets, { NavRedesignAbTestBuckets } from '@root/core/src/models/ab-test-buckets';
import AbTestProvider from '@root/core/src/contexts/ab-test/ab-test-provider';
import AgentsBanner from '@root/gatsby-contentful-core/src/components/agents-banner';
import AgentsPhoneNumber from '@root/agents/src/utils/agents-phone-number';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import normalizeStyles from '@root/gatsby-contentful-core/src/components/normalize.css';
import useAccountAbTest from '@root/core/src/hooks/use-account-ab-test';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useJoinrootAgentsBannerAbTest from '@root/gatsby-contentful-core/src/hooks/use-joinroot-agents-banner-ab-test';
import useJoinrootAgentsBannerPaidUrlAbTest from '@root/gatsby-contentful-core/src/hooks/use-joinroot-agents-banner-paid-url-ab-test';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Global, css } from '@root/vendor/@emotion/core';
import { Helmet } from '@root/website-tooling/react-helmet';

export const NAV_REDESIGN_COOKIE_KEY = 'joinroot_nav_redesign-bucket';
const isBrowser = typeof window !== 'undefined';
const removeTrailingSlash = (url = '') => {
  if (url === '/') { return url; }
  return url.replace(/\/$/, '');
};

const Layout = ({
  children,
  ctaLink,
  ctaText,
  footer,
  includeHotjar = true,
  includeOptimizely = false,
  isCtaButtonDisabled,
  isStickyFooterDisabled,
  location,
  mainStyles,
  nav,
  isDarkMode = false,
}) => {
  const Footer = footer;
  const Nav = nav;

  const [isNavOpen, setNavState] = useState(false);

  const [joinrootNavRedesignBucket, setJoinrootNavRedesignBucket] = useState();
  const [bucketFromAbTest, isLoading] = useAccountAbTest('joinroot_nav_redesign');
  const [joinrootAgentsBannerBucket, joinrootAgentsBannerEnabled] = useJoinrootAgentsBannerAbTest(removeTrailingSlash(location?.pathname));
  const [joinrootAgentsBannerPaidUrlBucket, joinrootAgentsBannerPaidUrlEnabled] = useJoinrootAgentsBannerPaidUrlAbTest(removeTrailingSlash(location?.pathname));
  const [shouldSlideCtaUp, setShouldSlideCtaUp] = useState(false);

  useAnalytics(`NAV_REDESIGN_TEST_BUCKET_${joinrootNavRedesignBucket}`, !isLoading);

  useScrollPosition(useCallback(({ previousPosition, currentPosition }) => {
    // This coordinates with the main nav.
    const isFooterVisible = currentPosition.y < -100;
    const shouldSlide = currentPosition.y < previousPosition.y && isFooterVisible;
    setShouldSlideCtaUp(shouldSlide);
  }, []));

  useEffect(() => {
    if (!isBrowser) { return; }
    if (isDarkMode) {
      document.body.style.backgroundColor = Colors.black();
    } else {
      document.body.style.backgroundColor = 'rgba(0, 0, 0, 0)';
    }
  });

  useEffect(() => {
    if (joinrootNavRedesignBucket) { return; }
    if (!environment.joinrootNavRedesignEnabled) {
      setJoinrootNavRedesignBucket(bucketFromAbTest);
      return;
    }
    if (CookieStorageService.has(NAV_REDESIGN_COOKIE_KEY)) {
      setJoinrootNavRedesignBucket(CookieStorageService.get(NAV_REDESIGN_COOKIE_KEY));
      return;
    }
    if (bucketFromAbTest && !CookieStorageService.has(NAV_REDESIGN_COOKIE_KEY)) {
      setJoinrootNavRedesignBucket(bucketFromAbTest);
      CookieStorageService.set(NAV_REDESIGN_COOKIE_KEY, bucketFromAbTest);
    }
  }, [joinrootNavRedesignBucket, bucketFromAbTest]);

  const toggleNavState = (event) => {
    event.preventDefault();
    setNavState(!isNavOpen);
  };

  const isBannerOn = environment.announcementBannerEnabled && Object.keys(PATHS_FOR_ANNOUNCEMENT_BANNER).includes(location?.pathname);
  const isHelpPage = environment.joinrootAgentsBannerUrls[removeTrailingSlash(location?.pathname)] === 'fixedExtraSpace';

  const mainPaddingTopStyles = isBannerOn ? styles.mainWhenBanner : styles.main;

  return (
    <>
      <Global styles={css`${normalizeStyles}`} />
      <Helmet
        link={[
          includeOptimizely ? {
            href: 'https://cdn.optimizely.com/js/12161803067.js',
            rel: 'preload',
            as: 'script',
          } : {},
          includeOptimizely ? {
            href: '//logx.optimizely.com',
            rel: 'preconnect',
          } : {},
        ]}
        script={[
          includeOptimizely ? {
            src: 'https://cdn.optimizely.com/js/12161803067.js',
          } : {},
          includeHotjar ? {
            innerHTML: `(function(h,o,t,j,a,r){
              h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
              h._hjSettings={hjid:1127811,hjsv:6};
              a=o.getElementsByTagName('head')[0];
              r=o.createElement('script');r.async=1;
              r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
              a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
          } : {},
        ]}
      />
      <div
        className={`${isNavOpen ? 'nav-is-open' : ''}`}
        css={styles.container}
      >
        {Nav && (
          <Nav
            bannerContent={PATHS_FOR_ANNOUNCEMENT_BANNER[location?.pathname]}
            ctaLink={ctaLink}
            ctaText={ctaText}
            isBannerOn={isBannerOn}
            isCtaButtonDisabled={isCtaButtonDisabled}
            isDarkMode={isDarkMode}
            isNavOpen={isNavOpen}
            isStickyFooterDisabled={isStickyFooterDisabled}
            navRedesignBucket={joinrootNavRedesignBucket}
            navRedesignIsLoading={isLoading}
            toggleNavState={toggleNavState}
          />
        )}
        <main
          css={[
            mainPaddingTopStyles,
            isDarkMode && styles.mainWithoutNavPadding,
            mainStyles,
          ]}
        >
          {joinrootAgentsBannerEnabled && (
            <div
              css={[
                bannerStyles[environment.joinrootAgentsBannerUrls[removeTrailingSlash(location?.pathname)]],
                shouldSlideCtaUp && styles.ctaUp,
                shouldSlideCtaUp && isBannerOn && styles.ctaUpOnBanner,
                shouldSlideCtaUp && isHelpPage && styles.ctaUpInHelp,
              ]}
            >
              {joinrootAgentsBannerBucket === AbTestBuckets.ON && (
                <AgentsBanner
                  conversionTrackingEvent={ConversionTrackingService.CONVERSION_EVENTS.WWW_SPEAK_TO_AN_AGENT_BUTTON_CLICKED}
                  eventPrefix={'JOINROOT'}
                  isaPhoneNumber={AgentsPhoneNumber.PHONE_NUMBERS.ISA_JOINROOT}
                />
              )}
            </div>
          )}
          {joinrootAgentsBannerPaidUrlEnabled && (
            <div
              css={[
                bannerStyles[environment.joinrootAgentsBannerPaidUrls[removeTrailingSlash(location?.pathname)]],
                shouldSlideCtaUp && styles.ctaUp,
                shouldSlideCtaUp && isBannerOn && styles.ctaUpOnBanner,
              ]}
            >
              {joinrootAgentsBannerPaidUrlBucket === AbTestBuckets.ON && (
                <AgentsBanner
                  conversionTrackingEvent={ConversionTrackingService.CONVERSION_EVENTS.WWW_SPEAK_TO_AN_AGENT_BUTTON_CLICKED}
                  eventPrefix={'JOINROOT_PAID_URL'}
                  isaPhoneNumber={AgentsPhoneNumber.PHONE_NUMBERS.ISA_JOINROOT_PAID_URL}
                />
              )}
            </div>
          )}
          {children}
        </main>
        {Footer && (
          <Footer
            isDarkMode={isDarkMode}
            navRedesignBucket={joinrootNavRedesignBucket}
            navRedesignIsLoading={isLoading}
          />
        )}
      </div>
    </>
  );
};

export default function LayoutWrapper(props) {
  const initialAbTests = {};

  if (!environment.joinrootNavRedesignEnabled) {
    initialAbTests.joinroot_nav_redesign = NavRedesignAbTestBuckets.CONTROL;
  }

  return (
    <AbTestProvider initialAbTests={initialAbTests}>
      <Layout {...props} />
    </AbTestProvider>
  );
}

const styles = StyleSheet.create({
  container: {
    '&.nav-is-open': {
      position: 'fixed',
      overflow: 'hidden',
    },
    ...Responsive.md({
      '&.nav-is-open': {
        position: 'static',
      },
    }),
  },
  main: {
    paddingTop: 50,
    ...Responsive.md({
      paddingTop: 103,
    }),
    ...Responsive.lg({
      paddingTop: 59,
    }),
  },
  mainWhenBanner: {
    paddingTop: 100,
    ...Responsive.md({
      paddingTop: 154,
    }),
    ...Responsive.lg({
      paddingTop: 110,
    }),
  },
  mainWithoutNavPadding: {
    paddingTop: 0,
    ...Responsive.md({
      paddingTop: 0,
    }),
    ...Responsive.lg({
      paddingTop: 0,
    }),
  },
  ctaUp: {
    transform: 'translateY(-100%)',
    transition: 'all 850ms cubic-bezier(0.37, 1, 0.28, 1)',
    position: 'fixed',
    button: {
      marginTop: 0,
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0,
    },
    ...Responsive.md({
      transform: 'translateY(-110px)',
    }),
    ...Responsive.lg({
      transform: 'translateY(-60px)',
    }),
  },
  ctaUpOnBanner: {
    transform: 'translateY(-100%)',
    transition: 'all 850ms cubic-bezier(0.37, 1, 0.28, 1)',
    position: 'fixed',
    button: {
      marginTop: 0,
    },
    ...Responsive.sm({
      transform: 'translateY(-105px)',
    }),
    ...Responsive.md({
      transform: 'translateY(-155px)',
    }),
    ...Responsive.lg({
      transform: 'translateY(-110px)',
    }),
  },
  ctaUpInHelp: {
    ...Responsive.sm({
      transform: 'translateY(-50px)',
    }),
  },
});

const bannerStyles = StyleSheet.create({
  fixed: {
    position: 'fixed',
    transition: 'all 850ms cubic-bezier(0.37, 1, 0.28, 1)',
    right: 15,
    zIndex: 5,
    ...Responsive.md({
      paddingRight: 20,
    }),
    ...Responsive.sm({
      zIndex: 3,
    }),
    '& + div > div > section': {
      paddingTop: 60,
    },
  },
  fixedExtraSpace: {
    position: 'fixed',
    transition: 'all 850ms cubic-bezier(0.37, 1, 0.28, 1)',
    right: 15,
    zIndex: 2,
    paddingTop: 50,
    '& + div > div > section': {
      paddingTop: 100,
    },
    ...Responsive.md({
      paddingRight: 20,
      paddingTop: 0,
      '& + div > div > section': {
        paddingTop: 60,
      },
    }),
  },
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  footer: PropTypes.func,
  includeHotjar: PropTypes.bool,
  includeOptimizely: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isStickyFooterDisabled: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }),
  mainStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  nav: PropTypes.func,
};

const BANNER_CONTENT = {
  DEFAULT: 'default',
  ROADSIDE: {
    SHORT_COPY: 'We’re temporarily suspending tow truck rides',
    LONG_COPY: 'Need a tow? For your safety, during COVID-19, customers are no longer riding in the tow truck.',
  },
};

const PATHS_FOR_ANNOUNCEMENT_BANNER = {
  '/': {
    content: BANNER_CONTENT.DEFAULT,
  },
  '/coverage': {
    content: BANNER_CONTENT.ROADSIDE,
  },
  '/coverage/': {
    content: BANNER_CONTENT.ROADSIDE,
  },
};
