import Button from '@root/core/src/components/button';
import CaretLoader from '@root/core/src/components/caret-loader';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import PlatformButton from '@root/gatsby-contentful-core/src/components/platform-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import generateDeepLinkConfiguration from '@root/joinroot.com/src/api/generate-deep-link-configuration';
import smsAppDownloadConfiguration from '@root/joinroot.com/src/api/sms-app-download-configuration';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { H2, Subhead } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { formatPhoneNumber } from '@root/core/src/utils/strings';
import { isRequired, isValidPhoneNumber } from '@root/core/src/utils/validators';
import { useCallback } from '@root/vendor/react';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const validations = [
  isRequired('phoneNumber', 'Please enter your phone number'),
  isValidPhoneNumber(),
];

export default function GetTheAppForm({ analytics }) {
  const [isModalShowing, setIsModalShowing] = useState(false);
  const [numberForTextMessage, setNumberForTextMessage] = useState();
  const [deepLinkUrl, setDeepLinkUrl] = useState('');
  const [useFallbackUrl, setUseFallbackUrl] = useState(true);

  const rootId = useRootId();

  const [sendTextMessage] = useImperativeNetworkRequest(smsAppDownloadConfiguration);

  const deepLinkConfiguration = useMemo(() => generateDeepLinkConfiguration({
    rootId,
  }), [rootId]);
  const deepLinksLoading = useNetworkRequest(deepLinkConfiguration, useCallback((result) => {
    if (!result?.error) {
      setDeepLinkUrl(result.data.deeplink);
      setUseFallbackUrl(false);
    }
  }, []));

  const form = useForm({
    validations,
  });

  const phoneFieldProps = form.getFieldProps('phoneNumber');

  const handleFormSubmit = async ({ phoneNumber }) => {
    setIsModalShowing(true);
    analytics.trackClick('TEXT_ME_THE_APP_BUTTON');
    await sendTextMessage({
      rootId,
      phoneNumber,
    });
    setNumberForTextMessage(phoneNumber);
  };

  const textMessageSentToNumber = () => phoneFieldProps.value === numberForTextMessage;

  const platformAppStoreLinks = () => (
    <>
      <PlatformButton
        href={useFallbackUrl ? 'https://joinroot.onelink.me/jURb/e1bd475b' : deepLinkUrl}
        platform={'IOS'}
      />
      <PlatformButton
        href={useFallbackUrl ? 'https://joinroot.onelink.me/yq3U/7065d205' : deepLinkUrl}
        platform={'ANDROID'}
      />
    </>
  );

  return (
    <div css={styles.container}>
      <form
        css={styles.form}
        onSubmit={form.createSubmitHandler(handleFormSubmit)}
      >
        <PhoneNumberInput
          id={'phoneNumber'}
          label={'Enter your phone number'}
          {...phoneFieldProps}
        />
        <Button
          className={'button'}
          disabled={form.submitting || textMessageSentToNumber()}
        >
          Text me the app
        </Button>
      </form>
      <div css={styles.platformContainer}>
        {
          deepLinksLoading ?
            <div css={styles.loadingContainer}>
              <CaretLoader />
            </div> :
            platformAppStoreLinks()
        }
      </div>

      <Modal
        isShowing={isModalShowing}
        name={'text-message-success-modal'}
        onClose={() => setIsModalShowing(false)}
        renderCloseButton={false}
      >
        <div css={styles.modalContent}>
          <H2 css={styles.heading}>Thanks! You should receive a text shortly.</H2>
          <Subhead css={styles.modalSubhead}>
            We sent a text message to <strong>{formatPhoneNumber(phoneFieldProps.value)}</strong> with a link to download the Root app.
          </Subhead>
          <Button onClick={() => setIsModalShowing(false)}>Okay</Button>
        </div>
      </Modal>
    </div>
  );
}

GetTheAppForm.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
  }),
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 334,
    marginTop: 50,

    button: {
      marginTop: 30,
    },
  },
  loadingContainer: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  form: {
    display: 'none',

    ...Responsive.md({
      display: 'block',
    }),
  },
  platformContainer: {
    display: 'none',

    ...Responsive.lessThanMd({
      display: 'flex',
    }),

    '& > a + a': {
      marginLeft: 20,
    },
  },
  modalContent: {
    textAlign: 'center',
  },
  modalSubhead: {
    margin: '15px 0 40px',
  },
});
