export class Easing {
  static easeOutQuart = 'cubic-bezier(0.165, 0.84, 0.44, 1)';
  static easeOutQuint = 'cubic-bezier(0.23, 1, 0.32, 1)';
  static easeOutExpo = 'cubic-bezier(0.19, 1, 0.22, 1)';
  static easeOutCirc = 'cubic-bezier(0.075, 0.82, 0.165, 1)';
  static easeInQuint = 'cubic-bezier(0.755, 0.05, 0.855, 0.06)';
}

export class Duration {
  static FAST = '0.15s';
  static NORMAL = '0.3s';
  static SLOW = '0.6s';
}

export const scan = (reducer, init) => {
  const state = {
    accumulator: init,
    reducer,
    listener: () => {},
  };

  const next = (v) => {
    state.accumulator = reducer(state.accumulator, v);
    state.listener(state.accumulator);
  };

  const start = (listener) => {
    state.listener = listener;

    return {
      next,
    };
  };

  return {
    start,
  };
};

export const lerp = (roundness) => (accum, target) => {
  return Object.keys(accum).reduce((acc, key) => {
    acc[key] = (1 - roundness) * accum[key] + target[key] * roundness;
    return acc;
  }, {});
};

export const rAF = () => {
  const state = {
    listener: () => {},
    animationFrameId: null,
  };

  const loop = (timeStamp) => {
    state.animationFrameId = requestAnimationFrame((ts) => {
      loop(ts);
    });
    state.listener(timeStamp);
  };

  const stop = () => {
    cancelAnimationFrame(state.animationFrameId);
  };

  const start = (listener) => {
    state.listener = listener;
    loop(Date.now());

    return {
      stop,
    };
  };

  return {
    start,
  };
};

export const smooth = (init, { roundness = 0.1 } = {}) => {
  const state = {
    scan: null,
    loop: null,
    target: init,
  };

  const update = (v) => {
    state.target = v;
  };

  const stop = () => {
    state.loop.stop();
  };

  const start = (listener) => {
    state.scan = scan(lerp(roundness), init).start(listener);

    state.loop = rAF().start(() => {
      state.scan.next(state.target);
    });

    return {
      update,
      stop,
    };
  };

  return {
    start,
  };
};
