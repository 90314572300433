import ErrorReportService from '@root/core/src/services/error-report-service';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { RootError } from '@root-common/root-errors';

export function AgentsOperatingHours({ operatingHours, cssOverrides = []}) {
  const formattedWeeklySchedule = getFormattedWeeklySchedule({ operatingHours });

  if (formattedWeeklySchedule && formattedWeeklySchedule !== 0) {
    const scheduleList = () => {
      if (formattedWeeklySchedule.length === 1) {
        return (
          <p
            aria-label={'agent-schedule'}
            css={[styles.agentBody, ...cssOverrides]}
          >
            {formattedWeeklySchedule[0]}
          </p>
        );
      }

      return (
        <ul
          aria-label={'agent-schedule'}
          css={[styles.list, ...cssOverrides]}
        >
          {formattedWeeklySchedule.map((string) => (
            <li
              css={styles.body}
              key={string.substring(0, 3)}
            >
              {string}
            </li>))}
        </ul>
      );
    };

    return (
      <div data-testid={'agent-operating-hours'}>
        <p css={[styles.agentBody, ...cssOverrides]}> We’re available:</p>
        {scheduleList()}
      </div>
    );
  }

  return null;
}

export function AgentsOperatingHoursInline({ operatingHours }) {
  const formattedWeeklySchedule = getFormattedWeeklySchedule({ operatingHours });

  if (formattedWeeklySchedule && formattedWeeklySchedule !== 0) {
    return (
      <span>{formattedWeeklySchedule.join(', ')}</span>
    );
  }

  return null;
}

function getFormattedWeeklySchedule({ operatingHours }) {
  if (!operatingHours || Object.keys(operatingHours).length === 0) {
    return null;
  }

  const timeFormat = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
  const isClosed = (time) => {
    return time.toUpperCase() === 'CLOSED' || time === '';
  };

  const hasValidTimes = Object.keys(operatingHours).every((day) => {
    return (isClosed(operatingHours[day]?.startTime) || timeFormat.test(operatingHours[day]?.startTime))
      && (isClosed(operatingHours[day]?.endTime) || timeFormat.test(operatingHours[day]?.endTime));
  });

  if (!hasValidTimes) {
    ErrorReportService.reportError({
      error: new RootError({
        message: 'Agent schedule is malformed.',
        name: 'AgentsOperatingHoursError',
      }),
      additionalData: {
        operatingHours,
      },
    });
    return null;
  }

  const workweek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  const formattedWeeklySchedule = [];
  let scheduleStartDay = null;
  let scheduleEndDay = null;
  let scheduleStartTime = null;
  let scheduleEndTime = null;

  const formatAgentAvailabilityTime = (time) => {
    if (!time || isClosed(time)) { return; }
    const hour = Number(time.split(':')[0]);
    const minutes = time.split(':')[1];

    const suffix = hour >= 12 ? 'p.m.' : 'a.m.';
    const twelveHourFormat = (hour + 11) % 12 + 1;
    return minutes === '00' ? `${twelveHourFormat}\xa0${suffix}` : `${twelveHourFormat}:${minutes}\xa0${suffix}`;
  };

  const formatSchedule = (startDay, endDay, startHour, endHour) => {
    if (startDay === endDay) {
      return `${startDay}, ${formatAgentAvailabilityTime(startHour)}\xa0-\xa0${formatAgentAvailabilityTime(endHour)}\xa0ET`;
    }

    return `${startDay}\xa0-\xa0${endDay}, ${formatAgentAvailabilityTime(startHour)}\xa0-\xa0${formatAgentAvailabilityTime(endHour)}\xa0ET`;
  };

  const updateSchedule = () => {
    if (scheduleStartDay && scheduleStartTime && scheduleEndTime) {
      if (isClosed(scheduleStartTime) || isClosed(scheduleEndTime)) { return; }
      formattedWeeklySchedule.push(formatSchedule(scheduleStartDay, scheduleEndDay, scheduleStartTime, scheduleEndTime));
    }
  };

  workweek.forEach((day) => {
    const scheduled = !!operatingHours[day] && !(isClosed(operatingHours[day].startTime) || isClosed(operatingHours[day].endTime));

    const clearSchedule = () => {
      scheduleStartDay = null;
      scheduleEndDay = null;
      scheduleStartTime = null;
      scheduleEndTime = null;
    };

    const setSchedule = () => {
      scheduleStartDay = day;
      scheduleEndDay = day;
      scheduleStartTime = operatingHours[day].startTime;
      scheduleEndTime = operatingHours[day].endTime;
    };

    const handleEndOfWeek = () => {
      if (day === workweek[workweek.length - 1] && scheduled) {
        updateSchedule();
      }
    };

    if (scheduleStartDay === null && scheduled) {
      setSchedule();
    }

    if (!scheduled) {
      updateSchedule();
      clearSchedule();
      handleEndOfWeek();
      return;
    }

    if (scheduled
      && formatAgentAvailabilityTime(operatingHours[day].startTime) === formatAgentAvailabilityTime(scheduleStartTime)
      && formatAgentAvailabilityTime(operatingHours[day].endTime) === formatAgentAvailabilityTime(scheduleEndTime)) {
      scheduleEndDay = day;
      handleEndOfWeek();
      return;
    }

    updateSchedule();
    setSchedule();
    handleEndOfWeek();
  });

  return formattedWeeklySchedule;
}

const styles = StyleSheet.create({
  list: {
    paddingLeft: 24,
  },
  agentBody: {
    ...Theme.paragraph1(),
    color: Colors.nearBlack(),
    margin: 0,
  },
});

AgentsOperatingHours.propTypes = {
  cssOverrides: PropTypes.array,
  operatingHours: PropTypes.object,
};

AgentsOperatingHoursInline.propTypes = {
  operatingHours: PropTypes.object,
};
