import getMarketSupportByMarketConfiguration from '@root/joinroot.com/src/api/get-market-support-by-market-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useEffect, useState } from '@root/vendor/react';

export default function useGetMarketSupportByMarket(market) {
  const [result, setResult] = useState({
    auto: null,
    homeowners: null,
    renters: null,
  });

  const [triggerRequest] = useSafeImperativeNetworkRequest(getMarketSupportByMarketConfiguration);

  useEffect(() => {
    const request = async () => {
      if (market) {
        const requestResult = await triggerRequest({
          market,
        });

        setResult(requestResult.data);
      }
    };
    request();
  }, [market, triggerRequest]);

  return result;
}
