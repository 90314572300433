import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import styled from '@root/vendor/@emotion/styled';
import { IntentType } from '@root/gatsby-contentful-core/src/utils/theme';

export class DecorationType {
  static NONE = 'none';
  static UNDERLINE = 'underline';
  static INVERSE = 'inverse';
}

export default class Link extends Component {
  static propTypes = {
    className: PropTypes.string,
    intent: PropTypes.string,
  };

  static defaultProps = {
    intent: IntentType.PRIMARY,
    decoration: DecorationType.UNDERLINE,
  };

  render() {
    const { className, ...rest } = this.props;
    return (
      <StyledLink
        className={['Link', className]}
        {...rest}
      />
    );
  }
}

const StyledLink = styled.a(({ intent, decoration }) => {
  let color;
  let hoverColor;
  let textDecoration;
  let hoverDecoration;

  switch (intent) {
  case IntentType.SECONDARY:
    color = Colors.white();
    hoverColor = Colors.rootOrange();
    break;
  case IntentType.TERTIARY:
    color = Colors.nearBlack();
    hoverColor = Colors.rootOrange();
    break;
  case IntentType.PRIMARY:
    color = Colors.rootOrange();
    hoverColor = Colors.rootOrange();
    break;
  case IntentType.NONE:
  default:
  }

  switch (decoration) {
  case DecorationType.UNDERLINE:
    textDecoration = 'underline';
    hoverDecoration = 'none';
    break;
  case DecorationType.INVERSE:
    textDecoration = 'none';
    hoverDecoration = 'underline';
    break;
  case DecorationType.NONE:
  default:
    textDecoration = 'none';
    hoverDecoration = 'none';
  }

  return {
    color,
    transition: 'color 0.15s ease-out',
    textDecoration,

    ':hover': {
      textDecoration: hoverDecoration,
      color: hoverColor,
    },
  };
});
