// It feels like we need to have a place for some of the more global constants outside of the sections folder.
// I'm putting this here for the moment but we can talk about where we'd prefer it to be going forward.

export const TEXT_ALIGNMENT_OPTIONS = {
  CENTER: 'center',
  LEFT: 'left',
};

export const VERTICAL_IMAGE_ALIGNMENT = {
  CENTER: 'center',
  BOTTOM: 'bottom',
};
