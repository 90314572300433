import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import greenCheck from '@root/core/src/assets/animations/green-check.json';
import useLottieAnimation from '@root/core/src/hooks/use-lottie-animation';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SuccessAnimation({ size = 80, options = {} }) {
  const [animationContainer, player] = useLottieAnimation(greenCheck, {
    ...options,
  });

  useEffect(() => {
    player?.play();

    return () => {
      player?.stop();
    };
  }, [player]);

  const propStyles = {
    animationContainer: {
      height: size,
      width: size,
    },
  };

  return (
    <div
      css={styles.loaderWrap}
    >
      <div
        css={propStyles.animationContainer}
        ref={animationContainer}
        title={'Loading'}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  loaderWrap: {
    position: 'relative',
  },
});

SuccessAnimation.propTypes = {
  options: PropTypes.object,
  size: PropTypes.number,
};
