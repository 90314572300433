import * as Sentry from '@root/vendor/@sentry/browser';
import AuthService from '@root/core/src/services/auth-service';
import ConsoleService from '@root/mocks/src/console-service';
import WindowLocationService from '@root/core/src/services/window-location-service';
import env from '@root/core/src/utils/environment';
import mapUrlToEngTeam from '@root/core/src/utils/map-url-to-eng-team';
import { RootError } from '@root-common/root-errors';

export default class ErrorReportService {
  static init({ environment, options = {} }) {
    if (environment.environment !== 'local') {
      Sentry.init({
        dsn: environment.sentryDsn,
        release: environment.releaseSha,
        environment: environment.environment,
        ...options,
      });
    }
  }

  static reportError({
    error, caughtAt, additionalData = {},
  }) {
    if (env.ENVIRONMENT !== 'production') {
      ConsoleService.logError(error);
    }

    const currentUserContext = AuthService.getCurrentUserContext();
    Sentry.configureScope((scope) => {
      if (error instanceof RootError) {
        scope.setLevel(error.severity);

        scope.setTags({
          ...error.tags,
        });

        scope.setFingerprint(error.fingerprint);
      }

      if (currentUserContext.userId) {
        scope.setUser({
          id: currentUserContext.userId,
          accountId: currentUserContext.accountId,
        });
      }

      scope.setExtras({
        caughtAt,
        ...additionalData,
      });

      const team = mapUrlToEngTeam({
        hostname: WindowLocationService.hostname(),
        pathname: WindowLocationService.pathname(),
      });

      scope.setTag('team', error.team || team);
    });

    Sentry.captureException(error);
  }
}
