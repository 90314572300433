import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function MixpanelDetails({
  analyticsContext,
  isPageView,
}) {
  const containerStyling = isPageView ? styles.mixpanelDetailsPageContainer : styles.mixpanelDetailsBlockContainer;
  const details = `${analyticsContext}_VIEWED`;

  if (!environment.renderAnalyticDetailsEnabled) {
    return null;
  }

  return (
    <div css={containerStyling}>
      <span css={styles.mixpanelDetailsText}>
        {details}
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  mixpanelDetailsPageContainer: {
    textAlign: 'center',
    zIndex: 9999,
  },

  mixpanelDetailsBlockContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    textAlign: 'center',
    zIndex: 9999,
  },

  mixpanelDetailsText: {
    paddingLeft: 20,
    paddingRight: 20,
    color: Colors.nearBlack(),
    '&:hover': {
      backgroundColor: Colors.white(),
    },
  },
});

MixpanelDetails.propTypes = {
  analyticsContext: PropTypes.string.isRequired,
  isPageView: PropTypes.bool.isRequired,
};
