import { boolean, object, string } from 'yup';
import { isRequired } from '@root/gatsby-contentful-core/src/utils/schema';

export default class PrivacyActRequest {
  static initialState = {
    firstName: '',
    lastName: '',
    email: '',
    policyNumber: '',
    dateOfBirth: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    optOut: false,
    deleteData: false,
  };

  static validationSchema = object().shape({
    firstName: string().required(isRequired),
    lastName: string().required(isRequired),
    email: string().required(isRequired)
      .matches(/^[a-z\d\-.+]+@[a-z\d\-.]+\.[a-z\d]+$/i, 'Invalid email address'),
    policyNumber: string()
      .min(6, 'Policy number must be at least 6 characters')
      .max(7, 'Policy number must be at most 7 characters'),
    dateOfBirth: string()
      .matches(
        /^((?:0[1-9])|(?:1[0-2]))\/((?:0[0-9])|(?:[1-2][0-9])|(?:3[0-1]))\/((?:19|20)\d{2})$/,
        'Date of birth must be in MM/DD/YYYY'
      ),
    address1: string(),
    address2: string(),
    city: string(),
    state: string(),
    zip: string(),
    optOut: boolean(),
    deleteData: boolean(),
  });

  static additionalValidations = (values) => {
    const errors = {};

    const hasAllAddressFields = values.address1 && values.city && values.state && values.zip;

    if (!values.dateOfBirth && !values.policyNumber && !hasAllAddressFields) {
      errors.oneOfThree = 'At least one field is required';
    }

    if (!values.optOut && !values.deleteData) {
      errors.checkboxes = 'At least one option must be selected';
    }

    return errors;
  };
}
