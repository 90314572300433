import getMarketSupportConfiguration from '@root/joinroot.com/src/api/get-market-support-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useEffect, useState } from '@root/vendor/react';

export default function useGetMarketSupport(allMarkets) {
  const [result, setResult] = useState(null);

  const [triggerRequest] = useSafeImperativeNetworkRequest(getMarketSupportConfiguration);

  useEffect(() => {
    const request = async () => {
      if (allMarkets) {
        const requestResult = await triggerRequest();

        setResult(requestResult.data);
      }
    };
    request();
  }, [allMarkets, triggerRequest]);

  return result;
}
