import AgentsOutOfBusinessHoursModal from '@root/gatsby-contentful-core/src/components/agents-out-of-business-hours-modal';
import AgentsPhoneNumberModal from '@root/gatsby-contentful-core/src/components/agents-phone-number-modal';
import AgentsStateSelectionModal from '@root/gatsby-contentful-core/src/components/agents-state-selection-modal';
import AgentsUnsupportedMarketModal from '@root/gatsby-contentful-core/src/components/agents-unsupported-market-modal';
import Button, { BUTTON_VARIANT_SECONDARY } from '@root/core/src/components/button';
import PhoneIcon from '@root/gatsby-contentful-core/src/assets/components/icons/phone-icon';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useAgentSchedule from '@root/agents/src/hooks/use-agent-schedule';
import useSpeakToAgentFlow, { SpeakToAgentSteps } from '@root/agents/src/hooks/use-speak-to-agent-flow';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function AgentsCta({ onClick, isDuringBusinessHours }) {
  return (
    <>
      {isDuringBusinessHours && (
        <Button
          css={styles.button}
          iconComponent={PhoneIcon}
          onClick={onClick}
          type={'button'}
          variant={BUTTON_VARIANT_SECONDARY}
        >
          Speak to an agent
        </Button>
      )}
    </>
  );
}

export default function AgentsBanner({
  eventPrefix, isaPhoneNumber, conversionTrackingEvent,
}) {
  const {
    activeStep,
    canAdvance,
    nextStep,
    cancelFlow,
    selectedMarket,
    setSelectedMarket,
    agentsMarketSupport,
  } = useSpeakToAgentFlow({
    eventPrefix,
    isaPhoneNumber,
    conversionTrackingEvent,
  });

  const { operatingHours, isDuringBusinessHours } = useAgentSchedule();

  return (
    <>
      <AgentsStateSelectionModal
        canAdvance={canAdvance}
        isShowing={activeStep === SpeakToAgentSteps.MARKET_SELECTION}
        onChange={setSelectedMarket}
        onContinue={nextStep}
        onDismiss={cancelFlow}
        selectedMarket={selectedMarket}
      />
      <AgentsOutOfBusinessHoursModal
        isShowing={activeStep === SpeakToAgentSteps.OUT_OF_BUSINESS_HOURS}
        onDismiss={cancelFlow}
        operatingHours={operatingHours}
      />
      <AgentsPhoneNumberModal
        ctaText={'Call a Root agent'}
        eventPrefix={eventPrefix}
        isShowing={activeStep === SpeakToAgentSteps.SHOW_PHONE_NUMBER}
        onDismiss={cancelFlow}
        operatingHours={operatingHours}
        phoneNumberString={isaPhoneNumber}
        speakWithString={'a Root agent'}
      />
      <AgentsUnsupportedMarketModal
        isShowing={activeStep === SpeakToAgentSteps.UNSUPPORTED_MARKET}
        market={selectedMarket}
        onDismiss={cancelFlow}
        triageState={agentsMarketSupport}
      />
      <AgentsCta
        isDuringBusinessHours={isDuringBusinessHours}
        onClick={nextStep}
      />
    </>
  );
}

const boxShadow = `2px 4px 8px ${Colors.toRgba(Colors.black(), 0.2)}`;

const styles = StyleSheet.create({
  button: {
    border: 'none',
    padding: '8px 14px',
    width: 'auto',
    textDecoration: 'underline',
    boxShadow,
    ':hover': {
      color: Colors.rootOrange(),
      textDecoration: 'underline',
      boxShadow,
    },
    '& svg': {
      marginRight: 5,
    },
  },
});

AgentsCta.propTypes = {
  isDuringBusinessHours: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

AgentsBanner.propTypes = {
  conversionTrackingEvent: PropTypes.string,
  eventPrefix: PropTypes.string.isRequired,
  isaPhoneNumber: PropTypes.string.isRequired,
};
