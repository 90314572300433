import DividedFooterLinks from '@root/joinroot.com/src/components/footer/divided-footer-links';
import FooterBase from '@root/brand/src/components/footer-base';
import FooterDisclaimer from '@root/brand/src/components/footer-disclaimer';
import FooterIconLinks from '@root/joinroot.com/src/components/footer/footer-icon-links';
import RootLogo from '@root/brand/src/components/root-logo';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function DividedFooter() {
  return (
    <FooterBase>
      <a href={'/'}>
        <RootLogo logoStyles={styles.logo} />
      </a>
      <FooterIconLinks />
      <div>
        <DividedFooterLinks />
        <FooterDisclaimer
          containerStyles={styles.footerDisclaimer}
          onlyShowCopyright={false}
        />
      </div>
    </FooterBase>
  );
}

const styles = StyleSheet.create({
  logo: {
    display: 'block',
    marginBottom: 40,
    width: 205,
    height: 'auto',
  },
  footerDisclaimer: {
    ...Theme.bodyTiny(),
  },
});
