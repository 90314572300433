import ContentfulFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-factory';
import uuid from '@root/vendor/uuid/v4';

export default class ContentfulCustomFormFactory {
  static create(overrides = {}) {
    return {
      id: uuid(),
      sys: ContentfulFactory.createSys('customForm'),
      formId: 'example-form-id',
      ...overrides,
    };
  }
}
