export default function IconSerifQuotation() {
  return (
    <svg
      fill={'none'}
      height={'48'}
      viewBox={'0 0 48 48'}
      width={'48'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M11.599 23.0154C10.8586 23.0154 10.2416 23.1388 9.62468 23.2622C11.4756 17.3393 16.2879 12.7738 21.4704 9.07198L18.6324 5C9.99486 8.5784 0 18.0797 0 29.9254C0 38.3162 4.81234 42.018 10.982 42.018C17.1517 42.018 21.7172 37.8226 21.7172 32.3933C21.7172 26.964 17.3985 23.0154 11.599 23.0154ZM37.8818 23.0154C37.1414 23.0154 36.401 23.1388 35.7841 23.2622C37.7584 17.3393 42.5707 12.7738 47.7532 9.07198L44.9152 5C36.2776 8.5784 26.2828 18.0797 26.2828 29.9254C26.2828 38.3162 30.9717 42.018 37.2648 42.018C43.4344 42.018 48 37.8226 48 32.3933C48 26.964 43.6812 23.0154 37.8818 23.0154Z'}
        fill={'#FF5715'}
      />
    </svg>
  );
}
