export default class HubSpotPersonas {
  static AFFILIATE_MARKETER = 'Affiliate Marketer';
  static APP_SOFTWARE = 'App / Software';
  static AUTO_DEALERSHIP = 'Auto Dealership';
  static CALL_CENTER = 'Call Center';
  static COMPARISON_SHOPPING_AGGREGATOR = 'Comparison Shopping Aggregator';
  static INSURANCE_AGENCY = 'Insurance Agency';
  static INFLUENCER = 'Influencer';
  static MEDIA_BUYER_AGENCY = 'Media Buyer / Agency';
  static WEB_PUBLISHER = 'Web Publisher';
  static OTHER = 'Other';

  static PERSONA_OPTIONS = {
    [this.AFFILIATE_MARKETER]: 'persona_11',
    [this.APP_SOFTWARE]: 'persona_7',
    [this.AUTO_DEALERSHIP]: 'persona_6',
    [this.CALL_CENTER]: 'persona_10',
    [this.COMPARISON_SHOPPING_AGGREGATOR]: 'persona_3',
    [this.INSURANCE_AGENCY]: 'persona_8',
    [this.INFLUENCER]: 'persona_5',
    [this.MEDIA_BUYER_AGENCY]: 'persona_2',
    [this.WEB_PUBLISHER]: 'persona_4',
    [this.OTHER]: 'persona_9',
  };
}
