import PropTypes from '@root/vendor/prop-types';
import useSaveAttribution from '@root/attribution/src/hooks/use-save-attribution';

export default function AttributionController({ location }) {
  useSaveAttribution(location);

  return null;
}

AttributionController.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
    search: PropTypes.string,
  }).isRequired,
};
