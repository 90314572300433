import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function MyAccountIconLink() {
  const { trackClick } = useAnalytics('MY_ACCOUNT_ICON_LINK', false);

  const handleClick = () => {
    trackClick('SIGN_IN');
  };

  return (
    <a
      css={styles.logInButton}
      href={environment.accountAppBaseUrl}
      onClick={handleClick}
    >
      <svg
        aria-label={'My Account icon'}
        css={styles.userIcon}
        data-testid={'my-account-icon'}
        role={'img'}
        viewBox={'0 0 48 48'}
      >
        <circle
          cx={'24'}
          cy={'12'}
          r={'9'}
          stroke={'currentColor'}
          strokeWidth={'3'}
        />
        <path
          d={'M10 46V34C10 31.7909 11.7909 30 14 30H34C36.2091 30 38 31.7909 38 34V46'}
          stroke={'currentColor'}
          strokeWidth={'3'}
        />
      </svg>
      My Account
    </a>
  );
}

const styles = StyleSheet.create({
  logInButton: {
    ...Theme.navLink(),
    display: 'inline-flex',
    color: Colors.DEPRECATED_shark(),
    transition: 'color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 350ms cubic-bezier(0.37, 0, 0.28, 1) 200ms, opacity 350ms cubic-bezier(0.37, 0, 0.28, 1) 200ms',
    textDecoration: 'none',
    '&:hover': {
      color: Colors.rootOrange(),
    },
    '&:focus': {
      color: Colors.rootOrange(),
    },
    textAlign: 'center',
    marginRight: 0,
    marginTop: 0,
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  userIcon: {
    display: 'none',
    fill: 'none',
    height: 24,
    width: 24,
    marginRight: 10,
    ...Responsive.md({
      display: 'inline-flex',
    }),
  },
});
