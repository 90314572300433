import FooterAddress from '@root/joinroot.com/src/components/footer/footer-address';
import FooterLink from '@root/joinroot.com/src/components/footer/footer-link';
import FooterLinksColumn from '@root/joinroot.com/src/components/footer/footer-links-column';
import FooterLinksContainer from '@root/joinroot.com/src/components/footer/footer-links-container';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function DividedFooterLinks() {
  return (
    <>
      <FooterLinksContainer>
        <FooterLinksColumn columnStyles={styles.column}>
          <p css={styles.sectionHeading}>Coverage</p>
          <FooterLink
            linkText={'Get a quote'}
            url={environment.quoteAppBaseUrl}
          />
          <FooterLink
            linkText={'Auto'}
            url={'/coverage'}
          />
          <FooterLink
            linkText={'Renters'}
            url={'/renters-insurance'}
          />
          <FooterLink
            linkText={'Homeowners'}
            url={'/homeowners-insurance/'}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <p css={styles.sectionHeading}>About us</p>
          <FooterLink
            linkText={'Our company'}
            url={`${environment.incSiteBaseUrl}/company/`}
          />
          <FooterLink
            linkText={'Customer reviews'}
            url={'/customer-reviews'}
          />
          <FooterLink
            linkText={'Careers'}
            url={`${environment.incSiteBaseUrl}/careers/`}
          />
          <FooterLink
            linkText={'Blog'}
            url={'/blog'}
          />
          <FooterLink
            linkText={'Press'}
            url={`${environment.incSiteBaseUrl}/press/`}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <p css={styles.sectionHeading}>Contact us</p>
          <FooterLink
            linkText={'Customer support'}
            url={'/contact'}
          />
          <FooterLink
            linkText={'FAQ'}
            url={'/help'}
          />
          <FooterLink
            linkText={'Roadside assistance'}
            url={'/coverage/roadside-assistance'}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <p css={styles.sectionHeading}>Claims</p>
          <FooterLink
            linkText={'File a claim'}
            url={'/claims'}
          />
        </FooterLinksColumn>
      </FooterLinksContainer>
      <div
        css={styles.divider}
        data-testid={'footer-divider'}
      />
      <FooterLinksContainer>
        <FooterLinksColumn columnStyles={styles.column}>
          <FooterLink
            linkText={'Coverage'}
            url={'/coverage'}
          />
          <FooterLink
            linkText={'Referrals'}
            url={'/referrals'}
          />
          <FooterLink
            linkText={'Affiliates'}
            url={'/affiliates'}
          />
          <FooterLink
            linkText={'Contact'}
            url={'/contact'}
          />
          <FooterLink
            linkText={'Meet our app'}
            url={'/mobile-app'}
          />
          <FooterLink
            linkText={'Test drive'}
            url={'/test-drive'}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <FooterLink
            linkText={'Terms and conditions'}
            url={'/terms-and-conditions'}
          />
          <FooterLink
            linkText={'Privacy policy'}
            url={'/privacy-policy'}
          />
          <FooterLink
            linkText={'App license agreement'}
            url={'/app-eula'}
          />
          <FooterLink
            linkText={'Weather related events'}
            url={'/weather'}
          />
          <FooterLink
            linkText={'Non-weather emergencies'}
            url={'/non-weather'}
          />
          <FooterLink
            linkText={'Car insurance comparison'}
            url={'/car-insurance-comparison'}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <FooterLink
            linkText={'Quote'}
            url={'/quote'}
          />
          <FooterLink
            linkText={'Discounts'}
            url={'/discounts'}
          />
          <FooterLink
            linkText={'Company'}
            url={`${environment.incSiteBaseUrl}/company/`}
          />
          <FooterLink
            linkText={'Careers'}
            url={`${environment.incSiteBaseUrl}/careers/`}
          />
          <FooterLink
            linkText={'Sitemap'}
            url={'/sitemap'}
          />
        </FooterLinksColumn>
        <FooterLinksColumn columnStyles={styles.column}>
          <FooterAddress />
        </FooterLinksColumn>
      </FooterLinksContainer>
    </>
  );
}

const styles = StyleSheet.create({
  column: {
    ...Responsive.md({
      flexBasis: '25%',
    }),
  },
  sectionHeading: {
    color: Colors.gray50(),
    marginBottom: 10,
  },
  divider: {
    margin: '55px 0',
    borderTop: `1px solid ${Colors.gray30()}`,
  },
});
