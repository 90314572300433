import Button from '@root/core/src/components/button';
import PropTypes from '@root/vendor/prop-types';
import { P1 } from '@root/gatsby-contentful-core/src/components/text';
import { P1Styles } from '@root/gatsby-contentful-core/src/components/text-styles';
import { styled } from '@root/core/src/utils/styles';

export default function SubmissionSuccessMessage({
  inlineMessage,
  email,
  closeModal,
}) {
  return (
    <SubmissionSuccess>
      <HubspotMessage>
        {inlineMessage
          ? <div dangerouslySetInnerHTML={{ __html: inlineMessage }} />
          : <P1>The form was submitted successfully!</P1>
        }
        {inlineMessage && email && <span className={'email'}> {email}</span>}
      </HubspotMessage>
      <DismissalButton onClick={closeModal}>Okay</DismissalButton>
    </SubmissionSuccess>
  );
}

SubmissionSuccessMessage.propTypes = {
  closeModal: PropTypes.func.isRequired,
  email: PropTypes.string,
  inlineMessage: PropTypes.string,
};

const SubmissionSuccess = styled.div({
  textAlign: 'center',
});

const HubspotMessage = styled.div({
  '& *': {
    ...P1Styles,
    display: 'inline',
  },
  '& .email': {
    fontWeight: 'bold',
  },
});

const DismissalButton = styled(Button)({
  marginTop: 40,
});
