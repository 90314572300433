import AgentsPhoneNumber from '@root/agents/src/utils/agents-phone-number';
import Button from '@root/core/src/components/button';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { AgentsOperatingHours } from '@root/agents/src/components/agents-operating-hours';
import { Colors } from '@root/core/src/utils/styles';
import { H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';

export default function AgentsPhoneNumberModal({
  eventPrefix,
  onDismiss,
  isShowing,
  operatingHours,
  phoneNumberString,
}) {
  const { trackEvent } = useAnalytics(`AGENTS_${eventPrefix}`, false);

  const handleClickPhoneNumber = () => {
    trackEvent('DID_CLICK_PHONE_NUMBER',
      {
        phoneNumber: phoneNumberString,
      }
    );

    location.assign(AgentsPhoneNumber.phoneFullURL(phoneNumberString));
  };

  const telProps = {
    css: styles.phoneNumber,
    onClick: handleClickPhoneNumber,
  };

  return (
    <Modal
      renderCloseButton
      isShowing={isShowing}
      name={'joinroot-agents-phone-number-modal'}
      onClose={onDismiss}
    >
      <div css={styles.container}>
        <H3 data-testid={'non-mobile-header'}>
          Call <span {...telProps}>{AgentsPhoneNumber.formatAgentPhoneNumber(phoneNumberString)}</span> to speak with a Root agent
        </H3>
        <div css={styles.operatingHoursBlock}>
          <AgentsOperatingHours
            operatingHours={operatingHours}
          />
        </div>

        {isMobileBrowser() ? (
          <Button onClick={handleClickPhoneNumber}>
            Call a Root agent
          </Button>
        ) : (
          <Button onClick={onDismiss}>
            Close
          </Button>
        )
        }
      </div>
    </Modal>
  );
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  phoneNumber: {
    color: Colors.rootOrange(),
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  operatingHoursBlock: {
    display: 'flex',
    margin: '12px 0px 18px',
  },
});

AgentsPhoneNumberModal.propTypes = {
  eventPrefix: PropTypes.string.isRequired,
  isShowing: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  operatingHours: PropTypes.object,
  phoneNumberString: PropTypes.string.isRequired,
  speakWithString: PropTypes.string,
};

