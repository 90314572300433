import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import LinkButton from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import VideoTout from '@root/gatsby-contentful-core/src/components/sections/video-tout';
import VideoToutSection from '@root/gatsby-contentful-core/src/components/sections/video-tout-section';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { constantize } from '@root/core/src/utils/strings';

export default function VideoToutSectionBlock({ content }) {
  return (
    <div data-testid={'videoToutSectionBlock'}>
      <VideoToutSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.cmsValues.OFF_WHITE}
        body={renderRichText(content.body)}
        eyebrow={content.eyebrow}
        sectionSpacing={content.sectionSpacing}
        title={content.headline}
      >
        <VideoTout
          body={renderRichText(content.videoTout.body)}
          button={buildCallToActionButton(content.videoTout.callToActionButton)}
          id={content.videoTout.id}
          title={content.videoTout.headline}
          video={{
            autoplay: content.videoTout.videoAutoplay,
            controls: content.videoTout.videoControls,
            loop: content.videoTout.videoLoop,
            muted: content.videoTout.videoMuted,
            poster: content.videoTout.videoPoster?.gatsbyImageData.images.fallback.src,
            src: content.videoTout.video.file.url,
          }}
        />
      </VideoToutSection>
    </div>
  );
}

VideoToutSectionBlock.propTypes = {
  content: PropTypes.object.isRequired,
};

function buildCallToActionButton(button) {
  if (!button) { return; }

  return {
    id: button.id,
    type: LinkButton.types[constantize(button.buttonType)],
    text: button.buttonText,
    link: button.buttonLink,
  };
}
