export default class ContentfulFactory {
  static createSys(contentTypeId) {
    return {
      contentType: {
        sys: {
          id: contentTypeId,
        },
      },
    };
  }

  static throwIfExtraKeys(extra) {
    const extraKeys = Object.keys(extra);

    if (extraKeys.length) {
      throw new Error(`Factory has unknown keys ${extraKeys.join(', ')} which do not exist. Please remove them from the factory.`);
    }
  }
}
