import ArrowRightCircleFilledIcon from '@root/joinroot.com/src/components/icons/arrow-right-circle-filled-icon';
import GetAQuoteButton from '@root/joinroot.com/src/components/core/get-a-quote-button';
import PropTypes from '@root/vendor/prop-types';

export default function GetAQuoteButtonWithIcon({ iconStyles, ...props }) {
  return (
    <GetAQuoteButton
      icon={<ArrowRightCircleFilledIcon />}
      iconStyles={iconStyles}
      {...props}
    />
  );
}

GetAQuoteButtonWithIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
