import ContentfulCustomForm from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-form';
import PropTypes from '@root/vendor/prop-types';

export default function CoreCustomFormBlock({
  content, componentMap, analytics,
}) {
  const customForm = ContentfulCustomForm.createFromGraphqlContent(content, componentMap);

  const ComponentToRender = customForm.componentToRender;

  return (
    <ComponentToRender
      analytics={analytics}
      anchor={customForm.anchor}
    />
  );
}

CoreCustomFormBlock.propTypes = {
  analytics: PropTypes.object,
  componentMap: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  content: ContentfulCustomForm.propType(),
};
