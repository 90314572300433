import KeyCode from '@root/core/src/utils/keycode';
import PropTypes from '@root/vendor/prop-types';
import ZIndex from '@root/core/src/utils/z-index';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { createPortal } from '@root/vendor/react-dom';
import { opacityKeyframes } from '@root/brand/src/utils/animation';
import { useEffect, useState } from '@root/vendor/react';

export default function OverlayModal({
  children,
  onCancel,
  onRender,
  visible,
}) {
  const [portalNode, setPortalNode] = useState(null);

  useEffect(() => {
    if (portalNode) { return; }

    setPortalNode(document.createElement('div'));
  }, [portalNode]);

  useEffect(() => {
    const handleKeydown = (event) => {
      if (event.keyCode === KeyCode.KEYCODES.ESC) {
        onCancel();
      }
    };

    if (!portalNode) { return; }
    portalNode.dataset.testid = 'overlay-modal';

    document.body.appendChild(portalNode);
    document.addEventListener('keydown', handleKeydown, false);

    onRender && onRender(visible);

    return () => {
      portalNode.parentNode && portalNode.parentNode.removeChild(portalNode);
      document.removeEventListener('keydown', handleKeydown, false);
    };
  }, [onCancel, onRender, portalNode, visible]);

  if (!visible || !portalNode) {
    return null;
  }

  return createPortal(
    <div css={styles.container}>
      {children}
    </div>,
    portalNode,
  );
}

OverlayModal.propTypes = {
  children: PropTypes.node,
  onCancel: PropTypes.func.isRequired,
  onRender: PropTypes.func,
  visible: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  container: {
    position: 'fixed',
    overflowY: 'scroll',
    '::-webkit-scrollbar': {
      width: 0,
    },
    top: 0,
    left: 0,
    background: Colors.DEPRECATED_overlayShadow(),
    width: '100%',
    height: '100vh',
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
    animationName: opacityKeyframes,
    animationDuration: '300ms',
  },
});
