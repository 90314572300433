import InterfaceActionsMap from '@root/joinroot.com/src/models/interface-actions-map';
import Projects from '@root/core/src/models/projects';
import coreWrapRootElement, { MARKETING_PREFIX } from '@root/gatsby-contentful-core/src/utils/gatsby-core-wrap-root-element';

export default function wrapRootElement({ element }) {
  return coreWrapRootElement({
    element,
    project: Projects.JoinRoot,
    interfaceActionsMap: new InterfaceActionsMap(),
  });
}

export { MARKETING_PREFIX };
