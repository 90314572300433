import { Lottie, LottieLight } from '@root/brand/src/utils/animation';
import { useEffect, useRef, useState } from '@root/vendor/react';

export default function useLottieAnimation(animationData, options = {}) {
  const animationContainerRef = useRef();
  const [player, setPlayer] = useState(undefined);

  const loop = options.loop || false;
  const autoplay = options.autoplay || false;
  const lottie = options.lottie || !!options.useFull ? Lottie : LottieLight;

  useEffect(() => {
    if (!animationContainerRef.current) {
      return;
    }

    setPlayer(lottie.loadAnimation({
      container: animationContainerRef.current,
      animationData,
      renderer: 'svg',
      loop,
      autoplay,
    }));
  }, [animationContainerRef, animationData, loop, autoplay, lottie]);

  return [animationContainerRef, player];
}
