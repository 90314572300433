import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import ContentfulCustomForm from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-form';
import IconTout from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout';
import IconToutSection, { ICON_SIZES } from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout-section';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useInterfaceActions from '@root/gatsby-contentful-core/src/hooks/use-interface-actions';

export default function IconToutSectionBlock({
  content,
  customFormRenderer,
  trackClick,
  trackEvent,
}) {
  const CustomFormRenderer = customFormRenderer;
  const { buildCallToActionButton } = useInterfaceActions();

  return (
    <div data-testid={'iconToutSectionBlock'}>
      <IconToutSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        body={renderRichText(content.subheadRich)}
        eyebrow={content.eyebrow}
        iconSize={content.iconSize ? content.iconSize.toLowerCase() : ICON_SIZES.LARGE}
        sectionSpacing={content.sectionSpacing}
        title={content.headline}
        withGrid={false}
      >
        {content.contentfulchildren.map((iconTout) => {
          const toutButton = iconTout.callToActionButton;
          return (
            <IconTout
              body={renderRichText(iconTout.body)}
              button={buildCallToActionButton({
                button: toutButton,
                trackClick,
              })}
              eyebrow={iconTout.eyebrow}
              form={CustomFormRenderer && iconTout.form ? (
                <CustomFormRenderer
                  analytics={{
                    trackClick,
                    trackEvent,
                  }}
                  content={iconTout.form}
                />
              ) : null}
              icon={iconTout.asset?.file.url}
              iconAltText={iconTout.iconAltText}
              id={iconTout.id}
              key={iconTout.id}
              title={iconTout.headline}
            />
          );
        })}
      </IconToutSection>
    </div>
  );
}

IconToutSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    subheadRich: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    eyebrow: PropTypes.string,
    iconSize: PropTypes.string,
    headline: PropTypes.string,
    contentfulchildren: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        eyebrow: PropTypes.string,
        headline: PropTypes.string,
        body: PropTypes.object,
        asset: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }),
        iconAltText: PropTypes.string,
        form: ContentfulCustomForm.propType(),
        callToActionButton: PropTypes.shape({
          id: PropTypes.string.isRequired,
          buttonType: PropTypes.string.isRequired,
          buttonText: PropTypes.string.isRequired,
          buttonLink: PropTypes.string.isRequired,
        }),
      }).isRequired
    ).isRequired,
  }).isRequired,
  customFormRenderer: PropTypes.func,
  trackClick: PropTypes.func.isRequired,
  trackEvent: PropTypes.func,
};
