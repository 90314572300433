import HubspotFormsContext from '@root/joinroot.com/src/contexts/hubspot-forms/hubspot-forms-context';
import { useContext } from '@root/vendor/react';

export default function useHubspotForms(formId) {
  const { forms } = useContext(HubspotFormsContext);

  const [formData] = forms.filter((form) => form.id === formId);

  return formData;
}
