import PropTypes from '@root/vendor/prop-types';
import { BlogNavigation } from '@root/joinroot.com/src/components/blog/navigation-elements';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function Pagination({
  baseCategorySlug,
  currentPage,
  numberOfPages,
}) {
  const isFirst = currentPage === 1;
  const isLast = currentPage === numberOfPages;
  const prevPage = currentPage - 1 === 1 ? baseCategorySlug : `${baseCategorySlug}/${(currentPage - 1).toString()}`;
  const nextPage = `${baseCategorySlug}/${(currentPage + 1).toString()}`;

  if (numberOfPages <= 1) { return null; }
  return (
    <nav css={styles.nav}>
      {!isFirst && (
        <BlogNavigation
          rel={'prev'}
          to={prevPage}
        >
          {'< PREVIOUS'}
        </BlogNavigation>
      )}
      <ul css={styles.pageNumberContainer}>
        {[...Array(numberOfPages)].map((_, i) => {
          const pageNumber = i + 1;
          return (
            <BlogNavigation
              isActive={pageNumber === currentPage}
              key={pageNumber}
              to={pageNumber === 1 ? baseCategorySlug : `${baseCategorySlug}/${pageNumber.toString()}`}
            >
              {pageNumber}
            </BlogNavigation>
          );
        })}
      </ul>
      {!isLast && (
        <BlogNavigation
          rel={'next'}
          to={nextPage}
        >
          {'NEXT >'}
        </BlogNavigation>
      )}
    </nav>
  );
}

Pagination.propTypes = {
  baseCategorySlug: PropTypes.string.isRequired,
  currentPage: PropTypes.number.isRequired,
  numberOfPages: PropTypes.number.isRequired,
};

const styles = StyleSheet.create({
  nav: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  pageNumberContainer: {
    display: 'flex',
    margin: '0 10px',
    '& > a': {
      margin: '0 8px',
      padding: '0 2px',
    },
  },
});
