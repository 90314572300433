import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H1Display } from '@root/gatsby-contentful-core/src/components/text';
import { graphql, useStaticQuery } from 'gatsby';

export default function CarvanaEmbeddedHero() {
  const { trackClick } = useAnalytics('CARVANA_HERO', true);

  const { rightImg, eyebrowImg } = useStaticQuery(graphql`
    query {
      rightImg: file(relativePath: {eq: "partners/carvana-hero-image.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 60, height: 800, width: 1000)
        }
      },
      eyebrowImg: file(relativePath: {eq: "partners/carvana-insurance-primary-logo.png"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, quality: 60)
        }
      }
    }
  `);

  return (
    <section
      css={[
        styles.heroSection,
        BackgroundColors.getBackgroundStyle(BackgroundColors.cmsValues.WHITE),
      ]}
    >
      <div className={'wrap'}>
        <div className={'container_content'}>
          <div className={'eyebrowImage'}>
            <GatsbyImage
              alt={'Carvana Insurance built with Root'}
              image={getImage(eyebrowImg)}
            />
          </div>
          <H1Display className={'headline'}>Car coverage in just 3 clicks</H1Display>
          <BodyCopy
            className={'body'}
            content={'Carvana and Root teamed up to help you get on the road even faster. After purchasing a car with Carvana, you can easily add insurance to your checkout to make sure your new ride is covered.'}
          />
          <div className={'container_cta'}>
            <LinkButton
              onClick={() => trackClick('SHOP_CARVANA')}
              to={'https://www.carvana.com/cars'}
              type={LinkButtonModel.types.PRIMARY}
            >
              Shop Carvana
            </LinkButton>
          </div>
        </div>
        <div className={'container_image'}>
          <GatsbyImage
            alt={'Carvana Tower with car and Root badge'}
            image={getImage(rightImg)}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: '50% 50%',
            }}
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </div>
      </div>
    </section >
  );
}

const styles = StyleSheet.create({
  heroSection: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',

    '.wrap': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      maxWidth: 1200,

      ...Responsive.lg({
        position: 'relative',
        flexDirection: 'row',
        margin: '0 0 0 30px',
        minHeight: 650,
        maxHeight: 700,
      }),

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'center',
        textAlign: 'center',
        padding: '80px 20px 20px',

        ...Responsive.lg({
          position: 'absolute',
          zIndex: 2,
          top: 0,
          left: 0,
          width: '45%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          textAlign: 'left',
          padding: 0,
        }),

        '.eyebrowImage': {
          maxWidth: 250,
          marginBottom: '30px',
        },

        '.headline, .subhead': {
          maxWidth: 550,

          ...Responsive.md({
            maxWidth: 800,
          }),
        },

        '.subhead': {
          color: Colors.gray50(),
          fontWeight: 400,
        },

        '.body': {
          maxWidth: 450,
        },

        '.eyebrow, .headline, .subhead, .body': {
          marginBottom: 12,
        },

        '.container_cta': {
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          marginTop: 30,
          width: '100%',
          maxWidth: 260,

          '& > a + a': {
            margin: '16px 0 0 0',
          },

          ...Responsive.md({
            flexDirection: 'row',
            maxWidth: 480,

            '& > a + a': {
              margin: '0 0 0 16px',
            },
          }),

          ...Responsive.lg({
            justifyContent: 'flex-start',
          }),
        },
      },

      '.container_image': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        maxHeight: 700,

        ...Responsive.lg({
          margin: '0 0 0 35%',
          height: '100%',
        }),
      },
    },
  },
});
