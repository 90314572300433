import FacebookIcon from '@root/brand/src/components/icons/facebook-icon';
import IconLink from '@root/brand/src/components/icons/icon-link';
import InstagramIcon from '@root/brand/src/components/icons/instagram-icon';
import LinkedinIcon from '@root/brand/src/components/icons/linkedin-icon';
import PropTypes from '@root/vendor/prop-types';
import TwitterIcon from '@root/brand/src/components/icons/twitter-icon';
import YouTubeIcon from '@root/brand/src/components/icons/youtube-icon';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function FooterIconLinks({ isDarkMode }) {
  return (
    <div css={styles.iconsContainer}>
      <IconLink
        linkStyles={styles.iconLink}
        to={'https://www.facebook.com/rootinsurance/'}
      >
        <FacebookIcon
          iconStyles={isDarkMode ? styles.iconSvgWhenDarkMode : styles.iconSvg}
        />
      </IconLink>
      <IconLink
        linkStyles={styles.iconLink}
        to={'https://www.instagram.com/rootinsurance/'}
      >
        <InstagramIcon
          iconStyles={isDarkMode ? styles.iconSvgWhenDarkMode : styles.iconSvg}
        />
      </IconLink>
      <IconLink
        linkStyles={styles.iconLink}
        to={'https://twitter.com/rootinsurance/'}
      >
        <TwitterIcon
          iconStyles={isDarkMode ? styles.iconSvgWhenDarkMode : styles.iconSvg}
        />
      </IconLink>
      <IconLink
        linkStyles={styles.iconLink}
        to={'https://linkedin.com/company/rootinsurance/'}
      >
        <LinkedinIcon
          iconStyles={isDarkMode ? styles.iconSvgWhenDarkMode : styles.iconSvg}
        />
      </IconLink>
      <IconLink
        linkStyles={styles.iconLink}
        to={'https://www.youtube.com/c/RootInsurance'}
      >
        <YouTubeIcon
          iconStyles={[
            isDarkMode ? styles.iconSvgWhenDarkMode : styles.iconSvg,
            styles.ytIconSvg,
          ]}
        />
      </IconLink>
    </div>
  );
}

FooterIconLinks.propTypes = {
  isDarkMode: PropTypes.bool,
};

const styles = StyleSheet.create({
  iconsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    maxWidth: 320,
    marginBottom: 40,
  },
  iconLink: {
    height: 30,
    '&:last-of-type': {
      marginRight: 0,
    },
    '&:hover svg': {
      fill: Colors.rootOrange(),
    },
    '&:focus svg': {
      fill: Colors.rootOrange(),
    },
  },
  iconSvg: {
    fill: '#c4c4c4',
    transition: 'fill 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
    width: 26,
  },
  iconSvgWhenDarkMode: {
    fill: Colors.gray60(),
    transition: 'fill 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
    width: 26,
  },
  ytIconSvg: {
    width: 30,
  },
});
