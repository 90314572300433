import Button from '@root/core/src/components/button';
import HubspotForm from '@root/joinroot.com/src/components/forms/hubspot-form-generation/hubspot-form';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import SubmissionSuccessMessage from '@root/joinroot.com/src/components/forms/hubspot-form-generation/submission-success-message';
import styled from '@root/vendor/@emotion/styled';
import useHubspotForms from '@root/joinroot.com/src/hooks/use-hubspot-forms';
import { toKebabCase } from '@root/core/src/utils/strings';

export default function HubspotFormModalButton({
  modal,
  children,
  variant = 'primary',
  onClick: onClickProp = () => {},
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { formId, fallbackUrl } = modal.linkedContent;
  const formData = useHubspotForms(formId);

  const handleButtonClick = () => {
    onClickProp();
    setIsModalOpen(true);
  };

  return (
    <>
      <ModalButton
        onClick={handleButtonClick}
        variant={variant}
      >
        {children}
      </ModalButton>
      <Modal
        isShowing={isModalOpen}
        name={toKebabCase(formData.name)}
        onClose={() => setIsModalOpen(false)}
      >
        <HubspotForm
          closeModal={() => setIsModalOpen(false)}
          fallbackUrl={fallbackUrl}
          formData={formData}
          successMessageComponent={SubmissionSuccessMessage}
        />
      </Modal>
    </>
  );
}

HubspotFormModalButton.propTypes = {
  children: PropTypes.string.isRequired,
  modal: PropTypes.shape({
    linkedContent: PropTypes.shape({
      formId: PropTypes.string.isRequired,
      fallbackUrl: PropTypes.string.isRequired,
    }),
  }),
  onClick: PropTypes.func,
  variant: PropTypes.string,
};

const ModalButton = styled(Button)({
  width: 'auto',
  paddingInline: '30px',
});
