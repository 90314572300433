import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import { CategoryTag } from '@root/joinroot.com/src/components/blog/navigation-elements';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H4, P1, Link as StyledLink } from '@root/gatsby-contentful-core/src/components/text';

export default function BlogPostPreviewTile({
  categorySlug,
  categoryTitle,
  dateCreated,
  previewBody,
  previewImage,
  previewTitle,
  imageAltText,
  slug,
  layout = TileLayoutModel.STACKED,
}) {
  const LAYOUT_STYLES_MAP = {
    SLIDING: slidingLayoutStyles,
    STACKED: stackedLayoutStyles,
  };

  const styles = LAYOUT_STYLES_MAP[layout];

  return (
    <div
      className={'tile'}
      css={styles.tile}
      data-testid={'tile'}
    >
      <Link to={`/blog${slug}`}>
        <GatsbyImage
          alt={imageAltText ?? ''}
          css={styles.img}
          image={getImage(previewImage)}
        />
      </Link>
      <div css={styles.textContent}>
        {categoryTitle &&
          <CategoryTag
            css={styles.categoryTag}
            to={`/blog/tag${categorySlug}`}
          >
            {categoryTitle}
          </CategoryTag>
        }
        <P1 css={styles.date}>{dateCreated}</P1>
        <Link to={`/blog${slug}`}>
          <H4 css={styles.title}>{previewTitle}</H4>
        </Link>
        <P1 css={styles.body}>{previewBody} <StyledLink to={`/blog${slug}`}>Read&nbsp;more</StyledLink></P1>
      </div>
    </div>
  );
}

BlogPostPreviewTile.propTypes = {
  categorySlug: PropTypes.string,
  categoryTitle: PropTypes.string,
  dateCreated: PropTypes.string.isRequired,
  imageAltText: PropTypes.string,
  layout: PropTypes.oneOf(Object.values(TileLayoutModel)),
  previewBody: PropTypes.string.isRequired,
  previewImage: PropTypes.object.isRequired,
  previewTitle: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

const baseStyles = {
  textContent: {
    paddingRight: 10,
    '& > * + *': {
      marginTop: 8,
    },
    '& a': {
      textDecoration: 'none',
    },
  },
  title: {
    transition: '100ms ease-in-out',
    '&:hover': {
      color: Colors.rootOrange(),
    },
  },
};

const stackedLayoutStyles = StyleSheet.create({
  ...baseStyles,
  tile: {
    margin: '0 10px 50px',
    width: 'calc(100% - 20px)',

    ...Responsive.sm({
      width: 'calc(50% - 20px)',
    }),
  },
  img: {
    marginBottom: 18,
    height: '50vw',
    ...Responsive.sm({
      height: '28vw',
    }),
    ...Responsive.lg({
      height: 320,
    }),
  },
});

// Sliding layout designed to work optimally with 3-4 tiles.
const slidingLayoutStyles = StyleSheet.create({
  ...baseStyles,
  img: {
    marginBottom: 18,
    height: 'max(30vw, 220px)',
    ...Responsive.lg({
      height: 230,
    }),
  },
});
