import * as RootSans from '@root/brand/src/assets/fonts/root-sans/emotion';
import Responsive from '@root/core/src/utils/responsive';

const headingBase = {
  marginTop: 0,
  marginBottom: 0,
  '&:only-child, &:not(:last-child)': {
    marginBottom: '0.5em',
  },
};

export default class Typography {
  static heading1 = () => ({
    ...RootSans.black(),
    ...headingBase,
    fontSize: 70,
    lineHeight: 84 / 94,

    ...Responsive.sm({
      fontSize: 94,
    }),
  });

  static heading2 = () => ({
    ...RootSans.black(),
    ...headingBase,
    fontSize: 48,
    lineHeight: 60 / 70,

    ...Responsive.sm({
      fontSize: 70,
    }),
  });

  static heading3 = () => ({
    ...RootSans.black(),
    ...headingBase,
    fontSize: 36,
    lineHeight: 42 / 48,

    ...Responsive.sm({
      fontSize: 48,
    }),
  });

  static heading4 = () => ({
    ...RootSans.black(),
    ...headingBase,
    fontSize: 28,
    lineHeight: 36 / 30,

    ...Responsive.sm({
      fontSize: 36,
    }),
  });

  static heading5 = () => ({
    ...RootSans.bold(),
    ...headingBase,
    fontSize: 20,
    lineHeight: 24 / 24,

    ...Responsive.sm({
      fontSize: 24,
    }),
  });

  static heading6 = () => ({
    ...RootSans.bold(),
    ...headingBase,
    fontSize: 18,
    lineHeight: 24 / 20,

    ...Responsive.sm({
      fontSize: 20,
    }),
  });

  static heading7 = () => ({
    ...RootSans.bold(),
    ...headingBase,
    fontSize: 18,
    lineHeight: 24 / 18,
  });

  static body1FontSize = () => ({
    fontSize: 20,
  });

  static body1LineHeight = () => ({
    lineHeight: 24 / 20,
  });

  static body1 = () => ({
    ...RootSans.regular(),
    ...Typography.body1FontSize(),
    ...Typography.body1LineHeight(),
  });

  static body2FontSize = () => ({
    fontSize: 18,

  });

  static body2LineHeight = () => ({
    lineHeight: 24 / 18,
  });

  static body2 = () => ({
    ...RootSans.regular(),
    ...Typography.body2FontSize(),
    ...Typography.body2LineHeight(),
  });

  static body3FontSize = () => ({
    fontSize: 16,
  });

  static body3LineHeight = () => ({
    lineHeight: 24 / 16,
  });

  static body3 = () => ({
    ...RootSans.regular(),
    ...Typography.body3FontSize(),
    ...Typography.body3LineHeight(),
  });

  static caption = () => ({
    ...RootSans.regular(),
    fontSize: 14,
    lineHeight: 18 / 14,
  });

  static button = () => ({
    ...RootSans.semiBold(),
    fontSize: 19,
    lineHeight: 24 / 19,
  });

  static formLarge = () => ({
    ...RootSans.regular(),
    fontSize: 18,
    lineHeight: 1,
  });

  static formNormal = () => ({
    ...RootSans.regular(),
    fontSize: 16,
    lineHeight: 1,
  });
}
