export default () => (
  <svg
    data-testid={'SVG - Root caret'}
    viewBox={'0 0 152 114'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M76 0L0 76L38 114L76 76L114 114L152 76L76 0Z'}
    />
  </svg>
);
