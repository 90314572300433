import AnalyticsService from '@root/core/src/services/analytics-service';
import CaptchaService from '@root/core/src/services/captcha-service';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import StateInformationScene from '@root/joinroot.com/src/components/sections/availability-map/state-information-scene';
import SubmissionSuccessScene from '@root/joinroot.com/src/components/sections/availability-map/submission-success-scene';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import submitLeadCaptureToBraze from '@root/core/src/api/submit-lead-capture-to-braze';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const SCENES = {
  REQUEST_EMAIL: 'request-email',
  SUBMISSION_SUCCESS: 'submission-success',
};

const sceneMap = {
  [SCENES.REQUEST_EMAIL]: StateInformationScene,
  [SCENES.SUBMISSION_SUCCESS]: SubmissionSuccessScene,
};

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

export default function MarketUnavailableForm({
  selectedMarketData, onClose, analytics, footerRichTextContent, formInputText,
}) {
  const { trackClick, trackEvent } = analytics;
  const [currentScene, setCurrentScene] = useState(SCENES.REQUEST_EMAIL);
  const [postLeadCaptureToBraze] = useImperativeNetworkRequest(submitLeadCaptureToBraze);
  const rootId = useRootId();
  const form = useForm({
    validations,
    validateOnBlur: false,
  });

  useEffect(() => {
    CaptchaService.setup();
  }, []);

  useEffect(() => {
    trackEvent(`VIEWED_${selectedMarketData.market}_MAP_MODAL`);
  }, [selectedMarketData, trackEvent]);

  const SceneToRender = sceneMap[currentScene];
  if (!SceneToRender) {
    throw new Error(`Unknown scene: ${currentScene}`);
  }

  async function handleSubmit({ email }) {
    trackClick('AUTO_UNAVAILABLE_EMAIL_FORM_SUBMIT');

    const productInterest = 'interested_in_auto';

    const captchaToken = await CaptchaService.challenge('Submit_Lead_Capture_To_Braze');

    await postLeadCaptureToBraze({
      captchaToken,
      email,
      market: selectedMarketData.market,
      lastTouchAttribution: 'AUTO_UNAVAILABLE',
      mixpanelDistinctId: AnalyticsService.getDistinctId(),
      [productInterest]: true,
      rootId,
    });

    setCurrentScene(SCENES.SUBMISSION_SUCCESS);
  }

  const modalFooter = renderRichText(footerRichTextContent);

  return (
    <div>
      <SceneToRender
        form={form}
        formInputText={formInputText}
        handleSubmit={handleSubmit}
        onClose={onClose}
        selectedMarketData={selectedMarketData}
      />
      <div>
        {modalFooter}
      </div>
    </div>
  );
}

MarketUnavailableForm.propTypes = {
  analytics: PropTypes.object.isRequired,
  footerRichTextContent: PropTypes.object,
  formInputText: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  selectedMarketData: PropTypes.object,
};
