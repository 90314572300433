import ContentfulVariationContainer from '@root/gatsby-contentful-core/src/models/contentful/contentful-variation-container.js';

export default function useContentfulVariationContainer(content) {
  const experimentId = ContentfulVariationContainer.getExperimentIdFromGraphqlContent(content);
  const [bucket] = ContentfulVariationContainer.getDynamicAbTest(experimentId);
  if (!ContentfulVariationContainer.isGraphqlContentVariationContainer(content)) { return undefined; }

  const variationContainer = ContentfulVariationContainer.createFromGraphqlContent(content);
  return variationContainer.getVariationFromBucket(bucket);
}
