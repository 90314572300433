import Button, { ButtonSize } from '@root/gatsby-contentful-core/src/components/button';
import Colors, { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';
import Icon from '@root/gatsby-contentful-core/src/components/icon';
import InputError from '@root/gatsby-contentful-core/src/components/form/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import S from 'string';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import chevronDown from '@root/gatsby-contentful-core/src/assets/icons/chevron-down.svg';
import chevronDownOrange from '@root/gatsby-contentful-core/src/assets/icons/chevron-down-orange.svg';
import styled from '@root/vendor/@emotion/styled';
import uuid from '@root/vendor/uuid/v4';
import { IntentType } from '@root/gatsby-contentful-core/src/utils/theme';

export class SelectOption extends Component {
  render() {
    return (
      <option
        className={'SelectOption'}
        {...this.props}
      />
    );
  }
}

export default class Select extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    hint: PropTypes.string,
    icon: PropTypes.object,
    id: PropTypes.string,
    intent: PropTypes.string,
    isTouched: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    size: Button.propTypes.size,
    value: PropTypes.string,
  };

  static defaultProps = {
    onChange: () => {},
    size: ButtonSize.NORMAL,
    intent: IntentType.PRIMARY,
    disabled: false,
    value: '',
  };

  state = {
    id: this.props.id || uuid(),
    value: this.props.value,
  };

  get errors() {
    return typeof this.props.errors === 'string'
      ? [this.props.errors]
      : this.props.errors;
  }

  get isInvalid() {
    return this.props.isTouched && this.props.errors;
  }

  get isValid() {
    return !this.isInvalid;
  }

  get label() {
    return this.props.label;
  }

  get inputId() {
    return S(this.props.name || this.state.id).slugify().s;
  }

  get labelId() {
    return `${this.inputId}-label`;
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({
      value,
    });
    this.props.onChange(event);
  };

  render() {
    const {
      icon, id, intent, isTouched, label, size, value, onChange, ...rest // eslint-disable-line no-unused-vars
    } = this.props;

    return (
      <StyledSelect
        className={'TextInput'}
        hasIcon={!!icon}
        hasValue={!!value}
        isDisabled={this.props.disabled}
        isInvalid={this.isInvalid}
        isReadOnly={this.props.readOnly}
        isValid={this.isValid}
        label={label}
        size={size}
      >
        <select
          {...rest}
          aria-invalid={!!this.isInvalid}
          aria-labelledby={this.labelId}
          aria-required={!!this.props.required}
          data-testid={this.inputId || this.props.name}
          id={this.inputId}
          onChange={this.handleChange}
          value={value}
        />
        <Icon
          className={'Select__indicator'}
          icon={chevronDown}
        />
        {label && (
          <label
            htmlFor={this.inputId}
            id={this.labelId}
          >{this.label} {this.props.required && !value || this.isInvalid ? <span className={'highlight'}> *</span> : ''}
          </label>
        )}
        <div className={'Input__meta'}>
          {isTouched && this.errors?.map((error) =>
            <InputError key={error}>{error}</InputError>
          )}
          {this.props.hint && this.errors.length === 0 && (
            <small
              className={'meta__hint'}
            >
              {this.props.hint}
            </small>
          )}
        </div>
      </StyledSelect>
    );
  }
}

const StyledSelect = styled.div(({
  size, intent, isDisabled, hasValue,
}) => {
  const labelYTranslation = size === ButtonSize.SMALL ? '-50%' : '-100%';
  const labelTransform = hasValue ? 'translate3d(0, ' + labelYTranslation + ', 0) scale3d(0.8, 0.8, 1)' : '';
  const inputCursor = isDisabled ? 'default' : 'auto';
  let labelTypography;
  let labelTop;
  let height;
  let backgroundColor;
  let color;

  switch (size) {
  case ButtonSize.SMALL:
    height = 48;
    labelTop = 14;
    labelTypography = Typography.formNormal();
    break;
  case ButtonSize.LARGE:
    height = 70;
    labelTop = 27;
    labelTypography = Typography.formLarge();
    break;
  case ButtonSize.NORMAL:
  default:
    height = 58;
    labelTop = 22;
    labelTypography = Typography.formNormal();
    break;
  }

  switch (intent) {
  case IntentType.PRIMARY:
  default:
    backgroundColor = Colors.white();
    color = Colors.nearBlack();
  }

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '.Select__indicator': {
      position: 'absolute',
      right: Sizes.NORMAL,
      top: 18,
      pointerEvents: 'none',
      transition: 'color 0.15s ease-out',
      color: Colors.greyDark(),
    },

    '> label': {
      boxSizing: 'border-box',
      flexOrder: 0,
      ...labelTypography,
      position: 'absolute',
      whiteSpace: 'nowrap',
      top: labelTop,
      left: Sizes.NORMAL,
      textOverflow: 'ellipsis',
      color: Colors.greyDark(),
      transformOrigin: '0 0',
      transform: labelTransform,
      pointerEvents: 'none',
    },

    '> select': {
      ...labelTypography,
      appearance: 'none',
      background: `url(${chevronDown}) no-repeat`,
      backgroundPosition: '93% center',
      backgroundSize: 18,
      height,
      color,
      backgroundColor,
      cursor: inputCursor,
      minHeight: height,
      borderRadius: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: toRgba(Colors.black(), 0.1),
      paddingBottom: 0,
      paddingTop: 10,
      paddingRight: Sizes.NORMAL,
      paddingLeft: Sizes.NORMAL,
      transition: [
        'border-color 0.15s ease-out',
        'background-color 0.15s ease-out',
      ],

      '&:not([disabled]):not([readonly])': {
        '&:active, &:hover, &:focus': {
          borderColor: Colors.nearBlack(),
          background: `url(${chevronDownOrange}) no-repeat`,
          backgroundPosition: '93% center',
          backgroundSize: 18,
        },

        '&:hover + .Select__indicator': {
          color: Colors.rootOrange(),
        },

        '&[aria-invalid="true"]': {
          borderColor: Colors.invalid(),
        },
      },

      '&[readonly]': {
        borderColor: Colors.greyMedium(),
        outline: 'none',
      },

      '&[disabled]': {
        backgroundColor: Colors.greyMedium(),
        borderColor: Colors.greyMedium(),
        outline: 'none',
      },
    },

    '.Input__meta': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: Sizes.XSMALL,
      marginBottom: 8,

      '.meta__hint': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '1 0 auto',
      },

      '.meta__count': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '0 1 0%',
        marginLeft: Sizes.XSMALL,
      },
    },
  };
});
