import PlayIndicator from '@root/joinroot.com/src/assets/images/progress/play-indicator';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import partneringVideoPoster from '@root/joinroot.com/src/assets/images/progress/partnering-video-poster.jpg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Duration, Easing } from '@root/gatsby-contentful-core/src/utils/animation';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useRef, useState } from '@root/vendor/react';

export default function PartneringVideo({
  videoFile: {
    url,
    contentType,
  },
}) {
  const { trackClick } = useAnalytics('PROGRESS_PAGE_PARTNERING_SECTION', false);
  const [hasTrackedClick, setHasTrackedClick] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handleVideoClick = () => {
    if (!hasTrackedClick) {
      setHasTrackedClick(true);
      trackClick('PLAY_VIDEO');
    }

    if (!isPlaying) {
      videoRef.current.play();
      setIsPlaying(true);
    } else if (videoRef.current.ended === true) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  const handleVideoEnd = () => {
    setIsPlaying(false);
    videoRef.current.load(); // Displays the poster image again after video ends
  };

  return (
    <div
      css={styles.videoContainer}
      data-testid={'video-container'}
      onClick={handleVideoClick}
    >
      <div css={[styles.videoOverlay, isPlaying && styles.isPlayingStyles]}>
        <PlayIndicator />
      </div>
      <video
        playsInline
        css={styles.video}
        id={'partneringVideo'}
        onEnded={handleVideoEnd}
        poster={partneringVideoPoster}
        ref={videoRef}
      >
        <source
          src={url}
          type={contentType}
        />
      </video>
    </div>
  );
}

PartneringVideo.propTypes = {
  videoFile: PropTypes.shape({
    url: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  videoContainer: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
    ...Responsive.lg({
      width: 'auto',
    }),
    svg: {
      transition: `all ${Duration.NORMAL} ${Easing.easeOutQuart}`,
    },
    '&:hover svg': {
      transform: 'scale(1.1)',
    },
  },
  videoOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: `all ${Duration.FAST} ${Easing.easeOutQuart}`,
  },
  video: {
    display: 'block',
    width: '100%',
    objectFit: 'cover',
    ...Responsive.lg({
      borderRadius: 6,
      maxWidth: 690,
    }),
  },
  isPlayingStyles: {
    opacity: 0,
    transform: 'scale(0.9)',
  },
});
