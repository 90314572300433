import { Colors } from '@root/core/src/utils/styles';

export default function PhoneIcon() {
  return (
    <svg
      fill={'none'}
      height={'18'}
      viewBox={'0 0 18 18'}
      width={'18'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M15.6443 17.5778L18.0716 15.1498C18.4049 14.8164 18.3253 14.2791 17.9423 14.003L15.0524 11.9282C14.6694 11.652 14.1073 11.7292 13.7741 12.0625L12.0058 13.8313C10.7772 12.9506 9.44914 11.8685 8.01413 10.4355C6.58161 9.00013 5.49975 7.67416 4.61935 6.44274L6.38762 4.67396C6.72088 4.3406 6.77062 3.75101 6.49456 3.3679L4.46018 0.514473C4.18661 0.131361 3.63449 0.0368269 3.30123 0.370184L0.871407 2.8007C0.871407 2.8007 0.503327 7.00248 5.97229 12.4755C11.4437 17.946 15.6443 17.5778 15.6443 17.5778Z'}
        fill={Colors.rootOrange()}
      />
    </svg>
  );
}
