import AnalyticsService from '@root/core/src/services/analytics-service';
import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function findOrCreateAbTestDynamic(dynamicAbTestConfigId, additionalParameters = {}) {
  return new NetworkRequestConfiguration({
    endpoint: 'dynamic_ab_tests',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      dynamicAbTestConfigId,
      identifier: AnalyticsService.getDistinctId(),
      ...additionalParameters,
    },
  });
}
