import AnalyticsService from '@root/core/src/services/analytics-service';
import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getJoinrootAgentsBannerPaidUrlConfiguration(partnerId) {
  const queryParameters = {
    mixpanelDistinctId: AnalyticsService.getDistinctId(),
  };
  if (partnerId) {
    queryParameters['partnerId'] = partnerId;
  }

  return new NetworkRequestConfiguration({
    endpoint: 'joinroot_agents_banner_paid_url',
    method: NetworkRequestConfiguration.Methods.GET,
    successCodes: [200],
    queryParameters,
  });
}
