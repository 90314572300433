import CTAButton from '@root/joinroot.com/src/components/core/cta-button';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';

export default function GetAQuoteButton({
  onClick, buttonStyles, ctaLink, ctaText, ...props
}) {
  const handleClick = () => {
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED);
    onClick();
  };

  const buttonText = ctaText || 'Get a quote';
  const buttonLink = ctaLink || environment.quoteAppBaseUrl;

  return (
    <CTAButton
      buttonStyles={buttonStyles}
      onClick={handleClick}
      to={buttonLink}
      {...props}
    >
      {buttonText}
    </CTAButton>
  );
}

GetAQuoteButton.propTypes = {
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  onClick: PropTypes.func,
};
