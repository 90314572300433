import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select';
import StateSVGData from '@root/joinroot.com/src/assets/data/interactive-states';
import { H5 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const allMarketIds = Object.keys(Market.MAPPING);

export default function AvailabilityMap({
  selectedMarketData, setSelectedMarketData, allMarketData, setIsModalVisible, dataTestId,
}) {
  const marketSelectorOptions = allMarketIds.map((marketId) => ({
    label: Market.MAPPING[marketId],
    value: marketId,
  }));

  const handleClick = (marketId) => {
    marketId = marketId.toUpperCase();

    const marketSupportInfo = allMarketData ? allMarketData[marketId] : null;

    const marketData = {
      market: marketId,
      auto: marketSupportInfo?.auto || false,
      renters: marketSupportInfo?.renters || false,
      homeowners: marketSupportInfo?.homeowners || false,
    };

    setSelectedMarketData(marketData);
    setIsModalVisible(true);
  };

  const handleKeyPress = (event, marketId) => {
    if (event.key === 'Enter') {
      handleClick(marketId);
    }
  };

  const stateSelectorPlaceholder = 'Select your state';

  return (
    <div
      css={styles.interactiveMapContainer}
      data-testid={dataTestId}
    >
      <svg
        css={styles.interactiveMap}
        viewBox={'0 0 980 593'}
        xmlns={'http://www.w3.org/2000/svg'}
      >
        <g
          data-testid={'availability-map'}
        >
          {StateSVGData.map((market, index) => {
            const marketIdentifier = market.id.toUpperCase();
            const autoAvailable = allMarketData && allMarketData[marketIdentifier]?.auto;
            const rentersAvailable = allMarketData && allMarketData[marketIdentifier]?.renters;
            const homeownersAvailable = allMarketData && allMarketData[marketIdentifier]?.homeowners;
            const presentInMarket = autoAvailable || rentersAvailable || homeownersAvailable;

            const className = `state-${presentInMarket ? 'available' : 'unavailable'}`;

            const isTextFloating = Boolean(market?.shape?.labelLine);
            const marketLabelCss = presentInMarket && isTextFloating ? styles.mapTextAvailableFloating : styles.mapText;

            return (
              <g
                aria-describedby={`${market.id}-info`}
                aria-label={`${Market.MAPPING[marketIdentifier]}`}
                className={className}
                css={styles.stateGroup}
                data-state-id={market.id}
                data-testid={market.id}
                key={`state-group-${index}`}
                onClick={() => handleClick(market.id)}
                onKeyPress={(event) => handleKeyPress(event, market.id)}
                role={'state'}
                tabIndex={'0'}
              >
                <path
                  className={'state'}
                  css={styles.stateCss}
                  d={market.shape.dimensions}
                />
                {isTextFloating &&
                  <path
                    css={styles.labelLine}
                    d={market.shape.labelLine.dimensions}
                    transform={'translate(0, -0.4)'}
                  />
                }
                {market.shape.labelLineShadow &&
                  <path
                    css={styles.labelLineShadow}
                    d={market.shape.labelLineShadow.dimensions}
                    transform={'translate(0, -0.4)'}
                  />
                }
                {market.id.toUpperCase() === 'DC' &&
                  <circle
                    css={styles.stateCss}
                    cx={market.shape.circleDimensions.cx}
                    cy={market.shape.circleDimensions.cy}
                    opacity={'1'}
                    r={market.shape.circleDimensions.r}
                    stroke={`${Colors.white()}`}
                    strokeWidth={'1.5'}
                  />
                }
                <text
                  css={marketLabelCss}
                  transform={`${market.shape.labelTransform}`}
                >
                  {market.id.toUpperCase()}
                </text>
              </g>
            );
          })}
        </g>
      </svg>
      <Legend />
      <div css={styles.stateSelectContainer}>
        <Select
          inputId={'map-state-select'}
          inputName={'map-state-select'}
          onChange={(marketId) => {
            handleClick(marketId);
          }}
          options={marketSelectorOptions}
          placeholder={stateSelectorPlaceholder}
          selectBoxStyles={styles.stateSelector}
          selectedValue={selectedMarketData?.market || ''}
          wrapperStyles={styles.stateSelector}
        />
      </div>
    </div>
  );
}

AvailabilityMap.propTypes = {
  allMarketData: PropTypes.object,
  dataTestId: PropTypes.string,
  selectedMarketData: PropTypes.object.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  setSelectedMarketData: PropTypes.func.isRequired,
};

const Legend = () => {
  const legendItems = [
    {
      label: 'Available',
      iconColor: Colors.rootOrange(),
    },
    {
      label: 'Unavailable',
      iconColor: Colors.grayLight(),
    },
  ];
  return (
    <div
      css={styles.legendContainer}
      data-testid={'legend-container'}
    >
      {legendItems.map((item) => (
        <div
          css={styles.legendItem}
          key={item.label}
        >
          <svg
            fill={'none'}
            height={'22'}
            viewBox={'0 0 22 22'}
            width={'22'}
            xmlns={'http://www.w3.org/2000/svg'}
          >
            <rect
              fill={item.iconColor}
              height={'20'}
              width={'20'}
            />
          </svg>
          <H5 css={styles.legendText}>{item.label}</H5>
        </div>
      ))
      }
    </div>
  );
};

const styles = StyleSheet.create({
  interactiveMapContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 1400,
    ...Responsive.md({
      position: 'relative',
    }),
  },
  interactiveMap: {
    width: '100%',
    maxWidth: 940,
    height: 'auto',
    overflow: 'visible',
  },
  stateGroup: {
    pointerEvents: 'all',
    textDecoration: 'none',
    outline: 'none',
    cursor: 'pointer',
  },
  stateCss: {
    strokeWidth: 1,
    '.state-available &': {
      fill: Colors.rootOrange(),
    },
    '.state-available:hover &, .state-available:focus &': {
      fill: '#FC7B45',
    },
    '.state-available:active &': {
      fill: Colors.white(),
      stroke: '#FC7B45',
    },
    '.state-unavailable &': {
      fill: Colors.grayLight(),
    },
    '.state-unavailable:hover &, .state-unavailable:focus &': {
      fill: Colors.white(),
      stroke: Colors.nearBlack(),
    },
    '.state-unavailable:active &': {
      fill: Colors.white(),
      stroke: '#FC7B45',
    },
  },
  mapText: {
    textAnchor: 'middle',
    color: Colors.nearBlack(),
    fontSize: 20,
    fontWeight: 400,
    '.state-unavailable:active &, .state-available:active &': {
      fill: Colors.rootOrange(),
    },
    '.state-unavailable &': {
      fill: Colors.nearBlack(),
    },
    '.state-available &': {
      fill: Colors.white(),
    },
    '.state-available:active &': {
      fill: Colors.rootOrange(),
    },
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  mapTextAvailableFloating: {
    fill: Colors.rootOrange(),
    textAnchor: 'middle',
    fontSize: 20,
    fontWeight: 400,
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  labelLine: {
    strokeWidth: 0.75,
    '.state-available &': {
      stroke: Colors.rootOrange(),
    },
    '.state-unavailable &': {
      stroke: Colors.nearBlack(),
    },
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  labelLineShadow: {
    stroke: Colors.white(),
    strokeWidth: 0.75,
    ...Responsive.lessThanSm({
      display: 'none',
    }),
  },
  stateSelectContainer: {
    marginTop: 30,
    width: '100%',
    maxWidth: 640,
    ...Responsive.md({
      display: 'none',
    }),
  },
  legendContainer: {
    marginTop: 40,
    maxWidth: 500,
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  legendItem: {
    display: 'flex',
  },
  legendText: {
    fontSize: 18,
    fontStyle: 'normal',
    lineHeight: '120%',
    marginLeft: 10,
  },
  stateSelector: {
    height: '50px',
  },
});
