import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import ZIndex from '@root/core/src/utils/z-index';
import { AnimationStyleSheet, Colors, StyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';
import { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';

export default function ModalAnimator({ children }) {
  return (
    <TransitionGroup>
      {children && (
        <CSSTransition
          classNames={backgroundAnimationClassNames}
          in={!!children}
          key={`${children}-background`}
          timeout={300}
        >
          <div css={styles.background} />
        </CSSTransition>
      )}
      {children && (
        <CSSTransition
          classNames={animationClassNames}
          in={!!children}
          key={`${children}-card`}
          timeout={400}
        >
          {children}
        </CSSTransition>
      )}
    </TransitionGroup>
  );
}

const styles = StyleSheet.create({
  background: {
    height: '100vh',
    width: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    background: toRgba(Colors.black(), 0.4),
    zIndex: ZIndex.MODAL_OVERLAY_BACKGROUND,
  },
});

const backgroundAnimationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: 0,
  },
  enterActive: {
    opacity: 1,
    transition: 'all 300ms ease-out',
  },
  enterDone: {
    opacity: 1,
  },
  exit: {
    opacity: 1,
  },
  exitActive: {
    opacity: 0,
    transition: 'all 300ms ease-out',
  },
  exitDone: {
    opacity: 0,
  },
});

const easeOutQuart = 'cubic-bezier(0.165, 0.85, 0.45, 1)';
const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';

const animationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transform: 'scale(0.94)',
      opacity: 0,
    }),
  },
  enterActive: {
    transition: `transform 400ms ${easeOutQuart}`,
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transition: `transform 300ms ${easeOutBack}, opacity 150ms ease-out`,
      transform: 'scale(1)',
      opacity: 1,
    }),
  },
  enterDone: {
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transform: 'scale(1)',
      opacity: 1,
    }),
  },
  exit: {
    transform: 'translateY(0%)',
    ...Responsive.sm({
      transform: 'scale(1)',
      opacity: 1,
    }),
  },
  exitActive: {
    transition: `transform 400ms ${easeOutQuart}`,
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transition: `transform 300ms ${easeOutBack}, opacity 150ms ease-out`,
      transform: 'scale(0.8)',
      opacity: 0,
    }),
  },
  exitDone: {
    transform: 'translateY(100%)',
    ...Responsive.sm({
      transform: 'scale(0.8)',
      opacity: 0,
    }),
  },
});

ModalAnimator.propTypes = {
  children: PropTypes.node,
};
