import CorePageTemplate from '@root/gatsby-contentful-core/src/components/pages/core-page-template';
import Footer from '@root/joinroot.com/src/components/footer/footer';
import NavWrapper from '@root/joinroot.com/src/components/nav/nav-wrapper';
import PropTypes from '@root/vendor/prop-types';

export default function PageTemplate({ children, ...props }) {
  return (
    <CorePageTemplate
      footer={Footer}
      nav={NavWrapper}
      {...props}
    >{children}
    </CorePageTemplate>
  );
}

PageTemplate.propTypes = {
  children: PropTypes.any,
};
