export default class HubSpotValues {
  static FORM_GUIDS = {
    AFFILIATE_ONBOARDING: '4deaf08e-c62a-48b6-9216-e169ea21e84f',
    AFFILIATE_APPLICATION: '87bf6bbd-212d-4633-ac63-b1e4c131ccbb',
  }

  static PORTAL_ID = '6215402';

  static COOKIE_NAME = 'hubspotutk';

  static HUBSPOT_AFFILIATE_DISCUSSION_URL = 'https://meetings.hubspot.com/rootinsurance/affiliate-discussion';

  static FORM_DIRECT_URL = 'https://share.hsforms.com/1TerwjsYqSLaSFuFp6iHoTw3p7u2';

  static onboardingSubmitUrl() {
    return `https://api.hsforms.com/submissions/v3/integration/submit/${HubSpotValues.PORTAL_ID}/${HubSpotValues.FORM_GUIDS.AFFILIATE_ONBOARDING}`;
  }

  static applicationFormUrl() {
    return `https://api.hsforms.com/submissions/v3/integration/submit/${HubSpotValues.PORTAL_ID}/${HubSpotValues.FORM_GUIDS.AFFILIATE_APPLICATION}`;
  }
}
