import PropTypes from '@root/vendor/prop-types';

export default function CloseIcon({ fillColor = '#ffffff', ...props }) {
  return (
    <svg
      aria-labelledby={'close-icon-title'}
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <title id={'close-icon-title'}>Close icon</title>
      <path
        clipRule={'evenodd'}
        d={'M4.27936 3L3 4.28989L10.715 12L3 19.7101L4.27936 21L12 13.2842L19.7206 21L21 19.7101L13.285 12L21 4.28989L19.7206 3L12 10.7158L4.27936 3Z'}
        fill={fillColor}
        fillRule={'evenodd'}
      />
    </svg>
  );
}

CloseIcon.propTypes = {
  fillColor: PropTypes.string,
};
