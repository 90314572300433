import Cookies from '@root/vendor/js-cookie';
import { checkStatus, fetchHelper, parseJSON } from '@root/gatsby-contentful-core/src/utils/fetch';

export default async function postSubmissionToHubspot({
  formValues,
  formId,
  portalId,
  locationData: {
    origin,
    pathname,
  },
}) {
  const fields = Object.entries(formValues).map(([key, value]) => ({
    name: key,
    value,
  }));

  const body = {
    fields,
    context: {
      hutk: Cookies.getJSON('hubspotutk'),
      pageUri: `${origin}${pathname}`,
      pageName: pathname,
    },
  };

  await fetchHelper(`https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formId}`, {
    method: 'POST',
    body: JSON.stringify(body),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON);
}
