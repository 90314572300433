import AlternateSizeGatsbyImage from '@root/gatsby-contentful-core/src/components/alternate-size-gatsby-image';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Eyebrow, H1Display, H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { TEXT_ALIGNMENT_OPTIONS } from '@root/gatsby-contentful-core/src/constants/constants';
import { getImage } from 'gatsby-plugin-image';

export default function HeroImageFull({
  anchor,
  body,
  textAlignment = TEXT_ALIGNMENT_OPTIONS.CENTER,
  buttons,
  eyebrow,
  gatsbyImage,
  mobileImage,
  imageAlt,
  headline,
  styleProps,
  subhead,
  sectionSpacing = {},
}) {
  const renderButtons = buttons?.length > 0;

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        stylesBase.heroSection,
        textAlignment === TEXT_ALIGNMENT_OPTIONS.LEFT && stylesAlignmentLeft.heroSection,
        sectionSpacingStyles,
        styleProps,
      ]}
      id={anchor}
    >
      <div className={'wrap_image'}>
        <AlternateSizeGatsbyImage
          className={'bgImage'}
          desktop={getImage(gatsbyImage)}
          imageAltText={imageAlt || ''}
          mobile={getImage(mobileImage)}
          styles={stylesBase.bgImage}
        />
      </div>
      <div className={'wrap_content'}>
        <div className={'container_content'}>
          {eyebrow && <Eyebrow className={'eyebrow'}>{eyebrow}</Eyebrow>}
          {headline && <H1Display className={'headline'}>{headline}</H1Display>}
          {subhead && <H3 className={'subhead'}>{subhead}</H3>}
          <BodyCopy
            className={'body'}
            content={body}
          />
          {renderButtons && (
            <div className={'container_cta'}>
              {buttons.map((button, i) => {
                const ButtonComponent = button;
                return <ButtonComponent key={i} />;
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

HeroImageFull.propTypes = {
  anchor: PropTypes.string,
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  buttons: PropTypes.arrayOf(PropTypes.func),
  eyebrow: PropTypes.string,
  gatsbyImage: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  mobileImage: PropTypes.object,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  styleProps: PropTypes.object,
  subhead: PropTypes.string,
  textAlignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENT_OPTIONS)),
};

const stylesAlignmentLeft = StyleSheet.create({
  heroSection: {

    '.wrap_content .container_content': {
      ...Responsive.md({
        alignItems: 'flex-start',
        textAlign: 'left',

        '.container_cta': {
          ...Responsive.md({
            justifyContent: 'flex-start',
          }),
        },
      }),
    },
  },
});

const stylesBase = StyleSheet.create({
  heroSection: {
    position: 'relative',

    '.wrap_image': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
    },

    '.wrap_content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: 'calc(100vh - 50px)',

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1200,
        padding: 20,
        textAlign: 'center',

        ...Responsive.md({
          padding: '100px 20px',
        }),

        '.eyebrow': {
          maxWidth: 350,
        },

        '.headline, .subhead': {
          maxWidth: 550,

          ...Responsive.md({
            maxWidth: 700,
          }),
        },

        '.body': {
          maxWidth: 550,
          p: {
            color: 'white',
          },
        },

        '.subhead': {
          fontWeight: 400,
        },

        '.eyebrow, .headline, .subhead, .body': {
          marginBottom: 12,
          color: 'white',
          textShadow: '0 0 10px hsla(0, 0%, 0%, 0.3)',
        },

        '.container_cta': {
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30,
          width: '100%',
          maxWidth: 260,

          '& > a + a': {
            margin: '16px 0 0 0',
          },

          ...Responsive.md({
            justifyContent: 'center',
            flexDirection: 'row',
            maxWidth: 480,

            '& > a + a': {
              margin: '0 0 0 16px',
            },
          }),
        },
      },
    },
  },
  bgImage: {
    height: '100%',
  },
});
