import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function StickyFooter({
  isVisible, ctaLink, ctaText,
}) {
  const { trackClick } = useAnalytics('STICKY_FOOTER', isVisible);

  const handleClick = () => {
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED);
    trackClick('GET_A_QUOTE_BUTTON');
  };

  const buttonLink = ctaLink || environment.quoteAppBaseUrl;
  const buttonText = ctaText || 'Get a quote';

  return (
    <div
      css={[
        stylesStickyFooter.baseStyles,
        isVisible && stylesStickyFooter.showFooter,
      ]}
      data-testid={'sticky-footer'}
    >
      <LinkButton
        buttonStyles={buttonStyles}
        onClick={handleClick}
        tabIndex={isVisible ? 0 : -1}
        to={buttonLink}
        type={LinkButtonModel.types.PRIMARY}
      >
        {buttonText}
      </LinkButton>
    </div>
  );
}

StickyFooter.propTypes = {
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isVisible: PropTypes.bool.isRequired,
};

const stylesStickyFooter = StyleSheet.create({
  baseStyles: {
    position: 'fixed',
    zIndex: 9,
    bottom: 0,
    left: 0,
    width: '100%',
    height: 50,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'white',
    boxShadow: '0 0 64px -16px rgba(0, 0, 0, 0.25)',
    transition: 'transform 600ms cubic-bezier(0.19, 1, 0.22, 1)',
    transform: 'translateY(100%)',
  },

  showFooter: {
    transform: 'translateY(0)',
  },
});

const buttonStyles = {
  height: 36,
  '&:focus': {
    boxShadow: `0 0 0 3px ${Colors.toRgba(Colors.rootOrange(), 0.3)}`,
  },
};
