import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

const generateDeepLinkConfiguration = ({ rootId }) => {
  return new NetworkRequestConfiguration({
    endpoint: 'deeplink_url/app_link_for_unauthenticated_user',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      rootId,
    },
    successCodes: [201],
    errorCodes: [422],
  });
};

export default generateDeepLinkConfiguration;
