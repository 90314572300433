import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

function NavBar({ children, isDarkMode }) {
  return (
    <div css={[styles.navBar, isDarkMode && stylesWhenDarkMode]}>
      {children}
    </div>
  );
}

const stylesWhenDarkMode = {
  boxShadow: 'none',
};

const styles = StyleSheet.create({
  navBar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 49,
    padding: '0 15px',
    boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
    ...Responsive.md({
      height: 59,
      padding: '0 25px',
      marginBottom: 1,
    }),
    ...Responsive.lg({
      marginBottom: 0,
    }),
  },
});

NavBar.propTypes = {
  children: PropTypes.node,
  isDarkMode: PropTypes.bool,
};

export default NavBar;
