import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import styled from '@root/vendor/@emotion/styled';

export const IconSize = {
  NORMAL: 24,
  LARGE: 48,
};

export default class Icon extends Component {
  static propTypes = {
    icon: PropTypes.oneOfType([
      PropTypes.shape({
        id: PropTypes.string,
        viewBox: PropTypes.string,
        content: PropTypes.string,
      }),
      PropTypes.shape({
        id: PropTypes.string,
        viewBox: PropTypes.string,
        url: PropTypes.string,
        toString: PropTypes.func,
      }),
      PropTypes.string,
    ]).isRequired,
    intent: PropTypes.string,
    size: PropTypes.number,
  };

  static defaultProps = {
    size: IconSize.NORMAL,
  };

  render() {
    return (
      <StyledIcon
        className={'Icon'}
        {...this.props}
      >
        <svg
          height={this.height}
          viewBox={this.props.icon.viewBox}
          width={this.width}
        >
          <use xlinkHref={`#${this.props.icon.id}`} />
        </svg>
      </StyledIcon>
    );
  }
}

const StyledIcon = styled.span(({ size }) =>
  (
    {
      display: 'flex',
      width: size,
      height: size,
      alignItems: 'center',
      justifyContent: 'center',

      '> svg': {
        fill: 'currentColor',
        flex: '1 0 0%',
      },
    }
  )
);
