import Caret from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-caret';
import ContentfulCustomReactComponentFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-custom-react-component-factory';
import PropTypes from '@root/vendor/prop-types';
import { graphql } from 'gatsby';

export const graphQlFragment = graphql`
fragment ContentfulCustomReactComponent_fragment on ContentfulCustomReactComponent {
  sys
  id
  componentId
  anchor {
    anchorSlug
  }
}
`;

export default class ContentfulCustomReactComponent {
  constructor({
    componentId = null, componentMap = defaultComponentMap, anchor, sectionSpacing,
  } = {}) {
    this.componentId = componentId;
    this.componentMap = componentMap;
    this.componentToRender = ContentfulCustomReactComponent._getComponentToRenderFromId(componentId, componentMap);
    this.anchor = anchor;
    this.sectionSpacing = sectionSpacing;

    if (!this.componentToRender) {
      throw new Error(`No component found to render for id '${this.componentId}'.`);
    }
  }

  static createFromGraphqlContent(content = {}, componentMap) {
    return new ContentfulCustomReactComponent({
      componentId: content.componentId,
      componentMap,
      anchor: content.anchor && content.anchor.anchorSlug,
      sectionSpacing: content.sectionSpacing,
    });
  }

  static graphQlDataFactory = ContentfulCustomReactComponentFactory;

  static graphQlFragment = graphQlFragment;

  static propType() {
    return PropTypes.shape({
      id: PropTypes.string.isRequired,
      componentId: PropTypes.string.isRequired,
      anchor: PropTypes.shape({
        id: PropTypes.string,
        anchorSlug: PropTypes.string,
      }),
    }).isRequired;
  }

  static EXAMPLE_COMPONENT_ID = 'example-component-id';

  static _getComponentToRenderFromId(componentId, componentMap) {
    return componentMap && componentMap.IDS && typeof componentMap.IDS === 'object'
      && Object.values(componentMap.IDS).includes(componentId)
      && componentMap.ID_TO_COMPONENT_MAP && typeof componentMap.ID_TO_COMPONENT_MAP === 'object'
      && componentMap.ID_TO_COMPONENT_MAP[componentId];
  }
}

const defaultComponentMap = {
  IDS: {
    EXAMPLE_COMPONENT_ID: ContentfulCustomReactComponent.EXAMPLE_COMPONENT_ID,
  },
  ID_TO_COMPONENT_MAP: {
    [ContentfulCustomReactComponent.EXAMPLE_COMPONENT_ID]: Caret,
  },
};

