import Select from '@root/core/src/components/select/select';
import SelectBox from '@root/core/src/components/select/select-box';
import { SelectOption } from '@root/core/src/components/select/select-option';

export default Select;

export {
  Select,
  SelectBox,
  SelectOption,
};
