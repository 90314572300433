import ExternalLink from '@root/gatsby-contentful-core/src/components/smart-link/external-link';
import InternalLink from '@root/gatsby-contentful-core/src/components/smart-link/internal-link';
import PropTypes from '@root/vendor/prop-types';
import { isRelativeLink, useTransformedUrl } from '@root/gatsby-contentful-core/src/components/smart-link/helpers';

export default function SmartLink({
  children,
  shouldTransformUrls = true,
  onClick,
  to,
  ...props
}) {
  const transformedUrl = useTransformedUrl(to, shouldTransformUrls);

  if (isRelativeLink(transformedUrl)) {
    return (
      <InternalLink
        onClick={onClick}
        to={transformedUrl}
        {...props}
      >
        {children}
      </InternalLink>
    );
  }

  return (
    <ExternalLink
      onClick={onClick}
      to={transformedUrl || to}
      {...props}
    >
      {children}
    </ExternalLink>
  );
}

SmartLink.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  shouldTransformUrls: PropTypes.bool,
  to: PropTypes.string.isRequired,
};
