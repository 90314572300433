import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PropTypes from '@root/vendor/prop-types';
import QuoteToutSection, { MOBILE_LAYOUT } from '@root/gatsby-contentful-core/src/components/sections/quote-tout-section';
import React from '@root/vendor/react';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';

export default function QuoteToutSectionBlock({ content }) {
  return (
    <div data-testid={'quoteToutSectionBlock'}>
      <QuoteToutSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        mobileLayout={MOBILE_LAYOUT.CAROUSEL}
        quoteToutContent={content.quoteTouts.map(({ quote, userName }) => ({
          userQuote: renderRichText(quote),
          userName,
        }))}
        sectionHeadline={content.headline}
        sectionSpacing={content.sectionSpacing}
      />
    </div>
  );
}

QuoteToutSectionBlock.propTypes = {
  content: PropTypes.object.isRequired,
};
