import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function OsanoFooterLink({
  isDarkMode,
}) {
  const toggleOsanoSidebar = () => {
    if (window.Osano.cm.drawerOpen) {
      window.Osano.cm.hideDrawer('osano-cm-dom-info-dialog-open');
    } else {
      window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    }
  };

  return (
    <a
      css={[
        styles.link,
        isDarkMode && linkWhenDarkMode,
      ]}
      onClick={toggleOsanoSidebar}
    >
      Manage Cookies
    </a>
  );
}

OsanoFooterLink.propTypes = {
  isDarkMode: PropTypes.bool,
};

const styles = StyleSheet.create({
  link: {
    ...Theme.bodyRegular(),
    display: 'block',
    transition: 'color 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
    lineHeight: '29px',
    textDecoration: 'none',
    color: Colors.rootOrange(),
    cursor: 'pointer',
    ':hover': {
      color: Colors.black(),
    },
    ':focus': {
      color: Colors.black(),
    },
    '.active': {
      color: Colors.black(),
    },
    ...Responsive.md({
      fontSize: 15,
    }),
  },
});

const linkWhenDarkMode = {
  color: Colors.white(),
  ':hover': {
    color: Colors.rootOrange(),
  },
  ':focus': {
    color: Colors.rootOrange(),
  },
  '.active': {
    color: Colors.rootOrange(),
  },
};
