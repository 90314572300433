import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { useEffect, useRef, useState } from '@root/vendor/react';

export default function NavDropdown({
  titleText,
  dropdownItems,
  isNavVisible,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownStyles = isOpen ? [styles.dropdown, styles.dropdownActive] : styles.dropdown;

  const node = useRef();

  const handleClick = (e) => {
    if (!node.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    if (!isNavVisible && isOpen) {
      setIsOpen(false);
    }
  }, [isOpen, isNavVisible]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <div
      css={styles.outerContainer}
      ref={node}
    >
      <button
        css={styles.navLink}
        onClick={() => setIsOpen(!isOpen)}
      >
        {titleText}
      </button>
      <div
        css={dropdownStyles}
        data-testid={'nav-dropdown-menu'}
        role={'menu'}
      >
        {dropdownItems.map((item, index) => (
          <Link
            css={styles.menuLink}
            key={index}
            onClick={() => setIsOpen(false)}
            to={item.url}
          >
            {item.linkText}
          </Link>
        ))}
      </div>
    </div>
  );
}

const styles = StyleSheet.create({
  outerContainer: {
    display: 'block',
  },
  menuLink: {
    color: Colors.rootOrange(),
    fontSize: 16,
    fontWeight: 'normal',
    transition: 'color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    marginBottom: 5,
    whiteSpace: 'nowrap',
    '&:hover': {
      color: Colors.gray60(),
      textDecoration: 'none',
    },
    '&:focus': {
      color: Colors.gray60(),
      textDecoration: 'none',
      outline: 'none',
    },
  },
  navLink: {
    ...Theme.navLink(),
    textDecoration: 'none',
    color: Colors.DEPRECATED_shark(),
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: 'color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
    '&:hover': {
      color: Colors.rootOrange(),
    },
    '&:focus': {
      color: Colors.rootOrange(),
      outline: 'none',
    },
  },
  dropdown: {
    position: 'fixed',
    display: 'none',
    flexDirection: 'column',
    textAlign: 'left',
    background: Colors.white(),
    borderRadius: '0 0 3px 3px',
    padding: '12px 15px',
    border: `1px solid ${Colors.DEPRECATED_blackShadow()}`,
    transition: 'all 500ms cubic-bezier(.37, 0, .28, 1)',
    ...Responsive.md({
      transform: 'translate(-10px, 8px)',
    }),
    ...Responsive.lg({
      transform: 'translate(-10px, 15px)',
    }),
  },
  dropdownActive: {
    display: 'flex',
  },
});

NavDropdown.propTypes = {
  dropdownItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isNavVisible: PropTypes.bool.isRequired,
  titleText: PropTypes.string.isRequired,
};
