import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

import { StyleSheet } from '@root/core/src/utils/styles';

function IconLink({
  children, linkStyles, to,
}) {
  return (
    <a
      css={[styles.link, linkStyles]}
      href={to}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      {children}
    </a>
  );
}

const styles = StyleSheet.create({
  link: {
    display: 'inline-block',
  },
});

IconLink.propTypes = {
  children: PropTypes.element,
  linkStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  to: PropTypes.string.isRequired,
};

export default IconLink;
