import ContentfulCustomForm from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-form';
import CoreCustomFormRenderer from '@root/gatsby-contentful-core/src/components/forms/core-custom-form-renderer';
import CustomFormMap from '@root/joinroot.com/src/models/custom-form-map';
import PropTypes from '@root/vendor/prop-types';

export default function CustomFormRenderer({ content, analytics }) {
  return (
    <CoreCustomFormRenderer
      analytics={analytics}
      componentMap={CustomFormMap}
      content={content}
    />
  );
}

CustomFormRenderer.propTypes = {
  analytics: PropTypes.object,
  content: ContentfulCustomForm.propType(),
};
