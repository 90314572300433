import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function findOrCreateAbTestForAccountConfiguration(abTestName) {
  return new NetworkRequestConfiguration({
    endpoint: 'ab_tests/find_or_create_for_account',
    method: NetworkRequestConfiguration.Methods.PUT,
    bodyParameters: {
      abTestName,
    },
  });
}
