import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

// ====== NOTE ====== //
// We're moving toward deprecating this file as we're in the process of splitting its functionality into two seperate files:
// ImageToutStacked and ImageToutSliding. (the sliding variant doesn't exist at the time of writing this comment)

export default function ImageToutCard({
  body,
  button: ButtonComponent,
  image,
  title,
  id,
}) {
  const { nodeRef } = useVisibilityAnalytics(`IMAGE_TOUT_${id}`);

  return (
    <div
      css={[baseStyles.imageTout, cardStyles.imageTout]}
      ref={nodeRef}
    >
      <div css={[baseStyles.imageContainer, cardStyles.imageContainer]}>
        <GatsbyImage
          alt={image.imageAltText ?? ''}
          css={[baseStyles.image, cardStyles.image]}
          image={getImage(image.gatsbyImageData)}
        />
      </div>
      <div css={[baseStyles.textContainer, cardStyles.textContainer]}>
        {title && <H3 css={[baseStyles.title, cardStyles.title]}>{title}</H3>}
        <BodyCopy
          content={body}
          css={[baseStyles.body, cardStyles.body]}
        />
        {ButtonComponent && <ButtonComponent />}
      </div>
    </div>
  );
}

const baseStyles = StyleSheet.create({
  imageTout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingBottom: 35,
    textAlign: 'center',
    '&:last-of-type': {
      paddingBottom: 0,
    },
  },
  imageContainer: {
    width: '100%',
    marginBottom: 35,
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginTop: 0,
    marginBottom: 15,
  },
  body: {
    marginBottom: 35,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const cardStyles = StyleSheet.create({
  imageTout: {
    width: 'auto',
    minWidth: 255,
    maxWidth: 335,
    marginTop: 10,
    marginBottom: 30,
    paddingBottom: 35,
    borderRadius: 6,
    boxShadow: '0px 8px 20px rgba(0, 0, 0, 0.25)',
    backgroundColor: Colors.white(),
    '&:last-of-type': {
      marginBottom: 0,
      paddingBottom: 35,
    },
    '.sliding &': {
      marginRight: 20,
    },
    '.sliding.one-tout &': {
      marginRight: 0,
    },
    '@media screen and (min-width: 375px)': {
      minWidth: 310,
    },
    '@media screen and (min-width: 768px)': {
      '.four-touts &, .four-plus-touts &': {
        marginRight: 20,
      },
      '.four-touts &:nth-of-type(2n), .four-plus-touts &:nth-of-type(2n)': {
        marginRight: 0,
      },
      '.sliding.four-touts &, .sliding.four-plus-touts &': {
        marginRight: 20,
      },
    },
    '@media screen and (min-width: 1280px)': {
      minWidth: 335,
      '.four-plus-touts &:nth-of-type(2n)': {
        marginRight: 20,
      },
      '.four-plus-touts &:nth-of-type(3n)': {
        marginRight: 0,
      },
      '.sliding.four-plus-touts &:nth-of-type(3n)': {
        marginRight: 20,
      },
    },
    '@media screen and (min-width: 1440px)': {
      '.four-touts &:nth-of-type(2n), .four-plus-touts &:nth-of-type(3n)': {
        marginRight: 20,
      },
      '.four-touts &:nth-of-type(4n), .four-plus-touts &:nth-of-type(4n)': {
        marginRight: 0,
      },
      '.sliding.four-touts &:nth-of-type(4n), .sliding.four-plus-touts &:nth-of-type(4n)': {
        marginRight: 20,
      },
    },
  },
  image: {
    borderTopLeftRadius: 6,
    borderTopRightRadius: 6,
  },
  textContainer: {
    paddingLeft: 35,
    paddingRight: 35,
  },
});

ImageToutCard.propTypes = {
  body: PropTypes.node,
  button: PropTypes.func,
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    imageAltText: PropTypes.string,
    gatsbyImageData: PropTypes.object.isRequired,
  }).isRequired,
  title: PropTypes.node,
};
