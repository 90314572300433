import Button, { ButtonType } from '@root/gatsby-contentful-core/src/components/button';
import OverlayModal from '@root/core/src/components/overlay-modal';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PrivacyActRequestFormRetry({ onCancelPress, onRetryPress }) {
  return (
    <OverlayModal
      onCancel={onCancelPress}
      visible={true}
    >
      <div css={styles.modalContent}>
        <h5 css={styles.modalHeading}>Something went wrong.</h5>
        <p css={styles.modalText}>We were unable to process your request. Please try again.</p>
        <div css={styles.buttonContainer} >
          <Button
            css={styles.modalButton}
            onClick={onRetryPress}
            type={ButtonType.BUTTON}
          >
            Try again
          </Button>
        </div>
      </div>
    </OverlayModal>
  );
}

const styles = StyleSheet.create({
  modalContent: {
    margin: '0 5px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white(),
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    minHeight: 400,
    maxWidth: 715,
    ...Responsive.md({
      minWidth: 600,
    }),
    ...Responsive.lg({
      minWidth: 715,
    }),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 35,
    marginBottom: 15,
  },
  modalHeading: {
    ...Theme.cardHeading(),
    width: '80%',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  modalText: {
    ...Theme.bodyCopy(),
    width: '80%',
    textAlign: 'center',
  },
  modalButton: {
    paddingLeft: 75,
    paddingRight: 75,
    margin: 10,
  },
});

PrivacyActRequestFormRetry.propTypes = {
  onCancelPress: PropTypes.func.isRequired,
  onRetryPress: PropTypes.func.isRequired,
};
