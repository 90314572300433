const environment = {
  accountAppBaseUrl: ENV.ACCOUNT_APP_BASE_URL,
  agentsAppBaseUrl: ENV.AGENTS_APP_BASE_URL,
  agentsISAOperatingDaysOff: ENV.AGENTS_ISA_OPERATING_DAYS_OFF?.split(' ') || [],
  agentsISAOperatingHoursEnd: ENV.AGENTS_ISA_OPERATING_HOURS_END && parseInt(ENV.AGENTS_ISA_OPERATING_HOURS_END),
  agentsISAOperatingHoursStart: ENV.AGENTS_ISA_OPERATING_HOURS_START && parseInt(ENV.AGENTS_ISA_OPERATING_HOURS_START),
  agentsQuoteBindEnabled: ENV.AGENT_QUOTE_BIND === 'true',
  announcementBannerEnabled: ENV.ANNOUNCEMENT_BANNER_ENABLED === 'true',
  apiBaseUrl: ENV.API_BASE_URL,
  apiVersion: 7,
  appsFlyerOneLinkUrl: 'https://root.onelink.me/IoYM',
  attributionEnabled: ENV.ATTRIBUTION_ENABLED === 'true',
  baseUrl: ENV.BASE_URL,
  bindAppBaseUrl: ENV.BIND_APP_BASE_URL,
  bindAppCoveredV1ExperienceEnabled: ENV.BIND_APP_COVERED_V1_EXPERIENCE_ENABLED === 'true',
  chatEnabled: ENV.CHAT_ENABLED === 'true',
  claimsAppBaseUrl: ENV.CLAIMS_APP_BASE_URL,
  claimsWebGoogleRecaptchaEnabled: ENV.CLAIMS_WEB_GOOGLE_RECAPTCHA_ENABLED === 'true',
  contentfulPreviewEnabled: ENV.CONTENTFUL_PREVIEW_ENABLED === 'true',
  dataDogApplicationId: ENV.DATA_DOG_APPLICATION_ID,
  dataDogClientToken: ENV.DATA_DOG_CLIENT_TOKEN,
  dataDogEnabled: ENV.DATA_DOG_ENABLED === 'true',
  engSiteBaseUrl: ENV.ENG_SITE_BASE_URL,
  environment: ENV.ENVIRONMENT,
  facebookAppId: ENV.FACEBOOK_APP_ID,
  googleApiKey: ENV.GOOGLE_API_KEY,
  googleOauthClientId: ENV.GOOGLE_OAUTH_CLIENT_ID,
  googlePayEnvironment: ENV.GOOGLE_PAY_ENVIRONMENT,
  googlePayMerchantId: ENV.GOOGLE_PAY_MERCHANT_ID,
  googleRecaptchaEnabled: ENV.GOOGLE_RECAPTCHA_ENABLED === 'true',
  googleRecaptchaSiteKey: ENV.GOOGLE_RECAPTCHA_SITE_KEY,
  googleTagManagerEnabled: ENV.GOOGLE_TAG_MANAGER_ENABLED === 'true',
  includeCoxGtmContainer: ENV.INCLUDE_COX_GTM_CONTAINER === 'true',
  hidePartnerEarningsEstimate: ENV.HIDE_PARTNER_EARNINGS_ESTIMATE === 'true',
  inactiveTimeout: ENV.INACTIVE_TIMEOUT && parseInt(ENV.INACTIVE_TIMEOUT),
  incSiteBaseUrl: ENV.INC_SITE_BASE_URL,
  incSiteInvestorsLinkEnabled: ENV.INC_SITE_INVESTORS_LINK_ENABLED === 'true',
  idScanApiKey: ENV.ID_SCAN_API_KEY,
  ipinfoApiKey: ENV.IPINFO_API_KEY,
  isAgentsPrefillProfileBannerEnabled: ENV.AGENTS_PREFILL_PROFILE_BANNER_ENABLED === 'true',
  isBoomerangEnabled: ENV.BOOMERANG_ENABLED === 'true',
  isBypassQuoteLandingEnabled: ENV.BYPASS_QUOTE_LANDING_ENABLED === 'true',
  isFunnelLoggingEnabled: ENV.FUNNEL_LOGGING_ENABLED === 'true',
  isGooglePayEnabled: ENV.GOOGLE_PAY_ENABLED === 'true',
  isProfileXstateEnabled: ENV.PROFILE_XSTATE_ENABLED === 'true',
  isUploadSessionFlowEnabled: ENV.UPLOAD_SESSION_FLOW_ENABLED === 'true',
  joinrootAgentsBannerEnabled: ENV.JOINROOT_AGENTS_BANNER_ENABLED === 'true',
  joinrootAgentsBannerPaidUrlEnabled: ENV.JOINROOT_AGENTS_BANNER_PAID_URL_ENABLED === 'true',
  joinrootAgentsBannerPaidUrls: ENV.JOINROOT_AGENTS_BANNER_PAID_URL_STYLES ? JSON.parse(ENV.JOINROOT_AGENTS_BANNER_PAID_URL_STYLES) : {},
  joinrootAgentsBannerUrls: ENV.JOINROOT_AGENTS_BANNER_URL_STYLES ? JSON.parse(ENV.JOINROOT_AGENTS_BANNER_URL_STYLES) : {},
  joinrootNavRedesignEnabled: ENV.JOINROOT_NAV_REDESIGN_ENABLED === 'true',
  kustomerBrandId: ENV.KUSTOMER_BRAND_ID,
  kustomerChatKey: ENV.KUSTOMER_CHAT_KEY,
  marketManagerAppBaseUrl: ENV.MARKET_MANAGER_APP_BASE_URL,
  mediaAlphaIntegrationSrc: ENV.MEDIA_ALPHA_INTEGRATION_SRC,
  minutesTillSessionInactivity: ENV.MINUTES_TILL_SESSION_INACTIVITY && parseInt(ENV.MINUTES_TILL_SESSION_INACTIVITY),
  mixpanelToken: ENV.MIXPANEL_TOKEN,
  osanoEnabled: ENV.OSANO_ENABLED,
  osanoScriptUrl: ENV.OSANO_SCRIPT_URL,
  partnersAdminAppBaseUrl: ENV.PARTNERS_ADMIN_APP_BASE_URL,
  partnersUserAppBaseUrl: ENV.PARTNERS_USER_APP_BASE_URL,
  qualtricsEnabled: ENV.QUALTRICS_ENABLED,
  quoteAppBaseUrl: ENV.QUOTE_APP_BASE_URL,
  quoteAppDomain: ENV.QUOTE_APP_BASE_URL ? new URL(ENV.QUOTE_APP_BASE_URL).host : undefined,
  quotesPollerMaxPollAttempts: ENV.QUOTES_POLLER_MAX_POLL_ATTEMPTS && parseInt(ENV.QUOTES_POLLER_MAX_POLL_ATTEMPTS),
  quotesPollerPollAttemptTimeout: ENV.QUOTES_POLLER_POLL_ATTEMPT_TIMEOUT,
  releaseSha: ENV.RELEASE_SHA,
  renderAnalyticDetailsEnabled: ENV.RENDER_ANALYTICS_DETAILS_ENABLED === 'true',
  rentersAppBaseUrl: ENV.RENTERS_APP_BASE_URL,
  rightQuoteEnabled: ENV.RIGHT_QUOTE_ENABLED === 'true',
  sentryDsn: ENV.SENTRY_DSN,
  sentryTeam: ENV.SENTRY_TEAM,
  siteSubdomain: ENV.SITE_SUBDOMAIN,
  webFnolClaimantSwitchVersion: ENV.WEB_FNOL_CLAIMANT_SWITCH_VERSION,
  webFnolPhoneUserSwitchVersion: ENV.WEB_FNOL_PHONE_USER_SWITCH_VERSION,
  websiteBaseUrl: ENV.WEBSITE_BASE_URL,
  zendeskChatKey: ENV.ZENDESK_CHAT_KEY,
};

if (typeof window !== 'undefined') {
  // we expose __environment on the window for debugging purposes
  // these variables are all public by nature, so there's no risk with exposing them
  window.__environment = environment;
}

export default environment;
