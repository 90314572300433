import React from '@root/vendor/react';

import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { StyleSheet } from '@root/core/src/utils/styles';

function FooterAddress() {
  return (
    <div css={styles.container}>
      <p
        css={styles.address}
      >Root Inc.
      </p>
      <p
        css={styles.address}
      >80 E. Rich Street
      </p>
      <p
        css={styles.address}
      >Suite 500
      </p>
      <p
        css={styles.address}
      >Columbus, OH 43215
      </p>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
  },
  address: {
    ...Theme.bodyRegular(),
    lineHeight: '29px',
    marginBottom: 0,
    ...Responsive.md({
      ...Theme.bodySmall(),
      lineHeight: '29px',
    }),
  },
});

export default FooterAddress;
