import AnalyticsService from '@root/core/src/services/analytics-service';
import CaptchaService from '@root/core/src/services/captcha-service';
import CaretLoader from '@root/core/src/components/caret-loader';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import InputWithSubmit from '@root/gatsby-contentful-core/src/components/core/input-with-submit';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import RootUnavailableFormFlow from '@root/joinroot.com/src/components/forms/root-unavailable-form-flow';
import environment from '@root/core/src/utils/environment';
import submitLeadCaptureToBraze from '@root/core/src/api/submit-lead-capture-to-braze';
import useGetMarketSupportByZipCode from '@root/joinroot.com/src/api/use-get-market-support-by-zip-code';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { EMAIL_REGEX } from '@root/core/src/utils/validators';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet, Theme } from '@root/core/src/utils/styles';
import { navigate } from 'gatsby';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

export default function HomeownersGetStartedBox({
  zipCode, onClose, analytics,
}) {
  const [marketSupport, setMarketSupport] = useState(null);
  const isLoading = useGetMarketSupportByZipCode(zipCode, useCallback((result) => {
    setMarketSupport(result.data);
  }, [setMarketSupport]));

  const isEnabledForHomeownersTieIn = marketSupport?.homeownersTieInRequired;
  const isEnabledForHomeowners = marketSupport?.homeowners;
  const isEnabledForAuto = marketSupport?.auto;
  const market = marketSupport?.market;

  if (isLoading) {
    return <div css={styles.loaderContainer}><CaretLoader /></div>;
  }

  if (isEnabledForHomeownersTieIn) {
    return (
      <HomeownersTieInEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  if (isEnabledForHomeowners) {
    return (
      <HomeownersEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  if (isEnabledForAuto) {
    return (
      <AutoEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  return (
    <RootUnavailableFormFlow
      analytics={analytics}
      market={market}
      onClose={onClose}
      product={'HOMEOWNERS'}
    />
  );
}

HomeownersGetStartedBox.propTypes = {
  analytics: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
};

function HomeownersTieInEnabled({ market, analytics }) {
  useEffect(() => {
    analytics.trackEvent('HOMEOWNERS_TIE_IN_ENABLED_VIEWED');
  }, [analytics]);

  const handleClick = () => {
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_THE_APP_BUTTON_CLICKED);
    analytics.trackClick('DOWNLOAD_THE_APP_FOR_HOMEOWNERS_TIE_IN_QUOTE_BUTTON');
  };

  return (
    <>
      <H2 css={styles.headline}>Hey there, {market}</H2>
      <P1 css={styles.paragraphBreak}>Good news. Root Home is available in your state. Start by downloading the app to save up to $900 a year on car insurance. Then bundle and save on homeowners insurance.</P1>
      <P1>Just want a homeowners policy?&nbsp;
        <Link
          css={Theme.link()}
          to={'/homeowners-insurance/home-only'}
        >
          Learn more
        </Link>.
      </P1>
      <LinkButton
        buttonStyles={styles.spaceAboveForm}
        onClick={handleClick}
        to={`${environment.appsFlyerOneLinkUrl}?pid=web_home&c=homeowners-zip-modal`}
        type={LinkButtonModel.types.PRIMARY}
      >
        Bundle and save
      </LinkButton>
    </>
  );
}

HomeownersTieInEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

function HomeownersEnabled({ market, analytics }) {
  useEffect(() => {
    analytics.trackEvent('HOMEOWNERS_ENABLED_VIEWED');
  }, [analytics]);

  const handleClick = () => {
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_THE_APP_BUTTON_CLICKED);
    analytics.trackClick('DOWNLOAD_THE_APP_FOR_HOMEOWNERS_QUOTE_BUTTON');
  };

  return (
    <>
      <H2 css={styles.headline}>Hey there, {market}</H2>
      <P1 css={styles.paragraphBreak}>Good news. Root Home is available in your state. Start by downloading the app to save up to $900 a year on car insurance. Then bundle and save on homeowners insurance.</P1>
      <P1>Already have Root car insurance? Just open the app to get your homeowners quote, or call&nbsp;
        <Link
          css={Theme.link()}
          to={'tel:1-844-208-0665'}
        >
          844-208-0665
        </Link>.
      </P1>
      <LinkButton
        buttonStyles={styles.spaceAboveForm}
        onClick={handleClick}
        to={`${environment.appsFlyerOneLinkUrl}?pid=web_home&c=homeowners-zip-modal`}
        type={LinkButtonModel.types.PRIMARY}
      >
        Get a quote
      </LinkButton>
    </>
  );
}

HomeownersEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

function AutoEnabled({ analytics, market }) {
  useEffect(() => {
    analytics.trackEvent('AUTO_ENABLED_VIEWED');
  }, [analytics]);
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [postLeadCaptureToBraze] = useImperativeNetworkRequest(submitLeadCaptureToBraze);
  const rootId = useRootId();

  useEffect(() => {
    CaptchaService.setup();
  }, []);

  async function handleSubmit() {
    setIsSubmitted(true);
    analytics.trackClick('AUTO_ENABLED_EMAIL_SUBMIT');

    const captchaToken = await CaptchaService.challenge('Submit_Lead_Capture_To_Braze');

    await postLeadCaptureToBraze({
      captchaToken,
      email,
      market: Market.marketNameToAbbreviation(market),
      last_touch_attribution: 'AUTO_AVAILABLE_HOMEOWNERS_UNAVAILABLE',
      interested_in_auto: true,
      interested_in_homeowners: true,
      mixpanel_distinct_id: AnalyticsService.getDistinctId(),
      rootId,
    });

    window.setTimeout(() => {
      navigate('/quote');
    }, 2000);
  }

  return (
    <>
      <H2 css={styles.headline}>You can save big on car insurance.</H2>
      <P1>Good news. You can save up to $900 a year on car insurance. Bundling isn’t available in your state yet, but if you provide your email, you’ll be notified when it is.</P1>
      <form
        action={'#'}
        css={[styles.form, styles.spaceAboveForm]}
        onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}
      >
        <InputWithSubmit
          autoCapitalize={'off'}
          autoComplete={'email'}
          inputType={InputWithSubmit.TextTypes.TEXT}
          isSubmittable={EMAIL_REGEX.test(email)}
          isSubmitted={isSubmitted}
          label={'Email'}
          name={'homeowners-email'}
          onChange={setEmail}
          value={email}
        />
      </form>
    </>
  );
}

AutoEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  loaderContainer: {
    width: '340px',
    height: '296px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    marginRight: 20,
    marginBottom: 16,
  },
  paragraphBreak: {
    marginBottom: 16,
  },
  spaceAboveForm: {
    marginTop: 34,
  },
  form: {
    height: 60,
  },
});

