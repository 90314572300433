import Button from '@root/core/src/components/button';
import ChevronDownIcon from '@root/gatsby-contentful-core/src/assets/components/icons/chevron-down-icon';
import Input, { TextTypes } from '@root/core/src/components/input';
import Market from '@root/core/src/models/market';
import ProductTile from '@root/joinroot.com/src/components/sections/availability-map/product-tile-availability-map';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import car from '@root/joinroot.com/src/assets/availability-map/car';
import renters from '@root/joinroot.com/src/assets/availability-map/renters';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H2, H5 } from '@root/gatsby-contentful-core/src/components/text';

const availabilityMapModalData = [
  {
    title: 'Car',
    productType: 'auto',
    link: 'https://quote.joinroot.com',
    icon: car,
  },
  {
    title: 'Renters',
    productType: 'renters',
    link: 'https://renters.joinroot.com',
    icon: renters,
  },
];

const StateInformationScene = ({
  handleSubmit, selectedMarketData, form, formInputText,
}) => {
  const allProductsAvailable = selectedMarketData.auto && selectedMarketData.renters;
  const noProductAvailable = !selectedMarketData.auto && !selectedMarketData.renters;

  return (
    <div
      className={'modalContentContainer'}
      css={styles.modalContentContainer}
      data-testid={'stateInformationSceneContainer'}
    >
      <H2>
        {Market.MAPPING[selectedMarketData.market]}
      </H2>
      <H5 css={styles.subHeadLine}>Coverage Available</H5>
      <div css={styles.productTilesContainer}>
        {availabilityMapModalData.map((product) => {
          return (
            <ProductTile
              key={product.title}
              marketData={selectedMarketData}
              product={product}
            />
          );
        })}
      </div>
      {!allProductsAvailable &&
        <ExpandableForm
          form={form}
          formInputText={formInputText}
          handleSubmit={handleSubmit}
          initialExpand={noProductAvailable}
        />
      }
    </div>
  );
};

StateInformationScene.propTypes = {
  form: PropTypes.object.isRequired,
  formInputText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  selectedMarketData: PropTypes.object.isRequired,
};

const ExpandableForm = ({
  form, handleSubmit, initialExpand, formInputText,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleArrowClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    setExpanded(initialExpand);
  }, [initialExpand]);

  return (
    <div css={styles.formContainer}>
      <div
        className={expanded ? 'expandedForm' : 'collapsedForm'}
        css={styles.expandablePanel}
        data-testid={expanded ? 'expandedForm' : 'collapsedForm'}
        onClick={handleArrowClick}
        onKeyPress={handleArrowClick}
        tabIndex={0}
      >
        <H5 css={styles.expandablePanelTitle}>{formInputText}</H5>
        <div
          css={[styles.arrow, expanded && styles.arrowRotate]}
        >
          <ChevronDownIcon
            strokeColor={expanded ? Colors.rootOrange() : Colors.black()}
          />
        </div>
      </div>
      {
        expanded &&
        <form
          css={[styles.form]}
          onSubmit={form.createSubmitHandler(handleSubmit)}
        >
          <Input
            autoFocus={true}
            id={'email'}
            inputType={TextTypes.EMAIL}
            label={'Email'}
            name={'Email'}
            {...form.getFieldProps('email')}
          />
          <Button
            className={'button'}
            disabled={form.submitted || form.submitting}
          >
            Submit
          </Button>
        </form>
      }
    </div>
  );
};

ExpandableForm.propTypes = {
  form: PropTypes.object.isRequired,
  formInputText: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  initialExpand: PropTypes.bool,
};

const styles = StyleSheet.create({
  subHeadLine: {
    marginBottom: 12,
    marginTop: 20,
    fontWeight: 600,
    fontSize: 20,
  },
  formContainer: {
    marginBottom: 30,
  },
  expandablePanel: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    cursor: 'pointer',
  },
  arrowRotate: {
    transform: 'rotate(-180deg)',
  },
  arrow: {
    transition: 'transform 150ms ease-in-out',
    '& svg path': {
      transition: '150ms ease-in-out',
    },
  },
  form: {
    marginTop: 15,
    button: {
      marginTop: 25,
    },
  },
  expandablePanelTitle: {
    fontSize: 18,
    '.expandedForm &': {
      color: Colors.rootOrange(),
    },
  },
  modalContentContainer: {
    minWidth: 250,
  },
  productTilesContainer: {
    marginBottom: 45,
  },
});

export default StateInformationScene;
