import AnnouncementBanner from '@root/joinroot.com/src/components/announcement-banner';
import GetAQuoteButton from '@root/joinroot.com/src/components/core/get-a-quote-button';
import MyAccountIconLink from '@root/joinroot.com/src/components/nav/my-account-icon-link';
import NavBar from '@root/joinroot.com/src/components/nav/nav-bar';
import NavContainer from '@root/joinroot.com/src/components/nav/nav-container';
import NavDropdownLinks from '@root/joinroot.com/src/components/nav/nav-dropdown-links';
import NavMenuButton from '@root/joinroot.com/src/components/nav/nav-menu-button';
import NavMenuMobile from '@root/joinroot.com/src/components/nav/nav-menu-mobile';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/brand/src/components/root-logo';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function MenuNav({
  isNavOpen,
  toggleNavState,
  isVisible,
  ctaLink,
  ctaText,
  isBannerOn,
  bannerContent,
  isCtaButtonDisabled,
}) {
  const { trackClick } = useAnalytics('MENU_NAV', false);

  return (
    <NavContainer
      isBannerOn={isBannerOn}
      isNavOpen={isNavOpen}
      isVisible={isVisible}
    >
      <div css={styles.wrapper}>
        {isBannerOn && <AnnouncementBanner bannerContent={bannerContent} />}
        <NavBar isBannerOn={isBannerOn}>
          <a href={'/'}>
            <RootLogo logoStyles={styles.logo} />
          </a>
          <NavMenuButton
            buttonBarStyles={styles.buttonBars}
            isNavOpen={isNavOpen}
            toggleNavState={toggleNavState}
          />
          <NavDropdownLinks isNavVisible={isVisible} />
          <div css={[styles.ctaContainer, isBannerOn && stylesWhenBanner.ctaContainer]}>
            <div css={styles.divider} />
            <MyAccountIconLink />
            {!isCtaButtonDisabled &&
              <GetAQuoteButton
                buttonStyles={styles.ctaButton}
                ctaLink={ctaLink}
                ctaText={ctaText}
                onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
              />
            }
          </div>
        </NavBar>
        <NavMenuMobile />
      </div>
    </NavContainer>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 44,
    ...Responsive.lg({
      marginBottom: 0,
    }),
  },
  logo: {
    display: 'block',
    width: 160,
  },
  buttonBars: {
    backgroundColor: Colors.black(),
  },
  ctaButton: {
    display: 'inline-flex',
    padding: '5px 17px',
    fontSize: 16,
    whiteSpace: 'nowrap',
  },
  ctaContainer: {
    display: 'none',
    ...Responsive.md({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',

      '& > * + *': {
        marginLeft: 20,
      },
    }),
  },
  divider: {
    opacity: 0,
    ...Responsive.lg({
      borderRight: `2px solid ${Colors.black()}`,
      opacity: '0.1',
      height: 34,
    }),
  },
});

const stylesWhenBanner = StyleSheet.create({
  ctaContainer: {
    ...Responsive.md({
      top: 60,
    }),
  },
});

MenuNav.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isBannerOn: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isNavOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleNavState: PropTypes.func.isRequired,
};
