import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import appStoreButton from '@root/gatsby-contentful-core/src/assets/images/app-store-button.svg';
import googlePlayButton from '@root/gatsby-contentful-core/src/assets/images/google-play-button.svg';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function PlatformButton({ platform, href }) {
  const platformImageModel = {
    IOS: appStoreButton,
    ANDROID: googlePlayButton,
  };
  const platformAltTextModel = {
    IOS: 'iOS App Store Button',
    ANDROID: 'Android Google Play Store Button',
  };

  return (
    <Link
      css={styles.link}
      to={href}
    >
      <img
        alt={platformAltTextModel[platform] ?? ''}
        css={styles.img}
        src={platformImageModel[platform]}
      />
    </Link>
  );
}

PlatformButton.propTypes = {
  href: PropTypes.string.isRequired,
  platform: PropTypes.oneOf(['IOS', 'ANDROID']).isRequired,
};

const styles = StyleSheet.create({
  link: {
    display: 'flex', // This fixes an issue where the anchor click area was larger than the contained image.
  },
  img: {
    width: '100%',
  },
});
