import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function FooterBase({ children, isDarkMode }) {
  return (
    <footer
      css={[styles.footer, isDarkMode && footerWhenDarkMode]}
      data-testid={'footer-base'}
      role={'footer'}
    >
      <div css={styles.container}>
        {children}
      </div>
    </footer>
  );
}

const styles = StyleSheet.create({
  footer: {
    backgroundColor: Colors.nearWhite(),
  },
  container: {
    padding: '40px 30px 60px',
    ...Responsive.md({
      maxWidth: 975,
      margin: '0 auto',
    }),
    ...Responsive.lg({
      padding: '80px 30px',
      maxWidth: '1280px',
    }),
  },
});

const footerWhenDarkMode = {
  backgroundColor: Colors.black(),
};

FooterBase.propTypes = {
  children: PropTypes.node,
  isDarkMode: PropTypes.bool,
};
