export default class Sizes {
  static BASE = 12;
  static NORMAL = Sizes.BASE;
  static LARGE = 2 * Sizes.BASE;
  static XLARGE = 3 * Sizes.BASE;
  static XXLARGE = 4 * Sizes.BASE;
  static SMALL = 0.75 * Sizes.BASE;
  static XSMALL = 0.5 * Sizes.BASE;
  static XXSMALL = 1 / 3 * Sizes.BASE;
}
