import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import IconToutMini from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout-mini';
import IconToutSideBySideSection, {
  DIVIDER_POSITIONS,
  ICON_SIZES,
  TOUT_LAYOUTS,
  VERTICAL_TEXT_ALIGNMENTS,
} from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout-side-by-side-section';
import LinkButton from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { constantize } from '@root/core/src/utils/strings';

const GRID_VIEW_TOUT_LAYOUT = 'GRID_VIEW';

export default function IconToutSideBySideSectionBlock({ content, trackClick }) {
  return (
    <div data-testid={'iconToutSideBySideSectionBlock'}>
      <IconToutSideBySideSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        body={renderRichText(content.body)}
        button={buildIconToutSideBySideButton(content.callToActionButton, trackClick)}
        dividerPosition={buildDividerPosition(content.dividerPosition)}
        eyebrow={content.eyebrow}
        iconSize={content.iconSize ? content.iconSize.toLowerCase() : ICON_SIZES.LARGE}
        sectionSpacing={content.sectionSpacing}
        title={content.headline}
        toutLayout={buildToutLayout(content.iconToutFormat)}
        verticalTextAlignment={VERTICAL_TEXT_ALIGNMENTS.CENTER}
      >
        {content.contentfulchildren.map((iconTout) => {
          return (
            <IconToutMini
              body={renderRichText(iconTout.body)}
              eyebrow={iconTout.eyebrow}
              icon={iconTout.asset.file.url}
              iconAltText={iconTout.iconAltText}
              id={iconTout.id}
              key={iconTout.id}
              styleAsListItem={constantize(content.iconToutFormat) !== GRID_VIEW_TOUT_LAYOUT}
              title={iconTout.headline}
            />
          );
        })}
      </IconToutSideBySideSection>
    </div>
  );
}

IconToutSideBySideSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    body: PropTypes.object,
    callToActionButton: PropTypes.object,
    dividerPosition: PropTypes.string,
    eyebrow: PropTypes.string,
    iconSize: PropTypes.string,
    headline: PropTypes.string,
    iconToutFormat: PropTypes.oneOf([
      'Grid View',
      'Stacked List',
    ]).isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    contentfulchildren: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        eyebrow: PropTypes.string,
        headline: PropTypes.string,
        body: PropTypes.object,
        asset: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        iconAltText: PropTypes.string,
        callToActionButton: PropTypes.shape({
          id: PropTypes.string.isRequired,
          buttonType: PropTypes.string.isRequired,
          buttonText: PropTypes.string.isRequired,
          buttonLink: PropTypes.string.isRequired,
        }),
      }).isRequired
    ).isRequired,
  }).isRequired,
  trackClick: PropTypes.func.isRequired,
};

function buildToutLayout(toutLayout) {
  if (!toutLayout) { return TOUT_LAYOUTS.LIST; }

  const toutLayoutMapping = {
    GRID_VIEW: TOUT_LAYOUTS.GRID,
    STACKED_LIST: TOUT_LAYOUTS.LIST,
  };

  return toutLayoutMapping[constantize(toutLayout)];
}

function buildDividerPosition(dividerPosition) {
  return DIVIDER_POSITIONS[constantize(dividerPosition)] || DIVIDER_POSITIONS.LEFT;
}

function buildIconToutSideBySideButton(button, trackClick) {
  if (!button) { return; }

  const {
    buttonText, buttonLink, id, buttonType,
  } = button;

  const trackButtonClick = () => trackClick(`CTA_BUTTON_${id}`, {
    buttonText,
    buttonLink,
  });

  return {
    id,
    type: LinkButton.types[constantize(buttonType)],
    onClick: trackButtonClick,
    text: buttonText,
    link: buttonLink,
  };
}
