import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H4, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ProductTile({
  productIsAvailable,
  img,
  alt,
  title,
  marketNotSelected,
  availableInMarket,
  notAvailableInMarket,
}) {
  const MessageToRender = () => {
    if (productIsAvailable === null) {
      return marketNotSelected;
    }
    return productIsAvailable ? availableInMarket : notAvailableInMarket;
  };

  const { body, button } = MessageToRender();

  return (
    <div
      css={styles.productTile}
      data-testid={`${alt}-product-tile`}
    >
      <div
        css={[
          styles.image,
          productIsAvailable === true && styles.imageForAvailable,
          productIsAvailable === false && styles.imageForUnavailable,
        ]}
      >
        <GatsbyImage
          alt={alt}
          image={getImage(img)}
        />
      </div>
      <H4>{title}</H4>
      <P1 css={styles.tileBody}>{body}</P1>
      {button &&
        <LinkButton
          buttonStyles={styles.button}
          onClick={(e) => {
            e.preventDefault();
            button.onClick();
          }}
          to={button.to}
          type={button.type}
        >
          {button.text}
        </LinkButton>
      }
    </div>
  );
}

ProductTile.propTypes = {
  alt: PropTypes.string,
  availableInMarket: PropTypes.shape({
    body: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node,
    ]).isRequired,
    button: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      to: PropTypes.string,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  img: PropTypes.object.isRequired,
  marketNotSelected: PropTypes.shape({
    body: PropTypes.string.isRequired,
    button: PropTypes.instanceOf(null),
  }).isRequired,
  notAvailableInMarket: PropTypes.shape({
    body: PropTypes.string.isRequired,
    button: PropTypes.shape({
      onClick: PropTypes.func.isRequired,
      to: PropTypes.string,
      type: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  productIsAvailable: PropTypes.bool,
  title: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  productTile: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '40px 0',
    textAlign: 'center',
  },

  image: {
    width: '100%',
    marginBottom: 20,
    transition: 'all 600ms cubic-bezier(0.165, 0.85, 0.45, 1)',
    transform: 'scale(0.95)',
  },

  imageForAvailable: {
    transform: 'scale(1.1)',
  },

  imageForUnavailable: {
    filter: 'grayscale(1)',
    transform: 'scale(0.9)',
    opacity: 0.4,
  },

  tileBody: {
    flexGrow: 1,
    margin: '6px 20px 0',
  },

  button: {
    maxWidth: 300,
    marginTop: 30,
  },
});
