import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Eyebrow, H2 } from '@root/gatsby-contentful-core/src/components/text';

export default function ToutContent({
  eyebrow,
  headline,
  body,
  button: ButtonComponent,
}) {
  return (
    <>
      {eyebrow && <Eyebrow>{eyebrow}</Eyebrow>}
      {headline && <H2>{headline}</H2>}
      <BodyCopy content={body} />
      {ButtonComponent && <ButtonComponent />}
    </>
  );
}

ToutContent.propTypes = {
  body: PropTypes.node,
  button: PropTypes.func,
  eyebrow: PropTypes.string,
  headline: PropTypes.string,
};
