export default class AbTestBuckets {
  static ON = 'on';
  static OFF = 'off';
  static CA_OFF = 'california_off';
  static CONTROL = 'control';
  static EXCLUDED = 'excluded';
}

export class NavRedesignAbTestBuckets {
  static CONTROL = AbTestBuckets.CONTROL;
  static MENU_NAV = 'menu_nav';
  static SIMPLE_NAV = 'simple_nav';
}
export class WebTextToDownloadAbTestBuckets {
  static PROMPT_FOR_SMS = 'prompt_for_sms';
  static SEND_SMS_IMMEDIATELY = 'send_sms_immediately';
}

export class AgentBannerBuckets {
  static CONTROL = 'control';
  static WITH_MODAL = 'with_modal';
  static WITHOUT_MODAL = 'without_modal';
}

export class RootAgentBuckets {
  static CONTROL = 'control';
  static CS_REP = 'cs_rep';
  static LICENSED_AGENT = 'licensed_agent';
}

export class RootAgentPrefillProfileBuckets {
  static CONTROL = 'control';
  static CS_REP = 'cs_rep';
  static LICENSED_AGENT = 'licensed_agent';
}

export class PhoenixBuckets {
  static CONTROL = AbTestBuckets.CONTROL;
  static NON_PHOENIX = 'non_phoenix';
  static PHOENIX = 'phoenix';
}

export class MultiDriver {
  static CONTROL = AbTestBuckets.CONTROL;
  static EXCLUDED = AbTestBuckets.EXCLUDED;
  static ON = AbTestBuckets.ON;
}
