import AnnouncementBanner from '@root/joinroot.com/src/components/announcement-banner';
import GetAQuoteButton from '@root/joinroot.com/src/components/core/get-a-quote-button';
import MyAccountIconLink from '@root/joinroot.com/src/components/nav/my-account-icon-link';
import NavBar from '@root/joinroot.com/src/components/nav/nav-bar';
import NavContainer from '@root/joinroot.com/src/components/nav/nav-container';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/brand/src/components/root-logo';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function SimpleNav({
  isVisible,
  ctaText,
  ctaLink,
  isBannerOn,
  bannerContent,
  isCtaButtonDisabled,
}) {
  const { trackClick } = useAnalytics('SIMPLE_NAV', false);

  return (
    <NavContainer
      isBannerOn={isBannerOn}
      isNavOpen={false}
      isVisible={isVisible}
    >
      <div css={styles.wrapper}>
        {isBannerOn && <AnnouncementBanner bannerContent={bannerContent} />}
        <NavBar isBannerOn={isBannerOn}>
          <a href={'/'}>
            <RootLogo logoStyles={styles.logo} />
          </a>
          <div css={[styles.ctaContainer, isBannerOn && stylesWhenBanner.ctaContainer]}>
            <MyAccountIconLink />
            {!isCtaButtonDisabled &&
              <GetAQuoteButton
                buttonStyles={styles.ctaButtonMobile}
                ctaLink={ctaLink}
                ctaText={ctaText}
                onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
              />
            }
          </div>
        </NavBar>
      </div>
    </NavContainer>
  );
}

SimpleNav.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isBannerOn: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isVisible: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  ctaContainer: {
    display: 'flex',
    alignItems: 'center',

    '& > * + *': {
      marginLeft: 20,
    },
  },
  ctaButtonMobile: {
    display: 'none',
    ...Responsive.md({
      width: 'auto',
      height: 'auto',
      display: 'inline-flex',
      padding: '5px 17px',
      fontSize: 16,
      marginTop: 0,
    }),
  },
  logo: {
    display: 'block',
    width: 160,
  },
});

const stylesWhenBanner = StyleSheet.create({
  ctaContainer: {
    ...Responsive.md({
      top: 60,
    }),
  },
});
