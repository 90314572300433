import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function ArrowRightCircleFilledIcon({ iconStyles }) {
  return (
    <svg
      aria-labelledby={'arrow-right-circle-filled-icon-title'}
      css={[styles.svg, iconStyles]}
      height={'22px'}
      version={'1.1'}
      viewBox={'0 0 22 22'}
      width={'22px'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'arrow-right-circle-filled-icon-title'}>Arrow icon pointing right</title>
      <g
        fillRule={'evenodd'}
        stroke={'none'}
        strokeWidth={'1'}
      >
        <g transform={'translate(-406.000000, -434.000000)'}>
          <g>
            <g transform={'translate(240.000000, 122.000000)'}>
              <g transform={'translate(0.000000, 298.000000)'}>
                <g>
                  <path d={'M181.100799,24.1851852 L170.888889,24.1851852 C170.438879,24.1851852 170.074074,24.5499902 170.074074,25 C170.074074,25.4500098 170.438879,25.8148148 170.888889,25.8148148 L181.100228,25.8148148 L178.342478,28.4891325 C178.019423,28.8024131 178.0115,29.3182645 178.324781,29.6413188 C178.638061,29.9643731 179.153913,29.9722962 179.476967,29.6590156 L184.281516,24.9998236 L179.477846,20.3410131 C179.154808,20.0277161 178.638956,20.0356132 178.325659,20.3586516 C178.012362,20.6816901 178.020259,21.1975419 178.343298,21.5108388 L181.100799,24.1851852 Z M177,36 C170.924868,36 166,31.0751322 166,25 C166,18.9248678 170.924868,14 177,14 C183.075132,14 188,18.9248678 188,25 C188,31.0751322 183.075132,36 177,36 Z'} />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

const styles = StyleSheet.create({
  svg: {
    fill: Colors.white(),
    verticalAlign: 'inherit',
  },
});

ArrowRightCircleFilledIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default ArrowRightCircleFilledIcon;
