import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function DisclaimerSectionBlock({ content: { sectionSpacing = {}, ...content } }) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.section,
        sectionSpacingStyles,
      ]}
      data-testid={'disclaimerSectionBlock'}
      id={content.anchor?.anchorSlug}
    >
      {renderRichText(content.disclaimer)}
    </section>
  );
}

DisclaimerSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    disclaimer: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }).isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};

const styles = StyleSheet.create({
  section: {
    margin: '0 auto',
    padding: '40px 20px',
    maxWidth: 700,
    textAlign: 'center',
    p: {
      fontSize: 14,
    },
    a: {
      fontSize: 14,
    },
  },
});
