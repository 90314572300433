import BlogHeader from '@root/joinroot.com/src/components/blog/header';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { graphql, useStaticQuery } from 'gatsby';

export default function BlogHeaderSectionBlock({ content }) {
  const { headerNavItems } = useStaticQuery(graphql`
    query {
      headerNavItems: allContentfulBlogCategoryTag(filter: {node_locale: {eq: "en-US"}}) {
        nodes {
          categoryTitle
          publishingMetadata {
            slug
          }
        }
      }
    }
  `);

  return (
    <div data-testid={'blogHeaderSectionBlock'}>
      <BlogHeader
        activeNavItem={''}
        logo={content.blogLogo.file.url}
        logoAltText={content.blogLogoAltText ?? ''}
        navItems={content.navItems || headerNavItems.nodes}
        sectionSpacing={content.sectionSpacing}
      />
    </div>
  );
}

BlogHeaderSectionBlock.propTypes = {
  content: PropTypes.shape({
    blogLogo: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    blogLogoAltText: PropTypes.string,
    navItems: PropTypes.arrayOf(
      PropTypes.shape({
        categoryTitle: PropTypes.string.isRequired,
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    ),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};

