import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Easing } from '@root/gatsby-contentful-core/src/utils/animation';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function LinkButton({
  buttonStyles,
  children,
  onClick,
  to,
  type = LinkButtonModel.types.PRIMARY,
  ...props
}) {
  return (
    <Link
      css={[
        type !== LinkButtonModel.types.TERTIARY && baseStyles.primaryAndSecondary,
        styles[type],
        buttonStyles,
      ]}
      {...props}
      data-testid={'joinroot-link-button'}
      onClick={onClick}
      to={to}
    >
      {children}
    </Link>
  );
}

LinkButton.propTypes = {
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(LinkButtonModel.types)).isRequired,
};

const buttonActionColors = {
  // Action state colors are specific to link-button.js so they're stored here rather than a global colors file.
  rootOrangeBtnPressed: () => '#FF7F50',
  rootOrangeBtnDisabled: () => '#DADADA',
};

const baseStyles = StyleSheet.create({
  primaryAndSecondary: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    minWidth: 220,
    padding: '6px 26px',
    border: `2px solid ${Colors.rootOrange()}`,
    borderRadius: 6,
    cursor: 'pointer',
    fontSize: 18,
    letterSpacing: '-0.15px',
    lineHeight: '130%',
    transition: `all 200ms ${Easing.easeOutQuart}`,
    userSelect: 'none',
    outline: 'none',
    textDecoration: 'none',

    svg: {
      transition: 'transform 300ms cubic-bezier(.17,.9,.45,1.7)',
    },

    '&:hover': {
      textDecoration: 'none',

      svg: {
        transform: 'translateX(5px)',
      },
    },

    '&:focus': {
      boxShadow: `0 0 0 6px ${Colors.toRgba(Colors.rootOrange(), 0.3)}`,
    },
  },
});

const styles = StyleSheet.create({
  primary: {
    ...semiBold(),
    position: 'relative',
    backgroundColor: Colors.rootOrange(),
    color: Colors.white(),

    '&:after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -1,
      backgroundColor: 'transparent',
      borderRadius: 6,
      boxShadow: `0 14px 10px -10px ${Colors.toRgba(Colors.nearBlack(), 0.3)}`,
      transition: `opacity 600ms ${Easing.easeOutQuart}`,
      opacity: 0,
    },

    'svg path': {
      fill: Colors.white(),
    },

    '&:hover': {
      color: Colors.white(),
    },

    '&:not(:active):not(:focus):hover:after': {
      opacity: 1,
    },

    '&:active': {
      borderColor: buttonActionColors.rootOrangeBtnPressed(),
      backgroundColor: buttonActionColors.rootOrangeBtnPressed(),
    },
  },

  secondary: {
    ...regular(),
    backgroundColor: Colors.white(),
    color: Colors.nearBlack(),

    'svg path': {
      fill: Colors.nearBlack(),
    },

    '&:hover': {
      color: Colors.nearBlack(),
      borderColor: Colors.nearBlack(),
    },

    '&:active': {
      color: Colors.toRgba(Colors.nearBlack(), 0.7),
      borderColor: buttonActionColors.rootOrangeBtnPressed(),
    },
  },

  tertiary: {
    display: 'flex',
    color: Colors.nearBlack(),
    transition: '200ms ease-out',
    lineHeight: '130%',
    textDecoration: 'underline',
    cursor: 'pointer',

    svg: {
      marginLeft: 20,
      transition: 'transform 300ms cubic-bezier(.17,.9,.45,1.7)',

      path: {
        fill: Colors.nearBlack(),
      },
    },

    '&:hover': {
      color: Colors.rootOrange(),

      svg: {
        transform: 'translateX(5px)',

        path: {
          fill: Colors.rootOrange(),
        },
      },
    },

    '&:focus': {},

    '&:active': {
      color: buttonActionColors.rootOrangeBtnPressed(),

      'svg path': {
        fill: buttonActionColors.rootOrangeBtnPressed(),
      },
    },
  },
});

