import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';
import { CaliforniaFaqLink } from '@root/core/src/components/disclaimer-links/california-faq-link';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { RecaptchaPrivacyPolicyLink } from '@root/core/src/components/disclaimer-links/recaptcha-privacy-policy-link';
import { RecaptchaTermsOfServiceLink } from '@root/core/src/components/disclaimer-links/recaptcha-terms-of-service-link';

export default function FooterDisclaimer({
  onlyShowCopyright = false,
  containerStyles,
  isDarkMode,
}) {
  return (
    <div css={[styles.container, containerStyles]}>
      <span css={styles.disclaimer}>
        Copyright ROOT {new Date().getFullYear()}. ROOT is a registered servicemark of Root Insurance Company, Columbus, OH.&nbsp;
      </span>
      {!onlyShowCopyright &&
        <>
          <span css={[styles.disclaimer, styles.disclaimerAllCaps]}>
            <span
              css={[
                styles.disclaimerTitle,
                isDarkMode && titleWhenDarkMode,
              ]}
            >Disclaimer for quotes:
            </span> We reserve the right to refuse to quote any individual a premium rate for the insurance advertised herein.&nbsp;
          </span>
          <span css={styles.disclaimer}>
            <span
              css={[
                styles.disclaimerTitle,
                isDarkMode && titleWhenDarkMode,
              ]}
            >Disclaimer for coverage:
            </span> Coverage is available in the event of a covered loss. Exclusions may apply.&nbsp;
          </span>
          <span css={styles.disclaimer}>
            Not available in all states.&nbsp;
          </span>
          <span css={styles.disclaimer}>
            <span
              css={[
                styles.disclaimerTitle,
                isDarkMode && titleWhenDarkMode,
              ]}
            >Disclaimer for savings:
            </span> Based on savings reported by actual customers who purchased a new Root policy between October 2019 - July 2020; changes in coverage levels not evaluated.&nbsp;
          </span>
        </>
      }
      <span css={styles.disclaimer}>
        <span
          css={[
            styles.disclaimerTitle,
            isDarkMode && titleWhenDarkMode,
          ]}
        >For California residents:&nbsp;
        </span>
        <a
          css={[
            styles.disclaimerLink,
            isDarkMode && linkWhenDarkMode,
          ]}
          href={`${environment.websiteBaseUrl}/car-insurance/telematics/`}
        >
          Telematics
        </a>
        &nbsp;is not used and resulting represented savings are not applicable. Referral program not applicable. Roadside Assistance purchased as separate coverage. Visit&nbsp;
        <CaliforniaFaqLink
          styles={[
            styles.disclaimerLink,
            isDarkMode && linkWhenDarkMode,
          ]}
        />
        &nbsp;for more information.&nbsp;
      </span>
      <span css={styles.disclaimer}>
        This site is protected by reCAPTCHA and the Google&nbsp;
        <RecaptchaPrivacyPolicyLink
          styles={[
            styles.disclaimerLink,
            isDarkMode && linkWhenDarkMode,
          ]}
        />
        &nbsp;and&nbsp;
        <RecaptchaTermsOfServiceLink
          styles={[
            styles.disclaimerLink,
            isDarkMode && linkWhenDarkMode,
          ]}
        />
        &nbsp;apply.
      </span>
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    ...Theme.paragraph2(),
  },
  disclaimer: {
    marginBottom: 5,
  },
  disclaimerAllCaps: {
    textTransform: 'uppercase',
  },
  disclaimerTitle: {
    color: Colors.black(),
    textTransform: 'none',
  },
  disclaimerLink: {
    color: Colors.rootOrange(),
    transition: '200ms ease-in-out',
    '&:hover': {
      color: Colors.nearBlack(),
    },
  },
});

const titleWhenDarkMode = {
  color: Colors.gray50(),
};

const linkWhenDarkMode = {
  color: Colors.white(),
  '&:hover': {
    color: Colors.rootOrange(),
  },
};

FooterDisclaimer.propTypes = {
  containerStyles: PropTypes.object,
  isDarkMode: PropTypes.bool,
  onlyShowCopyright: PropTypes.bool,
};
