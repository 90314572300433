import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import HeroSimpleSection from '@root/gatsby-contentful-core/src/components/sections/hero-simple-section';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { Alignments, SizesSML } from '@root/contentful/src/models/contentful-constants';

export default function HeroSimpleSectionBlock({ content }) {
  const alignmentX = content.alignmentX?.toLowerCase().includes(Alignments.CENTER)
    ? Alignments.CENTER
    : Alignments.LEFT;

  const iconSize = content.iconSize && Object.values(SizesSML).includes(content.iconSize.toUpperCase())
    ? content.iconSize.toUpperCase()
    : SizesSML.S;

  return (
    <div data-testid={'heroSimpleSectionBlock'}>
      <HeroSimpleSection
        alignmentX={alignmentX}
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        body={renderRichText(content.body)}
        eyebrow={content.eyebrow}
        headline={content.headline}
        icon={content.asset?.file.url}
        iconAlt={content.iconAltText}
        iconSize={iconSize}
        sectionSpacing={content.sectionSpacing}
        subhead={content.subhead}
      />
    </div>
  );
}

HeroSimpleSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    alignmentX: PropTypes.string,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    body: PropTypes.object,
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    iconAltText: PropTypes.string,
    iconSize: PropTypes.string,
    subhead: PropTypes.string,
    asset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};
