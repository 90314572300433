import CaretLoader from '@root/core/src/components/caret-loader';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import RootUnavailableFormFlow from '@root/joinroot.com/src/components/forms/root-unavailable-form-flow';
import environment from '@root/core/src/utils/environment';
import useGetMarketSupportByZipCode from '@root/joinroot.com/src/api/use-get-market-support-by-zip-code';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isDesktopBrowser, isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';

export default function AutoGetStartedBox({
  zipCode, analytics, onClose,
}) {
  const [marketSupport, setMarketSupport] = useState(null);
  const isLoading = useGetMarketSupportByZipCode(zipCode, useCallback((result) => {
    setMarketSupport(result.data);
  }, [setMarketSupport]));

  const isEnabledForAuto = marketSupport?.auto;
  const market = marketSupport?.market;

  if (isLoading) {
    return <div css={styles.loaderContainer}><CaretLoader /></div>;
  }

  if (isEnabledForAuto) {
    return (
      <AutoEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  return (
    <RootUnavailableFormFlow
      analytics={analytics}
      market={market}
      onClose={onClose}
      product={'AUTO'}
    />
  );
}

AutoGetStartedBox.propTypes = {
  analytics: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
};

function mobileBodyCopy(market) {
  if (market.toLowerCase() === 'california') {
    return 'Good news. Root is available in your area. To get started on your free quote, let’s create your account.';
  }

  return 'Good news. Root is available in your area. Let’s create your account. Then you can log in to the Root app to start your test drive.';
}

function AutoEnabled({ market, analytics }) {
  useEffect(() => {
    analytics.trackEvent('AUTO_ENABLED_MODAL_VIEWED');
  }, [analytics]);

  const handleClick = (e) => {
    e.preventDefault();
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED);
    analytics.trackClick('AUTO_ENABLED_MODAL_GET_A_QUOTE_BUTTON');

    window.setTimeout(() => {
      // eslint-disable-next-line root/prevent-use-of-window-location
      window.location.href = environment.quoteAppBaseUrl;
    }, 500);
  };

  if (isDesktopBrowser) {
    return (
      <>
        <H2 css={styles.headline}>Hey there, {market}</H2>
        <P1>Root is available in your area. To get started on your free quote, let&apos;s create your account.</P1>
        <LinkButton
          buttonStyles={styles.spaceAboveForm}
          onClick={handleClick}
          to={'#'}
          type={LinkButtonModel.types.PRIMARY}
        >
          Get a quote
        </LinkButton>
      </>
    );
  }

  if (isMobileBrowser) {
    return (
      <>
        <H2 css={styles.headline}>Hey there, {market}</H2>
        <P1>{mobileBodyCopy(market)}</P1>
        <LinkButton
          buttonStyles={styles.spaceAboveForm}
          onClick={handleClick}
          to={'#'}
          type={LinkButtonModel.types.PRIMARY}
        >
          Continue
        </LinkButton>
      </>
    );
  }
}

AutoEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  loaderContainer: {
    width: '340px',
    height: '296px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    marginRight: 20,
    marginBottom: 16,
  },
  spaceAboveForm: {
    marginTop: 34,
  },
});

