import AnalyticsService from '@root/core/src/services/analytics-service';
import { createApiReporter, getDeviceInfo } from 'web-vitals-reporter';
import { getCLS, getFCP, getFID, getLCP, getTTFB } from 'web-vitals';

export default class WebVitalsService {
  static initialize(context) {
    const reporter = this._buildReporter(context);

    getCLS(reporter);
    getFCP(reporter);
    getFID(reporter);
    getLCP(reporter);
    getTTFB(reporter);
  }

  static _buildReporter(context) {
    return createApiReporter('/', {
      initial: getDeviceInfo(),
      onSend: (_, result) => {
        AnalyticsService.trackEvent(context, 'WEB_VITALS', result);
      },
    });
  }
}
