import Button from '@root/core/src/components/button';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import React, { useEffect, useState } from '@root/vendor/react';
import chevronDownSVG from '@root/joinroot.com/src/assets/icons/arrow-down-root-orange.svg';
import useEmbeddedFormState from '@root/joinroot.com/src/components/sections/drop-the-score-nj/use-embedded-form-state';
import useScript from '@root/gatsby-contentful-core/src/hooks/use-script';
import {
  BUTTON_BASE_STYLES,
  BUTTON_SIZE_REGULAR,
  BUTTON_SIZE_STYLES,
  BUTTON_VARIANT_PRIMARY,
  BUTTON_VARIANT_STYLES,
} from '@root/core/src/components/button/styles';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H2, H4, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { regular } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function DropTheScoreNJFormSection() {
  const scriptStatus = useScript('https://rootinc.app.sparkinfluence.net/assets/js/widget.js/?id=7');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { isFormMounted, isFormSubmitted } = useEmbeddedFormState(scriptStatus);

  useEffect(() => {
    if (!isFormSubmitted) { return; }

    setIsModalOpen(true);
  }, [isFormSubmitted]);

  // Modify form elements that can't be modified by CSS
  useEffect(() => {
    if (!isFormMounted) { return; }

    const stateSelectInput = document.querySelector('#wsd_home_state');
    if (stateSelectInput.firstChild.innerText.includes('-- Select State --')) {
      stateSelectInput.firstChild.innerText = '';
    }
  }, [isFormMounted]);

  return (
    <>
      <section css={styles.section}>
        <div css={styles.wrap}>
          {isFormSubmitted
            ? <H2 css={styles.headline}>Thanks for signing up!</H2>
            : (
              <>
                <H2 css={styles.headline}>Tell your legislators to act</H2>
                <H4 css={styles.subhead}>Fill out this form to urge your legislators to drop the score.</H4>
                <P1 css={styles.requiredText}>Fields marked with * are required.</P1>
                <div
                  className={'spkactionform'}
                  css={styles.formContainer}
                  id={'wsd-root'}
                />
              </>
            )
          }
        </div>
      </section>

      <Modal
        isShowing={isModalOpen}
        name={'drop-the-score-nj-form-submitted-modal'}
        onClose={() => setIsModalOpen(false)}
      >
        <div css={styles.modalContent}>
          <H2>Thanks for signing up!</H2>
          <P1>Stay tuned for more information and updates.</P1>
          <Button onClick={() => setIsModalOpen(false)}>Okay</Button>
        </div>
      </Modal>
    </>
  );
}

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.nearWhite(),
    padding: '100px 20px',
  },
  wrap: {
    width: 'min(630px, 100%)',
    textAlign: 'center',
  },
  subhead: {
    marginTop: 8,
  },
  requiredText: {
    margin: '40px 0 30px',
    color: Colors.rootOrange(),
  },
  formContainer: {
    '& dd': {
      margin: 0,
    },
    '& dd input, & select, & textarea': {
      width: '100% !important',
      caretColor: Colors.rootOrange(),
      padding: '26px 14px 8px',
      backgroundColor: Colors.white(),
      border: `1px solid ${Colors.gray40()}`,
      borderRadius: 6,
      color: Colors.nearBlack(),
      marginBottom: 18,
      '&:focus': {
        outline: 'none',
        border: `1px solid ${Colors.nearBlack()}`,
      },
    },
    '& textarea': {
      minHeight: 160,
    },
    '& select': {
      appearance: 'none',
    },
    // selecting the select input container.
    '& dl > dd:nth-of-type(7)': {
      position: 'relative',
      '&::after': {
        content: '""',
        position: 'absolute',
        backgroundImage: `url(${chevronDownSVG})`,
        backgroundSize: 'cover',
        top: 20,
        right: 12,
        zIndex: 2,
        pointerEvents: 'none',
        width: 20,
        height: 20,
      },
    },
    'input::placeholder': {
      color: 'transparent',
    },
    // selecting all label containers.
    '& dt': {
      position: 'absolute',
      zIndex: 2,
      pointerEvents: 'none',
      padding: '4px 30px 4px 14px',
      ...regular(),
      fontSize: 14,
      color: Colors.gray50(),
      letterSpacing: '-0.015em',
    },
    '.wsd_remember_me': {
      textAlign: 'left',
    },
    // selecting submit button which is actually an input element.
    '#submit_0': {
      ...BUTTON_BASE_STYLES,
      ...BUTTON_SIZE_STYLES[BUTTON_SIZE_REGULAR],
      ...BUTTON_VARIANT_STYLES[BUTTON_VARIANT_PRIMARY],
    },
  },
  modalContent: {
    textAlign: 'center',
    '& > * + *': { marginTop: 8 },
    '& > button': { marginTop: 40 },
  },
});
