import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors } from '@root/core/src/utils/styles';

export default function Spacer({
  height = 100,
  backgroundColor = Colors.white(),
}) {
  return (
    <div
      aria-hidden={'true'}
      css={{
        width: '100%',
        height: height / 1.25, // if height prop is 100, height on mobile here becomes 80.
        backgroundColor,
        ...Responsive.sm({
          height,
        }),
      }}
      data-testid={'spacer'}
    />
  );
}

Spacer.propTypes = {
  backgroundColor: PropTypes.oneOf([Colors.white(), Colors.nearWhite()]),
  height: PropTypes.number,
};
