import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Eyebrow, H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function VideoToutSection({
  anchor,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  body,
  children,
  eyebrow,
  sectionSpacing = {},
  title,
}) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.videoToutSection,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(backgroundColor),
      ]}
      id={anchor}
    >
      {(eyebrow || title || body) &&
        <div css={styles.headingContainer}>
          {eyebrow && <Eyebrow css={styles.eyebrow}>{eyebrow}</Eyebrow>}
          {title && <H2 css={styles.title}>{title}</H2>}
          {body && <P1 css={styles.body}>{body}</P1>}
        </div>
      }
      <div css={styles.childrenContainer}>
        {children}
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  videoToutSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '80px 0',
    textAlign: 'center',
  },
  headingContainer: {
    maxWidth: 500,
    margin: '0 20px 50px',
  },
  eyebrow: {
    marginBottom: 15,
  },
  title: {
    marginTop: 0,
    marginBottom: 15,
  },
  childrenContainer: {
    maxWidth: 1160,
  },
});

VideoToutSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.node,
  children: PropTypes.node.isRequired,
  eyebrow: PropTypes.node,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  title: PropTypes.node,
};
