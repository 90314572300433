import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import ProgressVideoLightbox from '@root/joinroot.com/src/components/progress/progress-video-lightbox';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import rxbwSvg from '@root/joinroot.com/src/assets/images/progress/rxbw.svg';
import unapologeticSvg from '@root/joinroot.com/src/assets/images/progress/unapologetic.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { IconChevron } from '@root/core/src/components/icons/chevron';
import { P1 } from '@root/gatsby-contentful-core/src/components/text';
import { constantize } from '@root/core/src/utils/strings';
import { keyframes } from '@root/vendor/@emotion/core';
import { secondaryButtonStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';
import { semiBoldItalic } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { useState } from '@root/vendor/react';

export default function ProgressHero({
  content: {
    eyebrow,
    headline,
    body,
    ctaGroup,
  },
  heroVideoBackground,
  heroVideoBackgroundPoster,
  lightboxVideoFile,
}) {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const { trackClick } = useAnalytics('PROGRESS_PAGE_HERO', false);

  const handleWatchVideo = () => {
    trackClick('WATCH_VIDEO_BUTTON');
    setIsLightboxOpen(true);
  };

  const handleTrackClick = (buttonText) => {
    trackClick(`${constantize(buttonText)}_BUTTON`);
  };

  const renderButtons = ctaGroup?.length > 0;

  return (
    <section css={styles.section}>
      <div css={styles.mainContentWrap}>
        <img
          alt={''}
          css={[styles.decorativeText, styles.unapologetic]}
          src={unapologeticSvg}
        />
        <img
          alt={''}
          css={[styles.decorativeText, styles.rxbw]}
          src={rxbwSvg}
        />
        <video
          autoPlay
          loop
          muted
          playsInline
          css={styles.backgroundVisuals}
          id={'heroBackgroundVideo'}
          poster={heroVideoBackgroundPoster}
        >
          <source
            src={heroVideoBackground}
            type={'video/mp4'}
          />
        </video>
        <div css={styles.mainContent}>
          <h5 css={[styles.italics, styles.eyebrow]}>{eyebrow}</h5>
          <h1 css={[styles.italics, styles.headline]}>{headline}</h1>
          <P1 css={styles.bodyCopy}>{body}</P1>
          {renderButtons &&
            <div css={styles.ctaGroup}>
              {ctaGroup.map((button, i) => {
                const buttonType = validateButtonType(button.buttonVariant);
                return (
                  <LinkButton
                    buttonStyles={buttonType === 'secondary' ? secondaryButtonStyles : null}
                    key={i}
                    onClick={button.buttonId === 'watchVideo'
                      ? handleWatchVideo
                      : () => handleTrackClick(button.buttonText)
                    }
                    to={button.buttonLink ?? ''}
                    type={buttonType}
                  >
                    {button.buttonText}
                  </LinkButton>
                );
              })}
            </div>
          }
        </div>
      </div>
      <div css={styles.bottomBar}>
        <IconChevron
          color={Colors.gray50()}
          direction={'down'}
          iconStyles={styles.iconChevron}
        />
        <img
          alt={''}
          css={styles.rxbwMobile}
          src={rxbwSvg}
        />
      </div>
      <ProgressVideoLightbox
        isShowing={isLightboxOpen}
        name={'progress-video'}
        onClose={() => setIsLightboxOpen(false)}
        videoFile={lightboxVideoFile}
      />
    </section>
  );
}

ProgressHero.propTypes = {
  content: PropTypes.shape({
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    ctaGroup: PropTypes.arrayOf(
      PropTypes.shape({
        buttonId: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonVariant: PropTypes.string,
      }).isRequired,
    ),
  }).isRequired,
  heroVideoBackground: PropTypes.node.isRequired,
  heroVideoBackgroundPoster: PropTypes.node.isRequired,
  lightboxVideoFile: PropTypes.shape({
    url: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
  }).isRequired,
};

function validateButtonType(type) {
  const isValidButtonType = LinkButtonModel.types[constantize(type)];
  return isValidButtonType ? isValidButtonType : LinkButtonModel.types.SECONDARY;
}

const chevronBounce = keyframes`
  86%, 100% { transform: translate3d(0,0,0); }
  92% { transform: translate3d(0,5px,0); }
`;

const easeOutBack = 'cubic-bezier(0.175, 0.885, 0.32, 1.275)';

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: Colors.black(),
  },
  mainContentWrap: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  backgroundVisuals: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    opacity: 0.25,
  },
  decorativeText: {
    position: 'absolute',
    zIndex: 2,
    display: 'none',
    ...Responsive.lg({
      display: 'block',
    }),
  },
  unapologetic: {
    top: 100,
    right: 25,
  },
  rxbw: {
    bottom: 50,
    left: 25,
  },
  mainContent: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 840,
    margin: '80px 20px 30px',
    textAlign: 'center',
    color: 'white',
    '& > * + *': {
      marginTop: 30,
    },
  },
  italics: {
    ...semiBoldItalic(),
    lineHeight: '1.5em',
    letterSpacing: '0.5em',
  },
  eyebrow: {
    fontSize: 14,
    ...Responsive.sm({
      fontSize: 18,
    }),
  },
  headline: {
    fontSize: 28,
    ...Responsive.sm({
      fontSize: 32,
    }),
  },
  bodyCopy: {
    maxWidth: 700,
    color: Colors.white(),
  },
  ctaGroup: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginTop: 45,
    '& > * + *': {
      marginTop: 15,
    },
    ...Responsive.sm({
      flexDirection: 'row',
      '& > * + *': {
        marginTop: 0,
        marginLeft: 20,
      },
    }),
  },
  bottomBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 45,
    ...Responsive.sm({
      height: 60,
    }),
  },
  rxbwMobile: {
    transform: 'rotate(90deg)',
    ...Responsive.lg({
      display: 'none',
    }),
  },
  iconChevron: {
    display: 'none',
    animation: `${chevronBounce} 5s ${easeOutBack} infinite`,
    ...Responsive.lg({
      display: 'block',
    }),
    path: {
      strokeWidth: 2,
    },
  },
});
