import PartneringVideo from '@root/joinroot.com/src/components/progress/partnering-video';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H2, H5, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

export default function PartneringSection({
  content: {
    eyebrow,
    headline,
    body,
  },
  videoFile,
}) {
  return (
    <section css={styles.section}>
      <div css={styles.contentWrap}>
        <div css={styles.textContent}>
          <H5 css={[textStyles, semiBold()]}>{eyebrow}</H5>
          <H2 css={textStyles}>{headline}</H2>
          <P1 css={textStyles}>{body}</P1>
        </div>
        <PartneringVideo videoFile={videoFile} />
      </div>
    </section>
  );
}

PartneringSection.propTypes = {
  content: PropTypes.shape({
    eyebrow: PropTypes.string.isRequired,
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
  videoFile: PropTypes.shape({
    url: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.black(),
  },
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    margin: '40px 0',
    ...Responsive.lg({
      flexDirection: 'row',
      justifyContent: 'space-between',
      margin: '80px 20px',
    }),
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 480,
    flex: 1,
    margin: '0 20px 40px',
    textAlign: 'center',
    '& > * + *': {
      marginTop: 12,
    },
    ...Responsive.lg({
      alignItems: 'flex-start',
      margin: '0 40px 0 0',
      maxWidth: 360,
      textAlign: 'left',
    }),
  },
});
