import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import ExpandableGroup from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-group';
import ExpandableItem from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-item';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { pluralize } from '@root/core/src/utils/strings';

export default function FaqSearchResults({ filteredFaqData, sectionSpacing = {} }) {
  const noMatches = filteredFaqData.length === 0;

  const sectionSpacingStylesBottom = {
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingBottom: sectionSpacing.bottom,
    }),
  };
  return (
    <div css={[styles.faqResultsBlock, sectionSpacingStylesBottom]}>
      <div css={styles.groupWrap}>
        <ExpandableGroup
          groupStyles={styles.expandableGroup}
          groupTitle={`${filteredFaqData.length} ${pluralize('match', filteredFaqData.length, 'matches')}`}
        >
          {noMatches && (
            <P1 css={styles.noMatchesBody}>
              Try searching for specific words, like &quot;coverage&quot; or &quot;claim&quot;.
            </P1>
          )}
          {filteredFaqData.map((item, i) => (
            <ExpandableItem
              itemTitle={item.title}
              key={i}
              openExpandableItem={true}
            >
              {item.content}
            </ExpandableItem>
          ))}
        </ExpandableGroup>
      </div>
    </div>
  );
}

FaqSearchResults.propTypes = {
  filteredFaqData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.object,
      plainText: PropTypes.string,
    })
  ),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  faqResultsBlock: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '50px 20px 80px',
    backgroundColor: Colors.nearWhite(),

    ...Responsive.md({
      padding: '50px 30px 100px',
    }),
  },

  groupWrap: {
    width: '100%',

    ...Responsive.md({
      maxWidth: 1200,
      paddingLeft: 200,
    }),
  },

  expandableGroup: {
    maxWidth: '100%',
  },

  noMatchesBody: {
    marginTop: 30,
  },
});
