import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import ZipCodeForm, { FORM_FLOWS } from '@root/joinroot.com/src/components/forms/zip-code-form';
import { P2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function RentersZipCodeForm({ analytics }) {
  return (
    <>
      <P2 css={styles.formCaption}>See if we’re available where you live.</P2>
      <ZipCodeForm
        analytics={analytics}
        formFlow={FORM_FLOWS.RENTERS}
        namePrefix={'header'}
      />
    </>
  );
}

RentersZipCodeForm.propTypes = {
  analytics: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  formCaption: {
    margin: '40px 0 10px',
  },
});
