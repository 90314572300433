export default function BetLogo() {
  return (
    <svg
      aria-labelledby={'betTitle'}
      fill={'none'}
      height={'30'}
      role={'img'}
      viewBox={'0 0 95 30'}
      width={'95'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'betTitle'}>BET logo</title>
      <path
        d={'M95 11.6461H82.9855L79.2956 0.0160615L75.6061 11.6461H63.5916L73.3282 18.6138L69.5886 29.9469L79.2961 22.9159L89.0005 29.9469L85.261 18.6138L95 11.6461ZM74.8337 0H38.8253C35.5458 0 33.5666 0.446802 31.9468 1.4358C30.599 2.29047 29.3426 3.46296 28.3517 4.89876C27.6712 5.86148 27.1245 6.84999 26.6999 7.89935V7.87014C26.6999 3.95211 24.1836 0.807456 20.6323 0.268179C19.6901 0.131412 18.1161 0 16.9477 0H0V29.9995H16.5873C20.9008 29.9995 22.7901 29.5001 24.4079 28.0166C25.89 26.668 26.6546 24.5513 26.6546 22.0797C26.6546 22.0111 26.6522 21.9478 26.6493 21.8797C27.8872 25.3957 30.0829 27.9407 32.9377 28.9614C34.9587 29.6793 37.5693 30 41.2958 30H48.1902V22.2875H43.2142C37.2308 22.2744 35.2691 22.8186 34.2242 18.4508H48.1902V11.6777L34.2111 11.6675C34.2111 11.6675 34.9077 7.70466 39.0544 7.70466H52.8229V30H61.5132V7.70466H72.4371L74.8337 0ZM7.35802 7.72802H16.7517C19.0772 7.72802 18.9615 9.67877 18.9615 9.67877C18.9615 11.7191 16.7434 11.6324 16.7434 11.6324H7.35802V7.72802ZM18.977 20.3548C18.977 22.3951 16.7629 22.3133 16.7629 22.3133H7.3765V18.4036H16.7711C19.0928 18.4036 18.977 20.3548 18.977 20.3548ZM22.3406 15.0195C23.7322 14.449 24.9157 13.2683 25.6993 11.8086C25.5811 12.729 25.5218 13.7019 25.5218 14.7484C25.5218 15.9155 25.5991 17.0335 25.7475 18.1038C25.0344 16.7682 23.902 15.7267 22.3406 15.0195Z'}
        fill={'white'}
      />
    </svg>
  );
}
