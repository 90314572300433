import ContentfulCustomReactComponent from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-react-component';
import ContentfulCustomReactComponentMap from '@root/joinroot.com/src/models/contentful-custom-react-component-map';
import CoreCustomReactComponentBlock from '@root/gatsby-contentful-core/src/components/blocks/core-custom-react-component-block';

export default function CustomReactComponentBlock({ content }) {
  return (
    <CoreCustomReactComponentBlock
      componentMap={ContentfulCustomReactComponentMap}
      content={content}
    />
  );
}

CustomReactComponentBlock.propTypes = {
  content: ContentfulCustomReactComponent.propType(),
};
