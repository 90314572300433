import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import FaqBlock from '@root/gatsby-contentful-core/src/components/sections/faq/faq-block';
import FaqSearchResults from '@root/gatsby-contentful-core/src/components/sections/faq/faq-search-results';
import FaqTopicsNav from '@root/gatsby-contentful-core/src/components/sections/faq/faq-topics-nav';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';
import { useFaqNavbar, useFaqSearch, useObserverConfig } from '@root/gatsby-contentful-core/src/hooks/faq-section-hooks';

export default function FaqSection({
  faqData,
  anchor,
  sectionSpacing = {},
}) {
  const [searchQuery, setSearchQuery, filteredFaqData] = useFaqSearch(faqData);
  const isSearching = searchQuery.length > 2;
  const [faqBlockRefs, handleFaqNavClick] = useFaqNavbar(faqData, isSearching, setSearchQuery);
  const observerConfig = useObserverConfig();
  const [activeTopic, setActiveTopic] = useState({
    topic: '',
    index: 0,
  });

  const sectionSpacingStylesTop = {
    paddingTop: sectionSpacing.top / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
    }),
  };

  const sectionSpacingStylesBottom = {
    '& > .faq-block:last-of-type': {
      paddingBottom: sectionSpacing.bottom / 1.25,
      ...Responsive.md({
        paddingBottom: sectionSpacing.bottom,
      }),
    },
  };

  return (
    <section
      css={[
        styles.section,
        sectionSpacingStylesBottom,
      ]}
      id={anchor}
    >

      <FaqTopicsNav
        activeTopic={activeTopic}
        faqData={faqData}
        handleFaqNavClick={handleFaqNavClick}
        sectionSpacing={sectionSpacing}
      />

      <div css={[styles.inputBlock, sectionSpacingStylesTop]}>
        <div css={styles.inputWrap}>
          <Input
            aria-label={'search frequently asked questions'}
            inputStyle={styles.inputStyle}
            inputType={TextTypes.TEXT}
            label={'Search for keywords...'}
            onChange={setSearchQuery}
            value={searchQuery}
          />
        </div>
      </div>

      {isSearching
        ? (
          <FaqSearchResults
            filteredFaqData={filteredFaqData}
            sectionSpacing={sectionSpacing}
          />
        ) : faqData.map((group, i) => (
          <FaqBlock
            activeTopic={activeTopic}
            group={group}
            index={i}
            key={i}
            observerConfig={observerConfig}
            ref={faqBlockRefs[group.title]}
            setActiveTopic={setActiveTopic}
          />
        ))
      }
    </section>
  );
}

FaqSection.propTypes = {
  anchor: PropTypes.string,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      link: PropTypes.shape({
        to: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
      items: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string.isRequired,
          content: PropTypes.object.isRequired,
        }),
      ),
    })
  ),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    '& > .faq-block': {
      backgroundColor: Colors.white(),

      '&:nth-of-type(even)': {
        backgroundColor: Colors.nearWhite(),
      },
    },
  },

  inputBlock: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '80px 20px 10px',
    backgroundColor: Colors.nearWhite(),

    ...Responsive.md({
      padding: '100px 30px 10px',
    }),
  },

  inputWrap: {
    width: '100%',

    ...Responsive.md({
      maxWidth: 1200,
      paddingLeft: 200,
    }),
  },

  inputStyle: {
    border: `1px solid ${Colors.grayLighter()}`,
    borderRadius: 6,

    '&:focus': {
      border: `1px solid ${Colors.grayLighter()}`,
    },
  },
});
