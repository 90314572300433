import CourageSection from '@root/joinroot.com/src/components/progress/courage-section';
import NotAloneSection from '@root/joinroot.com/src/components/progress/not-alone-section';
import PageTemplate from '@root/joinroot.com/src/components/pages/page-template';
import PartneringSection from '@root/joinroot.com/src/components/progress/partnering-section';
import ProgressHero from '@root/joinroot.com/src/components/progress/progress-hero';
import PropTypes from '@root/vendor/prop-types';
import ReadySection from '@root/joinroot.com/src/components/progress/ready-section';
import StatusQuoSection from '@root/joinroot.com/src/components/progress/status-quo-section';
import heroVideoBackground from '@root/joinroot.com/src/assets/images/progress/hero-video-bg.mp4';
import heroVideoBackgroundPoster from '@root/joinroot.com/src/assets/images/progress/hero-video-bg-poster.jpg';
import ogImage from '@root/joinroot.com/src/assets/images/progress/og-image_1200x630.jpg';
import { graphql } from 'gatsby';

export default function ProgressLandingPage({
  location,
  data: {
    hero,
    courage,
    partnering,
    statusQuo,
    notAlone,
    ready,
    courageImg,
    readyImg,
    statusQuoFairWayImg,
    statusQuoDropTheScoreImg,
    statusQuoCovidDrivingImg,
  },
}) {
  return (
    <PageTemplate
      analyticsContext={'PROGRESS_LANDING_PAGE'}
      includeHotjar={false}
      includeOptimizely={false}
      isDarkMode={true}
      isStickyFooterDisabled={true}
      location={location}
      mainStyles={{ overflow: 'hidden' }}
      pageCanonical={'https://www.joinroot.com/progress/'}
      pageDescription={'At Root, we believe in the power of change. In fact, we’ve built a company around it. We’re proud to partner with Bubba Wallace and support the unapologetic change he brings.'}
      pageThumbnail={ogImage}
      pageTitle={'Root × Bubba Wallace | Partnering for unapologetic change'}
    >
      <ProgressHero
        content={hero.content}
        heroVideoBackground={heroVideoBackground}
        heroVideoBackgroundPoster={heroVideoBackgroundPoster}
        lightboxVideoFile={hero.media[0]?.file}
      />
      <CourageSection
        content={courage.content}
        image={courageImg.childImageSharp}
      />
      <PartneringSection
        content={partnering.content}
        videoFile={partnering.media[0]?.file}
      />
      <StatusQuoSection
        content={statusQuo.content}
        statusQuoCovidDrivingImg={statusQuoCovidDrivingImg.childImageSharp}
        statusQuoDropTheScoreImg={statusQuoDropTheScoreImg.childImageSharp}
        statusQuoFairWayImg={statusQuoFairWayImg.childImageSharp}
      />
      <NotAloneSection content={notAlone.content} />
      <ReadySection
        content={ready.content}
        image={readyImg.childImageSharp}
      />
    </PageTemplate>
  );
}

// No need for detailed proptypes here:
// 1. You can see the shape of the data by looking at the query below.
// 2. All of the page section components contain detailed proptypes.
ProgressLandingPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }.isRequired),
};

export const query = graphql`
  query {
    hero:contentfulCustomContentBlock(blockId: {eq: "progressHeroId"}) {
      content
      media {
        file {
          url
          contentType
        }
      }
    }
    courage:contentfulCustomContentBlock(blockId: {eq: "progressCourageId"}) {
      content
    }
    partnering:contentfulCustomContentBlock(blockId: {eq: "progressPartneringId"}) {
      content
      media {
        file {
          url
          contentType
        }
      }
    }
    statusQuo:contentfulCustomContentBlock(blockId: {eq: "progressStatusQuoId"}) {
      content
    }
    notAlone:contentfulCustomContentBlock(blockId: {eq: "progressNotAloneId"}) {
      content
    }
    ready:contentfulCustomContentBlock(blockId: {eq: "progressReadyId"}) {
      content
    }
    courageImg:file(relativePath: { eq: "progress/courage-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    readyImg:file(relativePath: { eq: "progress/ready-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED, layout:FULL_WIDTH, quality: 80)
      }
    }
    statusQuoFairWayImg:file(relativePath: { eq: "progress/statusquo-fair-way-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    statusQuoDropTheScoreImg:file(relativePath: { eq: "progress/statusquo-drop-the-score-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
    statusQuoCovidDrivingImg:file(relativePath: { eq: "progress/statusquo-covid-driving-image.jpg" }) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;

