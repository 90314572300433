import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Alignments, SizesSML } from '@root/contentful/src/models/contentful-constants';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Eyebrow, H1Display, H3 } from '@root/gatsby-contentful-core/src/components/text';

export default function HeroSimpleSection({
  alignmentX,
  anchor,
  backgroundColor,
  body,
  eyebrow,
  headline,
  icon,
  iconAlt,
  iconSize = SizesSML.S,
  sectionSpacing = {},
  subhead,
}) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.section,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(backgroundColor),
      ]}
      data-testid={'hero-simple'}
      id={anchor}
    >
      <div
        css={[
          styles.container,
          alignmentX === Alignments.CENTER && styles.containerCenter,
        ]}
        data-testid={'hero-simple-container'}
      >
        {icon &&
          <img
            alt={iconAlt ?? ''}
            css={[styles.icon, styles[`icon${iconSize}`]]}
            data-testid={'hero-simple-icon'}
            src={icon}
          />
        }
        {eyebrow && <Eyebrow css={styles.eyebrow}>{eyebrow}</Eyebrow>}
        {headline && <H1Display css={styles.headline}>{headline}</H1Display>}
        {subhead && <H3 css={styles.subhead}>{subhead}</H3>}
        <BodyCopy
          content={body}
          css={styles.body}
        />
      </div>
    </section>
  );
}

HeroSimpleSection.propTypes = {
  alignmentX: PropTypes.oneOf([Alignments.CENTER, Alignments.LEFT]).isRequired,
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList).isRequired,
  body: PropTypes.object,
  eyebrow: PropTypes.string,
  headline: PropTypes.string.isRequired,
  icon: PropTypes.string,
  iconAlt: PropTypes.string,
  iconSize: PropTypes.oneOf(Object.values(SizesSML)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  subhead: PropTypes.string,
};

const styles = StyleSheet.create({
  section: {
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    ...Responsive.md({
      padding: '50px 20px',
    }),
  },
  container: {
    width: '100%',
    maxWidth: 800,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    textAlign: 'left',
  },
  containerCenter: {
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    marginBottom: 20,
  },
  iconS: {
    height: 24,
    width: 24,
  },
  iconM: {
    height: 48,
    width: 48,
  },
  iconL: {
    height: 70,
    width: 70,
  },
  eyebrow: {
    marginBottom: 20,
  },
  headline: {
    fontSize: 40,
    ...Responsive.md({
      fontSize: 45,
    }),
  },
  subhead: {
    marginTop: 20,
    color: Colors.gray50(),
    fontWeight: 400,
  },
  body: {
    marginTop: 20,
  },
});
