import AutoZipCodeForm from '@root/joinroot.com/src/components/forms/auto-zip-code-form';
import GetTheAppForm from '@root/joinroot.com/src/components/forms/get-the-app-form';
import HomeownersZipCodeForm from '@root/joinroot.com/src/components/forms/homeowners-zip-code-form';
import RentersZipCodeForm from '@root/joinroot.com/src/components/forms/renters-zip-code-form';

export default class CustomFormMap {
  static IDS = {
    AUTO_ZIP_CODE_FORM: 'autoZipCodeForm',
    GET_THE_ROOT_APP: 'get-the-root-app',
    HOMEOWNERS_ZIP_CODE_FORM: 'HomeownersZipCodeForm',
    RENTERS_ZIP_CODE_FORM: 'RentersZipCodeForm',
  };

  static ID_TO_COMPONENT_MAP = {
    [CustomFormMap.IDS.AUTO_ZIP_CODE_FORM]: AutoZipCodeForm,
    [CustomFormMap.IDS.HOMEOWNERS_ZIP_CODE_FORM]: HomeownersZipCodeForm,
    [CustomFormMap.IDS.RENTERS_ZIP_CODE_FORM]: RentersZipCodeForm,
    [CustomFormMap.IDS.GET_THE_ROOT_APP]: GetTheAppForm,
  };
}
