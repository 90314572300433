import CloseIcon from '@root/core/src/components/icons/close-icon';
import PropTypes from '@root/vendor/prop-types';
import React, { useLayoutEffect, useRef } from '@root/vendor/react';
import ZIndex from '@root/core/src/utils/z-index';
import useModal from '@root/core/src/hooks/use-modal';
import usePortal from '@root/core/src/hooks/use-portal';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

const isBrowser = typeof window !== 'undefined';

export default function VideoLightbox({
  name,
  isShowing,
  onClose,
  videoFile: {
    url,
    contentType,
  },
}) {
  const portalRef = useRef();

  const renderPortal = usePortal(name);
  useModal(onClose, portalRef);

  useLayoutEffect(() => {
    if (!isBrowser) { return; }

    isShowing && (document.documentElement.style.overflow = 'hidden');
    return () => document.documentElement.style.overflow = '';
  }, [isShowing]);

  if (!isBrowser) { return null; }

  return renderPortal(
    <>
      {isShowing && (
        <div
          css={styles.lightbox}
          data-testid={`${name}-lightbox`}
        >
          <div css={styles.lightboxBackground} />
          <div
            css={styles.videoContainer}
            ref={portalRef}
          >
            <button
              aria-label={'close-button'}
              css={styles.closeButton}
              onClick={onClose}
            >
              <CloseIcon fillColor={Colors.gray40()} />
            </button>
            <video
              autoPlay
              controls
              playsInline
              controlsList={'nodownload'}
              css={styles.video}
              id={'progress-video'}
            >
              <source
                src={url}
                type={contentType}
              />
            </video>
          </div>
        </div>
      )}
    </>
  );
}

VideoLightbox.propTypes = {
  children: PropTypes.node,
  isShowing: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  videoFile: PropTypes.shape({
    url: PropTypes.string.isRequired,
    contentType: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  lightbox: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: ZIndex.MODAL,
  },
  lightboxBackground: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: Colors.black(),
    opacity: 0.95,
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: Colors.black(),
  },
  video: {
    display: 'block',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate3d(-50%,-50%,0)',
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    width: 64,
    height: 64,
    zIndex: 2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
});
