import React, { useCallback, useEffect, useRef, useState } from '@root/vendor/react';
import useWindowSize from '@root/core/src/hooks/use-window-size';

export function useFaqNavbar(faqData, isSearching, setSearchQuery) {
  const [selectedFaqNavItem, setSelectedFaqNavItem] = useState('');

  const faqBlockRefs = useRef(faqData.reduce((acc, value) => {
    acc[value.title] = React.createRef();
    return acc;
  }, {}));

  const handleFaqNavClick = useCallback((title) => {
    if (!isSearching) {
      faqBlockRefs.current[title].current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    } else {
      setSearchQuery('');
      setSelectedFaqNavItem(title);
    }
  }, [faqBlockRefs, isSearching, setSearchQuery]);

  useEffect(() => {
    if (selectedFaqNavItem) {
      handleFaqNavClick(selectedFaqNavItem);
      setSelectedFaqNavItem('');
    }
  }, [handleFaqNavClick, selectedFaqNavItem]);

  return [faqBlockRefs.current, handleFaqNavClick];
}

export function useFaqSearch(faqData, delay = 500) {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedResults, setDebouncedResults] = useState([]);
  const filteredSearchQuery = searchQuery.trim().toLowerCase();

  useEffect(() => {
    if (filteredSearchQuery.length < 2) {
      return;
    }

    const handler = setTimeout(() => {
      setDebouncedResults(reduceFaqData(filteredSearchQuery, faqData));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, faqData, filteredSearchQuery]);

  return [searchQuery, setSearchQuery, debouncedResults];
}

function reduceFaqData(filteredSearchQuery, data) {
  return data.reduce((results, group) => {
    group.items.forEach((item) => {
      if (item.title.toLowerCase().includes(filteredSearchQuery) || item.plainText.toLowerCase().includes(filteredSearchQuery)) {
        results.push(item);
      }
    });

    return results;
  }, []);
}

// This returns an intersection observer rootMargin that is exactly 1px tall, 200px from the top of the viewport.
export function useObserverConfig() {
  const { windowHeight } = useWindowSize();
  const marginTop = 200;
  const marginBottom = windowHeight - 201;

  if (Number.isNaN(marginBottom)) { return; }

  const observerConfig = {
    rootMargin: `${-marginTop}px 0px ${-marginBottom}px 0px`,
  };

  return observerConfig;
}
