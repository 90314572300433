import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function submitLeadCaptureToBraze(leadCapture) {
  return new NetworkRequestConfiguration({
    endpoint: 'lead_captures',
    method: NetworkRequestConfiguration.Methods.POST,
    bodyParameters: {
      leadCapture,
    },
    successCodes: [201],
  });
}
