import ContentfulExpandableSectionFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-expandable-section-factory';
import ContentfulFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-factory';
import uuid from '@root/vendor/uuid/v4';

export default class ContentfulVariationContainerFactory {
  static create(overrides = {}) {
    return {
      id: uuid(),
      sys: ContentfulFactory.createSys('variationContainer'),
      experimentTitle: 'cool test 1',
      contentful_id: 'ABCD',
      meta: {
        experimentId: '111-111-111',
        variationIds: createVariationId('bucketname1', 'bucket_uuid1', 'bucket_contentful_id1') + createVariationId('bucketname2', 'bucket_uuid2', 'bucket_contentful_id2'),
      },
      variations: [
        {

          name: 'bucket1',
          ...ContentfulExpandableSectionFactory.create({
            headline: 'FIRST expandable section test',
            contentful_id: 'bucket_contentful_id1',
          }),
        },
        {

          name: 'bucket2',
          ...ContentfulExpandableSectionFactory.create({
            headline: 'OTHER expandable section test',
            contentful_id: 'bucket_contentful_id2',
          }),
        },
      ],
      ...overrides,
    };
  }
}

const createVariationId = (bucket_name, bucket_uuid, bucket_contentful_id) => {
  return `${bucket_name},${bucket_uuid},${bucket_contentful_id}|`;
};
