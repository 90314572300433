import AccountSignInLink from '@root/joinroot.com/src/components/nav/account-sign-in-link';
import AnnouncementBanner from '@root/joinroot.com/src/components/announcement-banner';
import GetAQuoteButtonWithIcon from '@root/joinroot.com/src/components/core/get-a-quote-button-with-icon';
import NavBar from '@root/joinroot.com/src/components/nav/nav-bar';
import NavContainer from '@root/joinroot.com/src/components/nav/nav-container';
import NavLinks from '@root/joinroot.com/src/components/nav/nav-links';
import NavMenuButton from '@root/joinroot.com/src/components/nav/nav-menu-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/brand/src/components/root-logo';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function ClassicNav({
  isNavOpen,
  toggleNavState,
  isVisible,
  ctaLink,
  ctaText,
  isBannerOn,
  isCtaButtonDisabled,
  bannerContent,
  isDarkMode,
  isNavBgTransparent,
}) {
  const { trackClick } = useAnalytics('GLOBAL_NAV', false);

  return (
    <NavContainer
      isBannerOn={isBannerOn}
      isDarkMode={isDarkMode}
      isNavBgTransparent={isNavBgTransparent}
      isNavOpen={isNavOpen}
      isVisible={isVisible}
    >
      <div css={styles.wrapper}>
        {isBannerOn && <AnnouncementBanner bannerContent={bannerContent} />}
        <NavBar
          isBannerOn={isBannerOn}
          isDarkMode={isDarkMode}
        >
          <a
            css={styles.logoLink}
            href={'/'}
          >
            <RootLogo logoStyles={isDarkMode ? styles.logoWhenDarkMode : styles.logo} />
          </a>
          <NavMenuButton
            buttonBarStyles={isDarkMode ? menuButtonStylesWhenDarkMode : null}
            isNavOpen={isNavOpen}
            toggleNavState={toggleNavState}
          />
        </NavBar>
        <NavLinks
          isBannerOn={isBannerOn}
          isDarkMode={isDarkMode}
        />
        <div css={[styles.ctaContainer, isBannerOn && stylesWhenBanner.ctaContainer]}>
          <AccountSignInLink isDarkMode={isDarkMode} />
          {!isCtaButtonDisabled &&
            <GetAQuoteButtonWithIcon
              buttonStyles={styles.ctaButtonMobile}
              ctaLink={ctaLink}
              ctaText={ctaText}
              iconStyles={styles.ctaIcon}
              onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
            />
          }
        </div>
      </div>
    </NavContainer>
  );
}

const styles = StyleSheet.create({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  ctaButtonMobile: {
    width: 295,
    height: 44,
    transform: 'translate(0, -100px)',
    opacity: 0,
    transition: 'all 350ms cubic-bezier(0.37, 0, 0.28, 1) 200ms',
    marginTop: 20,
    '.nav-is-open &': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
    ...Responsive.md({
      opacity: 1,
      transform: 'none',
      transition: 'all 300ms cubic-bezier(0.37, 0, 0.28, 1)',
      width: 'auto',
      height: 'auto',
      display: 'inline-flex',
      padding: '5px 17px',
      fontSize: 16,
      marginTop: 0,
    }),
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column-reverse',
    ...Responsive.md({
      position: 'absolute',
      flexDirection: 'row',
      alignItems: 'center',
      top: 11,
      right: 25,

      '& > * + *': {
        marginLeft: 30,
      },
    }),
  },
  ctaIcon: {
    height: 22,
    marginTop: 1,
    opacity: 0,
    transform: 'translateX(-50px)',
    transition: 'all 350ms cubic-bezier(0.37, 0, 0.28, 1) 375ms',
    '.nav-is-open &': {
      opacity: 1,
      transform: 'translateX(0)',
    },
    ...Responsive.md({
      display: 'none',
    }),
  },
  logoLink: {
    height: 18,
    marginBottom: 2,
  },
  logo: {
    width: 160,
  },
  logoWhenDarkMode: {
    width: 160,
    fill: Colors.white(),
  },
});

const stylesWhenBanner = StyleSheet.create({
  ctaContainer: {
    ...Responsive.md({
      top: 61,
    }),
  },
});

const menuButtonStylesWhenDarkMode = {
  backgroundColor: Colors.white(),
};

ClassicNav.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isBannerOn: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isNavBgTransparent: PropTypes.bool,
  isNavOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool.isRequired,
  toggleNavState: PropTypes.func.isRequired,
};
