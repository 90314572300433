import { useEffect, useState } from '@root/vendor/react';

export default function useEmbeddedFormState(scriptStatus) {
  const [isFormMounted, setIsFormMounted] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    if (scriptStatus !== 'ready') { return; }

    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((addedNode) => {
          if (addedNode.nodeName === 'FORM') {
            setIsFormMounted(true);
          }
          if (addedNode.data?.includes('Thanks for signing up')) {
            setIsFormSubmitted(true);
          }
        });
      });
    });

    observer.observe(document.querySelector('#wsd-root'), {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [scriptStatus]);

  return {
    isFormMounted,
    isFormSubmitted,
  };
}
