import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';
import { styled } from '@root/core/src/utils/styles';

/**
  * Props from GatsbyImage:
  * style – Styles passed to the style prop are applied to the outer wrapper of the GatsbyImage component.
  * imgStyle – Styles passed to the imgStyle prop are applied to the <img> element inside the GatsbyImage component.
  *
  * Docs: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/#shared-props
  *
  * ---
  *
  * Props for Emotion:
  * className – No need to use this prop. It's here because it allows ArtDirectedGatsbyImage to be composed within another styled component.
  *
  * Docs: https://emotion.sh/docs/styled#styling-any-component
  */

export default function ArtDirectedGatsbyImage({
  className,
  desktopImg,
  mobileImg,
  alt,
  style,
  imgStyle,
  responsiveBreakpoint = Responsive.QUERIES.lessThanMd,
}) {
  if (!desktopImg) { return null; }

  const mobileImageHeight = mobileImg ? (mobileImg.height * 100).toFixed(2) : null;

  const images = mobileImg
    ? withArtDirection(desktopImg, [{
      media: responsiveBreakpoint,
      image: mobileImg,
    }])
    : desktopImg;

  return (
    <StyledGatsbyImage
      alt={alt ?? ''}
      className={className}
      image={images}
      imgStyle={imgStyle}
      mobileImageHeight={mobileImageHeight}
      responsiveBreakpoint={responsiveBreakpoint}
      style={style}
    />
  );
}

ArtDirectedGatsbyImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  desktopImg: PropTypes.object,
  imgStyle: PropTypes.object,
  mobileImg: PropTypes.object,
  responsiveBreakpoint: PropTypes.oneOf(Object.values(Responsive.QUERIES)),
  style: PropTypes.object,
};

const StyledGatsbyImage = styled(GatsbyImage, {
  shouldForwardProp: (prop) => prop !== 'mobileImageHeight' && prop !== 'responsiveBreakpoint',
})(({ mobileImageHeight, responsiveBreakpoint }) => {
  if (!mobileImageHeight) { return null; }

  return {
    [`@media ${responsiveBreakpoint}`]: {
      '& > div:first-of-type': {
        paddingTop: `${mobileImageHeight}% !important`,
      },
    },
  };
});
