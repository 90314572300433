module.exports = function buildPathFromSlug(slug) {
  let buildPath = slug;
  const firstCharacter = buildPath.trim()[0];
  const lastCharacter = buildPath.trim()[buildPath.length - 1];

  buildPath = firstCharacter === '/' ? buildPath : `/${buildPath}`;
  buildPath = lastCharacter === '/' ? buildPath : `${buildPath}/`;

  return buildPath;
};
