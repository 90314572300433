import AnalyticsService from '@root/core/src/services/analytics-service';
import CaptchaService from '@root/core/src/services/captcha-service';
import CaretLoader from '@root/core/src/components/caret-loader';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import InputWithSubmit from '@root/gatsby-contentful-core/src/components/core/input-with-submit';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import RootUnavailableFormFlow from '@root/joinroot.com/src/components/forms/root-unavailable-form-flow';
import environment from '@root/core/src/utils/environment';
import submitLeadCaptureToBraze from '@root/core/src/api/submit-lead-capture-to-braze';
import useGetMarketSupportByZipCode from '@root/joinroot.com/src/api/use-get-market-support-by-zip-code';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { EMAIL_REGEX } from '@root/core/src/utils/validators';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { navigate } from 'gatsby';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

export default function RentersGetStartedBox({
  zipCode, onClose, analytics,
}) {
  const [marketSupport, setMarketSupport] = useState(null);
  const isLoading = useGetMarketSupportByZipCode(zipCode, useCallback((result) => {
    setMarketSupport(result.data);
  }, [setMarketSupport]));

  const isEnabledForRenters = marketSupport?.renters;
  const isEnabledForAuto = marketSupport?.auto;
  const market = marketSupport?.market;

  if (isLoading) {
    return <div css={styles.loaderContainer}><CaretLoader /></div>;
  }

  if (isEnabledForRenters) {
    return (
      <RentersEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  if (isEnabledForAuto) {
    return (
      <AutoEnabled
        analytics={analytics}
        market={market}
      />
    );
  }

  return (
    <RootUnavailableFormFlow
      analytics={analytics}
      market={market}
      onClose={onClose}
      product={'RENTERS'}
    />
  );
}

RentersGetStartedBox.propTypes = {
  analytics: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  zipCode: PropTypes.string.isRequired,
};

function RentersEnabled({ market, analytics }) {
  useEffect(() => {
    analytics.trackEvent('RENTERS_ENABLED_VIEWED');
  }, [analytics]);

  const handleClick = () => {
    ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED);
    analytics.trackClick('GET_A_RENTERS_QUOTE_BUTTON');
  };

  return (
    <>
      <H2 css={styles.headline}>Hey there, {market}</H2>
      <P1>Good news. We’re in your state. Start by getting a renters quote for as low as $6/month. Then save up to $900 a year on car insurance.</P1>
      <LinkButton
        buttonStyles={styles.spaceAboveForm}
        onClick={handleClick}
        to={environment.rentersAppBaseUrl}
        type={LinkButtonModel.types.PRIMARY}
      >
        Get a renters quote
      </LinkButton>
    </>
  );
}

RentersEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

function AutoEnabled({ analytics, market }) {
  useEffect(() => {
    analytics.trackEvent('AUTO_ENABLED_VIEWED');
  }, [analytics]);
  const [email, setEmail] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const rootId = useRootId();

  useEffect(() => {
    CaptchaService.setup();
  }, []);

  const [postLeadCaptureToBraze] = useImperativeNetworkRequest(submitLeadCaptureToBraze);

  async function handleSubmit() {
    setIsSubmitted(true);
    analytics.trackClick('AUTO_ENABLED_EMAIL_SUBMIT');

    const captchaToken = await CaptchaService.challenge('Submit_Lead_Capture_To_Braze');

    await postLeadCaptureToBraze({
      captchaToken,
      email,
      market: Market.marketNameToAbbreviation(market),
      last_touch_attribution: 'AUTO_AVAILABLE_RENTERS_UNAVAILABLE',
      interested_in_auto: true,
      interested_in_renters: true,
      mixpanel_distinct_id: AnalyticsService.getDistinctId(),
      rootId,
    });

    window.setTimeout(() => {
      navigate('/quote');
    }, 2000);
  }

  return (
    <>
      <H2 css={styles.headline}>You can save big on car insurance.</H2>
      <P1>Good news. You could save up to $900 a year on car insurance. Renters isn’t available in your state yet, but if you give us your email, we’ll let you know when it is.</P1>
      <form
        action={'#'}
        css={[styles.form, styles.spaceAboveForm]}
        onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}
      >
        <InputWithSubmit
          autoCapitalize={'off'}
          autoComplete={'email'}
          inputType={InputWithSubmit.TextTypes.TEXT}
          isSubmittable={EMAIL_REGEX.test(email)}
          isSubmitted={isSubmitted}
          label={'Email'}
          name={'renters-email'}
          onChange={setEmail}
          value={email}
        />
      </form>
    </>
  );
}

AutoEnabled.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
    trackEvent: PropTypes.func.isRequired,
  }).isRequired,
  market: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  loaderContainer: {
    width: '340px',
    height: '296px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  headline: {
    marginRight: 20,
    marginBottom: 16,
  },
  spaceAboveForm: {
    marginTop: 34,
  },
  form: {
    height: 60,
  },
});

