import AbTestBuckets from '@root/core/src/models/ab-test-buckets';
import AgentsPhoneNumber from '@root/agents/src/utils/agents-phone-number';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import Market from '@root/core/src/models/market';
import getRootAgentTriageConfiguration from '@root/agents/src/api/root-agent-triage/root-agent-triage-configuration';
import useAgentSchedule from '@root/agents/src/hooks/use-agent-schedule';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { isMobileBrowser } from '@root/core/src/utils/detect-mobile-browser';
import { useCallback, useMemo, useState } from '@root/vendor/react';

export const SpeakToAgentSteps = {
  INITIAL: 'initial',
  MARKET_SELECTION: 'market selection',
  OUT_OF_BUSINESS_HOURS: 'out of business hours',
  AFTER_HOURS_CALLBACK_DAY_SELECTION: 'after hours callback experience day selection',
  AFTER_HOURS_CALLBACK_TIME_SELECTION: 'after hours callback experience time selection',
  AFTER_HOURS_CALLBACK_PHONE_NUMBER: 'after hours callback experience phone number',
  AFTER_HOURS_CALLBACK_CONFIRMATION: 'after hours callback experience confirmation',
  SHOW_PHONE_NUMBER: 'show phone number',
  UNSUPPORTED_MARKET: 'unsupported market',
};

export const AGENTS_TRIAGE_STATES = {
  NO_ROOT_SUPPORT: 'no_root_support',
  NO_ISA_SUPPORT: 'no_isa_support',
};

const Actions = {
  CANCEL: 'cancel',
  NEXT: 'next',
  UPDATE_MARKET: 'update market',
  BACK_TO_DAY_SELECTION: 'back to after hours day selection',
};

const SPEAK_WITH_STRING = {
  ISA: 'a Root agent',
  CSR: 'customer support',
};

export default function useSpeakToAgentFlow({
  isaPhoneNumber,
  preselectedMarket,
  eventPrefix,
  conversionTrackingEvent,
  afterHoursCallbackExperience = AbTestBuckets.CONTROL,
}) {
  const [activeStep, setActiveStep] = useState(SpeakToAgentSteps.INITIAL);
  const [selectedMarket, setSelectedMarket] = useState(preselectedMarket || '');
  const [triage, setTriage] = useState();
  const { isDuringBusinessHours, isLoading } = useAgentSchedule();
  const { trackEvent: trackAgentsEvent } = useAnalytics(`AGENTS_${eventPrefix}`, false);
  const { trackEvent: trackTriageEvent } = useAnalytics(`AGENTS_TRIAGE_${eventPrefix}`, false);

  const triageConfiguration = useMemo(() => selectedMarket.length > 0 ? getRootAgentTriageConfiguration(selectedMarket) : null, [selectedMarket]);
  const isFetchingTriage = useNetworkRequest(triageConfiguration, useCallback((result) => {
    if (!result.data) {
      setTriage(null);
      return;
    }

    if (!result.data.rootSupport) {
      setTriage(AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT);
      return;
    }

    const isaDisabledMarket = Market.MARKETS_AGENTS_CREATE_ACCOUNT_DISABLED_MARKETS.includes(selectedMarket) || !result.data.isaSupport;
    if (isaDisabledMarket) {
      setTriage(AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT);
    }
  }, [selectedMarket]));

  const initialStateMachine = {
    [SpeakToAgentSteps.INITIAL]: {
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.OUT_OF_BUSINESS_HOURS,
          condition: () => !isDuringBusinessHours && afterHoursCallbackExperience === AbTestBuckets.CONTROL,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('OUTSIDE_BUSINESS_HOURS');
          },
        },
        {
          target: SpeakToAgentSteps.MARKET_SELECTION,
          condition: () => !preselectedMarket && !isDuringBusinessHours && afterHoursCallbackExperience === AbTestBuckets.ON,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('OUTSIDE_BUSINESS_HOURS_CALLBACK_EXPERIENCE_MARKET_SELECTION');
          },
        },
        {
          target: SpeakToAgentSteps.MARKET_SELECTION,
          condition: () => !preselectedMarket,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('DURING_BUSINESS_HOURS');
          },
        },
        {
          target: SpeakToAgentSteps.UNSUPPORTED_MARKET,
          condition: () => triage === AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackTriageEvent(AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT);
          },
        },
        {
          target: SpeakToAgentSteps.UNSUPPORTED_MARKET,
          condition: () => triage === AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackTriageEvent(AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT);
          },
        },
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_DAY_SELECTION,
          condition: () => preselectedMarket && !isDuringBusinessHours && afterHoursCallbackExperience === AbTestBuckets.ON,
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('OUTSIDE_BUSINESS_HOURS_CALLBACK_EXPERIENCE_DAY_SELECTION');
          },
        },
        {
          target: SpeakToAgentSteps.SHOW_PHONE_NUMBER,
          condition: () => !!preselectedMarket && !isMobileBrowser(),
          action: () => {
            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }
            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('DID_CLICK_PHONE_NUMBER', {
              isaPhoneNumber,
              speakWithString: SPEAK_WITH_STRING.ISA,
            });
          },
        },
        {
          target: SpeakToAgentSteps.INITIAL,
          condition: () => isMobileBrowser(),
          action: () => {
            const phoneNumber = isaPhoneNumber;

            if (conversionTrackingEvent) {
              ConversionTrackingService.trackConversion(conversionTrackingEvent);
            }

            trackAgentsEvent('CLICK_BANNER', { href: location.href });
            trackAgentsEvent('DID_CLICK_PHONE_NUMBER', {
              phoneNumber,
              speakWithString: SPEAK_WITH_STRING.ISA,
            });

            location.assign(AgentsPhoneNumber.phoneFullURL(phoneNumber));
          },
        },
      ],
    },
  };

  const outOfBusinessHoursStateMachine = {
    [SpeakToAgentSteps.OUT_OF_BUSINESS_HOURS]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('DISMISSED_OUT_OF_BUSINESS_HOURS_MODAL'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('DISMISSED_OUT_OF_BUSINESS_HOURS_MODAL'),
        },
      ],
    },
  };

  const afterHoursCallbackDaySelectionStateMachine = {
    [SpeakToAgentSteps.AFTER_HOURS_CALLBACK_DAY_SELECTION]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('DISMISSED_AFTER_HOURS_CALLBACK_DAY_SELECTION'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_TIME_SELECTION,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_DAY_SELECTION_CLICKED_NEXT'),
        },
      ],
    },
  };

  const afterHoursCallbackTimeSelectionStateMachine = {
    [SpeakToAgentSteps.AFTER_HOURS_CALLBACK_TIME_SELECTION]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('DISMISSED_AFTER_HOURS_CALLBACK_TIME_SELECTION'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_PHONE_NUMBER,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_TIME_SELECTION_CLICKED_NEXT'),
        },
      ],
    },
  };

  const afterHoursCallbackPhoneNumberStateMachine = {
    [SpeakToAgentSteps.AFTER_HOURS_CALLBACK_PHONE_NUMBER]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('DISMISSED_AFTER_HOURS_CALLBACK_PHONE_NUMBER'),
        },
      ],
      [Actions.BACK_TO_DAY_SELECTION]: [
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_DAY_SELECTION,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_PHONE_NUMBER_BACK_TO_DAY_SELECTION'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_CONFIRMATION,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_PHONE_NUMBER_CLICKED_NEXT'),
        },
      ],
    },
  };

  const afterHoursCallbackConfirmationStateMachine = {
    [SpeakToAgentSteps.AFTER_HOURS_CALLBACK_CONFIRMATION]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_CONFIRMATION_DISMISSED'),
        },
      ],
      [Actions.BACK_TO_DAY_SELECTION]: [
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_DAY_SELECTION,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_CONFIRMATION_BACK_TO_DAY_SELECTION'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('AFTER_HOURS_CALLBACK_CONFIRMATION_DISMISSED'),
        },
      ],
    },
  };

  const marketSelectionStateMachine = {
    [SpeakToAgentSteps.MARKET_SELECTION]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('MARKET_SELECTOR_DISMISSED'),
        },
      ],
      [Actions.UPDATE_MARKET]: [
        {
          target: SpeakToAgentSteps.MARKET_SELECTION,
          action: (market) => {
            trackAgentsEvent('MARKET_SELECTOR_VALUE_CHANGED');
            setTriage(null);
            setSelectedMarket(market);
          },
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.UNSUPPORTED_MARKET,
          condition: () => triage === AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT,
          action: () => {
            trackAgentsEvent('MARKET_SELECTOR_CLICKED_NEXT');
            trackTriageEvent(AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT);
          },
        },
        {
          target: SpeakToAgentSteps.UNSUPPORTED_MARKET,
          condition: () => triage === AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT,
          action: () => {
            trackAgentsEvent('MARKET_SELECTOR_CLICKED_NEXT');
            trackTriageEvent(AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT);
          },
        },
        {
          target: SpeakToAgentSteps.AFTER_HOURS_CALLBACK_DAY_SELECTION,
          condition: () => !isDuringBusinessHours && afterHoursCallbackExperience === AbTestBuckets.ON,
          action: () => {
            trackAgentsEvent('OUTSIDE_BUSINESS_HOURS_CALLBACK_EXPERIENCE_MARKET_SELECTOR_CLICKED_NEXT');
            trackAgentsEvent('AFTER_HOURS_CALLBACK_DAY_SELECTION');
          },
        },
        {
          target: SpeakToAgentSteps.SHOW_PHONE_NUMBER,
          action: () => {
            trackAgentsEvent('MARKET_SELECTOR_CLICKED_NEXT');
            trackTriageEvent('show_phone_number');
          },
        },
      ],
    },
  };

  const unsupportedMarketStateMachine = {
    [SpeakToAgentSteps.UNSUPPORTED_MARKET]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('CONTINUE_MANUALLY'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('CONTINUE_MANUALLY'),
        },
      ],
    },
  };

  const showPhoneNumberStateMachine = {
    [SpeakToAgentSteps.SHOW_PHONE_NUMBER]: {
      [Actions.CANCEL]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => trackAgentsEvent('PHONE_NUMBER_DISMISSED'),
        },
      ],
      [Actions.NEXT]: [
        {
          target: SpeakToAgentSteps.INITIAL,
          action: () => {
            trackAgentsEvent('PHONE_NUMBER_DISMISSED');
          },
        },
      ],
    },
  };

  const send = (action, data) => {
    const machine = {
      ...initialStateMachine,
      ...marketSelectionStateMachine,
      ...outOfBusinessHoursStateMachine,
      ...afterHoursCallbackDaySelectionStateMachine,
      ...afterHoursCallbackTimeSelectionStateMachine,
      ...afterHoursCallbackPhoneNumberStateMachine,
      ...afterHoursCallbackConfirmationStateMachine,
      ...showPhoneNumberStateMachine,
      ...unsupportedMarketStateMachine,
    };

    const transitions = machine[activeStep][action];
    if (!transitions) {
      return;
    }
    const nextState = transitions.find((t) => !t.condition || t.condition());

    nextState.action(data);
    setActiveStep(nextState.target);
  };

  const canAdvance = activeStep === SpeakToAgentSteps.MARKET_SELECTION
    ? !isFetchingTriage && !!selectedMarket
    : !isLoading;

  const cancelFlow = () => {
    send(Actions.CANCEL);
  };

  const backToDaySelection = () => {
    send(Actions.BACK_TO_DAY_SELECTION);
  };

  const nextStep = () => {
    if (!canAdvance) { return; }
    send(Actions.NEXT);
  };

  const handleMarketUpdate = (value) => {
    send(Actions.UPDATE_MARKET, value);
  };

  return {
    activeStep,
    canAdvance,
    nextStep,
    cancelFlow,
    backToDaySelection,
    selectedMarket,
    setSelectedMarket: handleMarketUpdate,
    agentsMarketSupport: triage,
  };
}
