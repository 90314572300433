import Button from '@root/core/src/components/button';
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const SubmissionSuccessScene = ({
  onClose, selectedMarketData, form,
}) => {
  const title = 'Thank you';
  const message = `We’ll stay in touch with you about Root in ${Market.MAPPING[selectedMarketData.market]} at: ${form.values.email}`;
  const buttonText = 'Okay';

  return (
    <div
      css={styles.container}
      data-testid={'submission-success'}
    >
      <H2 css={styles.headline}>{title}</H2>
      <P1 css={styles.message}>{message}</P1>
      <Button
        css={styles.submissionForm}
        onClick={onClose}
      >
        {buttonText}
      </Button>
    </div>
  );
};

const styles = StyleSheet.create({
  submissionForm: {
    marginTop: 35,
  },
  container: {
    marginBottom: 30,
  },
  headline: {
    marginBottom: 10,
  },
  message: {
    maxWidth: 328,
  },
});

SubmissionSuccessScene.propTypes = {
  form: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedMarketData: PropTypes.object.isRequired,
};

export default SubmissionSuccessScene;
