import ContentfulExpandableItemFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-expandable-item-factory';
import ContentfulFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-factory';
import uuid from '@root/vendor/uuid/v4';

export default class ContentfulExpandableSectionFactory {
  static create(overrides = {}) {
    return {
      id: uuid(),
      sys: ContentfulFactory.createSys('expandableSection'),
      headline: 'Expandable Section test',
      backgroundColor: 'White',
      expandables: [
        ContentfulExpandableItemFactory.create(),
      ],
      ...overrides,
    };
  }
}
