import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import ContentfulCustomForm from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-form';
import HeroImageFull from '@root/gatsby-contentful-core/src/components/sections/hero-section/hero-section_image-full';
import HeroImageNone from '@root/gatsby-contentful-core/src/components/sections/hero-section/hero-section_image-none';
import HeroImageRight from '@root/gatsby-contentful-core/src/components/sections/hero-section/hero-section_image-right';
import HeroImageTop from '@root/gatsby-contentful-core/src/components/sections/hero-section/hero-section_image-top';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useInterfaceActions from '@root/gatsby-contentful-core/src/hooks/use-interface-actions';
import { BRAND_BLING_OPTIONS } from '@root/gatsby-contentful-core/src/components/brand-bling';
import { TEXT_ALIGNMENT_OPTIONS, VERTICAL_IMAGE_ALIGNMENT } from '@root/gatsby-contentful-core/src/constants/constants';
import { constantize } from '@root/core/src/utils/strings';

export default function HeroSectionBlock({
  content: hero,
  customFormRenderer,
  trackClick,
  trackEvent,
}) {
  const Component = getHeroComponent(hero.imageLocation);
  const CustomFormRenderer = customFormRenderer;
  const { buildCallToActionButton } = useInterfaceActions();

  const analytics = {
    trackClick,
    trackEvent,
  };

  return (
    <Component
      analytics={analytics}
      anchor={hero.anchor?.anchorSlug}
      backgroundColor={BackgroundColors.getDefaultedString(hero.backgroundColor)}
      body={renderRichText(hero.body)}
      brandBlingOptions={buildImageOverlay(hero.imageOverlay)}
      buttons={buildButtons(buildCallToActionButton, hero.callToActionButtons, trackClick)}
      eyebrow={hero.eyebrow}
      form={CustomFormRenderer && hero.form ? (
        <CustomFormRenderer
          analytics={analytics}
          content={hero.form}
        />
      ) : null}
      gatsbyImage={hero.asset?.gatsbyImageData}
      headline={hero.headline}
      hideImageOnMobile={hero.hideHeroImageOnMobile}
      imageAlt={hero.imageAltText}
      mobileImage={hero.mobileImage?.gatsbyImageData}
      sectionSpacing={hero.sectionSpacing}
      subhead={hero.subhead}
      textAlignment={buildTextAlignment(hero.textAlignment)}
      verticalImageAlignment={buildVerticalImageAlignment(hero.verticalImageAlignment)}
    />
  );
}

HeroSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.string.isRequired,
    body: PropTypes.object,
    subhead: PropTypes.string,
    imageAltText: PropTypes.string,
    textAlignment: PropTypes.string,
    backgroundColor: PropTypes.string,
    imageLocation: PropTypes.string,
    imageOverlay: PropTypes.string,
    verticalImageAlignment: PropTypes.string,
    hideImageOnMobile: PropTypes.bool,
    asset: PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
    mobileImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    form: ContentfulCustomForm.propType(),
    callToActionButtons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        buttonType: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
      }).isRequired,
    ),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
  customFormRenderer: PropTypes.func,
  trackClick: PropTypes.func.isRequired,
  trackEvent: PropTypes.func,
};

function getHeroComponent(imageLocation) {
  const imageLocationToComponent = {
    NO_IMAGE: HeroImageNone,
    TOP_IMAGE: HeroImageTop,
    FULL_BACKGROUND: HeroImageFull,
    RIGHT_IMAGE: HeroImageRight,
    RIGHT_HIDE_IMAGE_ON_MOBILE: HeroImageRight,
  };

  return imageLocationToComponent[constantize(imageLocation)] || HeroImageNone;
}

function buildTextAlignment(textAlignment) {
  const TEXT_ALIGNMENT = {
    CENTER: TEXT_ALIGNMENT_OPTIONS.CENTER,
    LEFT_ALIGN: TEXT_ALIGNMENT_OPTIONS.LEFT,
  };

  return TEXT_ALIGNMENT[constantize(textAlignment)] || TEXT_ALIGNMENT_OPTIONS.CENTER;
}

function buildVerticalImageAlignment(verticalImageAlignment) {
  const IMAGE_ALIGNMENT = {
    CENTER: VERTICAL_IMAGE_ALIGNMENT.CENTER,
    BOTTOM: VERTICAL_IMAGE_ALIGNMENT.BOTTOM,
  };

  return IMAGE_ALIGNMENT[constantize(verticalImageAlignment)] || VERTICAL_IMAGE_ALIGNMENT.CENTER;
}

function buildImageOverlay(imageOverlay) {
  const IMAGE_OVERLAY = {
    NONE: BRAND_BLING_OPTIONS.NONE,
    CARET: BRAND_BLING_OPTIONS.CARET,
    LARGE_ROOT_TEXT: BRAND_BLING_OPTIONS.WORDMARK,
    ROOT_LOGO: BRAND_BLING_OPTIONS.ICON,
  };

  return IMAGE_OVERLAY[constantize(imageOverlay)] || BRAND_BLING_OPTIONS.NONE;
}

function buildButtons(buildCallToActionButton, buttons, trackClick) {
  if (!buttons) { return []; }
  return buttons.map((button) => buildCallToActionButton({
    button,
    trackClick,
  }));
}

export { getHeroComponent, buildImageOverlay };
