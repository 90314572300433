import useAnalytics from '@root/core/src/hooks/use-analytics';
import { useEffect, useRef } from '@root/vendor/react';

export default function useVisibilityAnalytics(analyticsContext, shouldTrackViewEvent = true) {
  const nodeRef = useRef(null);
  const { trackClick, trackEvent } = useAnalytics(analyticsContext, false);

  useEffect(
    () => {
      if (!shouldTrackViewEvent || !nodeRef.current) { return; }
      if (typeof IntersectionObserver === 'undefined') { return; }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            trackEvent('VIEWED');
          }
        });
      }, {
        rootMargin: '0px',
        threshold: 0.40,
      });

      observer.observe(nodeRef.current);

      return () => observer.disconnect();
    }, [analyticsContext, shouldTrackViewEvent, trackEvent]);

  return {
    nodeRef,
    trackClick,
    trackEvent,
  };
}
