/* eslint-disable react/prop-types */
import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import GTMDistinctIdReporter from '@root/gatsby-contentful-core/src/components/gtm-distinct-id-reporter';
import InterfaceActionsProvider from '@root/gatsby-contentful-core/src/contexts/interface-actions/interface-actions-provider';
import OptimizelyTrackingController from '@root/gatsby-contentful-core/src/components/optimizely-tracking-controller';
import ProjectProvider from '@root/core/src/contexts/project/project-provider';
import React from '@root/vendor/react';
import TrackVisitAttributionController from '@root/attribution/src/components/track-visit-attribution-controller';

export const MARKETING_PREFIX = 'MARKETING_WEBSITE';

export default function coreWrapRootElement({
  element, project, interfaceActionsMap,
}) {
  return (
    <>
      <ErrorBoundary showErrorScreen={false}>
        <GTMDistinctIdReporter />
        <TrackVisitAttributionController />
        <AnalyticsProvider eventPrefix={MARKETING_PREFIX}>
          <ProjectProvider project={project}>
            <InterfaceActionsProvider interfaceActionsMap={interfaceActionsMap}>
              <OptimizelyTrackingController />
              {element}
            </InterfaceActionsProvider>
          </ProjectProvider>
        </AnalyticsProvider>
      </ErrorBoundary>
    </>
  );
}
