import ContentfulFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-factory';
import ContentfulRichTextFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-rich-text-factory';
import uuid from '@root/vendor/uuid/v4';

export default class ContentfulExpandableItemFactory {
  static create(overrides = {}) {
    return {
      id: uuid(),
      sys: ContentfulFactory.createSys('expandableItem'),
      title: 'Expandable Item',
      content: {
        json: ContentfulRichTextFactory.create(),
      },
      ...overrides,
    };
  }
}
