import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import QuotationMark from '@root/gatsby-contentful-core/src/assets/components/icons/serif-quotation';
import Responsive from '@root/core/src/utils/responsive';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export const MOBILE_LAYOUT = {
  CAROUSEL: 'carousel',
  COLUMN: 'column',
};

export default function QuoteToutSection({
  anchor,
  quoteToutContent,
  sectionHeadline,
  sectionSpacing = {},
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  mobileLayout = MOBILE_LAYOUT.CAROUSEL,
}) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        mobileLayout === MOBILE_LAYOUT.COLUMN
          ? stylesColumnOnMobile.quoteToutSection
          : stylesCarouselOnMobile.quoteToutSection,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(backgroundColor),
      ]}
      id={anchor}
    >
      {sectionHeadline && <H2 className={'sectionHeadline'}>{sectionHeadline}</H2>}
      <div className={'wrap_touts'}>
        {quoteToutContent.map((tout, i) => (
          <div
            className={'quoteTout'}
            key={i}
          >
            <QuotationMark />
            <div className={'userQuote'}>{tout.userQuote}</div>
            <P1>{tout.userName}</P1>
          </div>
        ))}
        <div className={'extraCarouselMargin'} />
      </div>
    </section>
  );
}

QuoteToutSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  mobileLayout: PropTypes.oneOf(Object.values(MOBILE_LAYOUT)),
  quoteToutContent: PropTypes.arrayOf(
    PropTypes.shape({
      userName: PropTypes.string,
      userQuote: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.node,
      ]).isRequired,
    })
  ),
  sectionHeadline: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const baseStyles_quoteToutSection = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
};

const baseStyles_QuoteTout = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '50px 30px',
  backgroundColor: 'white',
  borderRadius: 6,
  boxShadow: '0 8px 24px hsla(0, 0%, 0%, 0.25)',
  textAlign: 'center',

  svg: {
    marginBottom: 20,
  },

  '.userQuote': {
    marginBottom: 20,
    flex: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    p: {
      color: Colors.nearBlack(),
      fontSize: 20,
    },
  },
};

const baseStyles_shrinkToutOnSmallestDevices = {
  '@media (max-width: 340px)': {

    '.quoteTout': {
      padding: '40px 20px',

      '.userQuote': {
        fontSize: '18px',
      },
    },
  },
};

const stylesColumnOnMobile = StyleSheet.create({
  quoteToutSection: {
    ...baseStyles_quoteToutSection,
    padding: '70px 0 40px',

    ...Responsive.lg({
      padding: '100px 0',
    }),

    '.sectionHeadline': {
      margin: '0 20px 36px',
    },

    '.wrap_touts': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      padding: '0 20px',

      ...Responsive.lg({
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
        padding: '0 10px',
      }),

      '.quoteTout': {
        ...baseStyles_QuoteTout,
        margin: '0 0 30px',
        width: '100%',
        maxWidth: 570,

        ...Responsive.lg({
          margin: '0 10px',
          maxWidth: 330,
        }),
      },

      ...baseStyles_shrinkToutOnSmallestDevices,

      '.extraCarouselMargin': {
        display: 'none',
      },
    },
  },
});

const stylesCarouselOnMobile = StyleSheet.create({
  quoteToutSection: {
    ...baseStyles_quoteToutSection,
    padding: '70px 0 40px',

    '.sectionHeadline': {
      margin: '0 20px',
    },

    '.wrap_touts': {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: '0 7%',
      scrollSnapType: 'x mandatory',
      overflowX: 'scroll',
      webkitOverflowScrolling: 'touch',
      // Hide Scrollbar: Every browser is different here...
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      msOverflowStyle: 'none', // Not currently supported by the parser used in Emotion
      scrollbarWidth: 'none', // Current Firefox solution and likely the future web default solution

      '.quoteTout': {
        ...baseStyles_QuoteTout,
        margin: '36px 8px',
        width: 'calc(100vw - 60px)',
        maxWidth: 330,
        flexShrink: 0,
        userSelect: 'none',
        scrollSnapAlign: 'center',
      },

      ...baseStyles_shrinkToutOnSmallestDevices,

      '.extraCarouselMargin': {
        width: 30,
        flexShrink: 0,
      },
    },

    ...Responsive.sm({
      padding: '70px 0 40px',

      '.sectionHeadline': {
        margin: '0 20px 36px',
      },

      '.wrap_touts': {
        flexDirection: 'column',
        alignItems: 'center',
        overflowX: 'visible',
        padding: '0 10px',

        '.quoteTout': {
          margin: '0 0 30px',
          width: '100%',
          maxWidth: 570,
        },

        '.extraCarouselMargin': {
          display: 'none',
        },
      },
    }),

    ...Responsive.lg({
      padding: '100px 0',

      '.wrap_touts': {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'stretch',
        width: '100%',

        '.quoteTout': {
          flex: 1,
          margin: '0 10px',
          width: '100%',
          maxWidth: 330,
        },
      },
    }),
  },
});
