import Button, { ButtonSize, ButtonType } from '@root/gatsby-contentful-core/src/components/button';
import Checkbox from '@root/gatsby-contentful-core/src/components/checkbox';
import Fieldset from '@root/core/src/components/fieldset';
import InputError from '@root/gatsby-contentful-core/src/components/form/input-error';
import PrivacyActRequest from '@root/joinroot.com/src/models/privacy-act-request';
import PropTypes from '@root/vendor/prop-types';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import TextInput from '@root/gatsby-contentful-core/src/components/form/text-input';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import add from '@root/gatsby-contentful-core/src/assets/icons/add.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import {
  FlexContainer,
  FlexDirection,
  FlexItem,
  FlexPreset,
  FlexWrap,
} from '@root/gatsby-contentful-core/src/components/flex';
import { Formik } from 'formik/dist/index';

export default function PrivacyActRequestForm({ onFormSubmit }) {
  const renderErrors = (errors, value) => {
    if (!errors[value]) { return null; }

    return (
      <InputError css={styles.errors}>{errors[value]}</InputError>
    );
  };

  const renderRequiredFields = (formProps) => (
    <Fieldset title={'Required information'}>
      <FlexContainer flexDirection={FlexDirection.COLUMN}>
        <TextInput
          autoComplete={'given-name'}
          errors={formProps.errors['firstName']}
          isTouched={formProps.touched['firstName']}
          label={'First name'}
          name={'firstName'}
          onBlur={formProps.handleBlur}
          onChange={formProps.handleChange}
          value={formProps.values.firstName}
        />
        <TextInput
          autoComplete={'family-name'}
          errors={formProps.errors['lastName']}
          isTouched={formProps.touched['lastName']}
          label={'Last name'}
          name={'lastName'}
          onBlur={formProps.handleBlur}
          onChange={formProps.handleChange}
          value={formProps.values.lastName}
        />
        <TextInput
          autoComplete={'email'}
          errors={formProps.errors['email']}
          isTouched={formProps.touched['email']}
          label={'Email address'}
          name={'email'}
          onBlur={formProps.handleBlur}
          onChange={formProps.handleChange}
          value={formProps.values.email}
        />
      </FlexContainer>
    </Fieldset>
  );

  const renderOneOfThreeFields = (formProps) => (
    <Fieldset title={'Enter either your policy number, date of birth, or full address.'}>
      <FlexContainer flexDirection={FlexDirection.COLUMN}>
        {renderErrors(formProps.errors, 'oneOfThree')}
        <TextInput
          errors={formProps.errors['policyNumber']}
          isTouched={formProps.touched['policyNumber']}
          label={'Policy number'}
          name={'policyNumber'}
          onBlur={formProps.handleBlur}
          onChange={formProps.handleChange}
          value={formProps.values.policyNumber}
        />
        <TextInput
          errors={formProps.errors['dateOfBirth']}
          isTouched={formProps.touched['dateOfBirth']}
          label={'Date of birth'}
          name={'dateOfBirth'}
          onBlur={formProps.handleBlur}
          onChange={formProps.handleChange}
          value={formProps.values.dateOfBirth}
        />
        <FlexContainer flexDirection={FlexDirection.COLUMN}>
          <TextInput
            autoComplete={'address-line1'}
            errors={formProps.errors['address1']}
            isTouched={formProps.touched['address1']}
            label={'Address line 1'}
            name={'address1'}
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            value={formProps.values.address1}
          />
          <TextInput
            autoComplete={'address-line2'}
            errors={formProps.errors['address2']}
            isTouched={formProps.touched['address2']}
            label={'Apartment or suite (optional)'}
            name={'address2'}
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            value={formProps.values.address2}
          />
          <TextInput
            autoComplete={'address-level2'}
            errors={formProps.errors['city']}
            isTouched={formProps.touched['city']}
            label={'City'}
            name={'city'}
            onBlur={formProps.handleBlur}
            onChange={formProps.handleChange}
            value={formProps.values.city}
          />
          <FlexContainer
            flexWrap={FlexWrap.WRAP}
            gutter={Sizes.SMALL}
          >
            <FlexItem flex={FlexPreset.FLEX}>
              <TextInput
                autoComplete={'address-level1'}
                errors={formProps.errors['state']}
                isTouched={formProps.touched['state']}
                label={'State'}
                name={'state'}
                onBlur={formProps.handleBlur}
                onChange={formProps.handleChange}
                value={formProps.values.state}
              />
            </FlexItem>
            <FlexItem flex={FlexPreset.FLEX}>
              <TextInput
                autoComplete={'postal-code'}
                errors={formProps.errors['zip']}
                isTouched={formProps.touched['zip']}
                label={'ZIP'}
                name={'zip'}
                onBlur={formProps.handleBlur}
                onChange={formProps.handleChange}
                value={formProps.values.zip}
              />
            </FlexItem>
          </FlexContainer>
        </FlexContainer>
      </FlexContainer>
    </Fieldset>
  );

  const renderCheckboxFields = (formProps) => (
    <Fieldset>
      {renderErrors(formProps.errors, 'checkboxes')}
      <Checkbox
        checked={formProps.values.optOut}
        errors={formProps.errors['optOut']}
        fontColor={Colors.black()}
        isTouched={formProps.touched['optOut']}
        label={'I would like to opt-out of data sharing'}
        name={'optOut'}
        onBlur={formProps.handleBlur}
        onChange={formProps.handleChange}
        size={ButtonSize.SMALL}
      />
      <Checkbox
        checked={formProps.values.deleteData}
        errors={formProps.errors['deleteData']}
        fontColor={Colors.black()}
        isTouched={formProps.touched['deleteData']}
        label={'I would like my data deleted'}
        name={'deleteData'}
        onBlur={formProps.handleBlur}
        onChange={formProps.handleChange}
        size={ButtonSize.SMALL}
      />
    </Fieldset>
  );

  return (
    <section>
      <p css={styles.formPrompt}>In order to process this request, please submit the following:</p>
      <Formik
        initialValues={PrivacyActRequest.initialState}
        onSubmit={onFormSubmit}
        validate={PrivacyActRequest.additionalValidations}
        validationSchema={PrivacyActRequest.validationSchema}
      >
        {(formProps) => (
          <form
            css={styles.form}
            onSubmit={formProps.handleSubmit}
          >
            {renderRequiredFields(formProps)}
            <img
              css={styles.icon}
              src={add}
            />
            {renderOneOfThreeFields(formProps)}
            {renderCheckboxFields(formProps)}
            <Button
              css={styles.submitButton}
              disabled={formProps.isSubmitting || !formProps.isValid}
              onClick={formProps.handleSubmit}
              type={ButtonType.SUBMIT}
            >
              Submit request
            </Button>
          </form>
        )}
      </Formik>
    </section>
  );
}

const styles = StyleSheet.create({
  formPrompt: {
    ...Theme.bodyCopy(),
    textAlign: 'center',
  },
  form: {
    margin: '25px auto 80px',
    maxWidth: 475,
    display: 'flex',
    flexDirection: 'column',
  },
  errors: {
    marginBottom: 10,
  },
  icon: {
    alignSelf: 'center',
    color: Colors.black(),
    margin: '10px auto 40px',
  },
  submitButton: {
    borderRadius: 8,
    width: '100%',
    marginTop: 40,
  },
});

PrivacyActRequestForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
};
