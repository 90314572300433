import AuthService from '@root/core/src/services/auth-service';
import findOrCreateAbTestForAccountConfiguration from '@root/core/src/api/find-or-create-ab-test/find-or-create-ab-test-for-account-configuration';
import findOrCreateAbTestForIdentifierConfiguration from '@root/core/src/api/find-or-create-ab-test/find-or-create-ab-test-for-identifier-configuration';
import useAbTests from '@root/core/src/hooks/use-ab-tests';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useCallback, useMemo } from '@root/vendor/react';

export default function useAccountAbTest(abTestName, defaultBucket = null, forceIdentifierConfiguration = false) {
  const [abTests, abTestsDispatch] = useAbTests();
  const bucket = abTests[abTestName];
  const isLoggedIn = !!AuthService.getAccessToken();

  const configuration = useMemo(() => {
    if (bucket) { return null; }
    return isLoggedIn && !forceIdentifierConfiguration ? findOrCreateAbTestForAccountConfiguration(abTestName) : findOrCreateAbTestForIdentifierConfiguration(abTestName);
  }, [abTestName, bucket, forceIdentifierConfiguration, isLoggedIn]);

  const isLoading = useNetworkRequest(configuration, useCallback((result) => {
    abTestsDispatch({
      type: 'SET_BUCKET_FOR_AB_TEST',
      abTestName,
      bucket: result.data.bucket || defaultBucket,
    });
  }, [abTestName, abTestsDispatch, defaultBucket]));

  return [bucket, isLoading];
}
