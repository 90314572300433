import AutoGetStartedBox from '@root/joinroot.com/src/components/auto-get-started-box';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import HomeownersGetStartedBox from '@root/joinroot.com/src/components/homeowners-get-started-box';
import InputWithSubmit from '@root/gatsby-contentful-core/src/components/core/input-with-submit';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import RentersGetStartedBox from '@root/joinroot.com/src/components/renters-get-started-box';
import { Fragment, useState } from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export const FORM_FLOWS = {
  AUTO: 'auto',
  RENTERS: 'renters',
  HOMEOWNERS: 'homeowners',
};

const formFlowMap = {
  [FORM_FLOWS.AUTO]: AutoGetStartedBox,
  [FORM_FLOWS.RENTERS]: RentersGetStartedBox,
  [FORM_FLOWS.HOMEOWNERS]: HomeownersGetStartedBox,
};

export default function ZipCodeForm({
  analytics, className, formFlow, namePrefix = '',
}) {
  const [zipCode, setZipCode] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const isFormSubmittable = zipCode.length === 5;

  const GetStartedBox = formFlowMap[formFlow];
  if (!GetStartedBox) {
    throw new Error(`Unknown form flow ${formFlow}`);
  }

  const updateZipCode = (newZipCode) => {
    setZipCode(newZipCode.replace(/[^0-9]/g, '').substr(0, 5));
  };

  function submitForm() {
    if (!isFormSubmittable || isModalVisible) { return; }
    analytics.trackClick('ZIP_CODE_FORM_SUBMIT');
    setIsModalVisible(true);
  }

  return (
    <Fragment>
      <form
        action={'#'}
        className={className}
        css={styles.form}
        onSubmit={(e) => { e.preventDefault(); submitForm(); }}
      >
        <InputWithSubmit
          autoComplete={'shipping postal-code'}
          inputMode={'decimal'}
          isSubmittable={isFormSubmittable}
          isSubmitted={isModalVisible}
          label={'ZIP code'}
          maxLength={5}
          name={`${namePrefix}-zip-code`}
          onBlur={submitForm}
          onChange={updateZipCode}
          value={zipCode}
        />
      </form>
      <Modal
        isShowing={isModalVisible}
        name={'zip-code-modal'}
        onClose={() => setIsModalVisible(false)}
      >
        <GetStartedBox
          analytics={analytics}
          onClose={() => setIsModalVisible(false)}
          zipCode={zipCode}
        />
      </Modal>
    </Fragment>
  );
}

ZipCodeForm.propTypes = {
  analytics: PropTypes.shape({
    trackClick: PropTypes.func.isRequired,
  }),
  className: PropTypes.string,
  formFlow: PropTypes.oneOf(Object.values(FORM_FLOWS)).isRequired,
  namePrefix: PropTypes.string,
};

const styles = StyleSheet.create({
  form: {
    width: '100%',
    maxWidth: 345,
    height: 60,

    input: {
      caretColor: Colors.rootOrange(),
    },
  },
});
