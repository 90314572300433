import { useEffect } from '@root/vendor/react';

export default function useResize({ onResize }) {
  useEffect(() => {
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [onResize]);

  return onResize;
}
