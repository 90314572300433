import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import BrandBling, { BRAND_BLING_OPTIONS } from '@root/gatsby-contentful-core/src/components/brand-bling';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Eyebrow, H1Display, H3 } from '@root/gatsby-contentful-core/src/components/text';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const baseImageHeightMobile = 180;
const baseImageHeightDesktop = 330;

export default function HeroImageTop({
  anchor,
  buttons,
  body,
  form,
  eyebrow,
  gatsbyImage,
  imageAlt,
  headline,
  brandBlingOptions = BRAND_BLING_OPTIONS.NONE,
  subhead,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  sectionSpacing = {},
}) {
  let renderButtons = false;
  let renderForm = false;

  if (buttons?.length > 0) {
    renderButtons = true;
  } else if (form) {
    renderForm = true;
  }

  const sectionSpacingTop = {
    height: baseImageHeightMobile + sectionSpacing.top / 1.25,
    ...Responsive.md({
      height: baseImageHeightDesktop + sectionSpacing.top,
    }),
  };

  const sectionSpacingBottom = {
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.heroSection,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        sectionSpacingBottom,
      ]}
      id={anchor}
    >
      <div css={[styles.wrapImage, sectionSpacingTop]}>
        <GatsbyImage
          alt={imageAlt ?? ''}
          className={'bgImage'}
          css={styles.image}
          image={getImage(gatsbyImage)}
        />
        <BrandBling brandBlingOptions={brandBlingOptions} />
      </div>

      <div className={'wrap_content'}>
        <div className={'container_content'}>
          {eyebrow && <Eyebrow className={'eyebrow'}>{eyebrow}</Eyebrow>}
          {headline && <H1Display className={'headline'}>{headline}</H1Display>}
          {subhead && <H3 className={'subhead'}>{subhead}</H3>}
          <BodyCopy
            className={'body'}
            content={body}
          />
          {renderButtons && (
            <div className={'container_cta'}>
              {buttons.map((button, i) => {
                const ButtonComponent = button;
                return <ButtonComponent key={i} />;
              })}
            </div>
          )}
          {renderForm && form}
        </div>
      </div>
    </section>
  );
}

HeroImageTop.propTypes = {
  analytics: PropTypes.object,
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  brandBlingOptions: PropTypes.oneOf(Object.values(BRAND_BLING_OPTIONS)),
  buttons: PropTypes.arrayOf(PropTypes.func),
  eyebrow: PropTypes.string,
  form: PropTypes.node,
  gatsbyImage: PropTypes.object.isRequired,
  headline: PropTypes.string.isRequired,
  imageAlt: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  subhead: PropTypes.string,
};

const styles = StyleSheet.create({
  heroSection: {
    position: 'relative',

    '.wrap_content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1200,
        padding: '80px 20px',
        textAlign: 'center',

        ...Responsive.md({
          padding: '100px 20px',
        }),

        '.eyebrow': {
          maxWidth: 350,
        },

        '.headline, .subhead, .body': {
          maxWidth: 550,

          ...Responsive.md({
            maxWidth: 800,
          }),
        },

        '.subhead': {
          color: Colors.gray50(),
          fontWeight: 400,
        },

        '.eyebrow, .headline, .subhead, .body': {
          marginBottom: 12,
        },

        '.container_cta': {
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30,
          width: '100%',
          maxWidth: 260,

          '& > a + a': {
            margin: '16px 0 0 0',
          },

          ...Responsive.md({
            justifyContent: 'center',
            flexDirection: 'row',
            maxWidth: 480,

            '& > a + a': {
              margin: '0 0 0 16px',
            },
          }),
        },
      },
    },
  },
  wrapImage: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    height: baseImageHeightMobile,

    ...Responsive.md({
      height: baseImageHeightDesktop,
    }),
  },
  image: {
    width: '100%',
    minHeight: '100%',
  },
});
