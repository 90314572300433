import { isRequired, isValidEmail, isValidPhoneNumber } from '@root/core/src/utils/validators';

export default function generateFieldValidations(fieldGroups) {
  const validations = [];

  fieldGroups.forEach((group) => {
    if (!group.fields.length) { return null; }

    group.fields.forEach((field) => {
      if (field.required) {
        validations.push(isRequired(field.name, `${field.label} is required`));
      }
      if (field.name === 'email') {
        validations.push(isValidEmail(field.name, 'Please enter a valid email address'));
      }
      if (field.name === 'phone') {
        validations.push(isValidPhoneNumber(field.name, 'Please enter a valid phone number'));
      }
    });
  });

  return validations;
}
