import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function AccountSignInLink({ isDarkMode }) {
  const { trackClick } = useAnalytics('ACCOUNT_SIGN_IN_LINK', false);

  const handleClick = () => {
    trackClick('SIGN_IN');
  };

  return (
    <a
      css={[styles.logInButton, isDarkMode && linkStylesWhenDarkMode]}
      href={environment.accountAppBaseUrl}
      onClick={handleClick}
    >
      Sign in
    </a>
  );
}

AccountSignInLink.propTypes = {
  isDarkMode: PropTypes.bool,
};

const styles = StyleSheet.create({
  logInButton: {
    ...Theme.navLink(),
    transition: 'color 0.1s cubic-bezier(0.455, 0.03, 0.515, 0.955), transform 350ms cubic-bezier(0.37, 0, 0.28, 1) 200ms, opacity 350ms cubic-bezier(0.37, 0, 0.28, 1) 200ms',
    textDecoration: 'none',
    '&:hover': {
      color: Colors.rootOrange(),
    },
    '&:focus': {
      color: Colors.rootOrange(),
    },
    textAlign: 'center',
    marginRight: 0,
    marginTop: 30,
    opacity: 0,
    transform: 'translate(0, -100px)',
    fontSize: 21,
    ...Responsive.md({
      opacity: 1,
      marginTop: 0,
      transform: 'none',
      fontSize: 16,
      lineHeight: '36px',
    }),
    '.nav-is-open &': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
  },
});

const linkStylesWhenDarkMode = {
  color: Colors.white(),
};
