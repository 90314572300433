import NavLink from '@root/joinroot.com/src/components/nav/nav-link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function NavLinks({ isBannerOn, isDarkMode }) {
  return (
    <ul
      css={[
        styles.navList,
        isBannerOn && stylesWhenBanner.navList,
        isDarkMode && navListStylesWhenDarkMode,
      ]}
    >
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={isDarkMode ? linkStylesWhenDarkMode : null}
          to={'/coverage/'}
        >
          Coverage
        </NavLink>
      </li>
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={isDarkMode ? linkStylesWhenDarkMode : null}
          to={'/claims/'}
        >
          Claims
        </NavLink>
      </li>
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={isDarkMode ? linkStylesWhenDarkMode : null}
          to={'/test-drive/'}
        >
          Test Drive
        </NavLink>
      </li>
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={isDarkMode ? linkStylesWhenDarkMode : null}
          to={'/insurance-availability-by-state/'}
        >
          Availability
        </NavLink>
      </li>
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={isDarkMode ? linkStylesWhenDarkMode : null}
          to={`${environment.incSiteBaseUrl}/careers/`}
        >
          Careers
        </NavLink>
      </li>
    </ul>
  );
}

NavLinks.propTypes = {
  isBannerOn: PropTypes.bool,
  isDarkMode: PropTypes.bool,
};

const styles = StyleSheet.create({
  navList: {
    padding: '40px 40px 20px',
    margin: 0,
    ...Responsive.md({
      padding: '8px 0',
      textAlign: 'center',
      borderBottom: `1px solid ${Colors.gray20()}`,
    }),
    ...Responsive.lg({
      position: 'absolute',
      top: 16,
      padding: 0,
      border: 'none',
      display: 'flex',
      alignSelf: 'center',
    }),
  },
  navListItem: {
    listStyle: 'none',
    margin: 0,
    padding: '13px 0',
    borderBottom: `1px solid ${Colors.nearWhite()}`,
    opacity: 0,
    transform: 'translate(-50px, 0)',
    transition: 'all 350ms cubic-bezier(0.37, 0, 0.28, 1)',
    '&:nth-of-type(1)': {
      transitionDelay: '50ms',
    },
    '&:nth-of-type(2)': {
      transitionDelay: '100ms',
    },
    '&:nth-of-type(3)': {
      transitionDelay: '150ms',
    },
    '&:nth-of-type(4)': {
      transitionDelay: '200ms',
    },
    '&:nth-of-type(5)': {
      transitionDelay: '250ms',
    },
    '.nav-is-open &': {
      opacity: 1,
      transform: 'translate(0, 0)',
    },
    '&:last-of-type': {
      borderBottom: 'none',
    },
    ...Responsive.md({
      display: 'inline-block',
      margin: '0 15px',
      padding: 0,
      borderBottom: 'none',
      transitionDelay: 'none',
      opacity: 1,
      transform: 'none',
      transition: 'none',
    }),
    ...Responsive.lg({
      margin: '0 12px',
    }),
  },
});

const stylesWhenBanner = StyleSheet.create({
  navList: {
    ...Responsive.lg({
      top: 66,
    }),
  },
});

const navListStylesWhenDarkMode = {
  ...Responsive.md({
    borderBottom: 'none',
  }),
};

const linkStylesWhenDarkMode = {
  color: Colors.white(),
};
