import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

export default function CheckFilled({
  fillColor = Colors.white(), strokeColor = Colors.rootOrange(), ...props
}) {
  return (
    <svg
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
      {...props}
    >
      <title>Filled checkmark icon</title>
      <circle
        cx={'12'}
        cy={'12'}
        fill={fillColor}
        r={'11'}
      />
      <path
        d={'M7 11.5L10.5 15L17.5 8'}
        stroke={strokeColor}
        strokeWidth={'1.5'}
      />
    </svg>
  );
}

CheckFilled.propTypes = {
  fillColor: PropTypes.string,
  strokeColor: PropTypes.string,
};
