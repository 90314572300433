import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function TableSection({ sectionSpacing = {}, tableData }) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section css={[styles.section, sectionSpacingStyles]}>
      <table css={styles.table}>
        <tbody css={styles.tableBody}>
          {tableData.map((row, i) => (
            <tr
              css={styles.row}
              key={i}
            >
              {row.map((item, j) => (
                <td
                  css={styles.cell}
                  key={j}
                >
                  {item}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}

TableSection.propTypes = {
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  tableData: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string,
    ).isRequired,
  ).isRequired,
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
  },
  table: {
    display: 'flex',
    width: '100%',
    maxWidth: 840,
    padding: '80px 20px',
    ...Responsive.md({
      padding: '100px 20px',
    }),
  },
  tableBody: {
    flex: 1,
    color: Colors.gray60(),
    letterSpacing: '-0.015em',
    lineHeight: '130%',
  },
  row: {
    display: 'flex',
    '&:first-of-type': {
      color: Colors.nearBlack(),
      fontSize: 20,
    },
    '&:nth-of-type(even)': {
      backgroundColor: Colors.gray10(),
    },
  },
  cell: {
    flex: 1,
    padding: '10px 18px',
  },
});
