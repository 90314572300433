import InterfaceActionsContext from '@root/gatsby-contentful-core/src/contexts/interface-actions/interface-actions-context';
import PropTypes from '@root/vendor/prop-types';
import { useState } from '@root/vendor/react';

export default function InterfaceActionsProvider({ children, interfaceActionsMap }) {
  const [data] = useState({ interfaceActionsMap });

  return (
    <InterfaceActionsContext.Provider value={data}>
      {children}
    </InterfaceActionsContext.Provider>
  );
}

InterfaceActionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  interfaceActionsMap: PropTypes.object.isRequired,
};
