import AffiliatesLandingPageHero from '@root/joinroot.com/src/components/affiliates/affiliates-landing-page-hero';
import AllProductsAvailabilitySection from '@root/joinroot.com/src/components/all-products-availability';
import Caret from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-caret';
import CarvanaEmbeddedHero from '@root/joinroot.com/src/components/carvana-embedded-hero';
import DropTheScoreNJFormSection from '@root/joinroot.com/src/components/sections/drop-the-score-nj/drop-the-score-nj-form-section';
import QuoteHeaderLegacy from '@root/joinroot.com/src/components/quote-header-legacy';
import StaticAspectRatioHero from '@root/joinroot.com/src/components/static-aspect-ratio-hero';

export default class ContentfulCustomReactComponentMap {
  static IDS = {
    QUOTE_HEADER_LEGACY: 'quote-header-legacy',
    BRAND_BUILDING_HERO: 'brand-building-hero',
    AFFILIATES_LANDING_PAGE_HERO: 'affiliates-landing-page-hero',
    EXAMPLE_COMPONENT_ID: 'example-component-id',
    ALL_PRODUCTS_AVAILABILITY_SECTION: 'all-products-availability-section',
    DROP_THE_SCORE_NJ_FORM_SECTION: 'drop-the-score-nj-form-section',
    CARVANA_EMBEDDED_HERO: 'carvana-embedded-hero',
  };

  static ID_TO_COMPONENT_MAP = {
    [ContentfulCustomReactComponentMap.IDS.QUOTE_HEADER_LEGACY]: QuoteHeaderLegacy,
    [ContentfulCustomReactComponentMap.IDS.BRAND_BUILDING_HERO]: StaticAspectRatioHero,
    [ContentfulCustomReactComponentMap.IDS.AFFILIATES_LANDING_PAGE_HERO]: AffiliatesLandingPageHero,
    [ContentfulCustomReactComponentMap.IDS.EXAMPLE_COMPONENT_ID]: Caret,
    [ContentfulCustomReactComponentMap.IDS.ALL_PRODUCTS_AVAILABILITY_SECTION]: AllProductsAvailabilitySection,
    [ContentfulCustomReactComponentMap.IDS.DROP_THE_SCORE_NJ_FORM_SECTION]: DropTheScoreNJFormSection,
    [ContentfulCustomReactComponentMap.IDS.CARVANA_EMBEDDED_HERO]: CarvanaEmbeddedHero,
  };
}
