import Caret from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-caret';
import Colors, { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';
import Icon from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-r-icon';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Wordmark from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-wordmark-stroked';
import { StyleSheet } from '@root/core/src/utils/styles';

export const BRAND_BLING_OPTIONS = {
  NONE: 'none',
  CARET: 'caret',
  ICON: 'icon',
  WORDMARK: 'wordmark',
};

export default function BrandBling({
  brandBlingOptions = BRAND_BLING_OPTIONS.NONE,
  brandBlingStyles,
}) {
  if (brandBlingOptions !== BRAND_BLING_OPTIONS.NONE) {
    return (
      <div css={[styles[brandBlingOptions], brandBlingStyles]}>
        {brandBlingOptions === BRAND_BLING_OPTIONS.CARET && <Caret />}
        {brandBlingOptions === BRAND_BLING_OPTIONS.ICON && <Icon />}
        {brandBlingOptions === BRAND_BLING_OPTIONS.WORDMARK && (
          <Wordmark
            strokeColor={toRgba(Colors.white(), 0.70)}
            strokeWidth={5}
          />
        )}
      </div>
    );
  }
  return null;
}

BrandBling.propTypes = {
  brandBlingOptions: PropTypes.oneOf(Object.values(BRAND_BLING_OPTIONS)).isRequired,
  brandBlingStyles: PropTypes.oneOf([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

const styles = StyleSheet.create({
  icon: {
    position: 'absolute',
    width: 60,
    height: 60,
    bottom: -30,

    svg: {
      filter: `drop-shadow(0 10px 25px ${Colors.nearBlack(0.25)})`,
      overflow: 'visible',
    },
  },

  caret: {
    position: 'absolute',
    width: 92,
    height: 70,
    bottom: -30,

    ...Responsive.md({
      width: 152,
      height: 114,
      bottom: -50,
    }),

    svg: {
      fill: 'white',
      filter: `drop-shadow(0 10px 25px ${Colors.nearBlack(0.25)})`,
      overflow: 'visible',
    },
  },

  wordmark: {
    position: 'absolute',
    top: '30%',
    width: '90%',
    height: '100%',
    overflow: 'hidden',

    ...Responsive.md({
      top: '40%',
    }),

    ...Responsive.lg({
      top: '20%',
    }),

    svg: {
      width: '100%',
      height: 'auto',
    },
  },
});
