import AvailabilityMapBlock from '@root/joinroot.com/src/components/blocks/availability-map-block';
import BlogHeaderSectionBlock from '@root/joinroot.com/src/components/blocks/blog-header-section-block';
import BlogRelatedPostsSection from '@root/joinroot.com/src/components/blocks/blog-related-posts-section-block';
import CoreBlockComponentMap from '@root/gatsby-contentful-core/src/models/core-block-component-map';
import CustomFormRenderer from '@root/joinroot.com/src/components/forms/custom-form-renderer';
import CustomReactComponentBlock from '@root/joinroot.com/src/components/blocks/custom-react-component-block';
import DisclaimerSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/disclaimer-section-block';
import ExpandableSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/expandable-section-block';
import FaqSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/faq-section-block';
import HeroSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/hero-section-block';
import HeroSimpleSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/hero-simple-section-block';
import IconToutGridBlock from '@root/gatsby-contentful-core/src/components/blocks/icon-tout-grid-block';
import IconToutSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/icon-tout-section-block';
import IconToutSideBySideSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/icon-tout-side-by-side-section-block';
import ImageToutSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/image-tout-section-block';
import ImageToutSideBySideSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/image-tout-side-by-side-section-block';
import PressSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/press-section-block';
import QuoteToutSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/quote-tout-section-block';
import RichTextSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/rich-text-section-block';
import TableSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/table-section-block';
import VideoToutSectionBlock from '@root/gatsby-contentful-core/src/components/blocks/video-tout-section-block';

export default class BlockComponentMap {
  constructor() {
    return new CoreBlockComponentMap({
      ids: BlockComponentMap.IDS,
      map: BlockComponentMap.MAP,
    });
  }

  static IDS = {
    AVAILABILITY_MAP: 'AVAILABILITY_MAP',
    BLOG_HEADER_SECTION: 'BLOG_HEADER_SECTION',
    BLOG_RELATED_POSTS_SECTION: 'BLOG_RELATED_POSTS_SECTION',
    CUSTOM_FORM_RENDERER: 'CUSTOM_FORM_RENDERER',
    CUSTOM_REACT_COMPONENT: 'CUSTOM_REACT_COMPONENT',
    DISCLAIMER_SECTION: 'DISCLAIMER_SECTION',
    EXPANDABLE_SECTION: 'EXPANDABLE_SECTION',
    FAQ_SECTION: 'FAQ_SECTION',
    HERO: 'HERO',
    HERO_SIMPLE: 'HERO_SIMPLE',
    ICON_TOUT_GRID: 'ICON_TOUT_GRID',
    ICON_TOUT_SECTION: 'ICON_TOUT_SECTION',
    ICON_TOUT_SIDE_BY_SIDE: 'ICON_TOUT_SIDE_BY_SIDE',
    IMAGE_TOUT_SECTION: 'IMAGE_TOUT_SECTION',
    IMAGE_TOUT_SIDE_BY_SIDE_SECTION: 'IMAGE_TOUT_SIDE_BY_SIDE_SECTION',
    QUOTE_TOUT_SECTION: 'QUOTE_TOUT_SECTION',
    PRESS_SECTION: 'PRESS_SECTION',
    RICH_TEXT_SECTION: 'RICH_TEXT_SECTION',
    TABLE_SECTION: 'TABLE_SECTION',
    VIDEO_TOUT_SECTION: 'VIDEO_TOUT_SECTION',
  };

  static MAP = {
    [BlockComponentMap.IDS.AVAILABILITY_MAP]: AvailabilityMapBlock,
    [BlockComponentMap.IDS.BLOG_HEADER_SECTION]: BlogHeaderSectionBlock,
    [BlockComponentMap.IDS.BLOG_RELATED_POSTS_SECTION]: BlogRelatedPostsSection,
    [BlockComponentMap.IDS.CUSTOM_FORM_RENDERER]: CustomFormRenderer,
    [BlockComponentMap.IDS.CUSTOM_REACT_COMPONENT]: CustomReactComponentBlock,
    [BlockComponentMap.IDS.DISCLAIMER_SECTION]: DisclaimerSectionBlock,
    [BlockComponentMap.IDS.EXPANDABLE_SECTION]: ExpandableSectionBlock,
    [BlockComponentMap.IDS.FAQ_SECTION]: FaqSectionBlock,
    [BlockComponentMap.IDS.HERO]: HeroSectionBlock,
    [BlockComponentMap.IDS.HERO_SIMPLE]: HeroSimpleSectionBlock,
    [BlockComponentMap.IDS.ICON_TOUT_GRID]: IconToutGridBlock,
    [BlockComponentMap.IDS.ICON_TOUT_SECTION]: IconToutSectionBlock,
    [BlockComponentMap.IDS.ICON_TOUT_SIDE_BY_SIDE]: IconToutSideBySideSectionBlock,
    [BlockComponentMap.IDS.IMAGE_TOUT_SECTION]: ImageToutSectionBlock,
    [BlockComponentMap.IDS.IMAGE_TOUT_SIDE_BY_SIDE_SECTION]: ImageToutSideBySideSectionBlock,
    [BlockComponentMap.IDS.QUOTE_TOUT_SECTION]: QuoteToutSectionBlock,
    [BlockComponentMap.IDS.PRESS_SECTION]: PressSectionBlock,
    [BlockComponentMap.IDS.RICH_TEXT_SECTION]: RichTextSectionBlock,
    [BlockComponentMap.IDS.TABLE_SECTION]: TableSectionBlock,
    [BlockComponentMap.IDS.VIDEO_TOUT_SECTION]: VideoToutSectionBlock,
  }
}

