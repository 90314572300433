export default () => (
  <svg
    data-testid={'SVG - Root icon'}
    fill={'none'}
    viewBox={'0 0 60 60'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <rect
      fill={'white'}
      height={'41.0123'}
      width={'42.9094'}
      x={'10.6914'}
      y={'8.98438'}
    />
    <path
      clipRule={'evenodd'}
      d={'M0.653961 3.27606C0 4.55953 0 6.23968 0 9.6V50.4C0 53.7603 0 55.4405 0.653961 56.7239C1.2292 57.8529 2.14708 58.7708 3.27606 59.346C4.55953 60 6.23968 60 9.6 60H50.4C53.7603 60 55.4405 60 56.7239 59.346C57.8529 58.7708 58.7708 57.8529 59.346 56.7239C60 55.4405 60 53.7603 60 50.4V9.6C60 6.23969 60 4.55953 59.346 3.27606C58.7708 2.14708 57.8529 1.2292 56.7239 0.653961C55.4405 0 53.7603 0 50.4 0H9.6C6.23969 0 4.55953 0 3.27606 0.653961C2.14708 1.2292 1.2292 2.14708 0.653961 3.27606ZM37.621 32.9103L37.0313 33.2121L44.9469 44.7703H35.9214L30.16 34.5116H26.6879V44.7703H18.5469V14.574C20.6282 14.277 25.7823 13.5703 29.8652 13.5703C34.7961 13.5703 38.4432 14.5908 40.7054 16.6054C42.5695 18.2643 43.5151 20.6298 43.5151 23.6349C43.5151 27.6067 41.3667 30.9879 37.621 32.9103ZM26.6879 29.5063H29.6761C32.7218 29.5063 35.0193 27.3288 35.0181 24.441C35.0181 21.182 33.0683 19.3866 29.5293 19.3866C28.6988 19.3866 27.6828 19.4668 27.0004 19.5854L26.6879 19.6393V29.5063Z'}
      fill={'#FF5715'}
      fillRule={'evenodd'}
    />
  </svg>
);
