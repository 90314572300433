import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export const SelectOption = ({
  forwardRef,
  onClick,
  onKeyDown,
  option,
  overrideStyles,
  selectedValue,
}) => {
  const handleClick = () => {
    onClick(option);
  };

  return (
    <div
      css={[styles.item, overrideStyles, selectedValue === option.value && styles.selected]}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      ref={forwardRef}
      tabIndex={1}
    >
      <span>{option.label}</span>
      <SubLabel option={option} />
    </div>
  );
};

SelectOption.propTypes = {
  forwardRef: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string,
    value: PropTypes.string.isRequired,
  }).isRequired,
  overrideStyles: PropTypes.object,
  selectedValue: PropTypes.string.isRequired,
};

const SubLabel = ({ option }) => {
  if (!option.subLabel) {
    return null;
  }
  return (
    <span css={styles.subLabel}>
      {option.subLabel}
    </span>
  );
};

SubLabel.propTypes = {
  option: PropTypes.shape({
    label: PropTypes.string.isRequired,
    subLabel: PropTypes.string,
    value: PropTypes.string.isRequired,
  }),
};

const styles = StyleSheet.create({
  item: {
    ...Theme.input({
      placeholder: true,
    }),
    minHeight: 50,
    padding: '9px 17px',
    outline: 'none',
    color: Colors.black(),
    ':focus, :focus > span': {
      background: Colors.white(),
    },
    ':hover, :hover > span': {
      background: Colors.rootOrange(),
      color: Colors.white(),
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selected: {
    ':not(:focus):not(:hover)': {
      background: Colors.nearWhite(),
    },
  },
  subLabel: {
    marginLeft: 'auto',
    color: Colors.gray50(),
  },
});
