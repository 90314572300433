/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import WebVitalsService from '@root/web-vitals/src/services/web-vitals-service';
import buildOnRouteUpdate from '@root/gatsby-contentful-core/src/utils/gatsby-on-route-update';
import environment from '@root/core/src/utils/environment';
import wrapRootElement, { MARKETING_PREFIX } from '@root/joinroot.com/src/utils/gatsby-wrap-root-element';
import { RootIdService } from '@root/attribution/src/services/root-id-service';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';
import 'focus-visible';
import 'intersection-observer';

const onClientEntry = () => {
  WebVitalsService.initialize(MARKETING_PREFIX);

  if (environment.dataDogEnabled) {
    datadogRum.init({
      applicationId: environment.dataDogApplicationId,
      clientToken: environment.dataDogClientToken,
      site: 'datadoghq.com',
      service: 'www.joinroot.com',
      env: environment.environment,
      version: environment.releaseSha,
      sampleRate: 100,
      trackInteractions: true,
      allowedTracingOrigins: [/https?:\/\/localhost(:\d+)?/, /^https?:\/\/app\.(.*\.)?joinroot.com(:\d+)?/, /^https?:\/\/root-staging\.joinroot.com(:\d+)?/],
      trackSessionAcrossSubdomains: true,
    });

    // eslint-disable-next-line root/prevent-use-of-window-location
    const urlParams = Object.fromEntries(new URLSearchParams(window.location.search));
    datadogRum.addRumGlobalContext('urlParameters', urlParams);

    const rootId = RootIdService.read();
    datadogRum.addRumGlobalContext('rootId', rootId);
  }
};

const onRouteUpdate = buildOnRouteUpdate(MARKETING_PREFIX);

export { wrapRootElement, onClientEntry, onRouteUpdate };
