import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

function ArrowThinIcon({ iconStyles }) {
  return (
    <svg
      aria-labelledby={'arrow-thin-icon-title'}
      css={iconStyles}
      fill={'none'}
      height={'17'}
      viewBox={'0 0 30 17'}
      width={'30'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'arrow-thin-icon-title'}>Thin arrow icon</title>
      <path
        d={'M29.25 1L15.25 15L1.25 0.999998'}
        strokeWidth={'2'}
      />
    </svg>
  );
}

ArrowThinIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default ArrowThinIcon;
