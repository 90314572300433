import PropTypes from '@root/vendor/prop-types';
import { Helmet } from '@root/website-tooling/react-helmet';

export default function Seo({
  lang = 'en',
  canonical,
  deIndexPage = false,
  description,
  keywords,
  linkedData,
  title,
  image,
}) {
  const metaImage = buildMetaImageUrl(image, canonical);

  const meta = [
    {
      name: 'description',
      content: description,
    },
    {
      name: 'keywords',
      content: keywords,
    },
    {
      name: 'twitter:title',
      content: title,
    },
    {
      name: 'twitter:description',
      content: description,
    },
    {
      name: 'twitter:image',
      content: metaImage,
    },
    {
      property: 'og:title',
      content: title,
    },
    {
      property: 'og:description',
      content: description,
    },
    {
      property: 'og:type',
      content: 'website',
    },
    {
      property: 'og:image',
      content: metaImage,
    },
  ];

  if (deIndexPage) {
    meta.push({
      name: 'robots',
      content: 'noindex',
    });
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      link={[
        {
          rel: 'canonical',
          href: canonical,
        },
      ]}
      meta={meta}
      script={[
        {
          type: 'application/ld+json',
          innerHTML: linkedData,
        },
      ]}
      title={title}
      titleTemplate={'%s'}
    />
  );
}

Seo.propTypes = {
  canonical: PropTypes.string.isRequired,
  deIndexPage: PropTypes.bool,
  description: PropTypes.string,
  image: PropTypes.string,
  keywords: PropTypes.string,
  lang: PropTypes.string,
  linkedData: PropTypes.string,
  title: PropTypes.string.isRequired,
};

function buildMetaImageUrl(url, canonical) {
  if (!url) { return ''; }

  const isSourcedFromContentful = url.startsWith('//');
  const isSourcedFromMonorepo = url.startsWith('/static');

  if (isSourcedFromContentful) {
    return `https:${url}`;
  }

  if (isSourcedFromMonorepo && canonical) {
    const origin = new URL(canonical).origin;
    return `${origin}${url}`;
  }

  return url;
}

