export const checkStatus = (response) => {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
};

export const parseJSON = (response) => {
  return response.json();
};

export const fetchHelper = async (url, options) => {
  if (process.env.NODE_ENV === 'production') {
    return await fetch(url, options);
  }
  return {
    json: () => {},
    status: 200,
  };
};
