import HubspotFormModalButton from '@root/joinroot.com/src/components/forms/hubspot-form-modal-button';

export default class InterfaceActionsMap {
  constructor() {
    this.ids = InterfaceActionsMap.IDS;
    this.idToComponentMap = InterfaceActionsMap.ID_TO_COMPONENT_MAP;
  }

  static IDS = {
    OPEN_HUBSPOT_MODAL: 'openHubspotModal',
  };

  static ID_TO_COMPONENT_MAP = {
    [InterfaceActionsMap.IDS.OPEN_HUBSPOT_MODAL]: HubspotFormModalButton,
  };
}
