import PropTypes from '@root/vendor/prop-types';

export default function ChevronDownIcon({ strokeColor = 'black' }) {
  return (
    <svg
      fill={'none'}
      height={'13'}
      viewBox={'0 0 22 13'}
      width={'22'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        d={'M21 0.886429L11 10.8864L1 0.886427'}
        stroke={strokeColor}
        strokeWidth={'2'}
      />
    </svg>
  );
}

ChevronDownIcon.propTypes = {
  strokeColor: PropTypes.string,
};
