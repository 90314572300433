import CheckFilled from '@root/gatsby-contentful-core/src/components/core/icons/check-filled';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import Input, { TextTypes } from '@root/core/src/components/input';
import PropTypes from '@root/vendor/prop-types';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import arrowRightBlack from '@root/gatsby-contentful-core/src/assets/icons/arrow-right-black.svg';
import { AnimationStyleSheet, StyleSheet } from '@root/core/src/utils/styles';
import { CSSTransition, TransitionGroup } from '@root/vendor/react-transition-group';

export default function InputWithSubmit({
  isSubmittable, isSubmitted, inputRef, ...props
}) {
  const arrowButtonStyles = [styles.arrowButton];
  const arrowStyles = [styles.arrow];
  if (isSubmittable) {
    arrowButtonStyles.push(styles.arrowButtonClickable);
    arrowStyles.push(styles.arrowVisible);
  }
  return (
    <TransitionGroup css={styles.transitionGroup}>
      <CSSTransition
        classNames={animationClassNames}
        key={isSubmitted}
        timeout={400}
      >
        <div css={styles.transitionWrapper}>
          {!isSubmitted && (
            <div css={styles.container}>
              <Input
                css={styles.input}
                ref={inputRef}
                {...props}
              />
              <button
                css={arrowButtonStyles}
                type={'submit'}
              >
                <img
                  alt={'Submit Icon'}
                  css={arrowStyles}
                  src={arrowRightBlack}
                />
              </button>
            </div>
          )}
          {isSubmitted && (
            <div css={styles.thanksBox}>
              Thanks! <CheckFilled />
            </div>
          )}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
}

InputWithSubmit.TextTypes = TextTypes;
InputWithSubmit.propTypes = {
  inputRef: PropTypes.any,
  isSubmittable: PropTypes.bool.isRequired,
  isSubmitted: PropTypes.bool.isRequired,
  styleProps: PropTypes.object,
};

const animationClassNames = AnimationStyleSheet.createClassNames({
  enter: {
    opacity: '0',
  },
  enterActive: {
    transition: 'all 400ms cubic-bezier(0.165, 0.85, 0.45, 1)',
    opacity: '1',
  },
  enterDone: {
    opacity: '1',
  },
  exit: {
    opacity: '1',
  },
  exitActive: {
    transition: 'all 400ms cubic-bezier(0.165, 0.85, 0.45, 1)',
    opacity: '0',
  },
  exitDone: {
    opacity: '0',
  },
});

const styles = StyleSheet.create({
  input: {
    borderRadius: 8,
    '&:focus': {
      borderColor: Colors.nearBlack(),
    },
  },
  container: {
    position: 'relative',
    overflow: 'hidden',
  },
  arrowButton: {
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    width: 60,
    alignItems: 'center',
    justifyContent: 'center',
    padding: 0,
    display: 'flex',
    pointerEvents: 'none',
  },
  arrowButtonClickable: {
    pointerEvents: 'auto',
  },
  arrow: {
    height: 15,
    width: 15,
    transition: 'transform 150ms cubic-bezier(0.165, 0.85, 0.45, 1)',
    transform: 'translateX(60px)',
  },
  arrowVisible: {
    transform: 'translateX(0px)',
  },
  transitionGroup: {
    position: 'relative',
    width: '100%',
  },
  transitionWrapper: {
    position: 'absolute',
    width: '100%',
  },
  thanksBox: {
    height: 65,
    background: Colors.rootOrange(),
    borderRadius: 8,
    ...Theme.bodyCopy(),
    color: Colors.white(),
    padding: '0 20px 0 15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});
