import BetLogo from '@root/joinroot.com/src/assets/images/progress/bet-logo';
import FastCompanyLogo from '@root/joinroot.com/src/assets/images/progress/fast-company-logo';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import YahooSportsLogo from '@root/joinroot.com/src/assets/images/progress/yahoo-sports-logo';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Duration, Easing } from '@root/gatsby-contentful-core/src/utils/animation';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

const logos = [
  {
    svg: <FastCompanyLogo />,
    href: 'https://www.fastcompany.com/90567424/bubba-wallace-is-unapologetic-in-striking-new-root-insurance-ad',
  },
  {
    svg: <BetLogo />,
    href: 'https://www.bet.com/news/sports/2020/10/27/bubba-wallace-nascar-root-ad-no-apologies.html',
  },
  {
    svg: <YahooSportsLogo />,
    href: 'https://sports.yahoo.com/bubba-wallace-refuses-to-apologize-to-president-trump-in-new-ad-201907915.html',
  },
];

export default function NotAloneSection({
  content: {
    headline,
    body,
  },
}) {
  return (
    <section css={styles.section}>
      <div css={styles.contentWrap}>
        <div css={styles.textContent}>
          <H2 css={textStyles}>{headline}</H2>
          <P1 css={textStyles}>{body}</P1>
        </div>
        <div css={styles.logosContainer}>
          {logos.map((logo, i) => (
            <Link
              css={styles.logoAnchor}
              key={i}
              rel={'noopener'}
              target={'_blank'}
              to={logo.href}
            >
              {logo.svg}
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
}

NotAloneSection.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.black(),
  },
  contentWrap: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    margin: '60px 20px 30px',
    ...Responsive.lg({
      margin: '80px 20px',
    }),
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 640,
    textAlign: 'center',
    '& > * + *': {
      marginTop: 12,
    },
  },
  logosContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 760,
    marginTop: 25,
    ...Responsive.md({
      flexDirection: 'row',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
    }),
  },
  logoAnchor: {
    padding: 20,
    opacity: 0.4,
    transition: `opacity ${Duration.NORMAL} ${Easing.easeOutQuart}`,
    '&:hover': {
      opacity: 1,
    },
    svg: {
      display: 'block',
    },
  },
});
