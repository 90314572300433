import '@root/vendor/whatwg-fetch';
import 'url-search-params-polyfill';

import React, { useRef, useState } from '@root/vendor/react';
import RootLogo from '@root/brand/src/components/root-logo';
import Seo from '@root/gatsby-contentful-core/src/components/seo';
import getTheApp from '@root/joinroot.com/src/assets/images/app-legacy/get-the-app-hero.png';
import normalizeStyles from '@root/gatsby-contentful-core/src/components/normalize.css';
import pageThumbnail from '@root/joinroot.com/src/assets/images/app-legacy/get-the-app-thumbnail.png';
import smsAppDownloadConfiguration from '@root/joinroot.com/src/api/sms-app-download-configuration';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Global, css } from '@root/vendor/@emotion/core';
import { H1Display, Subhead } from '@root/gatsby-contentful-core/src/components/text';
import { VALID_PHONE_NUMBER_REGEX, cleanPhoneNumberValue } from '@root/core/src/models/phone';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const AppLegacyPage = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setErrorMessage] = useState('');
  const isSubmittingRef = useRef(false);

  const rootId = useRootId();

  const [sendTextMessage] = useImperativeNetworkRequest(smsAppDownloadConfiguration);

  useSaveTouchAttributionEffect();

  function handleSubmit(e) {
    e.preventDefault();

    if (isSubmitted || isSubmittingRef.current) { return; }

    const cleanedPhoneNumber = cleanPhoneNumberValue(phoneNumber);
    const isValid = cleanedPhoneNumber.match(VALID_PHONE_NUMBER_REGEX);

    if (isValid) {
      isSubmittingRef.current = true;
      setErrorMessage('');
      sendTextMessage({
        rootId,
        phoneNumber,
      })
        .then(() => {
          isSubmittingRef.current = false;
          setIsSubmitted(true);
        })
        .catch(() => {
          isSubmittingRef.current = false;
          setErrorMessage('Something went wrong. Please try again with your 10 digit number.');
        });
    } else {
      setErrorMessage('Phone number is invalid.');
    }
  }

  return (
    <>
      <Global styles={css`${normalizeStyles}`} />
      <Seo
        canonical={'https://www.joinroot.com/app-legacy/'}
        deIndexPage={true}
        description={'Download the Root app to get a free car insurance quote. Bundle with renters or homeowners insurance right in the app to get even more savings.'}
        image={pageThumbnail}
        title={'Download the Root app | Get a quote'}
      />
      <div css={styles.nav}><RootLogo logoStyles={styles.logo} /></div>
      <section css={styles.wrap}>
        <div css={styles.container}>
          <div css={styles.mainContent}>
            <H1Display>A personalized quote, right in the Root app</H1Display>
            <Subhead css={styles.subhead}>It’s insurance, made simple.</Subhead>
            <form
              action={'#'}
              css={styles.form}
              onSubmit={(e) => handleSubmit(e)}
            >
              <input
                css={styles.input}
                id={'phone-number'}
                name={'phone'}
                onChange={(e) => {
                  isError && setErrorMessage('');
                  setPhoneNumber(e.target.value);
                }}
                placeholder={'Enter your 10 digit phone number'}
                type={'tel'}
                value={phoneNumber}
              />
              {isError && <p css={styles.errorMessage}>{isError}</p>}
              <button
                css={[styles.button, isSubmitted && styles.buttonDisabled]}
                type={'submit'}
              >
                {isSubmitted ? 'Submitted successfully' : 'Text me the app'}
              </button>
            </form>
          </div>
          <div css={styles.heroImageContainer}>
            <img
              alt={'Three phones displaying the Root app'}
              css={styles.heroImage}
              src={getTheApp}
            />
          </div>
        </div>
      </section>
    </>
  );
};

const AppLegacy = () =>
  <AppLegacyPage />
;

export default AppLegacy;

const styles = StyleSheet.create({
  nav: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 60,
    paddingLeft: 25,
    zIndex: 10,
    backgroundColor: Colors.white(),
    borderBottom: `1px solid ${Colors.gray30()}`,
    svg: {
      width: 160,
    },
  },
  wrap: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'calc(100vh - 60px)',
  },
  container: {
    display: 'flex',
    width: '100%',
    maxWidth: 1200,
    minHeight: 560,
    margin: '20px 0 20px 20px',
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    h1: {
      wordWrap: 'normal',
    },
  },
  subhead: {
    margin: '14px 0 60px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 320,
  },
  input: {
    marginBottom: 2,
    padding: 16,
    borderRadius: 6,
    border: `1px solid ${Colors.gray40()}`,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 60,
    padding: '0 24px',
    marginTop: 14,
    borderRadius: 6,
    border: 'none',
    backgroundColor: Colors.rootOrange(),
    color: Colors.white(),
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: 18,
    userSelect: 'none',
  },
  buttonDisabled: {
    pointerEvents: 'none',
    cursor: 'default',
    backgroundColor: Colors.gray20(),
    color: Colors.gray60(),
  },
  errorMessage: {
    color: Colors.error(),
    fontSize: 16,
  },
  heroImageContainer: {
    flex: 1.75,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    img: {
      flexShrink: 0,
    },
  },
});
