import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function RichTextDocument({ children }) {
  return (
    <div css={styles.richTextDocument}>
      {children}
    </div>
  );
}

RichTextDocument.propTypes = {
  children: PropTypes.node.isRequired,
};

const styles = StyleSheet.create({
  richTextDocument: {
    'p + p': {
      marginTop: 10,
    },
  },
});

