import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import environmentalizeUrl from '@root/gatsby-contentful-core/src/utils/environmentalize-url';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';

function CTAButton({
  buttonStyles, children, icon, iconStyles, to, onClick,
}) {
  return (
    <a
      css={[styles.button, buttonStyles]}
      href={environmentalizeUrl(to)}
      onClick={onClick}
    >
      {children}
      {icon && <span css={[styles.icon, iconStyles]}>{icon}</span>}
    </a>
  );
}

const styles = StyleSheet.create({
  button: {
    display: 'inline-flex',
    alignItems: 'center',
    alignSelf: 'center',
    justifyContent: 'center',
    ...Theme.roundedCorners(),
    ...Theme.ctaButton(),
    textDecoration: 'none',
    '&:hover': {
      color: Colors.white(),
      boxShadow: `0 8px 6px -6px ${toRgba(Colors.nearBlack(), .3)}`,
      textDecoration: 'none',
    },
    '&:active': {
      backgroundColor: '#FF7F50',
    },
    ...Responsive.md({
      transition: 'all 300ms cubic-bezier(0.37, 0, 0.28, 1)',
    }),
  },
  icon: {
    display: 'inline-block',
    marginLeft: 8,
  },
});

CTAButton.propTypes = {
  buttonStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  children: PropTypes.string.isRequired,
  icon: PropTypes.node,
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  onClick: PropTypes.func,
  to: PropTypes.string.isRequired,
};

export default CTAButton;
