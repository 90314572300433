import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function NavContainer({
  isBannerOn,
  isNavOpen,
  navStyles,
  children,
  isVisible,
  isDarkMode,
  isNavBgTransparent,
}) {
  return (
    <nav
      className={`${isNavOpen ? 'nav-is-open' : ''}`}
      css={[
        styles.nav,
        isBannerOn && stylesWhenBanner.nav,
        isDarkMode && stylesWhenDarkMode.navOpaque,
        isNavBgTransparent && stylesWhenDarkMode.navTransparent,
        navStyles,
        isVisible || styles.hideNav,
      ]}
      data-testid={'global-nav'}
    >
      {children}
    </nav>
  );
}

const styles = StyleSheet.create({
  nav: {
    position: 'fixed',
    zIndex: 5,
    width: '100%',
    height: 50,
    backgroundColor: Colors.white(),
    overflow: 'hidden',
    transition: 'all 500ms cubic-bezier(.37, 0, .28, 1)',
    '&.nav-is-open': {
      height: '100vh',
    },
    ...Responsive.md({
      height: 'auto',
      overflow: 'visible',
      '&.nav-is-open': {
        height: 'auto',
      },
    }),
  },
  hideNav: {
    transform: 'translateY(-100%)',
  },
});

const stylesWhenBanner = StyleSheet.create({
  nav: {
    height: 100,
    '&.nav-is-open': {
      height: '100vh',
    },
    ...Responsive.md({
      height: 'auto',
      '&.nav-is-open': {
        height: 'auto',
      },
    }),
  },
});

const stylesWhenDarkMode = StyleSheet.create({
  navTransparent: {
    backgroundColor: 'rgba(0,0,0,0)',
    borderBottom: '1px solid rgba(0,0,0,0)',
    '&.nav-is-open': {
      backgroundColor: Colors.black(),
    },
  },
  navOpaque: {
    backgroundColor: Colors.black(),
    borderBottom: `1px solid ${Colors.nearBlack()}`,
    '&.nav-is-open': {
      backgroundColor: Colors.black(),
    },
  },
});

NavContainer.propTypes = {
  children: PropTypes.node,
  isBannerOn: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isNavBgTransparent: PropTypes.bool,
  isNavOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  navStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default NavContainer;
