import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import styled from '@root/vendor/@emotion/styled';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

const rootOrangePressed = '#EE4300';
const easeOutQuart = 'cubic-bezier(0.165, 0.85, 0.45, 1)';

const baseNavElementStyles = {
  ...semiBold(),
  cursor: 'pointer',
  fontSize: 16,
  fontWeight: 700,
  letterSpacing: 0,
  lineHeight: '130%',
  textTransform: 'uppercase',
  textDecoration: 'none',
};

export const BlogNavigation = styled(Link, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})({
  ...baseNavElementStyles,
  position: 'relative',
  color: Colors.gray50(),
  transition: `all 200ms ${easeOutQuart}`,
  '&:hover': {
    color: Colors.rootOrange(),
    textDecoration: 'none',
  },
  '&:active': {
    color: rootOrangePressed,
  },
  '&::after': {
    content: '\'\'',
    position: 'absolute',
    bottom: -1,
    left: 0,
    width: '100%',
    height: 1,
    backgroundColor: Colors.rootOrange(),
    transition: `all 200ms ${easeOutQuart}`,
    transform: 'scale(0.6)',
    opacity: 0,
  },
  '&:hover::after': {
    transform: 'scale(1)',
    opacity: 1,
  },
}, ({ isActive }) => {
  if (!isActive) { return; }

  return {
    color: Colors.rootOrange(),
    '&::after': {
      transform: 'scale(1)',
      opacity: 1,
    },
  };
});

export const CategoryTag = styled(Link)({
  ...baseNavElementStyles,
  color: Colors.rootOrange(),
  transition: `all 200ms ${easeOutQuart}`,
  '&:hover': {
    color: Colors.nearBlack(),
    textDecoration: 'none',
  },
  '&:active': {
    color: rootOrangePressed,
  },
});
