import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { PureComponent } from '@root/vendor/react';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import styled from '@root/vendor/@emotion/styled';

export default class InputError extends PureComponent {
  static propTypes = {
    children: PropTypes.node,
  };

  render() {
    return (
      <StyledInputError {...this.props} />
    );
  }
}

const StyledInputError = styled.span(() =>
  (
    {
      color: Colors.invalid(),
      ...Typography.caption(),
    }
  )
);
