import InterfaceActionsContext from '@root/gatsby-contentful-core/src/contexts/interface-actions/interface-actions-context';
import InterfaceActionsService from '@root/gatsby-contentful-core/src/services/interface-actions-service';
import { useCallback, useContext } from '@root/vendor/react';

export default function useInterfaceActions() {
  const { interfaceActionsMap } = useContext(InterfaceActionsContext);

  return {
    buildCallToActionButton: useCallback(({ button, trackClick }) => {
      return InterfaceActionsService.buildCallToActionButton({
        interfaceActionsMap,
        button,
        trackClick,
      });
    }, [interfaceActionsMap]),
  };
}
