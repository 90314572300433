import PropTypes from '@root/vendor/prop-types';
import { Colors } from '@root/core/src/utils/styles';

const RootWordmark = ({ strokeColor = Colors.white(), strokeWidth = 2 }) => (
  <svg
    data-testid={'SVG - Root wordmark'}
    fill={'none'}
    height={'1034'}
    stroke={strokeColor}
    strokeWidth={strokeWidth}
    viewBox={'0 0 3002 1034'}
    width={'3002'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path d={'M562.198 628.023L580.1 618.388C693.823 557.02 759.047 449.081 759.047 322.293C759.047 226.36 730.34 150.845 673.746 97.8887C605.062 33.5766 494.335 1 344.63 1C220.672 1 64.1914 23.5589 1 33.0413V996.995H248.167V669.509H353.581L528.498 996.995H802.518L562.198 628.023ZM338.888 509.723H248.167V194.739L257.653 193.018C278.372 189.233 309.218 186.671 334.431 186.671C441.878 186.671 501.075 243.986 501.075 348.025C501.111 440.211 431.358 509.723 338.888 509.723Z'} />
    <path d={'M1474.19 243.017C1396.48 167.961 1288.47 126.628 1170 126.628C1053.1 126.628 946.085 167.961 868.701 242.979C784.22 324.879 739.572 441.497 739.572 580.177C739.572 718.819 784.22 835.284 868.701 916.994C946.014 991.782 1053.03 1033 1170 1033C1288.54 1033 1396.56 991.782 1474.19 916.994C1558.74 835.476 1603.46 719.01 1603.46 580.177C1603.46 441.306 1558.74 324.726 1474.19 243.017ZM1281.98 777.549C1254.98 817.39 1216.93 838.458 1171.96 838.458C1127.14 838.458 1089.19 817.39 1062.23 777.549C1031.96 732.814 1016.62 666.398 1016.62 580.177C1016.62 493.88 1031.96 427.389 1062.2 382.462C1089.19 342.391 1127.14 321.209 1171.93 321.209C1216.86 321.209 1254.91 342.391 1281.98 382.462C1312.25 427.35 1327.62 493.88 1327.62 580.177C1327.62 666.437 1312.29 732.852 1281.98 777.549Z'} />
    <path d={'M2779.87 667.439V396.732H2958.71V200.355H2779.87V7.76288L2523.43 90.9633L2524.67 746.472C2524.67 827.99 2547.68 897.617 2591.25 947.782C2635.69 998.979 2698.77 1026.05 2773.62 1026.05C2862.78 1026.05 2954.43 1010.6 3001 995.156V763.793C2973.04 772.434 2933.42 780.846 2884.92 780.846C2797.73 780.884 2779.87 735.537 2779.87 667.439Z'} />
    <path d={'M2370.57 243.017C2292.87 167.961 2184.85 126.628 2066.38 126.628C1949.49 126.628 1842.47 167.961 1765.08 242.979C1680.6 324.879 1635.95 441.497 1635.95 580.177C1635.95 718.819 1680.6 835.284 1765.08 916.994C1842.4 991.782 1949.42 1033 2066.38 1033C2184.92 1033 2292.94 991.782 2370.57 916.994C2455.13 835.476 2499.84 719.01 2499.84 580.177C2499.84 441.306 2455.16 324.726 2370.57 243.017ZM2178.4 777.549C2151.4 817.39 2113.35 838.458 2068.38 838.458C2023.55 838.458 1985.61 817.39 1958.65 777.549C1928.38 732.814 1913.04 666.398 1913.04 580.177C1913.04 493.88 1928.38 427.389 1958.62 382.462C1985.61 342.391 2023.56 321.209 2068.35 321.209C2113.28 321.209 2151.33 342.391 2178.4 382.462C2208.67 427.35 2224.04 493.88 2224.04 580.177C2224.04 666.437 2208.67 732.852 2178.4 777.549Z'} />
  </svg>
);

export default RootWordmark;

RootWordmark.propTypes = {
  strokeColor: PropTypes.string,
  strokeWidth: PropTypes.number,
};
