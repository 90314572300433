import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function FacebookIcon({ iconStyles }) {
  return (
    <svg
      aria-label={'Facebook icon'}
      css={[styles.svg, iconStyles]}
      enableBackground={'new 0 0 50 50'}
      role={'img'}
      version={'1.1'}
      viewBox={'0 0 50 50'}
      x={'0px'}
      xmlns={'http://www.w3.org/2000/svg'}
      xmlSpace={'preserve'}
      y={'0px'}
    >
      <path d={'M40,0H10C4.486,0,0,4.486,0,10v30c0,5.514,4.486,10,10,10h30c5.514,0,10-4.486,10-10V10C50,4.486,45.514,0,40,0z M39,17h-3  c-2.145,0-3,0.504-3,2v3h6l-1,6h-5v20h-7V28h-3v-6h3v-3c0-4.677,1.581-8,7-8c2.902,0,6,1,6,1V17z'} />
    </svg>
  );
}

const styles = StyleSheet.create({
  svg: {
    fill: Colors.black(),
  },
});

FacebookIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default FacebookIcon;
