import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import starRating from '@root/joinroot.com/src/assets/images/quote/star-rating.svg';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Easing } from '@root/gatsby-contentful-core/src/utils/animation';
import { StaticImage } from 'gatsby-plugin-image';
import { black, light, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function QuoteHeaderLegacy() {
  const { trackClick } = useAnalytics('QUOTE_LANDING_HEADER', false);

  return (
    <section css={styles.section}>
      <div css={styles.contentWrap}>
        <h1 css={styles.h1}>Better drivers deserve <span>better&nbsp;</span>rates.</h1>
        <h2 css={styles.h2}>You could save hundreds with&nbsp;Root.</h2>
        <p css={styles.pQuote}>Get a free car insurance quote online&nbsp;now.</p>
        <Link
          css={styles.button}
          onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
          to={environment.quoteAppBaseUrl}
        >
          Get a quote
        </Link>
        <p css={styles.pStar}> 4.7 stars for a reason*</p>
        <img src={starRating} />
      </div>
      <div css={styles.whiteBand}>
        <div css={styles.carWrap}>
          <StaticImage
            alt={''}
            css={styles.carImage}
            formats={['auto', 'webp', 'avif']}
            layout={'constrained'}
            loading={'eager'}
            placeholder={'tracedSVG'}
            src={'../assets/images/quote/car.png'}
            style={{ position: 'absolute' }}
          />
        </div>
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.rootOrange(),
    backgroundImage: 'linear-gradient(353deg, hsl(30deg 100% 50%) 0%, hsl(13deg 100% 50%) 50%)',
  },
  contentWrap: {
    zIndex: 2,
    width: 'min(1200px, 100%)',
    padding: '60px 20px 26vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    ...Responsive.lg({
      alignItems: 'flex-start',
      textAlign: 'left',
      padding: '100px 20px',
    }),
    '& > img': { marginLeft: -3 },
  },
  h1: {
    ...black(),
    maxWidth: 726,
    color: Colors.white(),
    lineHeight: 1,
    letterSpacing: '-0.05rem',
    fontSize: 40,
    ...Responsive.lg({
      fontSize: 70,
    }),
    '& > span': { color: Colors.black() },
  },
  h2: {
    ...light(),
    color: Colors.white(),
    letterSpacing: '-0.025rem',
    fontSize: 30,
    margin: '20px 0',
    ...Responsive.lg({
      margin: '14px 0',
      fontSize: 45,
    }),
  },
  pQuote: {
    maxWidth: 220,
    fontSize: 20,
    letterSpacing: '-0.025rem',
    color: Colors.white(),
    ...Responsive.sm({
      maxWidth: '100%',
    }),
  },
  button: {
    display: 'block',
    padding: '0 40px',
    color: Colors.white(),
    fontWeight: '600',
    fontSize: '18px',
    lineHeight: '60px',
    textDecoration: 'none',
    borderRadius: '10px',
    transition: `all 150ms ${Easing.easeOutQuart}`,
    backgroundColor: Colors.black(),
    margin: '40px 0',
    '&:hover': {
      backgroundColor: Colors.nearBlack(),
    },
    '&:focus': {
      outline: 'none',
      boxShadow: `0 0 0 6px ${Colors.toRgba(Colors.white(), 0.5)}`,
    },
  },
  pStar: {
    ...semiBold(),
    fontSize: 20,
    letterSpacing: '-0.025rem',
    color: Colors.black(),
  },
  whiteBand: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 'min(90px, 10vw)',
    backgroundColor: Colors.white(),
  },
  carWrap: {
    position: 'relative',
    width: 'min(1440px, 100%)',
  },
  carImage: {
    bottom: 0,
    right: 0,
    width: '100%',
    ...Responsive.lg({
      width: '80%',
    }),
  },
});
