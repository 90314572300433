import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Eyebrow, H2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export const ICON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const CHILDREN_STYLES = ['', 'one-tout', 'two-touts', 'three-touts', 'four-touts', 'five-touts', 'six-touts'];

export default function IconToutSection({
  anchor,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  body,
  children,
  className,
  eyebrow,
  iconSize = ICON_SIZES.LARGE,
  title,
  withGrid = false,
  sectionSpacing = {},
}) {
  const numOfChildren = React.Children.count(children);

  const classes = [`icon-size-${iconSize}`, CHILDREN_STYLES[numOfChildren]];

  if (numOfChildren === 6 && withGrid) {
    classes.push('with-grid');
  }

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      className={className}
      css={[
        styles.iconToutSection,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        sectionSpacingStyles,
      ]}
      id={anchor}
    >
      {(eyebrow || title || body) &&
        <div css={styles.headingContainer}>
          {eyebrow && <Eyebrow css={styles.eyebrow}>{eyebrow}</Eyebrow>}
          {title && <H2 css={styles.title}>{title}</H2>}
          <BodyCopy
            content={body}
            css={styles.body}
          />
        </div>
      }
      <div
        className={`${classes.join(' ')}`}
        css={styles.childrenContainer}
      >
        {children}
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  iconToutSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '100px 20px',
    textAlign: 'center',
  },
  headingContainer: {
    maxWidth: 700,
    marginBottom: 50,
  },
  eyebrow: {
    marginBottom: 15,
  },
  title: {
    marginTop: 0,
    marginBottom: 15,
  },
  childrenContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 1440,
    '@media screen and (min-width: 768px)': {
      flexDirection: 'row',
      alignItems: 'flex-start',
      '&.four-touts': {
        flexWrap: 'wrap',
        maxWidth: 750,
        justifyContent: 'space-between',
      },
      '&.three-touts, &.five-touts, &.six-touts': {
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
    },
  },
});

IconToutSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  eyebrow: PropTypes.node,
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  title: PropTypes.node,
  withGrid: PropTypes.bool,
};
