import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useSaveAttribution from '@root/attribution/src/hooks/use-save-attribution';

export default function PrivacyPolicy({ location }) {
  useSaveAttribution(location);

  return (
    <>
      <main>
        <div
          style={{
            display: 'block',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            boxSizing: 'border-box',
          }}
        >
          <iframe
            height={'100%'}
            src={'https://docs.google.com/document/d/e/2PACX-1vR3QpHtw3FSabwxNfHuy-mfyynAWzo62AnJz8tdxC_MY4YbmP_KoHvs6yWwbOil2mmXRMFr6wplCiGY/pub?embedded=true'}
            style={{
              border: 'none',
              display: 'block',
            }}
            width={'100%'}
          />
        </div>
      </main>
    </>
  );
}

PrivacyPolicy.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }.isRequired),
};
