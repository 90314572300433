import ChevronDownIcon from '@root/gatsby-contentful-core/src/assets/components/icons/chevron-down-icon';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import IconArrowRight from '@root/gatsby-contentful-core/src/assets/components/icons/arrow-right';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { H4 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function FaqTopicsNavMobile({
  faqData,
  handleFaqNavClick,
  activeTopic,
}) {
  const [faqNavIsOpen, setFaqNavIsOpen] = useState(false);
  const [slideFaqNavUp, setSlideFaqNavUp] = useState(false);

  useScrollPosition(useCallback(({ previousPosition, currentPosition }) => {
    // If user isn't scrolling up and they have scrolled at least 100px down the page,
    // slide the mobile faq nav up. This coordinates with the main nav.
    const isBeyond100px = currentPosition.y < -100;
    const shouldSlideFaqNavUp = currentPosition.y < previousPosition.y && isBeyond100px;
    setSlideFaqNavUp(shouldSlideFaqNavUp);
  }, []));

  // TODO: Need to make the mobile list scrollable in case there are more list items than will fit in the mobile viewport.
  return (
    <div
      css={[
        styles.faqNav,
        slideFaqNavUp && styles.faqNavUp,
        faqNavIsOpen && styles.faqNavOpen,
      ]}
      data-testid={'faq-topics-nav-mobile'}
    >
      <div css={[styles.container, faqNavIsOpen && styles.containerIsActive]}>
        <H4 css={styles.title}>Help Topics</H4>
        <ul css={styles.list}>
          {faqData.map((group, i) => {
            const isActive = activeTopic.topic === group.title;
            return (
              <li
                css={[styles.listItem, faqNavIsOpen && styles.listItemIsActive]}
                key={i}
                onClick={(e) => {
                  handleFaqNavClick(e.target.innerText);
                  setFaqNavIsOpen(!faqNavIsOpen);
                }}
              >
                <div css={[styles.topicContainer, isActive && styles.topicContainerIsActive]}>
                  <IconArrowRight />
                  <span>{group.title}</span>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      <div
        css={[styles.arrowContainer, faqNavIsOpen && styles.arrowRotate]}
        data-testid={'dropdown-button'}
        onClick={() => setFaqNavIsOpen(!faqNavIsOpen)}
      >
        <ChevronDownIcon />
      </div>
    </div>
  );
}

FaqTopicsNavMobile.propTypes = {
  activeTopic: PropTypes.shape({
    topic: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  handleFaqNavClick: PropTypes.func,
};

const easing = 'cubic-bezier(0.37, 0, 0.28, 1)';

const styles = StyleSheet.create({
  faqNav: {
    position: 'fixed',
    top: 0,
    zIndex: 2,
    width: '100%',
    height: '100%',
    maxHeight: 50,
    padding: '0 20px',
    backgroundColor: Colors.nearBlack(),
    overflow: 'hidden',
    transform: 'translate3d(0,50px,0)',
    transition: `500ms ${easing}`,

    ...Responsive.md({
      display: 'none',
    }),
  },

  faqNavUp: {
    transform: 'translate3d(0,0,0)',
  },

  faqNavOpen: {
    maxHeight: '100vh',
  },

  container: {
    transition: `transform 500ms ${easing}`,
    transform: 'translate3d(0,0,0) scale(1)',
  },

  containerIsActive: {
    transform: 'translate3d(0,30px,0) scale(0.96)',
  },

  title: {
    color: Colors.white(),
    lineHeight: '50px',
  },

  list: {
    listStyle: 'none',
  },

  listItem: {
    margin: 0,
    borderTop: `1px solid ${Colors.nearBlack(0)}`,
    transition: `500ms ${easing}`,
  },

  listItemIsActive: {
    borderTop: `1px solid ${Colors.grayDark()}`,
  },

  topicContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    cursor: 'pointer',
    transition: `transform 300ms ${easing}`,
    transform: 'translate3d(-32px, 0, 0)',

    '& svg': {
      width: 20,
      height: 18,
      opacity: 0,
      marginRight: 14,
      transition: `opacity 300ms ${easing}`,
    },

    '& span': {
      color: Colors.white(),
      fontSize: 20,
    },
  },

  topicContainerIsActive: {
    transform: 'translate3d(0, 0, 0)',

    '& svg': {
      opacity: 1,
    },
  },

  arrowContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: 60,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'transform 150ms ease-in-out',

    '& svg path': {
      stroke: Colors.white(),
      transition: '150ms ease-in-out',
    },
  },

  arrowRotate: {
    transform: 'rotate(-180deg)',
  },
});
