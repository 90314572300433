import ArrowThinIcon from '@root/joinroot.com/src/components/icons/arrow-thin-icon';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function NavDropdownMobile({
  title, dropdownItems, setOpen, isOpen = false,
}) {
  return (
    <li
      className={isOpen ? 'is-open' : ''}
      css={styles.item}
      data-testid={'nav-dropdown-mobile'}
    >
      <div
        css={styles.dropdownContainer}
        onClick={setOpen}
      >
        <p css={styles.title}>{title}</p>
        <ArrowThinIcon iconStyles={styles.arrowIcon} />
      </div>
      <div css={styles.linkContainer}>
        {dropdownItems.map((item, index) => (
          <Link
            css={styles.link}
            key={index}
            to={item.url}
          >
            {item.linkText}
          </Link>
        ))}
      </div>
    </li>
  );
}

const styles = StyleSheet.create({
  item: {
    listStyle: 'none',
    borderBottom: `1px solid ${Colors.gray20()}`,
    margin: 0,
    overflow: 'hidden',
    width: '100%',
  },
  dropdownContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    cursor: 'pointer',
    '.is-open &': {
      color: Colors.gray50(),
    },
  },
  title: {
    ...Theme.listHeader(),
    fontWeight: 'normal',
    marginBottom: 0,
    transition: 'color 300ms cubic-bezier(.37, 0, 1, 1)',
  },
  arrowIcon: {
    stroke: Colors.gray20(),
    width: 20,
    strokeWidth: 10,
    transform: 'rotate(-90deg)',
    transition: 'all 300ms cubic-bezier(.37, 0, 1, 1)',
    '.is-open &': {
      stroke: Colors.gray50(),
      transform: 'rotate(0)',
    },
  },
  link: {
    ...Theme.link(),
    marginBottom: 15,
    ':last-child': {
      marginBottom: 0,
    },
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 0,
    transition: 'all 300ms cubic-bezier(0,1,0,1)',
    '.is-open &': {
      padding: '10px 0 20px',
      maxHeight: 1000,
      transition: 'all 300ms ease-in',
    },
  },
});

NavDropdownMobile.propTypes = {
  dropdownItems: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};
