import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ExpandableSection({
  anchor,
  children,
  backgroundColor,
  sectionSpacing = {},
}) {
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.section,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(backgroundColor),
      ]}
      id={anchor}
    >
      {children}
    </section>
  );
}

ExpandableSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  children: PropTypes.node.isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    padding: '100px 20px',

    ...Responsive.md({
      padding: '100px 30px',
    }),
  },
});
