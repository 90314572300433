import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import { H2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ExpandableGroup({
  anchor,
  groupTitle,
  groupStyles,
  children,
  link,
}) {
  return (
    <div
      css={[styles.container, groupStyles]}
      id={anchor}
    >
      <H2 css={styles.title}>{groupTitle}</H2>
      <div css={styles.content}>
        {children}
        {link &&
          <LinkButton
            to={link.to}
            type={LinkButtonModel.types.TERTIARY}
          >
            {link.text}
          </LinkButton>
        }
      </div>
    </div>
  );
}

ExpandableGroup.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.node.isRequired,
  groupStyles: PropTypes.object,
  groupTitle: PropTypes.string,
  link: PropTypes.shape({
    text: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
  }),
};

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 800,
  },
  title: {
    marginBottom: 10,
  },
  content: {
    '& > *:last-child': {
      border: 'none',
    },
    '& > a': {
      height: 70,
      padding: '0 10px',
      alignItems: 'center',
    },
  },
});
