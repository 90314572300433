import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import ExpandableGroup from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-group';
import ExpandableItem from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-item';
import ExpandableSection from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-section';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';

export default function ExpandableSectionBlock({ content }) {
  return (
    <div data-testid={'expandableSectionBlock'}>
      <ExpandableSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        sectionSpacing={content.sectionSpacing}
      >
        <ExpandableGroup
          groupTitle={content.headline}
          link={content.sectionButton && {
            text: content.sectionButton.buttonText,
            to: content.sectionButton.buttonLink,
          }}
        >
          {content.expandables.map((expandable) => (
            <ExpandableItem
              anchor={expandable.anchor?.anchorSlug}
              itemTitle={expandable.title}
              key={expandable.id}
            >
              {renderRichText(expandable.content)}
            </ExpandableItem>
          ))}
        </ExpandableGroup>
      </ExpandableSection>
    </div>
  );
}

ExpandableSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    headline: PropTypes.string,
    backgroundColor: PropTypes.string.isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    sectionButton: PropTypes.shape({
      id: PropTypes.string.isRequired,
      buttonType: PropTypes.string.isRequired,
      buttonText: PropTypes.string.isRequired,
      buttonLink: PropTypes.string.isRequired,
    }),
    expandables: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        anchor: PropTypes.shape({
          id: PropTypes.string,
          anchorSlug: PropTypes.string,
        }),
        title: PropTypes.string,
        content: PropTypes.object,
      }).isRequired
    ).isRequired,
  }).isRequired,
};
