import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getMarketSupportByZipCodeConfiguration(zipCode) {
  return new NetworkRequestConfiguration({
    endpoint: 'supported_markets/support_by_zip_code',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      zipCode,
      shouldIncludeHomeownersTieIn: true,
    },
  });
}
