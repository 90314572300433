import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PressSection from '@root/gatsby-contentful-core/src/components/sections/press-section';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function PressSectionBlock({ content }) {
  return (
    <div data-testid={'pressSectionBlock'}>
      <PressSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        eyebrow={content.eyebrow}
        headline={content.headline}
        logos={buildPressIcons(content.pressIcons)}
        sectionSpacing={content.sectionSpacing}
        subhead={content.subheadLong}
      />
    </div>
  );
}

PressSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    subheadLong: PropTypes.string,
    backgroundColor: PropTypes.string,
    pressIcons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        link: PropTypes.string,
        asset: PropTypes.shape({
          title: PropTypes.string.isRequired,
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      }).isRequired
    ).isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};

function buildPressIcons(pressIcons) {
  return pressIcons.map((pressIcon) => ({
    link: pressIcon.link || null,
    alt: pressIcon.asset.title,
    svg: pressIcon.asset.file.url,
  }));
}
