import Button from '@root/core/src/components/button';
import ErrorReportService from '@root/core/src/services/error-report-service';
import HubspotFieldRenderer from '@root/joinroot.com/src/components/forms/hubspot-form-generation/hubspot-field-renderer';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo, useState } from '@root/vendor/react';
import SubmissionErrorMessage from '@root/joinroot.com/src/components/forms/hubspot-form-generation/submission-error-message';
import generateFieldValidations from '@root/joinroot.com/src/components/forms/hubspot-form-generation/generate-field-validations';
import postSubmissionToHubspot from '@root/joinroot.com/src/components/forms/hubspot-form-generation/post-submission-to-hubspot';
import useForm from '@root/core/src/hooks/use-form';
import { Colors, styled } from '@root/core/src/utils/styles';
import { useLocation } from '@reach/router';

const submissionStates = {
  INITIAL: 'initial',
  ERROR: 'error',
  PROCESSING: 'processing',
  SUCCESS: 'success',
};

export default function HubspotForm({
  formData: {
    portalId,
    id: formId,
    name,
    submitText = 'Submit',
    inlineMessage,
    formFieldGroups,
  },
  fallbackUrl,
  closeModal,
  successMessageComponent,
}) {
  const [submissionState, setSubmissionState] = useState(submissionStates.INITIAL);
  const { origin, pathname } = useLocation();

  const form = useForm({
    validateOnBlur: true,
    validations: useMemo(() => generateFieldValidations(formFieldGroups), [formFieldGroups]),
  });

  const handleSubmit = async (formValues) => {
    setSubmissionState(submissionStates.PROCESSING);

    try {
      await postSubmissionToHubspot({
        formValues,
        formId,
        portalId,
        locationData: {
          origin,
          pathname,
        },
      });
      setSubmissionState(submissionStates.SUCCESS);
    } catch (error) {
      setSubmissionState(submissionStates.ERROR);
      ErrorReportService.reportError({
        error,
        caughtAt: 'hubspot-form',
        additionalData: {
          hubspotFormName: name,
          hubspotFormId: formId,
          methodThatThrewException: 'handleSubmit',
        },
      });
    }
  };

  if (submissionState === submissionStates.SUCCESS) {
    // Passing this component in as a prop allows us to wrap the success message in a modal if the form isn't already in a modal.
    const SuccessMessageComponent = successMessageComponent;
    return (
      <SuccessMessageComponent
        closeModal={closeModal}
        email={form.values.email}
        inlineMessage={inlineMessage}
      />
    );
  }

  return (
    <Form onSubmit={form.createSubmitHandler(handleSubmit)}>
      {formFieldGroups.map(({ fields, richText }, i) => {
        const renderRichText = !fields.length && richText.content;
        const renderFormRow = fields.length && !richText.content;

        if (renderRichText) {
          return (
            <RichTextRow
              dangerouslySetInnerHTML={{ __html: richText.content }}
              key={i}
            />
          );
        }
        if (renderFormRow) {
          return (
            <FormFieldRow key={i}>
              {fields.map((fieldData, j) => {
                return (
                  <FieldContainer key={j}>
                    <HubspotFieldRenderer
                      form={form}
                      {...fieldData}
                    />
                    {fieldData.description && <FieldDescription>{fieldData.description}</FieldDescription>}
                  </FieldContainer>
                );
              })}
            </FormFieldRow>
          );
        }
        return null;
      })}
      {submissionState === submissionStates.ERROR && <SubmissionErrorMessage fallbackUrl={fallbackUrl} />}
      <SubmitButton disabled={!form.isValid || submissionState !== submissionStates.INITIAL}>
        {submitText}
      </SubmitButton>
    </Form>
  );
}

HubspotForm.propTypes = {
  closeModal: PropTypes.func.isRequired,
  fallbackUrl: PropTypes.string.isRequired,
  formData: PropTypes.shape({
    formFieldGroups: PropTypes.arrayOf(
      PropTypes.object.isRequired,
    ).isRequired,
    portalId: PropTypes.number.isRequired,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    submitText: PropTypes.string,
    inlineMessage: PropTypes.string,
  }).isRequired,
  successMessageComponent: PropTypes.func.isRequired,
};

const Form = styled.form({
  width: '100%',
  '& > * + *': {
    marginTop: 20,
  },
});

const RichTextRow = styled.div({
  marginBottom: 28,
  '& > * + *': {
    marginTop: 5,
  },
  '& br': {
    display: 'none',
  },
});

const FormFieldRow = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  gap: 20,
  justifyContent: 'space-between',
});

const FieldContainer = styled.div({
  minWidth: 250,
  flex: 1,
});

const FieldDescription = styled.p({
  marginTop: 4,
  color: Colors.gray50(),
  fontSize: 16,
  letterSpacing: '-0.015em',
  lineHeight: 1.3,
});

const SubmitButton = styled(Button)({
  marginTop: 40,
});
