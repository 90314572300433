import Button from '@root/gatsby-contentful-core/src/components/button';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import StateSelect from '@root/core/src/components/state-select';
import { Colors } from '@root/core/src/utils/styles';
import { H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';

export default function AgentsStateSelectionModal({
  canAdvance,
  onContinue,
  onDismiss,
  isShowing,
  onChange,
  selectedMarket,
}) {
  return (
    <Modal
      renderCloseButton
      isShowing={isShowing}
      name={'joinroot-agents-state-selection-modal'}
      onClose={onDismiss}
    >
      <H3 css={styles.header}>To connect with an agent, let us know what state you’re in</H3>
      <div css={styles.selectBoxContainer}>
        <StateSelect
          id={'licenseState'}
          onChange={onChange}
          selectedValue={selectedMarket}
        />
      </div>
      <Button
        css={styles.continueButton}
        disabled={!canAdvance}
        onClick={onContinue}
      >
        Continue
      </Button>
      <a
        css={styles.cancelLabel}
        onClick={onDismiss}
      >
        Cancel
      </a>
    </Modal>
  );
}

const styles = StyleSheet.create({
  continueButton: {
    marginBottom: 20,
    marginTop: 20,
    width: '100%',
    ...semiBold(),
    transition: 'all 300ms cubic-bezier(0.37, 0, 0.28, 1)',
    '&:hover:enabled': {
      boxShadow: `0 8px 6px -6px ${toRgba(Colors.nearBlack(), .3)}`,
    },
  },
  cancelLabel: {
    color: Colors.gray50(),
    cursor: 'pointer',
    display: 'block',
    textAlign: 'center',
    ...regular(),
    ...Responsive.lessThanSm({
      marginBottom: 20,
    }),
  },
  header: {
    marginBottom: 20,
  },
  selectBoxContainer: {
    height: 65,
    '& > div': {
      position: 'absolute',
      zIndex: 3,
      width: 'calc(100% - 80px)',
    },
  },
});

AgentsStateSelectionModal.propTypes = {
  canAdvance: PropTypes.bool,
  isShowing: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  selectedMarket: PropTypes.string.isRequired,
};
