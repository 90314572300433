import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import environment from '@root/core/src/utils/environment';
import environmentalizeUrl from '@root/gatsby-contentful-core/src/utils/environmentalize-url';

const urlToEventMapping = {
  [environment.quoteAppBaseUrl]: ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED,
  [`${environment.quoteAppBaseUrl}/`]: ConversionTrackingService.CONVERSION_EVENTS.GET_A_QUOTE_BUTTON_CLICKED,
  '/app/': ConversionTrackingService.CONVERSION_EVENTS.GET_THE_APP_BUTTON_CLICKED,
};

export default class UrlConversionTrackingService {
  static trackConversionForUrl(url) {
    const event = urlToEventMapping[environmentalizeUrl(url)];

    if (event) {
      ConversionTrackingService.trackConversion(event);
    }
  }
}
