import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import ImageToutSection, { TOUT_COLUMNS, TOUT_LAYOUT } from '@root/gatsby-contentful-core/src/components/sections/image-tout/image-tout-section';
import LinkButton from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useInterfaceActions from '@root/gatsby-contentful-core/src/hooks/use-interface-actions';
import { constantize } from '@root/core/src/utils/strings';

export default function ImageToutSectionBlock({ content }) {
  const { trackClick } = useAnalytics(`IMAGE_TOUT_SECTION_${content.id}`, false);
  const { buildCallToActionButton } = useInterfaceActions();

  return (
    <div data-testid={'imageToutSectionBlock'}>
      <ImageToutSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        body={renderRichText(content.subheading)}
        buttons={buildSectionCtaButtons(content.ctaButtons)}
        cardLayout={buildCardLayout(content.cardStyle)}
        eyebrow={content.eyebrow}
        id={content.id}
        sectionSpacing={content.sectionSpacing}
        styleToutsAsCards={!!content.showImageToutsAsCards}
        title={content.headline}
        toutColumns={content.toutColumns}
        touts={content.contentfulchildren.map((imageTout) => {
          const toutButton = imageTout.callToActionButton;
          return {
            body: renderRichText(imageTout.body),
            button: buildCallToActionButton({
              button: toutButton,
              trackClick,
            }),
            id: imageTout.id,
            image: imageTout.asset,
            mobileImage: imageTout.mobileImage,
            imageAltText: imageTout.imageAltText,
            key: imageTout.id,
            title: imageTout.headline,
          };
        })}
      />
    </div>
  );
}

ImageToutSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    cardStyle: PropTypes.string,
    ctaButtons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        buttonType: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        buttonLink: PropTypes.string.isRequired,
      }).isRequired,
    ),
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    id: PropTypes.string.isRequired,
    showImageToutsAsCards: PropTypes.bool,
    subheading: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    toutColumns: PropTypes.oneOf(Object.keys(TOUT_COLUMNS)),
    contentfulchildren: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        asset: PropTypes.object.isRequired,
        headline: PropTypes.string,
        body: PropTypes.object,
        imageAltText: PropTypes.string,
        callToActionButton: PropTypes.shape({
          id: PropTypes.string.isRequired,
          buttonLink: PropTypes.string.isRequired,
          buttonText: PropTypes.string.isRequired,
          buttonType: PropTypes.oneOf(Object.keys(LinkButton.types)).isRequired,
        }),
      })
    ).isRequired,
  }).isRequired,
  trackClick: PropTypes.func,
};

function buildCardLayout(cardStyle) {
  const mapping = {
    STACKED_CARDS: TOUT_LAYOUT.STACKED,
    SLIDING_CARDS: TOUT_LAYOUT.SLIDING,
  };

  return mapping[constantize(cardStyle)] || TOUT_LAYOUT.STACKED;
}

function buildSectionCtaButtons(buttons = []) {
  if (!buttons) { return []; }

  return buttons.map((btn) => ({
    id: btn.id,
    type: LinkButton.types[constantize(btn.buttonType)],
    text: btn.buttonText,
    link: btn.buttonLink,
  }));
}

export { buildSectionCtaButtons };
