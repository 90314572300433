import ProjectContext from '@root/core/src/contexts/project/project-context';
import PropTypes from '@root/vendor/prop-types';
import { useState } from '@root/vendor/react';

export default function ProjectProvider({ children, project }) {
  const [data] = useState({
    project,
  });

  return (
    <ProjectContext.Provider value={data}>
      {children}
    </ProjectContext.Provider>
  );
}

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
  project: PropTypes.string.isRequired,
};
