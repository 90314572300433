import AnalyticsService from '@root/core/src/services/analytics-service';
import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function findOrCreateAbTestForIdentifierConfiguration(abTestName, additionalParameters = {}) {
  return new NetworkRequestConfiguration({
    endpoint: 'ab_tests/find_or_create_for_identifier',
    method: NetworkRequestConfiguration.Methods.PUT,
    bodyParameters: {
      abTestName,
      identifier: AnalyticsService.getDistinctId(),
      ...additionalParameters,
    },
  });
}
