import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function YouTubeIcon({ iconStyles }) {
  return (
    <svg
      aria-label={'YouTube icon'}
      css={[styles.svg, iconStyles]}
      role={'img'}
      version={'1.1'}
      viewBox={'0 0 256 256'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <g
        id={'g10'}
        transform={'matrix(1.3333333,0,0,-1.3333333,0,256)'}
      >
        <g id={'g12'}>
          <g
            clipPath={'url(#clipPath18)'}
            id={'g14'}
          >
            <g
              id={'g20'}
              transform={'translate(78,69.8311)'}
            >
              <path
                d={'M 0,0 V 52.338 L 46,26.168 Z M 102.322,68.806 C 100.298,76.428 94.334,82.43 86.762,84.467 73.037,88.169 18,88.169 18,88.169 c 0,0 -55.037,0 -68.762,-3.702 C -58.334,82.43 -64.298,76.428 -66.322,68.806 -70,54.992 -70,26.169 -70,26.169 c 0,0 0,-28.822 3.678,-42.637 2.024,-7.622 7.988,-13.624 15.56,-15.662 13.725,-3.701 68.762,-3.701 68.762,-3.701 0,0 55.037,0 68.762,3.701 7.572,2.038 13.536,8.04 15.56,15.662 C 106,-2.653 106,26.169 106,26.169 c 0,0 0,28.823 -3.678,42.637'}
                id={'path22'}
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

const styles = StyleSheet.create({
  svg: {
    fill: Colors.black(),
  },
});

YouTubeIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default YouTubeIcon;
