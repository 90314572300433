import FaqTopicsNavDesktop from '@root/gatsby-contentful-core/src/components/sections/faq/faq-topics-nav-desktop';
import FaqTopicsNavMobile from '@root/gatsby-contentful-core/src/components/sections/faq/faq-topics-nav-mobile';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

// The visibility of each of these navs is controlled by CSS media queries within. See `display` property.
export default function FaqTopicsNav({
  faqData,
  handleFaqNavClick,
  activeTopic,
  sectionSpacing,
}) {
  return (
    <>
      <FaqTopicsNavMobile
        activeTopic={activeTopic}
        faqData={faqData}
        handleFaqNavClick={handleFaqNavClick}
      />
      <FaqTopicsNavDesktop
        activeTopic={activeTopic}
        faqData={faqData}
        handleFaqNavClick={handleFaqNavClick}
        sectionSpacing={sectionSpacing}
      />
    </>
  );
}

FaqTopicsNav.propTypes = {
  activeTopic: PropTypes.shape({
    topic: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  handleFaqNavClick: PropTypes.func,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};
