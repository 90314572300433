import AnalyticsService from '@root/core/src/services/analytics-service';
import { useEffect } from '@root/vendor/react';

function trackCampaignEvent(campaignId) {
  AnalyticsService.trackEvent('Optimizely Campaign Decision', window.optimizely.get('state').getDecisionString({
    campaignId,
  }), {
    eventPrefix: 'MARKETING_WEBSITE',
  });
}

function registerCurrentlyActiveCampaigns() {
  if (!window.optimizely?.get) { return; }

  const state = window.optimizely.get('state');
  if (!state) { return; }

  const activeCampaigns = state.getCampaignStates({
    isActive: true,
  });

  for (const id in activeCampaigns) {
    trackCampaignEvent(id);
  }
}

function registerFutureActiveCampaigns() {
  window.optimizely = window.optimizely || [];
  if (!window.optimizely.push) { return; }

  window.optimizely.push({
    type: 'addListener',
    filter: {
      type: 'lifecycle',
      name: 'campaignDecided',
    },
    handler(event) {
      const { id } = event.data.campaign;
      trackCampaignEvent(id);
    },
  });
}

export default function OptimizelyTrackingController() {
  useEffect(() => {
    registerCurrentlyActiveCampaigns();
    registerFutureActiveCampaigns();
  }, []);

  return null;
}
