import BlogHeader from '@root/joinroot.com/src/components/blog/header';
import BlogPostPreviewTile from '@root/joinroot.com/src/components/blog/blog-post-preview-tile';
import CategorySectionSliding from '@root/joinroot.com/src/components/blog/category-section-sliding';
import CategorySectionStacked from '@root/joinroot.com/src/components/blog/category-section-stacked';
import PageTemplate from '@root/joinroot.com/src/components/pages/page-template';
import PropTypes, { arrayOf } from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import { Colors } from '@root/core/src/utils/styles';
import { graphql } from 'gatsby';

export default function BlogLandingPage({
  location,
  data: {
    allCategoryData,
    recentPostData,
    topLevelOptionData: { nodes: { 0: topLevelData } },
  },
}) {
  return (
    <PageTemplate
      analyticsContext={'BLOG_LANDING_PAGE'}
      ctaLink={topLevelData.navigationOptions?.ctaLink}
      ctaText={topLevelData.navigationOptions?.ctaText}
      deIndexPage={topLevelData.publishingMetadata.deIndexPage}
      includeHotjar={topLevelData.publishingMetadata.includeHotjar}
      includeOptimizely={topLevelData.publishingMetadata.includeOptimizely}
      isCtaButtonDisabled={topLevelData.navigationOptions?.disableCtaButton}
      isStickyFooterDisabled={topLevelData.navigationOptions?.disableStickyFooter}
      location={location}
      pageCanonical={'https://www.joinroot.com/blog/'}
      pageDescription={topLevelData.publishingMetadata.pageDescription}
      pageThumbnail={topLevelData.publishingMetadata.pageThumbnail?.file?.url}
      pageTitle={topLevelData.publishingMetadata.pageTitle}
    >
      <BlogHeader
        activeNavItem={''}
        logo={topLevelData.primaryHeaderLogo.file.url}
        logoAltText={'Root Insurance blog logo'}
        navItems={allCategoryData.nodes}
      />
      <CategorySectionStacked
        sectionSpacing={{
          top: 0,
          bottom: 100,
        }}
        sectionTitle={'Recent posts'}
        viewAllButtonSlug={'/blog/tag/all'}
      >
        {recentPostData.nodes.map(({
          previewTitle,
          previewBody,
          previewImage: { gatsbyImageData },
          imageAltText,
          customDateCreated,
          publishingMetadata: { slug },
          blogCategory: { 0: firstCategory },
        }) => {
          return (
            <BlogPostPreviewTile
              categorySlug={firstCategory.publishingMetadata.slug}
              categoryTitle={firstCategory.categoryTitle}
              dateCreated={customDateCreated}
              imageAltText={imageAltText}
              key={slug}
              layout={TileLayoutModel.STACKED}
              previewBody={previewBody}
              previewImage={gatsbyImageData}
              previewTitle={previewTitle}
              slug={slug}
            />
          );
        })}
      </CategorySectionStacked>
      {allCategoryData.nodes.map(({
        categoryTitle,
        featuredPosts,
        publishingMetadata,
      }, i) => {
        const isEven = i % 2 === 0;
        if (featuredPosts && featuredPosts.length) {
          return (
            <CategorySectionSliding
              backgroundColor={isEven ? Colors.nearWhite() : Colors.white()}
              key={i}
              sectionSpacing={{
                top: 100,
                bottom: 100,
              }}
              sectionTitle={categoryTitle}
              viewAllButtonSlug={`/blog/tag${publishingMetadata.slug}`}
            >
              {featuredPosts.map(({
                previewTitle,
                previewBody,
                previewImage: { gatsbyImageData },
                imageAltText,
                customDateCreated,
                publishingMetadata: { slug },
              }) => {
                return (
                  <BlogPostPreviewTile
                    dateCreated={customDateCreated}
                    imageAltText={imageAltText}
                    key={slug}
                    layout={TileLayoutModel.SLIDING}
                    previewBody={previewBody}
                    previewImage={gatsbyImageData}
                    previewTitle={previewTitle}
                    slug={slug}
                  />
                );
              })}
            </CategorySectionSliding>
          );
        } else { return null; }
      })}
    </PageTemplate>
  );
}

BlogLandingPage.propTypes = {
  data: PropTypes.shape({
    topLevelOptionData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        navigationOptions: PropTypes.shape({
          ctaLink: PropTypes.string,
          ctaText: PropTypes.string,
          disableCtaButton: PropTypes.bool,
          disableStickyFooter: PropTypes.bool,
        }),
        publishingMetadata: PropTypes.shape({
          deIndexPage: PropTypes.bool,
          includeHotjar: PropTypes.bool,
          includeOptimizely: PropTypes.bool,
          pageTitle: PropTypes.string.isRequired,
          pageDescription: PropTypes.string.isRequired,
          pageThumbnail: PropTypes.shape({
            file: PropTypes.shape({
              url: PropTypes.string,
            }),
          }),
        }).isRequired,
        primaryHeaderLogo: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    allCategoryData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        categoryTitle: PropTypes.string.isRequired,
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
        featuredPosts: arrayOf(PropTypes.shape({
          previewTitle: PropTypes.string.isRequired,
          previewBody: PropTypes.string.isRequired,
          previewImage: PropTypes.shape({
            gatsbyImageData: PropTypes.object.isRequired,
          }).isRequired,
          imageAltText: PropTypes.string,
          customDateCreated: PropTypes.string,
          publishingMetadata: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }).isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
    recentPostData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        previewTitle: PropTypes.string.isRequired,
        previewBody: PropTypes.string.isRequired,
        previewImage: PropTypes.shape({
          gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
        imageAltText: PropTypes.string,
        customDateCreated: PropTypes.string,
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
        blogCategory: arrayOf(PropTypes.shape({
          categoryTitle: PropTypes.string.isRequired,
          publishingMetadata: PropTypes.shape({
            slug: PropTypes.string.isRequired,
          }).isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }.isRequired),
};

export const query = graphql`
  query {
    topLevelOptionData: allContentfulBlogTopLevelPageOptions(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        publishingMetadata {
          ...ContentfulPublishingMetadata_fragment
        }
        navigationOptions {
          ctaLink
          ctaText
          disableCtaButton
          disableStickyFooter
        }
        primaryHeaderLogo {
          file {
            url
          }
        }
      }
    }
    recentPostData: allContentfulBlogPostPage(filter: {node_locale: {eq: "en-US"}}, sort: {fields: customDateCreated, order: DESC}, limit: 4) {
      nodes {
        previewTitle
        previewBody
        previewImage {
          gatsbyImageData(quality: 30)
        }
        customDateCreated(formatString: "MMMM DD, YYYY")
        imageAltText
        publishingMetadata {
          slug
        }
        blogCategory {
          categoryTitle
          publishingMetadata {
            slug
          }
        }
      }
    }
    allCategoryData: allContentfulBlogCategoryTag(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        categoryTitle
        publishingMetadata {
          slug
        }
        featuredPosts {
          customDateCreated(formatString: "MMMM DD, YYYY")
          imageAltText
          previewTitle
          previewBody
          publishingMetadata {
            slug
          }
          previewImage {
            gatsbyImageData(quality: 30)
          }
        }
      }
    }
  }
`;
