import PropTypes from '@root/vendor/prop-types';
import React, { useLayoutEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { GatsbyImage, withArtDirection } from 'gatsby-plugin-image';

// ====== NOTE ====== //
// Let's work toward deprecating this component in favor of using our ArtDirectedGatsbyImage component.

export default function AlternateSizeGatsbyImage({
  desktop,
  mobile,
  imageAltText,
  styles,
  loading,
  className,
  imgStyle,
  breakpointIsLg = false,
}) {
  const [sources, setSources] = useState();

  useLayoutEffect(() => {
    if (!desktop && !mobile) {
      setSources(null);
    } else if (desktop && mobile) {
      const images = withArtDirection(desktop, [
        {
          image: desktop,
          media: breakpointIsLg ? Responsive.QUERIES.lg : Responsive.QUERIES.md,
        },
        {
          image: mobile,
          media: breakpointIsLg ? Responsive.QUERIES.lessThanLg : Responsive.QUERIES.lessThanMd,
        },
      ].filter((source) => source));

      setSources(images);
    } else {
      setSources(desktop ?? mobile);
    }
  }, [breakpointIsLg, desktop, mobile]);

  if (!sources) {
    return null;
  }

  return (
    sources &&
    <GatsbyImage
      alt={imageAltText ?? ''}
      className={className}
      css={styles}
      image={sources}
      imgStyle={imgStyle}
      loading={loading}
    />
  );
}

AlternateSizeGatsbyImage.propTypes = {
  breakpointIsLg: PropTypes.bool,
  className: PropTypes.string,
  desktop: PropTypes.object,
  imageAltText: PropTypes.string.isRequired,
  imgStyle: PropTypes.object,
  loading: PropTypes.oneOf(['eager', 'lazy', 'auto']),
  mobile: PropTypes.object,
  styles: PropTypes.object,
};
