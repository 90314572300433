import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export const RECAPTCHA_PRIVACY_POLICY_URL = 'https://policies.google.com/privacy';

export function RecaptchaPrivacyPolicyLink({ styles }) {
  return (
    <a
      css={styles}
      href={RECAPTCHA_PRIVACY_POLICY_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Privacy Policy
    </a>
  );
}

RecaptchaPrivacyPolicyLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
