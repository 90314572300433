import HubSpotValues from '@root/joinroot.com/src/third-party-api/hubspot-values';
import { checkStatus, fetchHelper, parseJSON } from '@root/gatsby-contentful-core/src/utils/fetch';

export const postAffiliateInfoRequestFormToWebhook = async (value) => {
  return await fetchHelper(HubSpotValues.onboardingSubmitUrl(), {
    method: 'POST',
    body: JSON.stringify(value),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON);
};

export const postAffiliateApplicationForm = async (value) => {
  return await fetchHelper(HubSpotValues.applicationFormUrl(), {
    method: 'POST',
    body: JSON.stringify(value),
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
    .then(checkStatus)
    .then(parseJSON);
};
