import GatsbyLink from '@root/gatsby-contentful-core/src/components/smart-link';
import styled from '@root/vendor/@emotion/styled';
import { BlockquoteStyles, H1DisplayStyles, H2Styles, H3Styles, H4Styles, H5Styles, LIStyles, LinkStyles, OLStyles, P1Styles, P2Styles, SubheadStyles, ULStyles } from '@root/gatsby-contentful-core/src/components/text-styles';

export const H1Display = styled.h1(H1DisplayStyles);
export const H2 = styled.h2(H2Styles);
export const H3 = styled.h3(H3Styles);
export const H4 = styled.h4(H4Styles);
export const H5 = styled.h5(H5Styles);
export const Eyebrow = styled(H5)();
export const Subhead = styled(H5)(SubheadStyles);
export const P1 = styled.p(P1Styles);
export const P2 = styled.p(P2Styles);
export const Link = styled(GatsbyLink)(LinkStyles);
export const UL = styled.ul(ULStyles);
export const OL = styled.ol(OLStyles);
export const LI = styled.li(LIStyles);
export const Blockquote = styled.blockquote(BlockquoteStyles);
