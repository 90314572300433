import Nav from '@root/joinroot.com/src/components/nav/nav';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useLayoutEffect, useState } from '@root/vendor/react';
import StickyFooter from '@root/joinroot.com/src/components/sticky-footer/sticky-footer';
import UserAgentService from '@root/core/src/services/user-agent-service';
import useScrollPosition from '@root/core/src/hooks/use-scroll-position';
import { NavRedesignAbTestBuckets } from '@root/core/src/models/ab-test-buckets';

const NavWrapper = ({
  bannerContent,
  isNavOpen,
  toggleNavState,
  ctaLink,
  ctaText,
  isBannerOn = false,
  isStickyFooterDisabled = false,
  isCtaButtonDisabled = false,
  navRedesignBucket,
  navRedesignIsLoading,
  isDarkMode,
}) => {
  const [isNavHidden, setNavHidden] = useState(false);
  const [isNavBgTransparent, setIsNavBgTransparent] = useState(false); // used for "dark mode"
  const [isFooterVisible, setFooterVisible] = useState(false);

  useLayoutEffect(() => {
    if (isDarkMode) {
      setIsNavBgTransparent(true);
    }
  }, [isDarkMode]);

  useScrollPosition(useCallback(({ previousPosition, currentPosition }) => {
    let shouldShowFooter = !UserAgentService.isRootMobileAgent();

    if (shouldShowFooter) {
      // Show the footer after scrolling past 100px
      shouldShowFooter = currentPosition.y < -100;
      setFooterVisible(shouldShowFooter);
    }

    if (isDarkMode) {
      currentPosition.y > -30 ? setIsNavBgTransparent(true) : setIsNavBgTransparent(false);
    }

    // Hide the nav after scrolling past 100px as long as the user isn't scrolling up
    const shouldHideNav = currentPosition.y < previousPosition.y && shouldShowFooter;
    setNavHidden(shouldHideNav);
  }, [isDarkMode]));

  return (
    <>
      <Nav
        bannerContent={bannerContent}
        ctaLink={ctaLink}
        ctaText={ctaText}
        isBannerOn={isBannerOn}
        isCtaButtonDisabled={isCtaButtonDisabled}
        isDarkMode={isDarkMode}
        isNavBgTransparent={isNavBgTransparent}
        isNavOpen={isNavOpen}
        isVisible={!isNavHidden}
        navRedesignBucket={navRedesignBucket}
        navRedesignIsLoading={navRedesignIsLoading}
        toggleNavState={toggleNavState}
      />
      {!isStickyFooterDisabled && !isCtaButtonDisabled &&
        <StickyFooter
          ctaLink={ctaLink}
          ctaText={ctaText}
          isVisible={isFooterVisible}
        />
      }
    </>
  );
};

NavWrapper.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isBannerOn: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isNavOpen: PropTypes.bool.isRequired,
  isStickyFooterDisabled: PropTypes.bool,
  navRedesignBucket: PropTypes.oneOf(Object.values(NavRedesignAbTestBuckets)),
  navRedesignIsLoading: PropTypes.bool,
  toggleNavState: PropTypes.func.isRequired,
};

export default NavWrapper;
