import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import styled from '@root/vendor/@emotion/styled';

export default class Fieldset extends Component {
  static propTypes = {
    children: PropTypes.node,
    title: PropTypes.node,
  };

  render() {
    return (
      <StyledFieldset>
        {this.props.title && (
          <legend className={'Fieldset__legend'}>
            {this.props.title}
          </legend>
        )}
        <div className={'Fieldset__content'}>
          {this.props.children}
        </div>
      </StyledFieldset>
    );
  }
}

const StyledFieldset = styled.fieldset(() => {
  return {
    padding: 0,
    margin: 0,
    border: 0,

    '&:only-child-of-type': {
      marginTop: '1rem',
      marginBottom: '1rem',
    },

    '&:not(:only-child), &:not(:last-child)': {
      marginBottom: '1.5rem',
    },

    '.Fieldset__legend': {
      display: 'block',
    },

    '> .Fieldset__content': {},
  };
});
