export default function PlayIndicator() {
  return (
    <svg
      aria-labelledby={'playIndicatorTitle'}
      data-testid={'play indicator'}
      fill={'none'}
      height={'90'}
      role={'img'}
      viewBox={'0 0 90 90'}
      width={'90'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'playIndicatorTitle'}>Play indicator</title>
      <circle
        cx={'45'}
        cy={'45'}
        r={'43'}
        stroke={'white'}
        strokeWidth={'4'}
      />
      <path
        d={'M64 45L36 25V65L64 45Z'}
        fill={'white'}
      />
    </svg>
  );
}
