import InputError from '@root/core/src/components/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { forwardRef, useRef } from '@root/vendor/react';
import useInputFocusOnMount from '@root/core/src/hooks/use-input-focus-on-mount';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

function TextArea({
  ariaLabel,
  autocapitalize,
  autocomplete,
  disabled,
  errorLabel,
  focusOnMount = false,
  inputRef,
  maxLength,
  name,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  textAreaStyles,
  value,
  wrapperStyles,
}) {
  const ref = useRef(null);
  inputRef = inputRef || ref;

  useInputFocusOnMount(focusOnMount, inputRef);

  const _handleChange = (e) => {
    onChange(e.target.value);
  };

  const _handleBlur = (e) => {
    onBlur && onBlur(e.target.value);
  };

  const _handleFocus = (e) => {
    onFocus && onFocus(e.target.value);
  };

  const componentStyles = [styles.textarea, textAreaStyles];

  if (errorLabel) {
    componentStyles.push(styles.errorInput);
  }

  return (
    <>
      <div css={[styles.wrapper, wrapperStyles]}>
        <textarea
          aria-label={ariaLabel}
          autoCapitalize={autocapitalize}
          autoComplete={autocomplete}
          css={componentStyles}
          disabled={disabled}
          id={name}
          maxLength={maxLength}
          name={name || undefined}
          onBlur={_handleBlur}
          onChange={_handleChange}
          onFocus={_handleFocus}
          placeholder={placeholder}
          ref={inputRef}
          value={value}
        />
      </div>
      <InputError
        message={errorLabel}
      />
    </>
  );
}

TextArea.propTypes = {
  ariaLabel: PropTypes.string,
  autocapitalize: PropTypes.string,
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  errorLabel: PropTypes.string,
  focusOnMount: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  maxLength: PropTypes.number,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  textAreaStyles: PropTypes.object,
  value: PropTypes.string.isRequired,
  wrapperStyles: PropTypes.object,
};

TextArea.defaultProps = {
  disabled: false,
};

export default forwardRef((props, ref) => (
  <TextArea
    {...props}
    inputRef={ref}
  />
));

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    height: '180px',
    position: 'relative',
  },
  textarea: {
    ...Theme.input(),
    width: '100%',
    height: '100%',
    resize: 'none',
    paddingLeft: '12px',
    paddingRight: '12px',
    paddingTop: '12px',
    paddingBottom: '12px',
    ...Theme.roundedCorners(),
    border: `1px solid ${Colors.gray40()}`,
    position: 'absolute',
    top: 0,
    left: 0,
    transition: 'border-color 200ms ease-in',
    caretColor: Colors.rootOrange(),
    ':focus': {
      outline: 'none',
      borderColor: Colors.black(),
    },
    ':disabled': {
      background: Colors.nearWhite(),
    },
  },
  errorInput: {
    borderColor: Colors.error(),
    color: Colors.error(),
    ':focus': {
      borderColor: Colors.error(),
    },
  },
});
