import AffiliateInfoRequestForm from '@root/joinroot.com/src/models/affiliate-info-request-form';
import Button from '@root/core/src/components/button';
import Colors from '@root/brand/src/utils/colors';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import ErrorReportService from '@root/core/src/services/error-report-service';
import HubSpotPersonas from '@root/joinroot.com/src/models/hubspot-personas';
import HubSpotValues from '@root/joinroot.com/src/third-party-api/hubspot-values';
import Input, { TextTypes } from '@root/core/src/components/input';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import React, { useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Select from '@root/core/src/components/select/select';
import TextArea from '@root/core/src/components/text-area';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useForm from '@root/core/src/hooks/use-form';
import { H2, H5, P1, P2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { postAffiliateInfoRequestFormToWebhook } from '@root/joinroot.com/src/third-party-api/hubspot-api';

const validations = [
  isRequired('persona', 'Required field'),
  isRequired('firstName', 'Required field'),
  isRequired('lastName', 'Required field'),
  isRequired('email', 'Required field'),
  isValidEmail('email'),
];

const landingExperience = 'AFFILIATES_LANDING_PAGE';

export default function AffiliateLandingInfoForm() {
  const { trackEvent, trackClick } = useAnalytics(landingExperience);
  const [isSubmitError, setIsSubmitError] = useState(false);
  const [shouldDisplaySuccessModal, setShouldDisplaySuccessModal] = useState(false);

  const form = useForm({
    initialValues: {
      variant: landingExperience,
    },
    validations,
  });

  const makeRequestAndCallSubmit = async (values) => {
    const partnerAgreement = new AffiliateInfoRequestForm(values);
    try {
      await postAffiliateInfoRequestFormToWebhook(partnerAgreement.serialize());
      trackEvent('INFO_REQUEST_FORM_SUCCESS');
      ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.AFFILIATES_INITIAL_SIGNUP);
      setShouldDisplaySuccessModal(true);
    } catch (error) {
      setIsSubmitError(true);
      trackEvent('INFO_REQUEST_FORM_ERROR');
      ErrorReportService.reportError({
        error,
        caughtAt: 'affiliate-landing-info-form',
        additionalData: {
          methodThatThrewException: 'makeRequestAndCallSubmit',
        },
      });
    }
  };

  const handleCloseModal = () => {
    setShouldDisplaySuccessModal(false);
  };

  const FormSubmitErrorMessage = () => (
    <div css={styles.formSubmitErrorMessage}>
      We’re having trouble submitting your info. To ensure your application is processed,&nbsp;
      <Link
        onClick={() => trackClick('FORM_ERROR_TRY_AGAIN')}
        to={HubSpotValues.FORM_DIRECT_URL}
      >
        please try again here.
      </Link>
    </div>
  );

  const personaOptions = Object.keys(HubSpotPersonas.PERSONA_OPTIONS).map((key) => ({
    label: key,
    value: HubSpotPersonas.PERSONA_OPTIONS[key],
  }));

  return (
    <div css={styles.formContainer}>
      <form onSubmit={form.createSubmitHandler(makeRequestAndCallSubmit)}>
        <fieldset css={styles.fieldset}>
          <legend css={styles.legend}>
            <H5 css={styles.formHeadline}>Apply now and get a response in less than one business day.</H5>
            <P2 css={styles.required}>*required</P2>
          </legend>
          <div css={[styles.inputBase, styles.selectBoxWrapper]}>
            <Select
              inputId={'persona'}
              inputName={'persona'}
              options={personaOptions}
              placeholder={'What type of affiliate are you?*'}
              selectBoxStyles={styles.selectBoxStyles}
              selectedValue={form.values.persona || ''}
              {...form.getFieldProps('persona')}
            />
          </div>
          <div css={styles.formRow}>
            <div css={styles.rowInput}>
              <Input
                label={'First name*'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('firstName')}
              />
            </div>
            <div css={styles.rowInput}>
              <Input
                label={'Last name*'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('lastName')}
              />
            </div>
          </div>
          <Input
            inputType={TextTypes.EMAIL}
            label={'Email address*'}
            wrapperStyle={styles.inputBase}
            {...form.getFieldProps('email')}
          />
          <Input
            label={'Company name'}
            wrapperStyle={styles.inputBase}
            {...form.getFieldProps('companyName')}
          />
          <div css={styles.formRow}>
            <div css={styles.rowInput}>
              <Input
                label={'Company website'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('companyWebsite')}
              />
            </div>
            <div css={styles.rowInput}>
              <Input
                inputType={TextTypes.NUMBER}
                label={'Number of employees'}
                wrapperStyle={styles.inputBase}
                {...form.getFieldProps('numberOfEmployees')}
              />
            </div>
          </div>
          <TextArea
            maxLength={2000}
            placeholder={'How do you plan to market Root?'}
            textAreaStyles={styles.textAreaStyles}
            wrapperStyles={styles.textArea}
            {...form.getFieldProps('message')}
          />

          {isSubmitError && <FormSubmitErrorMessage />}

          <Button
            disabled={!form.isValid || form.submitting || form.submitted}
            testID={'info-request-form-submit-button'}
            type={'submit'}
          >
            {form.submitted ? 'Thanks for applying' : 'Apply now'}
          </Button>
        </fieldset>
      </form >
      <Modal
        isShowing={shouldDisplaySuccessModal}
        name={'affiliate-landing-success-modal'}
        onClose={handleCloseModal}
        renderCloseButton={false}
      >
        <div css={styles.modalContent}>
          <H2 css={styles.modalHeadline}>Thanks for your interest in the Root Partner&nbsp;Program.</H2>
          <P1>We’ll send information regarding your&nbsp;inquiry&nbsp;to <span css={styles.modalEmail}>{form.values.email}</span></P1>
        </div>
        <Button
          css={styles.modalButton}
          onClick={handleCloseModal}
        >
          Ok
        </Button>
      </Modal>
    </div>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    ...Responsive.lg({
      maxWidth: 400,
    }),
  },
  fieldset: {
    border: 'none',
  },
  legend: {
    padding: 0,
  },
  formHeadline: {
    color: Colors.nearWhite(),
    fontSize: 25,
    lineHeight: '1.15',
  },
  required: {
    color: Colors.rootOrange(),
    marginBottom: 6,
  },
  inputBase: {
    flexGrow: 1,
    width: 'auto',
    marginTop: 10,
  },
  textArea: {
    marginTop: 10,
    height: 130,
  },
  formRow: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -5px',

    ...Responsive.sm({
      flexDirection: 'row',
    }),
  },
  rowInput: {
    flex: 1,
    marginLeft: 5,
    marginRight: 5,
  },
  selectBoxWrapper: {
    '& > div > div': {
      height: 'auto',
      '& > div:first-of-type': {
        top: 35,
      },
      '& > span': {
        fontSize: 16,
      },
    },
  },
  selectBoxStyles: {
    flex: 1,
    '::placeholder': {
      color: Colors.gray50(),
      opacity: 1,
    },
  },
  textAreaStyles: {
    '::placeholder': {
      color: Colors.gray50(),
      opacity: 1,
    },
  },
  formSubmitErrorMessage: {
    color: Colors.red(),
    margin: '6px 0 20px',
    fontSize: 16,
    lineHeight: 1.25,

    '& > a': {
      color: Colors.white(),
    },
  },
  modalContent: {
    padding: '0 10px',
    textAlign: 'center',
  },
  modalHeadline: {
    marginBottom: 16,
  },
  modalEmail: {
    fontWeight: 'bold',
  },
  modalButton: {
    marginTop: 34,
  },
});
