import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import StatusQuoCard from '@root/joinroot.com/src/components/progress/status-quo-card';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

export default function StatusQuoSection({
  content: {
    headline,
    body,
    cards,
  },
  statusQuoFairWayImg,
  statusQuoDropTheScoreImg,
  statusQuoCovidDrivingImg,
}) {
  const cardCount = cards?.length;
  const renderCards = cardCount > 0;

  const imageMap = {
    statusQuoFairWayId: statusQuoFairWayImg,
    statusQuoDropTheScoreId: statusQuoDropTheScoreImg,
    statusQuoCovidDrivingId: statusQuoCovidDrivingImg,
  };

  return (
    <section css={styles.section}>
      <div css={styles.textContent}>
        <H2 css={textStyles}>{headline}</H2>
        <P1 css={textStyles}>{body}</P1>
      </div>
      {renderCards &&
        <TileLayout cardCount={cardCount}>
          {cards.map((card) => {
            if (card.cardId) {
              return (
                <StatusQuoCard
                  buttonLink={card.buttonLink || null}
                  buttonText={card.buttonText || null}
                  cardBody={card.cardBody || ''}
                  cardHeadline={card.cardHeadline || ''}
                  image={imageMap[card.cardId]}
                  key={card.cardId}
                />
              );
            } else { return null; }
          })}
          <div css={styles.extraSliderMargin} />
        </TileLayout>
      }
    </section>
  );
}

StatusQuoSection.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        buttonLink: PropTypes.string.isRequired,
        buttonText: PropTypes.string.isRequired,
        cardBody: PropTypes.string.isRequired,
        cardHeadline: PropTypes.string.isRequired,
        cardId: PropTypes.string.isRequired,
      }).isRequired,
    ).isRequired,
  }).isRequired,
  statusQuoCovidDrivingImg: PropTypes.object.isRequired,
  statusQuoDropTheScoreImg: PropTypes.object.isRequired,
  statusQuoFairWayImg: PropTypes.object.isRequired,
};

const TileLayout = styled.div({
  display: 'flex',
  width: '100%',
  padding: '0 7%',
  scrollSnapType: 'x mandatory',
  overflowX: 'scroll',
  webkitOverflowScrolling: 'touch',
  // Hide Scrollbar: Every browser is different here...
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none', // Not currently supported by the parser used in Emotion
  scrollbarWidth: 'none', // Current Firefox solution and likely the future web default solution
  ...Responsive.md({
    padding: 0,
  }),
}, ({ cardCount }) => ({ maxWidth: 480 * cardCount }));

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.black(),
    padding: '60px 0',
  },
  textContent: {
    maxWidth: 670,
    margin: '0 20px 35px',
    textAlign: 'center',
    '& > * + *': {
      marginTop: 12,
    },
  },
  extraSliderMargin: {
    width: 30,
    flexShrink: 0,
    ...Responsive.md({
      width: 1,
    }),
    ...Responsive.xl({
      display: 'none',
    }),
  },
});
