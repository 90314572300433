import DividedFooter from '@root/joinroot.com/src/components/footer/divided-footer';
import FooterAddress from '@root/joinroot.com/src/components/footer/footer-address';
import FooterBase from '@root/brand/src/components/footer-base';
import FooterDisclaimer from '@root/brand/src/components/footer-disclaimer';
import FooterIconLinks from '@root/joinroot.com/src/components/footer/footer-icon-links';
import FooterLinks from '@root/joinroot.com/src/components/footer/footer-links';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/brand/src/components/root-logo';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { NavRedesignAbTestBuckets } from '@root/core/src/models/ab-test-buckets';

export default function Footer({
  navRedesignBucket,
  navRedesignIsLoading,
  isDarkMode,
}) {
  if (!navRedesignIsLoading && navRedesignBucket === NavRedesignAbTestBuckets.SIMPLE_NAV) {
    return <DividedFooter />;
  }

  return (
    <FooterBase isDarkMode={isDarkMode}>
      <a href={'/'}>
        <RootLogo logoStyles={isDarkMode ? styles.logoWhenDarkMode : styles.logo} />
      </a>
      <FooterIconLinks isDarkMode={isDarkMode} />
      <div css={styles.linksAndAddressContainer}>
        <FooterLinks isDarkMode={isDarkMode} />
        <FooterAddress />
      </div>
      <FooterDisclaimer
        containerStyles={styles.footerDisclaimer}
        isDarkMode={isDarkMode}
        onlyShowCopyright={false}
      />
    </FooterBase>
  );
}

Footer.propTypes = {
  isDarkMode: PropTypes.bool,
  navRedesignBucket: PropTypes.oneOf(Object.values(NavRedesignAbTestBuckets)),
  navRedesignIsLoading: PropTypes.bool,
};

const logoBaseStyles = {
  display: 'block',
  marginBottom: 40,
  width: 205,
  height: 'auto',
};

const styles = StyleSheet.create({
  logo: {
    ...logoBaseStyles,
  },
  logoWhenDarkMode: {
    ...logoBaseStyles,
    fill: Colors.white(),
  },
  linksAndAddressContainer: {
    ...Responsive.md({
      display: 'flex',
      justifyContent: 'space-between',
    }),
  },
  footerDisclaimer: {
    ...Theme.bodyTiny(),
  },
});
