
import AbTestContext from '@root/core/src/contexts/ab-test/ab-test-context';
import AbTestDispatchContext from '@root/core/src/contexts/ab-test/ab-test-dispatch-context';
import { useContext } from '@root/vendor/react';

export default function useAbTests() {
  const abTests = useContext(AbTestContext);
  const abTestsDispatch = useContext(AbTestDispatchContext);

  return [abTests, abTestsDispatch];
}
