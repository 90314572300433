import AlternateSizeGatsbyImage from '@root/gatsby-contentful-core/src/components/alternate-size-gatsby-image';
import BrandBling, { BRAND_BLING_OPTIONS } from '@root/gatsby-contentful-core/src/components/brand-bling';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { bold, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export default function StaticAspectRatioHero({ sectionSpacing = {} }) {
  const { trackClick } = useAnalytics('BRAND_BUILDING_CAMPAIGN_HEADER', true);
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  const { desktopImg, mobileImg } = useStaticQuery(graphql`
    query {
      desktopImg: file(relativePath: {eq: "brand-building/brand-building-hero_desktop.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
      }
      mobileImg: file(relativePath: {eq: "brand-building/brand-building-hero_mobile.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <section css={[styles.section, sectionSpacingStyles]}>
      <div css={styles.wrapImage}>
        <AlternateSizeGatsbyImage
          desktop={getImage(desktopImg)}
          imageAltText={''}
          loading={'eager'}
          mobile={getImage(mobileImg)}
          styles={{
            height: '100%',
          }}
        />
      </div>
      <div css={styles.wrapContent}>
        <div css={styles.containerContent}>
          <h1 css={styles.headline}>
            <span>With Root, you&apos;re</span>
            <br />
            <span>back in control.</span>
          </h1>
          <h2 css={styles.subhead}>Your driving is the #1 factor we use to give you a better price.</h2>
          <LinkButton
            buttonStyles={styles.button}
            onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
            to={environment.quoteAppBaseUrl}
            type={LinkButtonModel.types.PRIMARY}
          >
            Get started
          </LinkButton>
        </div>
      </div>
      <BrandBling
        brandBlingOptions={BRAND_BLING_OPTIONS.ICON}
      />
    </section>
  );
}

StaticAspectRatioHero.propTypes = {
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 30,
  },

  wrapImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  },

  wrapContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '133vw',

    ...Responsive.md({
      height: '50vw',
    }),
    ...Responsive.xl({
      height: '43vw',
    }),
  },

  containerContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    padding: '40px 20px 20px',
    textAlign: 'center',

    ...Responsive.sm({
      padding: 20,
    }),
    ...Responsive.md({
      alignItems: 'flex-start',
      textAlign: 'left',
    }),
  },

  headline: {
    ...bold(),
    color: Colors.white(),
    fontSize: '8vw',
    letterSpacing: '-0.02em',

    ...Responsive.md({
      fontSize: 50,
    }),
    ...Responsive.xl({
      fontSize: 70,
    }),

    span: {
      padding: '0 2vw',
      backgroundColor: Colors.rootOrange(),
      boxDecorationBreak: 'clone',
      lineHeight: '120%',

      ...Responsive.md({
        padding: '0 10px',
      }),
      ...Responsive.xl({
        padding: '0 14px',
      }),
    },
  },

  subhead: {
    margin: '10px 14px 30px',
    ...semiBold(),
    color: Colors.white(),
    fontSize: '6vw',
    letterSpacing: '-0.02em',
    lineHeight: '110%',
    textShadow: '0 0 20px rgba(0,0,0,0.9)',

    ...Responsive.sm({
      margin: '20px 14px 50px',
      fontSize: 32,
    }),
    ...Responsive.md({
      maxWidth: 400,
      textShadow: 'none',
    }),
    ...Responsive.xl({
      maxWidth: 600,
      fontSize: 42,
    }),
  },

  button: {
    margin: '0 14px',
  },
});

