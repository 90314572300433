import { useCallback, useEffect, useRef } from '@root/vendor/react';

const isBrowser = typeof window !== 'undefined';

function getScrollPosition() {
  if (!isBrowser) {
    return {
      x: 0,
      y: 0,
    };
  }

  const position = document.body.getBoundingClientRect();

  return {
    x: position.left,
    y: position.top,
  };
}

export default function useScrollPosition(onScrollCallback) {
  const previousPosition = useRef(getScrollPosition());

  const handleScroll = useCallback(() => {
    const currentPosition = getScrollPosition();
    onScrollCallback({
      previousPosition: previousPosition.current,
      currentPosition,
    });
    previousPosition.current = currentPosition;
  }, [onScrollCallback]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    });
    return () => window.removeEventListener('scroll', handleScroll, {
      capture: true,
      passive: true,
    });
  }, [handleScroll]);
}
