export default [
  {
    title: 'Insurance',
    items: [
      {
        url: '/coverage',
        linkText: 'Car insurance',
      },
      {
        url: '/renters-insurance',
        linkText: 'Renters insurance',
      },
      {
        url: '/homeowners-insurance',
        linkText: 'Homeowners insurance',
      },
    ],
  },
  {
    title: 'Resources',
    items: [
      {
        url: '/contact',
        linkText: 'Contact',
      },
      {
        url: '/coverage/roadside-assistance',
        linkText: 'Roadside assistance',
      },
      {
        url: '/customer-reviews',
        linkText: 'Customer reviews',
      },
      {
        url: '/blog',
        linkText: 'Blog',
      },
      {
        url: '/company',
        linkText: 'About',
      },
    ],
  },
];
