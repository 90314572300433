import ContentfulVariationContainerFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-variation-container-factory';
import snakeCase from '@root/vendor/lodash/snakeCase';
import useDynamicAbTest from '@root/core/src/hooks/use-dynamic-ab-test';
import { graphql } from 'gatsby';

export const graphQlFragment = graphql`
  fragment ContentfulVariationContainer_fragment on ContentfulVariationContainer {
    sys
    id
    experimentTitle
    meta: meta
    variations {
      ...ContentfulAvailabilityMap_fragment
      ...ContentfulCustomReactComponent_fragment
      ...ContentfulDisclaimerSection_fragment
      ...ContentfulExpandableSection_fragment
      ...ContentfulFaqSection_fragment
      ...ContentfulHero_fragment
      ...ContentfulHeroSimple_fragment
      ...ContentfulIconToutGrid_fragment
      ...ContentfulIconToutSection_fragment
      ...ContentfulIconToutSideBySide_fragment
      ...ContentfulImageToutSection_fragment
      ...ContentfulImageToutSideBySideSection_fragment
      ...ContentfulPressSection_fragment
      ...ContentfulQuoteToutSection_fragment
      ...ContentfulRichTextSection_fragment
      ...ContentfulVideoToutSection_fragment
    }
  }
`;

export default class ContentfulVariationContainer {
  constructor({ bucketIds = null, variations = null } = {}) {
    this.bucketIds = bucketIds;
    this.variations = variations;
  }

  static createFromGraphqlContent(content = {}) {
    const bucketIds = content?.meta?.variationIds.split('|').map((value) => {
      const [, id] = value.split(',');
      return id;
    }).filter((element) => {
      return element !== undefined;
    });

    return new ContentfulVariationContainer({
      bucketIds,
      variations: content.variations,
    });
  }

  static graphQlDataFactory = ContentfulVariationContainerFactory;

  static graphQlFragment = graphQlFragment;

  static propType() {}

  static VARIATION_CONTAINER = 'VARIATION_CONTAINER';

  static isGraphqlContentVariationContainer(content) {
    return snakeCase(content?.sys?.contentType?.sys?.id)?.toUpperCase() === this.VARIATION_CONTAINER;
  }

  static getExperimentIdFromGraphqlContent(content) {
    return content?.meta?.experimentId;
  }

  static getDynamicAbTest(experimentId) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useDynamicAbTest(experimentId);
  }

  getVariationFromBucket(bucket) {
    if (!this.bucketIds || !this.variations || !bucket) { return undefined; }
    const bucketIndex = this.bucketIds.indexOf(bucket);
    const variation = this.variations[bucketIndex];
    return variation;
  }
}
