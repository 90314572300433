import CoreBlockComponentMap from '@root/gatsby-contentful-core/src/models/core-block-component-map';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import MixpanelDetails from '@root/gatsby-contentful-core/src/components/mixpanel-details';
import PropTypes from '@root/vendor/prop-types';
import environment from '@root/core/src/utils/environment';
import snakeCase from '@root/vendor/lodash/snakeCase';
import useContentfulVariationContainer from '@root/gatsby-contentful-core/src/hooks/use-contentful-variation-container';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { useEffect, useState } from '@root/vendor/react';

const VARIATION_CONTAINER = 'VARIATION_CONTAINER';

const formattedContentId = (blockToFormat) => {
  return snakeCase(blockToFormat?.sys?.contentType?.sys?.id)?.toUpperCase();
};

function BlockRenderer({ block, blockComponentMap }) {
  const [blockToUse, setBlockToUse] = useState(block);
  const [loaded, setLoaded] = useState(false);
  const newBlockToUse = useContentfulVariationContainer(block);
  const isVariation = formattedContentId(block) === VARIATION_CONTAINER;
  const contentId = formattedContentId(blockToUse);

  useEffect(() => {
    async function fetchData() {
      if (!isVariation) { return; }
      setBlockToUse(newBlockToUse);
      setLoaded(true);
    }

    fetchData();
  }, [isVariation, newBlockToUse]);

  const analyticsContext = `${contentId}_${block.id}`;
  const {
    nodeRef,
    trackClick,
    trackEvent,
  } = useVisibilityAnalytics(analyticsContext);
  if (!contentId) {
    return null;
  }
  const ComponentToRender = blockComponentMap.map[contentId];

  if (!ComponentToRender && (!isVariation || loaded)) {
    throw new Error(`No block component found to render ${formattedContentId(blockToUse)} content type`);
  }

  const customFormRenderer = blockComponentMap.ids?.CUSTOM_FORM_RENDERER && blockComponentMap.map[blockComponentMap.ids.CUSTOM_FORM_RENDERER] || null;

  return (
    <div>
      {(!isVariation || loaded) &&
        <div
          data-testid={'block-renderer'}
          ref={nodeRef}
        >
          <MixpanelDetails
            analyticsContext={analyticsContext}
            isPageView={false}
          />

          <ComponentToRender
            content={blockToUse}
            customFormRenderer={customFormRenderer}
            trackClick={trackClick}
            trackEvent={trackEvent}
          />
        </div>

      }
    </div>
  );
}

export default function BlockRendererWrapper(props) {
  const isInPreviewMode = environment.contentfulPreviewEnabled;

  return isInPreviewMode
    ? <ErrorBoundary showErrorScreen={false}><BlockRenderer {...props} /></ErrorBoundary>
    : <BlockRenderer {...props} />;
}

BlockRenderer.propTypes = {
  block: PropTypes.shape({
    id: PropTypes.string.isRequired,
    content: PropTypes.object,
    sys: PropTypes.shape({
      contentType: PropTypes.shape({
        sys: PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      }),
    }).isRequired,
  }).isRequired,
  blockComponentMap: PropTypes.instanceOf(CoreBlockComponentMap).isRequired,
};
