import Colors from '@root/brand/src/utils/colors';
import Responsive from '@root/core/src/utils/responsive';
import { black, bold, light, regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

const Theme = {
  heading1() {
    return {
      ...black(),
      fontSize: 70,
      lineHeight: '90%',
    };
  },

  heading1Small() {
    return {
      ...black(),
      fontSize: 40,
      lineHeight: '96%',
    };
  },

  heading2() {
    return {
      ...black(),
      fontSize: 48,
      lineHeight: '90%',
    };
  },

  heading3() {
    return {
      ...black(),
      fontSize: 36,
      lineHeight: '95%',
    };
  },

  subhead1() {
    return {
      ...regular(),
      fontSize: 45,
      lineHeight: '120%',
    };
  },

  subhead1Light() {
    return {
      ...light(),
      fontSize: 45,
      lineHeight: '120%',
    };
  },

  subhead1Black() {
    return {
      ...black(),
      fontSize: 45,
      lineHeight: '120%',
    };
  },

  subhead2() {
    return {
      ...regular(),
      fontSize: 30,
      lineHeight: '120%',
    };
  },

  subhead2Light() {
    return {
      ...light(),
      fontSize: 30,
      lineHeight: '120%',
    };
  },

  cardHeading() {
    return {
      ...black(),
      fontSize: 27,
      lineHeight: '120%',
      color: Colors.DEPRECATED_shark(),
    };
  },

  bannerHeading() {
    return {
      ...black(),
      fontSize: 36,
      lineHeight: '95%',
      color: Colors.white(),
    };
  },

  superheading() {
    return {
      ...semiBold(),
      fontSize: 18,
      lineHeight: '140%',
      color: '#767676',
    };
  },

  content() {
    return {
      ...regular(),
      fontSize: 19,
      lineHeight: '22px',
      color: Colors.gray50(),
    };
  },

  bodyCopy() {
    return {
      ...regular(),
      fontSize: 20,
      lineHeight: '140%',
      color: '#767676',
    };
  },

  bodyRegular() {
    return {
      ...regular(),
      fontSize: 17,
      lineHeight: '24px',
      color: Colors.gray50(),
    };
  },

  bodySmall() {
    return {
      ...regular(),
      fontSize: 15,
      lineHeight: '20px',
      color: Colors.gray50(),
    };
  },

  bodyCopySmall() {
    return {
      ...regular(),
      fontSize: 16,
      lineHeight: '140%',
      color: '#767676',
    };
  },

  bodyCopySemiBold() {
    return {
      ...semiBold(),
      fontSize: 20,
      lineHeight: '140%',
      color: '#767676',
    };
  },

  bodyCopyBold() {
    return {
      ...bold(),
      fontSize: 20,
      lineHeight: '140%',
    };
  },

  bodyTiny() {
    return {
      ...regular(),
      fontSize: 12,
      lineHeight: '15px',
      color: Colors.gray50(),
    };
  },

  subhead3Light() {
    return {
      ...light(),
      fontSize: 20,
      lineHeight: '120%',
      color: '#737373',
    };
  },

  subhead3Semibold() {
    return {
      ...semiBold(),
      fontSize: 20,
      lineHeight: '120%',
      color: Colors.black(),
    };
  },

  ctaButton() {
    return {
      ...semiBold(),
      color: Colors.white(),
      backgroundColor: Colors.rootOrange(),
    };
  },

  navLink() {
    return {
      ...semiBold(),
      fontSize: 23,
      color: Colors.gray60(),
      ...Responsive.md({
        fontSize: 17,
      }),
      ...Responsive.lg({
        fontSize: 18,
      }),
    };
  },

  roundedCorners() {
    return {
      borderRadius: 3,
    };
  },
};

export default Theme;
