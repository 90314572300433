import BrandColors from '@root/brand/src/utils/colors';

export default class Colors extends BrandColors {
  static rootOrange = (opacity = 1) => toRgba('#ff5715', opacity);
  static rootOrangeLight = () => '#FF895B';
  static nearBlack = (opacity = 1) => toRgba('#1d1d21', opacity);
  static grayDark = () => '#535255';
  static grayMedium = (opacity = 1) => toRgba(this.gray50(), opacity);
  static grayLight = () => '#F3F3F3';
  static grayLighter = () => this.gray30();
  static greyLight = () => this.gray10();
  static greyMedium = () => this.gray20();
  static greyMediumDark = () => '#8b8b8e';
  static greyDark = () => '#767678';
  static greyDarker = () => this.gray60();
  static greyDisabled = () => '#b5b4b6';
  static green = () => this.DEPRECATED_green();
  static darkGreen = () => '#007a52';
  static darkBlue = () => '#006bc6';
  static purple = () => '#db5ed1';
  static darkPurple = () => this.DEPRECATED_darkPurple();
  static invalid = () => this.DEPRECATED_amaranth();
}

export const toRgb = (value) => {
  const raw = value.replace('#', '');
  const color = raw.length === 3 ? raw.split.map((s) => `${s}${s}`) : raw.padEnd(6, '0');
  return `${parseInt(color.substring(0, 2), 16)},${parseInt(color.substring(2, 4), 16)},${parseInt(color.substring(4, 6), 16)}`;
};

export const toRgba = (value, opacity = 1) => {
  return `rgba(${toRgb(value)},${opacity})`;
};
