import Button, { ButtonSize } from '@root/gatsby-contentful-core/src/components/button';
import Colors, { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';
import Icon from '@root/gatsby-contentful-core/src/components/icon';
import InputError from '@root/gatsby-contentful-core/src/components/form/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import S from 'string';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import styled from '@root/vendor/@emotion/styled';
import uuid from '@root/vendor/uuid/v4';
import { IntentType } from '@root/brand/src/utils/theme';

export default class Checkbox extends Component {
  static propTypes = {
    checked: PropTypes.bool,
    disabled: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    fontColor: PropTypes.string,
    hint: PropTypes.string,
    icon: PropTypes.oneOfType([Icon]),
    id: PropTypes.string,
    intent: PropTypes.string,
    isTouched: PropTypes.bool,
    label: PropTypes.string,
    name: PropTypes.string,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    size: Button.propTypes.size,
  };

  static defaultProps = {
    onChange: () => {},
    size: ButtonSize.NORMAL,
    intent: IntentType.PRIMARY,
    disabled: false,
    value: '',
    checked: false,
    fontColor: Colors.greyDark(),
  };

  state = {
    id: this.props.id || uuid(),
  };

  get errors() {
    return typeof this.props.errors === 'string'
      ? [this.props.errors]
      : this.props.errors;
  }

  get isInvalid() {
    return this.props.isTouched && this.props.errors;
  }

  get isValid() {
    return !this.isInvalid;
  }

  get isSelected() {
    return !!this.props.checked;
  }

  get label() {
    return this.props.label;
  }

  get inputId() {
    return S(this.props.name || this.state.id).slugify().s;
  }

  get labelId() {
    return `${this.inputId}-label`;
  }

  handleChange = (event) => {
    const { value, checked } = event.target;
    this.setState({
      value,
      checked,
    });
    this.props.onChange(event);
  };

  render() {
    const {
      name, icon, id, intent, isTouched, label, checked, placeholder, size, onChange, fontColor, ...rest // eslint-disable-line no-unused-vars
    } = this.props;

    return (
      <StyledCheckbox
        className={'Checkbox'}
        fontColor={fontColor}
        hasIcon={!!icon}
        isDisabled={this.props.disabled}
        isInvalid={this.isInvalid}
        isReadOnly={this.props.readOnly}
        isValid={this.isValid}
        size={size}
      >
        <div className={'Checkbox__content'}>
          <input
            {...rest}
            aria-invalid={!!this.isInvalid}
            aria-labelledby={this.labelId}
            aria-required={!!this.props.required}
            data-testid={name}
            defaultChecked={this.props.checked}
            id={this.inputId}
            name={name}
            onChange={this.handleChange}
            type={'checkbox'}
          />
          <div className={'Checkbox__indicator'} />
          {this.props.label && (
            <label
              htmlFor={this.inputId}
              id={this.labelId}
            >{this.props.label}
            </label>
          )}
        </div>
        <div className={'Input__meta'}>
          {this.props.isTouched && this.errors?.map((error) =>
            <InputError key={error}>{error}</InputError>
          )}
          {this.props.hint && this.errors.length === 0 && (
            <small
              className={'meta__hint'}
            >
              {this.props.hint}
            </small>
          )}
        </div>
      </StyledCheckbox>
    );
  }
}

const StyledCheckbox = styled.div(({
  fontColor, size, intent,
}) => {
  let labelTypography;
  let height;
  let backgroundColor;
  let color;

  switch (size) {
  case ButtonSize.LARGE:
    height = 70;
    labelTypography = Typography.formLarge();
    break;
  case ButtonSize.SMALL:
    height = 30;
    labelTypography = Typography.formNormal();
    break;
  case ButtonSize.NORMAL:
  default:
    height = 58;
    labelTypography = Typography.formNormal();
    break;
  }

  switch (intent) {
  case IntentType.PRIMARY:
  default:
    backgroundColor = Colors.white();
    color = Colors.nearBlack();
  }

  return {

    '.Checkbox__content': {
      minHeight: height,
      position: 'relative',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },

    '.Checkbox__indicator': {
      width: Sizes.LARGE,
      height: Sizes.LARGE,
      flex: '0 0 auto',
      color,
      backgroundColor,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: toRgba(Colors.black(), 0.1),
      ...Theme.roundedCorners(),
      position: 'relative',
      transition: [
        'background-color 0.15s ease-out',
      ],
      '&:after': {
        content: '""',
        position: 'absolute',
        top: 4,
        left: 8,
        width: 7,
        height: 12,
        borderWidth: 2,
        borderColor: 'transparent',
        borderBottomStyle: 'solid',
        borderRightStyle: 'solid',
        transformOrigin: '50% 50%',
        transform: 'rotate(45deg)',
        transition: [
          'border-color 0.15s ease-out',
        ],
      },
    },

    label: {
      ...labelTypography,
      paddingLeft: Sizes.NORMAL,
      boxSizing: 'border-box',
      color: fontColor,
    },

    'input[type="checkbox"]': {
      appearance: 'none',
      position: 'absolute',
      width: Sizes.LARGE,
      height: Sizes.LARGE,
      zIndex: 1,

      '&:not([disabled]):not([readonly])': {
        '&:active, &:hover, &:focus': {
          '+ .Checkbox__indicator:after': {
            borderColor: Colors.greyMedium(),
          },
        },

        '&:checked + .Checkbox__indicator': {
          backgroundColor: Colors.rootOrange(),

          '&:after': {
            borderColor: Colors.white(),
          },
        },

        '&[aria-invalid="true"]': {
          '+ .Checkbox__indicator': {
            borderColor: Colors.invalid(),
          },
        },
      },

      '&[readonly]': {
        outline: 'none',
        '+ .Checkbox__indicator': {
          borderColor: Colors.greyMedium(),
        },
      },

      '&[disabled]': {
        outline: 'none',
        '+ .Checkbox__indicator': {
          backgroundColor: Colors.greyMedium(),
          borderColor: Colors.greyMedium(),
        },
      },
    },

    '.Input__meta': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: Sizes.XXSMALL,
      marginBottom: Sizes.SMALL,

      '.meta__hint': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '1 0 auto',
      },

      '.meta__count': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '0 1 0%',
        marginLeft: Sizes.XXSMALL,
      },
    },
  };
});
