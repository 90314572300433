import FaqSection from '@root/gatsby-contentful-core/src/components/sections/faq/faq-section';
import PropTypes from '@root/vendor/prop-types';
import React, { useMemo } from '@root/vendor/react';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

const useFaqData = (faqGroups) => {
  return useMemo(() => faqGroups.map((faqGroup) => {
    return {
      anchor: faqGroup.anchor ? faqGroup.anchor.anchorSlug : null,
      title: faqGroup.title,
      link: null,
      items: faqGroup.faqs.map((faq) => {
        return {
          anchor: faq.anchor ? faq.anchor.anchorSlug : null,
          title: faq.question,
          content: renderRichText(faq.answer),
          plainText: documentToPlainTextString(faq.answer.json),
        };
      }),
    };
  }), [faqGroups]);
};

export default function FaqSectionBlock({ content }) {
  const faqData = useFaqData(content.faqGroups);

  return (
    <div data-testid={'faqSectionBlock'}>
      <FaqSection
        anchor={content.anchor?.anchorSlug}
        faqData={faqData}
        sectionSpacing={content.sectionSpacing}
      />
    </div>
  );
}

FaqSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    faqGroups: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        anchor: PropTypes.shape({
          id: PropTypes.string,
          anchorSlug: PropTypes.string,
        }),
        title: PropTypes.string.isRequired,
        tags: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.string.isRequired,
            tagName: PropTypes.string.isRequired,
            faq: PropTypes.arrayOf(
              PropTypes.shape({
                id: PropTypes.string.isRequired,
                anchor: PropTypes.shape({
                  id: PropTypes.string,
                  anchorSlug: PropTypes.string,
                }),
                question: PropTypes.string.isRequired,
                answer: PropTypes.shape({
                  json: PropTypes.object,
                }).isRequired,
              }).isRequired,
            ),
          }).isRequired,
        ),
      }).isRequired,
    ),
  }).isRequired,
};
