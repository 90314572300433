import AttributionQueryParamService from '@root/attribution/src/services/attribution-query-param-service';
import CookieStorageService from '@root/core/src/services/cookie-storage-service';
import ProjectContext from '@root/core/src/contexts/project/project-context';
import Projects from '@root/core/src/models/projects';
import environment from '@root/core/src/utils/environment';
import getJoinrootAgentsBannerPaidUrlConfiguration from '@root/gatsby-contentful-core/src/api/joinroot-agents-banner-paid-url-configuration';
import useSafeImperativeNetworkRequest from '@root/core/src/hooks/use-safe-imperative-network-request';
import { useContext, useEffect, useState } from '@root/vendor/react';

export const JOINROOT_AGENTS_BANNER_PAID_URL_COOKIE_KEY = 'joinroot_agents_banner_paid_url-bucket';

export default function useJoinrootAgentsBannerPaidUrlAbTest(url) {
  const [bucket, setBucket] = useState(null);

  const { project } = useContext(ProjectContext);
  const isBannerEnabledPage = project === Projects.JoinRoot && url in environment.joinrootAgentsBannerPaidUrls;
  const isRunningAbTest = environment.joinrootAgentsBannerPaidUrlEnabled && isBannerEnabledPage;
  const partnerAttribution = AttributionQueryParamService.read();
  const partnerId = partnerAttribution?.pid?.toUpperCase();

  const [fetchJoinrootAgentsBannerPaidUrlBucket] = useSafeImperativeNetworkRequest(getJoinrootAgentsBannerPaidUrlConfiguration);

  useEffect(() => {
    (async () => {
      if (!isRunningAbTest || bucket) {
        return;
      }

      if (CookieStorageService.has(JOINROOT_AGENTS_BANNER_PAID_URL_COOKIE_KEY)) {
        setBucket(CookieStorageService.get(JOINROOT_AGENTS_BANNER_PAID_URL_COOKIE_KEY));
      } else {
        const result = await fetchJoinrootAgentsBannerPaidUrlBucket(partnerId);
        if (result.isSuccess() && result.data.bucket) {
          CookieStorageService.set(JOINROOT_AGENTS_BANNER_PAID_URL_COOKIE_KEY, result.data.bucket);
          setBucket(result.data.bucket);
        }
      }
    })();
  }, [bucket, fetchJoinrootAgentsBannerPaidUrlBucket, isRunningAbTest, partnerId]);

  return [bucket, isBannerEnabledPage];
}
