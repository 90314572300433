import AnalyticsService from '@root/core/src/services/analytics-service';
import TrackingService from '@root/attribution/src/services/tracking-service';

export default function (marketingPrefix) {
  return function onRouteUpdate({ location }) {
    AnalyticsService.trackEvent('PAGE', 'VIEW', {
      eventPrefix: marketingPrefix,
      'Page Hostname': location.hostname,
      'Page Query String': location.search,
      'Page Path': location.pathname,
      'Page URL': location.origin + location.pathname,
    });
    TrackingService.fireHistoryChangeEvent(location.href);

    if (!location.hash) { window.scrollTo(0, 0); }
  };
}
