import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import TableSection from '@root/gatsby-contentful-core/src/components/sections/table-section';

export default function TableSectionBlock({ content }) {
  return (
    <div data-testid={'TableSectionBlock'}>
      <TableSection tableData={content.table.tableData} />
    </div>
  );
}

TableSectionBlock.propTypes = {
  content: PropTypes.shape({
    table: PropTypes.shape({
      tableData: PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.string,
        ).isRequired,
      ).isRequired,
    }).isRequired,
  }).isRequired,
};
