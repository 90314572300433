import AlternateSizeGatsbyImage from '@root/gatsby-contentful-core/src/components/alternate-size-gatsby-image';
import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import ToutContent from '@root/gatsby-contentful-core/src/components/sections/image-tout-side-by-side/tout-content';
import { Colors, styled } from '@root/core/src/utils/styles';
import { IMAGE_SIZE, TOUT_LAYOUT_X, TOUT_LAYOUT_Y } from '@root/gatsby-contentful-core/src/components/sections/image-tout-side-by-side/tout-options';
import { getImage } from 'gatsby-plugin-image';
export default function ImageToutSideBySideSection({
  anchor,
  toutContent,
  backgroundColor = BackgroundColors.cmsValues['WHITE'],
  toutLayoutX = TOUT_LAYOUT_X['Image left'],
  toutLayoutY = TOUT_LAYOUT_Y['Align center'],
  imageSize = IMAGE_SIZE['Large'],
  image: {
    desktopImg,
    imageAltText,
    mobileImg,
  },
  sectionSpacing = {},
}) {
  return (
    <Section
      backgroundColor={backgroundColor}
      id={anchor}
      sectionSpacing={sectionSpacing}
    >
      <Wrap
        toutLayoutX={toutLayoutX}
        toutLayoutY={toutLayoutY}
      >
        <Image
          desktop={getImage(desktopImg)}
          imageAltText={imageAltText}
          imgStyle={{ objectFit: 'contain' }}
          mobile={getImage(mobileImg)}
        />
        <ContentContainer
          imageSize={imageSize}
          toutLayoutX={toutLayoutX}
        >
          <ToutContent {...toutContent} />
        </ContentContainer>
      </Wrap>
    </Section>
  );
}

ImageToutSideBySideSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  image: PropTypes.shape({
    imageAltText: PropTypes.string.isRequired,
    desktopImg: PropTypes.object.isRequired,
    mobileImg: PropTypes.object,
  }).isRequired,
  imageSize: PropTypes.oneOf([IMAGE_SIZE['Small'], IMAGE_SIZE['Large']]),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  toutContent: PropTypes.object.isRequired,
  toutLayoutX: PropTypes.oneOf(Object.values(TOUT_LAYOUT_X)),
  toutLayoutY: PropTypes.oneOf(Object.values(TOUT_LAYOUT_Y)),
};

const Section = styled.section({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  const backgroundColorMap = {
    white: { backgroundColor: Colors.white() },
    offWhite: { backgroundColor: Colors.nearWhite() },
  };

  return {
    ...sectionSpacingStyles,
    ...backgroundColorMap[backgroundColor],
  };
});

const Wrap = styled.div({
  ...Responsive.lessThanMd({
    flexDirection: 'column',
  }),
  display: 'flex',
  width: 'min(1200px, 100%)',
  margin: '0 8%',
}, ({ toutLayoutX, toutLayoutY }) => {
  const toutLayoutXStyles = toutLayoutX === 'IMAGE_RIGHT'
    ? { flexDirection: 'row-reverse' }
    : { flexDirection: 'row' };

  const toutLayoutYStyles = toutLayoutY === 'ALIGN_TOP'
    ? { alignItems: 'flex-start' }
    : { alignItems: 'center' };

  return {
    ...Responsive.md({
      ...toutLayoutXStyles,
      ...toutLayoutYStyles,
    }),
  };
});

const Image = styled(AlternateSizeGatsbyImage)({
  ...Responsive.lessThanMd({
    margin: '0 calc(50% - 50vw)',
  }),
  flex: 1,
});

const ContentContainer = styled.div({
  ...Responsive.lessThanMd({
    width: '100%',
    padding: '30px 0 0',
    alignItems: 'center',
    textAlign: 'center',
  }),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: 0,
  textAlign: 'left',
  '& > * + *': { marginTop: 14 },
  '& > a': {
    maxWidth: 240,
    marginTop: 35,
  },
}, ({ toutLayoutX, imageSize }) => {
  const toutLayoutXStyles = {
    IMAGE_LEFT: { paddingLeft: '12%' },
    IMAGE_RIGHT: { paddingRight: '12%' },
  };

  const imageSizeStyles = {
    SMALL: { width: '60%' },
    LARGE: { width: 'max(42%, 340px)' },
  };

  return {
    ...toutLayoutXStyles[toutLayoutX],
    ...imageSizeStyles[imageSize],
  };
});
