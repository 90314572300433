import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import { Colors, styled } from '@root/core/src/utils/styles';

export default function SubmissionErrorMessage({ fallbackUrl }) {
  return (
    <SubmissionError>
      We’re having trouble submitting the form. To ensure we hear from you,&nbsp;
      <ErrorLink to={fallbackUrl}>please try again here</ErrorLink>.
    </SubmissionError>
  );
}

SubmissionErrorMessage.propTypes = {
  fallbackUrl: PropTypes.string.isRequired,
};

const SubmissionError = styled.div({
  padding: '6px 12px',
  borderRadius: 6,
  backgroundColor: '#FFF5F5',
  color: '#D32F2F',
  fontSize: 16,
  lineHeight: 1.4,
});

const ErrorLink = styled(Link)({
  color: '#D32F2F',
  fontSize: 16,
  lineHeight: 1.4,
  textDecoration: 'underline',
  transition: 'color 100ms ease-in-out',

  '&:hover': {
    color: Colors.black(),
  },
});
