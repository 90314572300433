import ChevronDownIcon from '@root/gatsby-contentful-core/src/assets/components/icons/chevron-down-icon';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import KeyCode from '@root/core/src/utils/keycode';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useState } from '@root/vendor/react';
import { H4 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function ExpandableItem({
  anchor,
  itemTitle,
  itemTitleSecondary,
  openExpandableItem,
  children,
}) {
  const [expanded, setExpanded] = useState(false);
  const [focused, setFocused] = useState(false);

  function handleKeyDown(e) {
    const keyCode = KeyCode.fromEvent(e);

    if (keyCode === KeyCode.KEYCODES.ENTER) {
      setExpanded(!expanded);
    }
  }

  function handleClick() {
    if (expanded && focused) {
      setFocused(false);
    }
    setExpanded(!expanded);
  }

  // openExpandableItem allows for toggling from the outside
  useEffect(() => openExpandableItem ? setExpanded(true) : setExpanded(false), [openExpandableItem]);

  return (
    <div
      css={styles.wrap}
      id={anchor}
    >
      <div
        aria-pressed={expanded}
        css={styles.itemTitleContainer}
        onBlur={() => setFocused(false)}
        onClick={() => handleClick()}
        onFocus={() => setFocused(true)}
        onKeyDown={handleKeyDown}
        role={'button'}
        tabIndex={0}
      >
        <div
          css={[styles.itemTitle, (expanded || focused) && styles.itemTitleHighlighted]}
          data-testid={'item-title'}
        >
          <H4 css={styles.titleText}>{itemTitle}</H4>
          {itemTitleSecondary && <H4 css={styles.titleText}>{itemTitleSecondary}</H4>}
        </div>
        <div css={[styles.arrow, expanded && styles.arrowRotate]}>
          <ChevronDownIcon strokeColor={expanded || focused ? Colors.rootOrange() : Colors.nearBlack()} />
        </div>
      </div>
      <div
        css={[styles.itemContentContainer, expanded && styles.itemContentContainerExpanded]}
        data-testid={'content-container'}
      >
        <div css={styles.itemContent}>
          {children}
        </div>
      </div>
    </div>
  );
}

ExpandableItem.propTypes = {
  anchor: PropTypes.string,
  children: PropTypes.node.isRequired,
  itemTitle: PropTypes.string.isRequired,
  itemTitleSecondary: PropTypes.string,
  openExpandableItem: PropTypes.bool,
};

const styles = StyleSheet.create({
  wrap: {
    borderBottom: `1px solid ${Colors.grayLighter()}`,
  },
  itemTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 10px',
    cursor: 'pointer',

    '@media(hover: hover) and (pointer: fine)': {
      '&:hover > div': {
        color: Colors.rootOrange(),
      },
      '&:hover svg path': {
        stroke: Colors.rootOrange(),
      },
    },
  },
  itemTitle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    transition: '150ms ease-in-out',
    marginRight: 50,
    color: Colors.nearBlack(),
    userSelect: 'none',
  },
  itemTitleHighlighted: {
    color: Colors.rootOrange(),
  },
  titleText: {
    color: 'inherit',
  },
  arrow: {
    transition: 'transform 150ms ease-in-out',

    '& svg path': {
      transition: '150ms ease-in-out',
    },
  },
  arrowRotate: {
    transform: 'rotate(-180deg)',
  },
  itemContentContainer: {
    maxHeight: 0,
    overflow: 'hidden',
    visibility: 'hidden',
    transition: '200ms cubic-bezier(0.37, 0, 0.28, 1)',
  },
  itemContent: {
    padding: '10px 10px 20px',
  },
  itemContentContainerExpanded: {
    maxHeight: 2000,
    visibility: 'visible',
  },
});
