import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function FooterLinksContainer({ children, containerStyles }) {
  return (
    <div css={[styles.container, containerStyles]}>
      {children}
    </div>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 30,
    ...Responsive.md({
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 2,
    }),
  },
});

FooterLinksContainer.propTypes = {
  children: PropTypes.node.isRequired,
  containerStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
