import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function LinkedinIcon({ iconStyles }) {
  return (
    <svg
      aria-label={'LinkedIn icon'}
      css={[styles.svg, iconStyles]}
      role={'img'}
      version={'1.1'}
      viewBox={'0 0 34 33'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <g
        fillRule={'evenodd'}
        stroke={'none'}
        strokeWidth={'1'}
      >
        <g fillRule={'nonzero'}>
          <path
            d={'M8,25.9 L8,33 L0,33 L0,10 L8,10 L8,25.9 Z M4,7.5 C1.6,7.5 0,5.8 0,3.7 C0,1.6 1.6,0 4,0 C6.4,0 7.9,1.6 8,3.7 C8,5.8 6.5,7.5 4,7.5 Z M34,33 L26,33 C26,33 26,20.9 26,20 C26,19.1 25.9,16 22.5,16 C19.5,16 19,18.9 19,20 C19,21.1 19,33 19,33 L11,33 L11,10 L19,10 L19,13.1 C19,13.1 20.6,10 25.3,10 C30.1,10 34,13.3 34,20 L34,33 Z'}
          />
        </g>
      </g>
    </svg>
  );
}

const styles = StyleSheet.create({
  svg: {
    fill: Colors.black(),
  },
});

LinkedinIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default LinkedinIcon;
