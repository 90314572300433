import environment from '@root/core/src/utils/environment';
import { useEffect, useState } from '@root/vendor/react';
import { useLocation } from '@reach/router';

// ==========================================================================
// Link transformation (occurs before bucketing links as internal/external)
// ==========================================================================

export function useTransformedUrl(to, shouldTransformUrls) {
  const currentLocation = useLocation();
  const [transformedHref, setTransformedHref] = useState();

  to = transformUrl(to, currentLocation, shouldTransformUrls);

  useEffect(() => setTransformedHref(to), [to]);

  return transformedHref;
}

export function transformUrl(to, currentLocation, shouldTransformUrls) {
  // relative urls don't need to be transformed so they're returned early.
  if (isRelativeLink(to)) { return addTrailingSlashToRelativeLinks(to); }
  // There are cases where we'd want to forgo href transformation and use exactly what was given.
  if (!shouldTransformUrls) { return to; }

  const url = new URL(to);

  url.host = environmentalizeUrl(url.host);

  const urlPointsToCurrentSite = url.host === currentLocation.host;

  if (url.host.includes('localhost')) {
    url.protocol = 'http';
  }

  // same-site urls are transformed to relative urls and are returned here. https://www.joinroot.com/claims/ becomes /claims/
  if (urlPointsToCurrentSite) { return addTrailingSlashToRelativeLinks(url.pathname + url.hash); }

  // urls to external sites have been environmentalized if applicable and are returned here.
  return url.href;
}

export function addTrailingSlashToRelativeLinks(to) {
  const [pathname, hash] = to.split('#');

  if (hash) {
    return pathname.endsWith('/') ? to : `${pathname}/#${hash}`;
  }

  return to.endsWith('/') ? to : `${to}/`;
}

export function isRelativeLink(to) {
  if (typeof to !== 'string') { return false; }

  const startsWithSingleSlash = to.startsWith('/') && !to.startsWith('//');
  const startsWithHash = to.startsWith('#');

  // If this is a relative or anchor href we can assume it's an internal link.
  if (startsWithSingleSlash || startsWithHash) { return true; }

  return false;
}

// This fn takes a urlHost and if it's in the list, it changes it to the current environment.
// So locally, quote.joinroot.com will become localhost:8080.
export function environmentalizeUrl(urlHost) {
  const environmentMap = {
    'www.joinroot.com': environment.websiteBaseUrl,
    'inc.joinroot.com': environment.incSiteBaseUrl,
    'quote.joinroot.com': environment.quoteAppBaseUrl,
    'account.joinroot.com': environment.accountAppBaseUrl,
    'agents.joinroot.com': environment.agentsAppBaseUrl,
    'claims.joinroot.com': environment.claimsAppBaseUrl,
    'renters.joinroot.com': environment.rentersAppBaseUrl,
  };

  try {
    if (urlHost === 'joinroot.com') { urlHost = 'www.joinroot.com'; }
    const environmentalizedUrlHost = new URL(environmentMap[urlHost]).host;
    return environmentalizedUrlHost;
  } catch (e) {
    return urlHost;
  }
}

// ==========================================================================
// Internal links
// ==========================================================================

// We only need to handle id anchors for internal same-page links because we want smooth scroll.
// For all other links we just let the browser do its thing.
export function scrollToIdAnchors(to, event) {
  const targetSectionId = to.split('#')[1];

  if (targetSectionId) {
    const pageSection = document.getElementById(targetSectionId);

    if (pageSection) {
      event.preventDefault();
      pageSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }
}

// ==========================================================================
// External links
// ==========================================================================

// By default all external links should open in a new tab unless they're Root sites.
// But if the user clicks with a specific key modifier, even Root sites should open in a new tab.
export function shouldOpenNewTab(to, event) {
  if (clickedWithKeyModifier(event)) { return true; }

  if (
    isRootDomainLink(to)
    || isEmailLink(to)
    || isPhoneLink(to)
    || isRootOnelink(to)
  ) { return false; }

  return true;
}

export function clickedWithKeyModifier(e) {
  if (
    e.button !== 0
    || e.altKey
    || e.ctrlKey
    || e.metaKey
    || e.shiftKey
  ) { return true; }

  return false;
}

export function isRootDomainLink(to) {
  return /.*\.joinroot.com|localhost/.test(to);
}

export function isEmailLink(to) {
  return /^(mailto:)/.test(to);
}

export function isPhoneLink(to) {
  return /^(tel:)/.test(to);
}

export function isRootOnelink(to) {
  return /^http(s)?:\/\/root\.onelink\.me/.test(to);
}
