import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { H3, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function VideoTout({
  body,
  button,
  title,
  id,
  video = {},
  buttonComponent = LinkButton,
}) {
  const { nodeRef, trackClick } = useVisibilityAnalytics(`VIDEO_TOUT_${id}`);
  const ButtonComponent = buttonComponent;

  const videoOptions = {
    controls: false,
    autoplay: false,
    muted: false,
    loop: false,
    poster: null,
    ...video,
  };

  return (
    <div
      css={styles.videoTout}
      ref={nodeRef}
    >
      <video
        playsInline
        autoPlay={videoOptions.autoplay}
        controls={videoOptions.controls}
        controlsList={'nodownload'}
        css={styles.video}
        data-testid={'video'}
        loop={videoOptions.loop}
        muted={videoOptions.muted}
        poster={videoOptions.poster}
        preload={'metadata'}
      >
        <source src={videoOptions.src} />
        Sorry, your browser doesn&apos;t support embedded videos.
      </video>

      {(title || body || button) &&
        <div css={styles.textContainer}>
          {title && <H3 css={styles.title}>{title}</H3>}
          {body && <P1 css={styles.body}>{body}</P1>}
          {button &&
            <ButtonComponent
              buttonStyles={styles.button}
              onClick={() => trackClick(`CTA_${button.id}`)}
              to={button.link}
              type={button.type}
            >
              {button.text}
            </ButtonComponent>
          }
        </div>
      }
    </div>
  );
}

const styles = StyleSheet.create({
  video: {
    width: '100%',
    height: 'auto',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
  },
  title: {
    margin: '0 20px 15px',
  },
  body: {
    paddingLeft: 20,
    paddingRight: 20,
    ...Responsive.md({
      maxWidth: 700,
    }),
  },
  button: {
    marginTop: 35,
  },
});

VideoTout.propTypes = {
  body: PropTypes.node,
  button: PropTypes.shape({
    id: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  }),
  buttonComponent: PropTypes.func,
  id: PropTypes.string.isRequired,
  title: PropTypes.node,
  video: PropTypes.shape({
    autoplay: PropTypes.bool,
    loop: PropTypes.bool,
    muted: PropTypes.bool,
    src: PropTypes.string.isRequired,
  }),
};
