import NavDropdown from '@root/joinroot.com/src/components/nav/nav-dropdown';
import NavDropdownData from '@root/joinroot.com/src/components/nav/nav-dropdown-data';
import NavLink from '@root/joinroot.com/src/components/nav/nav-link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function NavDropdownLinks({ isNavVisible }) {
  return (
    <ul css={styles.navList}>
      {NavDropdownData.map((dropdown, index) => (
        <li
          css={styles.navListItem}
          key={index}
        >
          <NavDropdown
            dropdownItems={dropdown.items}
            isNavVisible={isNavVisible}
            titleText={dropdown.title}
          />
        </li>
      ))}
      <li css={styles.navListItem}>
        <NavLink
          linkStyles={styles.link}
          to={'/claims/'}
        >
          Claims
        </NavLink>
      </li>
    </ul>
  );
}

NavDropdownLinks.propTypes = {
  isNavVisible: PropTypes.bool.isRequired,
};

const styles = StyleSheet.create({
  navList: {
    display: 'none',
    ...Responsive.md({
      display: 'flex',
      justifyContent: 'center',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: '8px 0',
      margin: 0,
      borderBottom: `1px solid ${Colors.gray20()}`,

      '& > li': {
        margin: '0 15px',
      },
    }),
    ...Responsive.lg({
      justifyContent: 'flex-end',
      position: 'relative',
      top: 'auto',
      left: 'auto',
      border: 'none',
      padding: 0,

      '& > li': {
        margin: '0 30px 0 0',
      },
    }),
  },
  navListItem: {
    listStyle: 'none',
    transition: 'all 350ms cubic-bezier(0.37, 0, 0.28, 1)',
  },
  link: {
    color: Colors.nearBlack(),
  },
});
