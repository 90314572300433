import ErrorReportService from '@root/core/src/services/error-report-service';
import Input, { TextTypes } from '@root/core/src/components/input';
import PhoneNumberInput from '@root/core/src/components/phone-number-input';
import Select from '@root/core/src/components/select';
import environment from '@root/core/src/utils/environment';

export default function HubspotFieldRenderer({
  form,
  fieldType,
  label,
  required,
  name,
  options,
  placeholder,
}) {
  if (fieldType === 'text') {
    if (name === 'phone') {
      return (
        <PhoneNumberInput
          autoFocus={false}
          id={name}
          label={`${label}${required ? '*' : ''}`}
          wrapperStyle={{ height: 'auto' }}
          {...form.getFieldProps(name)}
        />
      );
    }
    return (
      <Input
        id={name}
        inputType={fieldNameToTextType(name)}
        label={`${label}${required ? '*' : ''}`}
        placeholder={placeholder}
        {...form.getFieldProps(name)}
      />
    );
  }

  if (fieldType === 'select') {
    return (
      <Select
        inputId={name}
        inputName={name}
        options={options.map((option) => ({
          label: option.label,
          value: option.value,
        }))}
        placeholder={`${label}${required ? '*' : ''}`}
        selectedValue={form.values[name] || ''}
        {...form.getFieldProps(name)}
      />
    );
  }

  ErrorReportService.reportError({
    error: new Error(`An unsupported field type "${fieldType}" has been supplied to the HubspotFieldRenderer.`),
    additionalData: {
      hubspotFieldName: name,
      hubspotFieldLabel: label,
    },
  });

  if (environment.contentfulPreviewEnabled) {
    return (
      <h3 style={{ color: 'red' }}>
        {`MESSAGE TO THE CONTENT AUTHOR: The field "${label}" is a field type of "${fieldType}" which isn't currently supported by our field renderer. Let us in MarTech know if you'd like to have us add support for this field type.`}
      </h3>
    );
  } else {
    return null;
  }
}

function fieldNameToTextType(fieldName) {
  if (fieldName === 'email') {
    return TextTypes.EMAIL;
  }
  return TextTypes.TEXT;
}
