import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import { BLOCKS } from '@contentful/rich-text-types';
import { H1Display, H2, H3, H4, H5, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

const richTextOptions = {
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1Display css={styles.margin}>{children}</H1Display>,
    [BLOCKS.HEADING_2]: (node, children) => <H2 css={styles.margin}>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3 css={styles.margin}>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4 css={styles.margin}>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5 css={styles.margin}>{children}</H5>,
    [BLOCKS.PARAGRAPH]: (node, children) => <P1 css={styles.margin}>{children}</P1>,
  },
};

export default function RichTextSectionBlock({ content: { sectionSpacing = {}, ...content } }) {
  const isCenterAlign = content.textAlignment?.toLowerCase() === 'center';
  const colorString = BackgroundColors.getDefaultedString(content.backgroundColor);
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        styles.section,
        isCenterAlign && styles.sectionCenter,
        sectionSpacingStyles,
        BackgroundColors.getBackgroundStyle(colorString),
      ]}
      data-testid={'richTextSectionBlock'}
      id={content.anchor?.anchorSlug}
    >
      <div css={styles.wrap}>
        {renderRichText(content.content, richTextOptions)}
      </div>
    </section>
  );
}

RichTextSectionBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    content: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }).isRequired,
    textAlignment: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
};

const styles = StyleSheet.create({
  margin: {
    marginTop: 20,
    marginBottom: 20,
  },
  section: {
    margin: '0 auto',
    padding: '60px 20px',
    textAlign: 'left',
    ...Responsive.md({
      padding: '100px 30px',
    }),
  },
  wrap: {
    margin: '0 auto',
    maxWidth: 800,
  },
  sectionCenter: {
    textAlign: 'center',
  },
});
