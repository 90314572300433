import ArtDirectedGatsbyImage from '@root/gatsby-contentful-core/src/components/art-directed-gatsby-image';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

// ====== NOTE ====== //
// We're moving toward deprecating this file as we're in the process of splitting its functionality into two seperate files:
// ImageToutStacked and ImageToutSliding. (the sliding variant doesn't exist at the time of writing this comment)

export default function ImageToutTile({
  body,
  button: ButtonComponent,
  image,
  mobileImage,
  title,
  id,
}) {
  const { nodeRef } = useVisibilityAnalytics(`IMAGE_TOUT_${id}`);

  return (
    <div
      css={[styles.imageTout, columnStyles.imageTout]}
      ref={nodeRef}
    >
      <div css={styles.imageContainer}>
        <ArtDirectedGatsbyImage
          alt={image.imageAltText}
          desktopImg={image.gatsbyImageData}
          mobileImg={mobileImage?.gatsbyImageData}
        />
      </div>
      {(title || body || ButtonComponent) &&
        <div css={styles.textContainer}>
          {title && <H3 css={styles.title}>{title}</H3>}
          <BodyCopy
            content={body}
            css={styles.body}
          />
          {ButtonComponent && <ButtonComponent />}
        </div>
      }
    </div>
  );
}

const styles = StyleSheet.create({
  imageTout: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: 25,
    marginBottom: 25,
    textAlign: 'center',
    '&:last-of-type': {
      paddingBottom: 0,
    },
    '.container-off-white &': {
      backgroundColor: Colors.nearWhite(),
    },
    '.sliding &': {
      marginRight: 20,
      width: 'auto',
      minWidth: 255,
      maxWidth: 335,
    },
    '.sliding.one-tout &': {
      marginRight: 0,
    },
    '@media screen and (min-width: 768px)': {
      '.two-touts &': {
        maxWidth: 'calc(50% - 10px)',
      },
      '.four-touts &': {
        maxWidth: 'calc(50% - 10px)',
      },
      '.four-plus-touts &': {
        maxWidth: 'calc(33% - 11px)',
      },
      '.four-touts &, .four-plus-touts &': {
        marginRight: 20,
      },
      '.four-touts &:nth-of-type(2n)': {
        marginRight: 0,
      },
      '.four-plus-touts &:nth-of-type(3n), .four-plus-touts &:last-of-type': {
        marginRight: 0,
      },
      '.sliding.four-touts &, .sliding.four-plus-touts &': {
        marginRight: 20,
        width: 335,
      },
    },
    '@media screen and (min-width: 1280px)': {
      '.three-touts &': {
        maxWidth: 'calc(33% - 10px)',
      },
      '.four-touts &, .four-plus-touts &': {
        maxWidth: 'calc(25% - 15px)',
      },
      '.four-touts &:nth-of-type(2n), .four-plus-touts &:nth-of-type(3n)': {
        marginRight: 20,
      },
      '.four-touts &:nth-of-type(4n), .four-plus-touts &:nth-of-type(4n)': {
        marginRight: 0,
      },
      '.sliding.four-plus-touts &:nth-of-type(4n)': {
        marginRight: 20,
      },
    },
    '@media screen and (min-width: 1440px)': {
      '.sliding.four-touts &:nth-of-type(4n), .sliding.four-plus-touts &:nth-of-type(4n)': {
        marginRight: 20,
      },
    },
  },
  imageContainer: {
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 460,
    marginTop: 35,
    '@media screen and (min-width: 768px)': {
      '.one-tout & ': {
        maxWidth: 690,
      },
    },
  },
  title: {
    marginTop: 0,
    marginBottom: 15,
    width: '100%',
  },
  body: {
    marginBottom: 35,
    '&:last-child': {
      marginBottom: 0,
    },
  },
});

const columnStyles = StyleSheet.create({
  imageTout: {
    '.two-columns.one-tout &, .three-columns.one-tout &, .four-columns.one-tout &': {
      maxWidth: 925,
      marginRight: '0 !important',
    },
    '@media screen and (min-width: 768px)': {
      '.two-columns &, .four-columns &': {
        maxWidth: 'calc(50% - 10px)',
      },
      '.three-columns &': {
        maxWidth: 'calc(33% - 11px)',
      },
      '.two-columns &:nth-of-type(2n), .three-columns &:nth-of-type(3n), .four-columns &:nth-of-type(4n)': {
        marginRight: 0,
      },
      '.two-columns.three-touts &:nth-of-type(2n-1)': {
        marginRight: 20,
      },
      '.two-columns.four-plus-touts &': {
        marginRight: 20,
      },
      '.two-columns.four-plus-touts &:nth-of-type(2n)': {
        marginRight: 0,
      },
      '.three-columns.four-touts &:nth-of-type(2n)': {
        marginRight: 20,
      },
      '.four-columns &:nth-of-type(n)': {
        marginRight: 20,
      },
      '.four-columns &:nth-of-type(2n)': {
        marginRight: 0,
      },
      '.sliding.four-columns &:nth-of-type(2n)': {
        marginRight: 20,
      },
    },
    '@media screen and (min-width: 1280px)': {
      '.two-columns &': {
        maxWidth: 'calc(50% - 10px)',
      },
      '.three-columns &': {
        maxWidth: 'calc(33% - 10px)',
      },
      '.four-columns &': {
        maxWidth: 'calc(25% - 15px)',
      },
      '.three-columns &:nth-of-type(4n)': {
        marginRight: 20,
      },
      '.four-columns &:nth-of-type(2n)': {
        marginRight: 20,
      },
      '.four-columns &:nth-of-type(4n)': {
        marginRight: 0,
      },
    },
  },
});

ImageToutTile.propTypes = {
  body: PropTypes.node,
  button: PropTypes.func,
  id: PropTypes.string.isRequired,
  image: PropTypes.shape({
    imageAltText: PropTypes.string,
    gatsbyImageData: PropTypes.object.isRequired,
  }).isRequired,
  mobileImage: PropTypes.shape({
    gatsbyImageData: PropTypes.object.isRequired,
  }),
  title: PropTypes.node,
};
