import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';
import environment from '@root/core/src/utils/environment';

export default function submitPrivacyActRequestForm(formData) {
  return new NetworkRequestConfiguration({
    url: `${environment.apiBaseUrl}/api/customer_service/privacy_act_requests`,
    endpoint: 'api/customer_service/privacy_act_requests',
    method: NetworkRequestConfiguration.Methods.POST,
    successCodes: [201],
    bodyParameters: {
      privacyActRequest: formData,
    },
  });
}
