import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage } from 'gatsby-plugin-image';
import { H4, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';

export default function FormHero({
  image,
  sectionId,
  sectionSpacing = {},
  tertiaryTab,
  children,
}) {
  const existingPadding = 60;
  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25 + existingPadding,
    paddingBottom: sectionSpacing.bottom / 1.25 + existingPadding,
    ...Responsive.md({
      paddingTop: sectionSpacing.top + existingPadding,
      paddingBottom: sectionSpacing.bottom + existingPadding,
    }),
  };

  return (
    <section
      css={styles.section}
      id={sectionId}
    >
      <div css={styles.wrapImage}>
        <GatsbyImage
          alt={image.alt ?? ''}
          css={[styles.gatsbyImageBaseStyles, image.styles]}
          image={image.gatsbyImage}
          loading={'eager'}
        />
      </div>
      <div css={styles.wrapContent}>
        <div css={[styles.mainContent, sectionSpacingStyles]}>
          {children}
        </div>
        {tertiaryTab &&
          <div css={styles.tertiaryTabContainer}>
            <div css={styles.tertiaryTab}>
              <H4>{tertiaryTab.title}</H4>
              <P1 css={styles.tertiaryP1}>{tertiaryTab.body}</P1>
            </div>
          </div>
        }
      </div>
    </section>
  );
}

FormHero.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.shape({
    styles: PropTypes.object,
    alt: PropTypes.string,
    gatsbyImage: PropTypes.object.isRequired,
  }),
  sectionId: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  tertiaryTab: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string,
  }),
};

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    backgroundColor: toRgba(Colors.black(), 0.3),

    '&:before': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: -2,
      backgroundColor: Colors.black(),
    },
  },
  wrapImage: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    overflow: 'hidden',
  },
  gatsbyImageBaseStyles: {
    height: '100%',
  },
  wrapContent: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
  },
  mainContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '60px 20px',
    width: '100%',
    maxWidth: 550,

    ...Responsive.lg({
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: 1200,
    }),
  },
  tertiaryTabContainer: {
    display: 'flex',
    width: '100%',
    pointerEvents: 'none',

    ...Responsive.lg({
      position: 'absolute',
      bottom: 0,
      maxWidth: 1200,
      justifyContent: 'flex-start',
    }),
  },
  tertiaryTab: {
    flexGrow: 1,
    margin: 0,
    padding: 20,
    backgroundColor: toRgba(Colors.nearWhite(), 0.9),

    ...Responsive.lg({
      margin: '0 20px',
      padding: '20px 30px',
      maxWidth: 400,
    }),
  },
  tertiaryP1: {
    marginTop: 10,
    color: Colors.nearBlack(),
    maxWidth: 260,
  },
});
