import GetAQuoteButton from '@root/joinroot.com/src/components/core/get-a-quote-button';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import NavDropdownData from '@root/joinroot.com/src/components/nav/nav-dropdown-data';
import NavDropdownMobile from '@root/joinroot.com/src/components/nav/nav-dropdown-mobile';
import NavMenuArrowLink from '@root/joinroot.com/src/components/nav/nav-menu-arrow-link';
import Responsive from '@root/core/src/utils/responsive';
import environment from '@root/core/src/utils/environment';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';
import { useState } from '@root/vendor/react';

export default function NavMenuMobile() {
  const { trackClick } = useAnalytics('NAV_MENU_MOBILE', false);
  const [openedIndex, setOpenedIndex] = useState(undefined);

  const isOpen = (index) => {
    return openedIndex === index;
  };

  const toggleOpen = (index) => {
    isOpen(index) ? setOpenedIndex(undefined) : setOpenedIndex(index);
  };

  return (
    <ul css={styles.navList}>
      {NavDropdownData.map((dropdown, index) => (
        <NavDropdownMobile
          dropdownItems={dropdown.items}
          isOpen={isOpen(index)}
          key={index}
          setOpen={() => toggleOpen(index)}
          title={dropdown.title}
        />
      ))}
      <NavMenuArrowLink
        link={'/claims/'}
        title={'Claims'}
      />
      <GetAQuoteButton
        buttonStyles={styles.ctaButton}
        onClick={() => trackClick('GET_A_QUOTE_BUTTON')}
      />
      <Link
        css={styles.accountLink}
        onClick={() => trackClick('MY_ACCOUNT_LINK_SIGN_IN')}
        to={environment.accountAppBaseUrl}
      >
        My Account
      </Link>
    </ul>
  );
}

const styles = StyleSheet.create({
  navList: {
    display: 'none',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px 20px',
    ...Responsive.lessThanMd({
      '.nav-is-open &': {
        display: 'flex',
      },
    }),
  },
  ctaButton: {
    padding: '8px 35px',
    fontSize: 20,
    lineHeight: '120%',
    margin: '35px 0 15px',
    borderRadius: 8,
  },
  accountLink: {
    ...Theme.link(Colors.black()),
    fontSize: 20,
  },
});
