import ClassicNav from '@root/joinroot.com/src/components/nav/classic-nav';
import LoadingNav from '@root/joinroot.com/src/components/nav/loading-nav';
import MenuNav from '@root/joinroot.com/src/components/nav/menu-nav';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import SimpleNav from '@root/joinroot.com/src/components/nav/simple-nav';
import { NavRedesignAbTestBuckets } from '@root/core/src/models/ab-test-buckets';

export default function Nav({
  isNavOpen,
  toggleNavState,
  navRedesignBucket = NavRedesignAbTestBuckets.CONTROL,
  navRedesignIsLoading = true,
  isVisible,
  ctaLink,
  ctaText,
  isBannerOn = false,
  bannerContent,
  isCtaButtonDisabled,
  isDarkMode,
  isNavBgTransparent,
}) {
  if (navRedesignIsLoading) {
    return <LoadingNav />;
  } else if (navRedesignBucket === NavRedesignAbTestBuckets.MENU_NAV) {
    return (
      <MenuNav
        bannerContent={bannerContent}
        ctaLink={ctaLink}
        ctaText={ctaText}
        isBannerOn={isBannerOn}
        isCtaButtonDisabled={isCtaButtonDisabled}
        isNavOpen={isNavOpen}
        isVisible={isVisible}
        toggleNavState={toggleNavState}
      />
    );
  } else if (navRedesignBucket === NavRedesignAbTestBuckets.SIMPLE_NAV) {
    return (
      <SimpleNav
        bannerContent={bannerContent}
        ctaLink={ctaLink}
        ctaText={ctaText}
        isBannerOn={isBannerOn}
        isCtaButtonDisabled={isCtaButtonDisabled}
        isVisible={isVisible}
      />
    );
  }

  return (
    <ClassicNav
      bannerContent={bannerContent}
      ctaLink={ctaLink}
      ctaText={ctaText}
      isBannerOn={isBannerOn}
      isCtaButtonDisabled={isCtaButtonDisabled}
      isDarkMode={isDarkMode}
      isNavBgTransparent={isNavBgTransparent}
      isNavOpen={isNavOpen}
      isVisible={isVisible}
      toggleNavState={toggleNavState}
    />
  );
}

Nav.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }),
  ctaLink: PropTypes.string,
  ctaText: PropTypes.string,
  isBannerOn: PropTypes.bool,
  isCtaButtonDisabled: PropTypes.bool,
  isDarkMode: PropTypes.bool,
  isNavBgTransparent: PropTypes.bool,
  isNavOpen: PropTypes.bool.isRequired,
  isVisible: PropTypes.bool,
  navRedesignBucket: PropTypes.oneOf(Object.values(NavRedesignAbTestBuckets)),
  navRedesignIsLoading: PropTypes.bool,
  toggleNavState: PropTypes.func.isRequired,
};
