import S from 'string';

export const URL_REGEX = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/i;

export const isRequired = ({ path, label }) => {
  const value = label || S(path).humanize().s;
  return `${value} is required.`;
};

export const invalid = ({ path, label }) => {
  const value = label || S(path).humanize().toLowerCase().s;
  return `Invalid ${value}.`;
};
