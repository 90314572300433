export default class ContentfulRichTextFactory {
  static create({ text = 'Welcome to Root', isBold = true } = {}) {
    const marks = [];
    if (isBold) {
      marks.push({
        type: 'bold',
      });
    }

    return {
      data: {},
      content: [
        {
          data: {},
          content: [
            {
              data: {},
              marks,
              value: text,
              nodeType: 'text',
            },
          ],
          nodeType: 'paragraph',
        },
      ],
      nodeType: 'document',
    };
  }
}
