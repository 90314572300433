import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Eyebrow, H1Display, H3 } from '@root/gatsby-contentful-core/src/components/text';
import { TEXT_ALIGNMENT_OPTIONS } from '@root/gatsby-contentful-core/src/constants/constants';

export default function HeroImageNone({
  anchor,
  textAlignment = TEXT_ALIGNMENT_OPTIONS.CENTER,
  buttons,
  eyebrow,
  headline,
  subhead,
  form,
  body,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  sectionSpacing = {},
}) {
  let renderButtons = false;
  let renderForm = false;

  if (buttons?.length > 0) {
    renderButtons = true;
  } else if (form) {
    renderForm = true;
  }

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        stylesBase.heroSection,
        textAlignment === TEXT_ALIGNMENT_OPTIONS.LEFT && stylesAlignmentLeft.heroSection,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        sectionSpacingStyles,
      ]}
      id={anchor}
    >
      <div className={'wrap_content'}>
        <div className={'container_content'}>

          {eyebrow && <Eyebrow className={'eyebrow'}>{eyebrow}</Eyebrow>}
          {headline && <H1Display className={'headline'}>{headline}</H1Display>}
          {subhead && <H3 className={'subhead'}>{subhead}</H3>}
          <BodyCopy
            className={'body'}
            content={body}
          />

          {renderButtons && (
            <div className={'container_cta'}>
              {buttons.map((button, i) => {
                const ButtonComponent = button;
                return <ButtonComponent key={i} />;
              })}
            </div>
          )}

          {renderForm && form}
        </div>
      </div>
    </section>
  );
}

HeroImageNone.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  buttons: PropTypes.arrayOf(PropTypes.func),
  eyebrow: PropTypes.string,
  form: PropTypes.node,
  headline: PropTypes.string.isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  subhead: PropTypes.string,
  textAlignment: PropTypes.oneOf(Object.values(TEXT_ALIGNMENT_OPTIONS)),
};

const stylesAlignmentLeft = StyleSheet.create({
  heroSection: {

    '.wrap_content .container_content': {
      ...Responsive.md({
        alignItems: 'flex-start',
        textAlign: 'left',

        '.container_cta': {
          ...Responsive.md({
            justifyContent: 'flex-start',
          }),
        },
      }),
    },
  },
});

const stylesBase = StyleSheet.create({
  heroSection: {
    position: 'relative',
    backgroundColor: 'white',

    '.wrap_content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '.container_content': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        maxWidth: 1200,
        padding: '80px 20px',
        textAlign: 'center',

        ...Responsive.md({
          padding: '100px 20px',
        }),

        '.eyebrow': {
          maxWidth: 350,
        },

        '.headline, .subhead, .body': {
          maxWidth: 550,

          ...Responsive.md({
            maxWidth: 800,
          }),
        },

        '.subhead': {
          color: Colors.gray50(),
          fontWeight: 400,
        },

        '.eyebrow, .headline, .subhead, .body': {
          marginBottom: 12,
        },

        '.container_cta': {
          display: 'flex',
          flexDirection: 'column',
          marginTop: 30,
          width: '100%',
          maxWidth: 260,

          '& > a + a': {
            margin: '16px 0 0 0',
          },

          ...Responsive.md({
            justifyContent: 'center',
            flexDirection: 'row',
            maxWidth: 480,

            '& > a + a': {
              margin: '0 0 0 16px',
            },
          }),
        },
      },
    },
  },
});
