import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Eyebrow, H2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export const VERTICAL_TEXT_ALIGNMENTS = {
  TOP: 'top',
  CENTER: 'center',
};

export const DIVIDER_POSITIONS = {
  LEFT: 'left',
  RIGHT: 'right',
  CENTER: 'center',
};

export const ICON_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

export const TOUT_LAYOUTS = {
  GRID: 'grid',
  LIST: 'list',
};

export default function IconToutSideBySideSection({
  anchor,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  body,
  button,
  children,
  dividerPosition = DIVIDER_POSITIONS.LEFT,
  eyebrow,
  title,
  toutLayout = TOUT_LAYOUTS.LIST,
  verticalTextAlignment = VERTICAL_TEXT_ALIGNMENTS.CENTER,
  buttonComponent = LinkButton,
  sectionSpacing = {},
}) {
  const ButtonComponent = buttonComponent;

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      className={`divider-${dividerPosition} tout-layout-${toutLayout}`}
      css={[
        styles.section,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        sectionSpacingStyles,
      ]}
      id={anchor}
    >
      <div
        className={`text-alignment-${verticalTextAlignment}`}
        css={[styles.innerContainer, dividerPositionStyles.innerContainer, textAlignmentStyles.innerContainer]}
      >
        <div css={[styles.textContainer, dividerPositionStyles.textContainer]}>
          {eyebrow && <Eyebrow css={styles.eyebrow}>{eyebrow}</Eyebrow>}
          <H2 css={styles.title}>{title}</H2>
          <BodyCopy content={body} />
          {button &&
            <ButtonComponent
              buttonStyles={styles.button}
              onClick={button.onClick}
              to={button.link}
              type={button.type}
            >
              {button.text}
            </ButtonComponent>
          }
        </div>
        <div css={styles.divider} />
        <div css={[styles.toutContainer, dividerPositionStyles.toutContainer]}>
          {children}
        </div>
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    display: 'flex',
    justifyContent: 'center',
    padding: '80px 20px',
    '@media screen and (min-width: 768px)': {
      padding: '100px 20px',
    },
    '@media screen and (min-width: 1280px)': {
      padding: '100px 40px',
    },
  },
  innerContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 340,
    '@media screen and (min-width: 768px)': {
      maxWidth: 'none',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  textContainer: {
    width: '100%',
    textAlign: 'center',
    '& > * + *': {
      marginTop: 15,
    },
    '@media screen and (min-width: 768px)': {
      marginRight: 40,
      textAlign: 'left',
    },
    '@media screen and (min-width: 1280px)': {
      marginRight: 20,
      textAlign: 'left',
      '.tout-layout-list &': {
        maxWidth: 480,
      },
      '.tout-layout-grid &': {
        maxWidth: 580,
      },
    },
  },
  button: {
    marginTop: 35,
    display: 'inline-flex',
  },
  divider: {
    display: 'none',
    '@media screen and (min-width: 768px)': {
      display: 'block',
      height: '100%',
      width: 2,
      minWidth: 2,
      backgroundColor: Colors.grayMedium(),
      opacity: '.5',
    },
  },
  toutContainer: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '100%',
    marginTop: 50,
    '@media screen and (min-width: 768px)': {
      marginLeft: 40,
      marginTop: 0,
      '.tout-layout-grid &': {
        maxWidth: 600,
        flexFlow: 'row wrap',
        justifyContent: 'space-between',
      },
    },
    '@media screen and (min-width: 1280px)': {
      marginLeft: 20,
    },
  },
});

const dividerPositionStyles = StyleSheet.create({
  innerContainer: {
    '@media screen and (min-width: 768px)': {
      maxWidth: 1180,
      '.tout-layout-list.divider-left &': {
        maxWidth: 1060,
        marginRight: 100,
      },
      '.tout-layout-list.divider-center &': {
        maxWidth: 1160,
      },
      '.tout-layout-list.divider-right &': {
        maxWidth: 1160,
      },
    },
  },
  textContainer: {
    '@media screen and (min-width: 768px)': {
      '.divider-left &': {
        maxWidth: 360,
      },
      '.divider-center &': {
        maxWidth: 460,
      },
      '.divider-right &': {
        maxWidth: 580,
      },
    },
  },
  toutContainer: {
    '@media screen and (min-width: 768px)': {
      '.tout-layout-list.divider-left &': {
        maxWidth: 480,
      },
      '.tout-layout-list.divider-center &': {
        maxWidth: 460,
      },
      '.tout-layout-list.divider-right &': {
        maxWidth: 340,
      },
    },
  },
});

const textAlignmentStyles = StyleSheet.create({
  innerContainer: {
    '@media screen and (min-width: 768px)': {
      '&.text-alignment-center': {
        alignItems: 'center',
      },
      '&.text-alignment-top': {
        alignItems: 'flex-start',
      },
    },
  },
});

IconToutSideBySideSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  button: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
    type: PropTypes.string,
    onClick: PropTypes.func,
    id: PropTypes.string,
  }),
  buttonComponent: PropTypes.func,
  children: PropTypes.node,
  dividerPosition: PropTypes.oneOf(Object.values(DIVIDER_POSITIONS)),
  eyebrow: PropTypes.string,
  iconSize: PropTypes.oneOf(Object.values(ICON_SIZES)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  title: PropTypes.string.isRequired,
  toutLayout: PropTypes.oneOf(Object.values(TOUT_LAYOUTS)),
  verticalTextAlignment: PropTypes.oneOf(Object.values(VERTICAL_TEXT_ALIGNMENTS)),
};
