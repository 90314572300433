export default (
  <svg
    display={'block'}
    fill={'none'}
    height={'23'}
    viewBox={'0 0 24 23'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      d={'M12 7.22368L6.26087 0L0 7.88038V23.9999H1.04348V8.3487L6.26087 1.78171L11.4783 8.3487V23.9999H12.5217V8.3487L17.7391 1.78171L22.9565 8.3487V23.9999H24V7.88038L17.7391 0L12 7.22368Z'}
    />
    <path
      d={'M16.1739 17.9803V24H19.3043V17.9803H16.1739Z'}
    />
    <path
      d={'M14.6087 13.1645V16.1743H17.2174V13.1645H14.6087Z'}
    />
    <path
      d={'M14.6087 8.95066V11.9605H17.2174V8.95066H14.6087Z'}
    />
    <path
      d={'M18.2609 13.1645V16.1743H20.8696V13.1645H18.2609Z'}
    />
    <path
      d={'M18.2609 8.95066V11.9605H20.8696V8.95066H18.2609Z'}
    />
    <path
      d={'M4.69565 17.9803V24H7.82609V17.9803H4.69565Z'}
    />
    <path
      d={'M3.13043 13.1645V16.1743H5.73913V13.1645H3.13043Z'}
    />
    <path
      d={'M3.13043 8.95066V11.9605H5.73913V8.95066H3.13043Z'}
    />
    <path
      d={'M6.78261 13.1645V16.1743H9.3913V13.1645H6.78261Z'}
    />
    <path
      d={'M6.78261 8.95066V11.9605H9.3913V8.95066H6.78261Z'}
    />
  </svg>
);
