import AnalyticsService from '@root/core/src/services/analytics-service';
import Button from '@root/core/src/components/button';
import CaptchaService from '@root/core/src/services/captcha-service';
import Input, { TextTypes } from '@root/core/src/components/input';
import Market from '@root/core/src/models/market';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import submitLeadCaptureToBraze from '@root/core/src/api/submit-lead-capture-to-braze';
import useForm from '@root/core/src/hooks/use-form';
import useImperativeNetworkRequest from '@root/core/src/hooks/use-imperative-network-request';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { isRequired, isValidEmail } from '@root/core/src/utils/validators';
import { useRootId } from '@root/attribution/src/hooks/use-root-id';

const SCENES = {
  REQUEST_EMAIL: 'request-email',
  SUBMISSION_SUCCESS: 'submission-success',
};

const sceneMap = {
  [SCENES.REQUEST_EMAIL]: RequestEmailScene,
  [SCENES.SUBMISSION_SUCCESS]: SubmissionSuccessScene,
};

const validations = [
  isRequired('email'),
  isValidEmail('email'),
];

export default function RootUnavailableFormFlow({
  market, onClose, analytics, product,
}) {
  const hasFiredTrackEvent = useRef(false);
  const { trackClick, trackEvent } = analytics;
  const [currentScene, setCurrentScene] = useState(SCENES.REQUEST_EMAIL);
  const [postLeadCaptureToBraze] = useImperativeNetworkRequest(submitLeadCaptureToBraze);
  const form = useForm({
    validations,
    validateOnBlur: false,
  });
  const rootId = useRootId();

  useEffect(() => {
    CaptchaService.setup();
  }, []);

  useEffect(() => {
    if (hasFiredTrackEvent.current) { return; }

    hasFiredTrackEvent.current = true;
    trackEvent(`VIEWED_${product}_UNAVAILABLE_MODAL`);
  }, [product, trackEvent]);

  const SceneToRender = sceneMap[currentScene];
  if (!SceneToRender) {
    throw new Error(`Unknown scene: ${currentScene}`);
  }

  async function handleSubmit({ email }) {
    trackClick(`${product}_UNAVAILABLE_EMAIL_FORM_SUBMIT`);

    const productInterest = `interested_in_${product}`.toLowerCase();

    const captchaToken = await CaptchaService.challenge('Submit_Lead_Capture_To_Braze');

    await postLeadCaptureToBraze({
      captchaToken,
      email,
      market: Market.marketNameToAbbreviation(market),
      lastTouchAttribution: `${product}_UNAVAILABLE`,
      mixpanelDistinctId: AnalyticsService.getDistinctId(),
      [productInterest]: true,
      rootId,
    });

    form.resetForm();
    setCurrentScene(SCENES.SUBMISSION_SUCCESS);
  }

  return (
    <SceneToRender
      form={form}
      handleSubmit={handleSubmit}
      market={market}
      onClose={onClose}
    />
  );
}

RootUnavailableFormFlow.propTypes = {
  analytics: PropTypes.object.isRequired,
  market: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  product: PropTypes.oneOf(['AUTO', 'RENTERS', 'HOMEOWNERS']).isRequired,
};

function RequestEmailScene({
  handleSubmit, market, form,
}) {
  return (
    <>
      <H2 css={styles.headline}>Hey there, {market}</H2>
      <P1>Root is not currently available in your state, but if you give us your email, we’ll let you know as soon as we arrive.</P1>
      <form
        css={[styles.form, styles.spaceAboveForm]}
        onSubmit={form.createSubmitHandler(handleSubmit)}
      >
        <Input
          autoFocus={true}
          id={'email'}
          inputType={TextTypes.EMAIL}
          label={'Email address'}
          {...form.getFieldProps('email')}
        />
        <Button
          className={'button'}
          disabled={form.submitted || form.submitting}
        >
          Continue
        </Button>
      </form>
    </>
  );
}

RequestEmailScene.propTypes = {
  form: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  market: PropTypes.string.isRequired,
};

function SubmissionSuccessScene({ onClose, market }) {
  return (
    <>
      <H2 css={styles.headline}>Thanks, we’ll be in touch</H2>
      <P1>We’ll notify you as soon as we’re available in {market}.</P1>
      <Button
        css={styles.spaceAboveForm}
        onClick={() => onClose()}
      >
        Got it
      </Button>
    </>
  );
}

SubmissionSuccessScene.propTypes = {
  market: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  headline: {
    marginRight: 20,
    marginBottom: 16,
  },
  spaceAboveForm: {
    marginTop: 34,
  },
  form: {
    button: {
      marginTop: 14,
    },
  },
});
