import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import ImageToutCard from '@root/gatsby-contentful-core/src/components/sections/image-tout/image-tout-card';
import ImageToutTile from '@root/gatsby-contentful-core/src/components/sections/image-tout/image-tout-tile';
import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { Eyebrow, H2 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export const TOUT_LAYOUT = {
  STACKED: 'stacked',
  SLIDING: 'sliding',
};

export const TOUT_COLUMNS = {
  2: 'two',
  3: 'three',
  4: 'four',
};

// ====== NOTE ====== //
// We're moving toward deprecating this file as we're in the process of splitting its functionality into two seperate files:
// ImageToutSectionStacked and ImageToutSectionSliding. (the sliding variant doesn't exist at the time of writing this comment)

export default function ImageToutSection({
  anchor,
  backgroundColor = BackgroundColors.cmsValues.WHITE,
  body,
  buttons,
  id,
  eyebrow,
  cardLayout = TOUT_LAYOUT.STACKED,
  styleToutsAsCards = false,
  sectionSpacing = {},
  title,
  touts,
  toutColumns = TOUT_COLUMNS['4'],
  buttonComponent = LinkButton,
}) {
  const { nodeRef, trackClick } = useVisibilityAnalytics(`IMAGE_TOUT_SECTION_${id}`);

  const ButtonComponent = buttonComponent;

  const ChildTout = styleToutsAsCards ? ImageToutCard : ImageToutTile;

  const CHILDREN_STYLES = ['', 'one-tout', 'two-touts', 'three-touts', 'four-touts'];

  const classes = [`${TOUT_COLUMNS[toutColumns]}-columns`, CHILDREN_STYLES[touts.length] || 'four-plus-touts'];

  let layoutStyles;

  if (styleToutsAsCards && cardLayout === TOUT_LAYOUT.STACKED) {
    layoutStyles = stackedCardStyles;
  } else if (cardLayout === TOUT_LAYOUT.SLIDING) {
    layoutStyles = slidingToutStyles;
    classes.push('sliding');
  } else {
    layoutStyles = stackedTileStyles;
  }

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <section
      css={[
        baseStyles.imageToutContainer,
        layoutStyles.imageToutContainer,
        BackgroundColors.getBackgroundStyle(backgroundColor),
        sectionSpacingStyles,
      ]}
      id={anchor}
      ref={nodeRef}
    >
      {(eyebrow || title || body) &&
        <div css={baseStyles.headingContainer}>
          {eyebrow && <Eyebrow css={baseStyles.eyebrow}>{eyebrow}</Eyebrow>}
          {title && <H2 css={baseStyles.title}>{title}</H2>}
          <BodyCopy
            content={body}
            css={[baseStyles.body, layoutStyles.body]}
          />
        </div>
      }
      <div
        className={`${classes.join(' ')}`}
        css={[baseStyles.childrenContainer, layoutStyles.childrenContainer, columnStyles.childrenContainer]}
      >
        <div css={[baseStyles.childrenInnerContainer, layoutStyles.childrenInnerContainer, columnStyles.childrenInnerContainer]}>
          {touts.map((tout, index) => {
            return (
              <ChildTout
                body={tout.body}
                button={tout.button}
                id={tout.id}
                image={{
                  gatsbyImageData: tout.image.gatsbyImageData,
                  imageAltText: tout.imageAltText,
                }}
                key={index}
                mobileImage={tout.mobileImage && {
                  gatsbyImageData: tout.mobileImage.gatsbyImageData,
                  title: tout.mobileImage.title,
                }}
                title={tout.title}
              />
            );
          })}

          {cardLayout === 'sliding' && <div css={layoutStyles.sliderMargin} />}
        </div>
      </div>
      {buttons && buttons.length > 0 && (
        <div css={baseStyles.ctaContainer}>
          {buttons.map((btn, i) => (
            <ButtonComponent
              buttonStyles={buttonStyles}
              key={i}
              onClick={() => trackClick(`CTA_${btn.id}`)}
              to={btn.link}
              type={btn.type}
            >
              {btn.text}
            </ButtonComponent>
          ))}
        </div>
      )}
    </section>
  );
}

ImageToutSection.propTypes = {
  anchor: PropTypes.string,
  backgroundColor: PropTypes.oneOf(BackgroundColors.cmsValueList),
  body: PropTypes.node,
  buttonComponent: PropTypes.func,
  buttons: PropTypes.arrayOf(PropTypes.object),
  cardLayout: PropTypes.oneOf(Object.values(TOUT_LAYOUT)),
  eyebrow: PropTypes.node,
  id: PropTypes.string.isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  styleToutsAsCards: PropTypes.bool,
  title: PropTypes.node,
  toutColumns: PropTypes.oneOf(Object.keys(TOUT_COLUMNS)),
  touts: PropTypes.arrayOf(
    PropTypes.shape({
      body: PropTypes.node,
      button: PropTypes.func,
      id: PropTypes.string,
      image: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }).isRequired,
      imageAltText: PropTypes.string,
      title: PropTypes.node,
    }).isRequired
  ),
};

const buttonStyles = {
  margin: 10,
};

const baseStyles = StyleSheet.create({
  imageToutContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '80px 20px 30px',
    textAlign: 'center',
    '& > * + *': {
      marginTop: 25,
    },
    '&.container-white': {
      backgroundColor: Colors.white(),
    },
    '&.container-off-white': {
      backgroundColor: Colors.nearWhite(),
    },
    '@media screen and (min-width: 768px)': {
      padding: '100px 20px 30px',
    },
  },
  headingContainer: {
    maxWidth: 350,
    '@media screen and (min-width: 768px)': {
      maxWidth: 700,
    },
  },
  eyebrow: {
    marginBottom: 15,
  },
  title: {
    marginTop: 0,
    marginBottom: 15,
  },
  childrenContainer: {
    width: '100%',
  },
  childrenInnerContainer: {
    display: 'inline-flex',
  },
  ctaContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 260,

    ...Responsive.md({
      justifyContent: 'center',
      flexDirection: 'row',
      maxWidth: 480,
    }),
  },
});

const stackedTileStyles = StyleSheet.create({
  childrenContainer: {
    '&.three-touts': {
      maxWidth: 460,
    },
    '@media screen and (min-width: 768px)': {
      '&.one-tout': {
        maxWidth: 925,
      },
      '&.two-touts, &.three-touts': {
        maxWidth: 1160,
      },
    },
    '@media screen and (min-width: 1280px)': {
      '&.three-touts': {
        maxWidth: 1400,
      },
      '&.four-touts, &.four-plus-touts': {
        maxWidth: 1400,
      },
    },
  },
  childrenInnerContainer: {
    flexDirection: 'column',
    width: '100%',
    '@media screen and (min-width: 768px)': {
      flexDirection: 'row',
      justifyContent: 'space-between',
      '.one-tout &': {
        justifyContent: 'center',
      },
      '.four-touts &, .four-plus-touts &': {
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
      },
    },
    '@media screen and (min-width: 1280px)': {
      '.three-touts &': {
        flexDirection: 'row',
      },
    },
  },
});

const stackedCardStyles = StyleSheet.create({
  imageToutContainer: {
    padding: '80px 20px 55px',
    '@media screen and (min-width: 768px)': {
      padding: '100px 40px 65px',
    },
  },
  childrenContainer: {
    '@media screen and (min-width: 768px)': {
      '&.two-touts, &.three-touts, &.four-touts, &.four-plus-touts': {
        maxWidth: 690,
      },
    },
    '@media screen and (min-width: 1280px)': {
      '&.three-touts, &.four-plus-touts': {
        maxWidth: 1045,
      },
    },
    '@media screen and (min-width: 1440px)': {
      '&.four-touts, &.four-plus-touts': {
        maxWidth: 1400,
      },
    },
  },
  childrenInnerContainer: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '@media screen and (min-width: 768px)': {
      flexDirection: 'row',
      flexWrap: 'wrap',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      '.one-tout &': {
        justifyContent: 'center',
      },
      '.four-touts &, .four-plus-touts &': {
        justifyContent: 'flex-start',
      },
    },
  },
});

const slidingToutStyles = StyleSheet.create({
  imageToutContainer: {
    padding: '80px 20px 55px',
    '@media screen and (min-width: 768px)': {
      padding: '100px 40px 65px',
    },
  },
  childrenContainer: {
    width: 'calc(100vw - 5px)',
    maxWidth: 'none',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    msOverflowStyle: 'none',
    scrollbarWidth: 'none',
    '@media screen and (min-width: 768px)': {
      maxWidth: 'none',
      '&.three-touts.sliding': {
        maxWidth: 1200,
      },
      '&.four-touts.sliding': {
        maxWidth: 'none',
      },
    },
    '@media screen and (min-width: 1280px)': {
      '&.four-touts.sliding': {
        maxWidth: 1440,
      },
    },
  },
  childrenInnerContainer: {
    marginLeft: 20,
    marginRight: 20,
    alignItems: 'stretch',
    '.sliding &': {
      flexWrap: 'nowrap',
    },
    '.sliding.one-tout &': {
      justifyContent: 'center',
    },
    '@media screen and (min-width: 768px)': {
      '.sliding.two-touts &': {
        justifyContent: 'center',
      },
      '.sliding.three-touts &': {
        flexDirection: 'row',
        alignItems: 'flex-start',
      },
      '.sliding.four-touts &, .sliding.four-plus-touts &': {
        flexWrap: 'nowrap',
      },
    },
    '@media screen and (min-width: 1280px)': {
      '.sliding.three-touts &': {
        justifyContent: 'center',
      },
    },
  },
  sliderMargin: {
    display: 'block',
    minWidth: 1,
    minHeight: 1,
  },
});

const columnStyles = StyleSheet.create({
  childrenContainer: {
    '@media screen and (min-width: 768px)': {
      '&.two-columns': {
        maxWidth: 1160,
      },
    },
  },
  childrenInnerContainer: {
    flexWrap: 'wrap',
    '@media screen and (min-width: 768px)': {
      '.two-columns.two-touts &, .three-columns.three-touts &': {
        justifyContent: 'space-between',
      },
      '.two-columns &, .three-columns &, .four-columns &': {
        justifyContent: 'center',
      },
    },
  },
});
