import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import dotGridSvg from '@root/joinroot.com/src/assets/images/progress/dot-grid.svg';
import rxbwSvg from '@root/joinroot.com/src/assets/images/progress/rxbw.svg';
import unapologeticLargeSvg from '@root/joinroot.com/src/assets/images/progress/unapologetic-large.svg';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

export default function CourageSection({
  content: {
    headline,
    body,
  },
  image,
}) {
  return (
    <section css={styles.section}>
      <img
        alt={''}
        css={styles.dotGrid}
        src={dotGridSvg}
      />
      <div css={styles.contentWrap}>
        <div css={styles.textContent}>
          <H2 css={textStyles}>{headline}</H2>
          <P1 css={textStyles}>{body}</P1>
        </div>
        <div css={styles.imageContainer}>
          <GatsbyImage
            alt={''}
            css={styles.gatsbyImage}
            image={getImage(image)}
          />
          <img
            alt={''}
            css={styles.rxbw}
            src={rxbwSvg}
          />
        </div>
        <img
          alt={''}
          css={styles.unapologetic}
          src={unapologeticLargeSvg}
        />
      </div>
    </section>
  );
}

CourageSection.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
  }).isRequired,
  image: PropTypes.object.isRequired,
};

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.black(),
    ...Responsive.lg({
      minHeight: 560,
    }),
  },
  dotGrid: {
    display: 'none',
    ...Responsive.lg({
      display: 'block',
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: '50%',
      transform: 'translateX(-126%)',
    }),
  },
  contentWrap: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    margin: '30px 0',
    ...Responsive.lg({
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'center',
      margin: '48px 0',
    }),
  },
  imageContainer: {
    width: '100%',
    marginTop: 50,
    ...Responsive.lg({
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '49%',
    }),
  },
  gatsbyImage: {
    ...Responsive.lg({
      borderRadius: 6,
    }),
  },
  rxbw: {
    display: 'none',
    ...Responsive.lg({
      display: 'block',
      position: 'absolute',
      right: -20,
      bottom: 0,
    }),
  },
  textContent: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 600,
    margin: '0 20px',
    textAlign: 'center',
    '& > * + *': {
      marginTop: 12,
    },
    h2: {
      maxWidth: 540,
    },
  },
  unapologetic: {
    display: 'none',
    ...Responsive.lg({
      display: 'block',
      position: 'absolute',
      top: -180,
      right: -70,
      svg: {
        width: '100%',
        height: '100%',
      },
    }),
  },
});
