import Button from '@root/core/src/components/button';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import clockIcon from '@root/core/src/assets/clock.svg';
import { AgentsOperatingHours } from '@root/agents/src/components/agents-operating-hours';
import { StyleSheet } from '@root/core/src/utils/styles';
import { regular } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function AgentsOutOfBusinessHoursModal({
  onDismiss,
  operatingHours,
  isShowing,
}) {
  return (
    <Modal
      renderCloseButton
      isShowing={isShowing}
      name={'joinroot-agents-out-of-business-hours-modal'}
      onClose={onDismiss}
    >
      <div css={styles.clockIconContainer}>
        <img
          src={clockIcon}
        />
      </div>

      <p css={styles.text}>
        Our Root agents are not currently available, but you can call us during regular business hours:
      </p>
      <div css={styles.operatingHours}>
        <AgentsOperatingHours
          operatingHours={operatingHours}
        />
      </div>

      <Button
        css={styles.button}
        onClick={onDismiss}
      >
        Got it
      </Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  text: {
    marginTop: 40,
    fontSize: 18,
    ...regular(),
  },
  operatingHours: {
    ...regular(),
    fontSize: 18,
    display: 'flex',
    justifyContent: 'center',
    margin: '20px 0px 18px',
  },
  clockIconContainer: {
    textAlign: 'center',
  },
  button: {
    marginTop: 30,
  },
});

AgentsOutOfBusinessHoursModal.propTypes = {
  isShowing: PropTypes.bool,
  onDismiss: PropTypes.func.isRequired,
  operatingHours: PropTypes.object,
};
