import Cookies from '@root/vendor/js-cookie';
import HubSpotPersonas from '@root/joinroot.com/src/models/hubspot-personas';
import HubSpotValues from '@root/joinroot.com/src/third-party-api/hubspot-values';
import { invalid, isRequired } from '@root/gatsby-contentful-core/src/utils/schema';
import { object, string } from 'yup';

export default class AffiliateInfoRequestForm {
  static schema = object()
    .shape({
      persona: string()
        .oneOf(Object.values(HubSpotPersonas.PERSONA_OPTIONS))
        .required(),
      firstName: string()
        .required(),
      lastName: string()
        .required(),
      email: string()
        .email(invalid)
        .required(isRequired),
      companyName: string(),
      companyWebsite: string(),
      numberOfEmployees: string(),
      message: string(),
    });

  constructor(value) {
    return Object.assign(
      this,
      {
        ...value,
      }
    );
  }

  set(key, value) {
    return this.setAttributes({
      [key]: value,
    });
  }

  setAttributes(value = {}) {
    return Object.assign(
      this,
      new AffiliateInfoRequestForm(value),
    );
  }

  serialize() {
    return {
      fields: [
        {
          name: 'hs_persona',
          value: this.persona,
        },
        {
          name: 'firstname',
          value: this.firstName,
        },
        {
          name: 'lastname',
          value: this.lastName,
        },
        {
          name: 'email',
          value: this.email,
        },
        {
          name: 'company',
          value: this.companyName || '',
        },
        {
          name: 'company_website',
          value: this.companyWebsite || '',
        },
        {
          name: 'company_size',
          value: this.numberOfEmployees || '',
        },
        {
          name: 'message',
          value: this.message || '',
        },
      ],
      context: {
        hutk: Cookies.getJSON(HubSpotValues.COOKIE_NAME),
        pageUri: 'joinroot.com/partners/affiliates',
        pageName: 'Root Insurance Affiliate Marketing Program Info, variant: ' + this.variant,
      },
    };
  }
}
