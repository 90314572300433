import { Colors } from '@root/core/src/utils/styles';
import { semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export const secondaryButtonStyles = {
  ...semiBold(),
  color: Colors.white(),
  backgroundColor: 'rgba(0, 0, 0, 0)',
  '&:hover': {
    borderColor: Colors.rootOrange(),
    color: Colors.white(),
  },
};

export const textStyles = {
  color: Colors.white(),
};
