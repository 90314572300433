import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { constantize } from '@root/core/src/utils/strings';
import { secondaryButtonStyles, textStyles } from '@root/joinroot.com/src/components/progress/dark-mode-styles';

export default function ReadySection({
  content: {
    headline,
    body,
    cta,
  },
  image,
}) {
  const { trackClick } = useAnalytics('PROGRESS_PAGE_READY_SECTION', true);

  const renderCta = cta && cta.buttonText && cta.buttonLink;

  return (
    <section css={styles.section}>
      <GatsbyImage
        alt={''}
        css={styles.gatsbyImageWrapperStyles}
        image={getImage(image)}
      />
      <div css={styles.contentWrap}>
        <H2 css={textStyles}>{headline}</H2>
        <P1 css={textStyles}>{body}</P1>
        {renderCta &&
          <LinkButton
            buttonStyles={validateButtonType(cta.buttonVariant) === 'secondary' ? secondaryButtonStyles : null}
            onClick={() => trackClick('GET_STARTED_BUTTON')}
            to={cta.buttonLink}
            type={cta.buttonVariant}
          >
            {cta.buttonText}
          </LinkButton>
        }
      </div>
    </section>
  );
}

ReadySection.propTypes = {
  content: PropTypes.shape({
    headline: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    cta: PropTypes.shape({
      buttonText: PropTypes.string.isRequired,
      buttonLink: PropTypes.string.isRequired,
      buttonVariant: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  image: PropTypes.object.isRequired,
};

function validateButtonType(type) {
  const isValidButtonType = LinkButtonModel.types[constantize(type)];
  return isValidButtonType ? isValidButtonType : LinkButtonModel.types.PRIMARY;
}

const imageDistanceFromSectionTop_Mobile = 280;
const imageDistanceFromSectionTop_Desktop = 180;

const styles = StyleSheet.create({
  section: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: Colors.black(),
  },
  // Passing styles directly into GatsbyImage using the `style` and `imgStyle` props will override Gatsby default styles.
  // Unfortunately Media queries cannot easily be passed in this way so we're using !important in this block to override the defaults.
  gatsbyImageWrapperStyles: {
    position: 'absolute !important',
    top: imageDistanceFromSectionTop_Mobile,
    left: 0,
    width: '100%',
    height: `calc(100% - ${imageDistanceFromSectionTop_Mobile}px)`,
    ...Responsive.md({
      top: imageDistanceFromSectionTop_Desktop,
      height: `calc(100% - ${imageDistanceFromSectionTop_Desktop}px)`,
    }),
    img: {
      objectPosition: '100% top !important',
      ...Responsive.md({
        objectPosition: 'top !important',
      }),
    },
  },
  contentWrap: {
    position: 'relative',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    maxWidth: 1200,
    margin: '80px 20px',
    marginBottom: 'calc(80px + 30vw)',
    textAlign: 'center',
    p: {
      marginTop: 12,
      marginBottom: 35,
      maxWidth: 400,
      ...Responsive.md({
        maxWidth: 800,
      }),
    },
  },
});
