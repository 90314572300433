import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React, { useCallback, useEffect, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import useResize from '@root/core/src/hooks/use-resize';
import { Link, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function AnnouncementBanner({ bannerContent }) {
  const [customCopy, setCustomCopy] = useState(bannerContent.content.SHORT_COPY);

  useEffect(() => {
    setCustomCopy(window.innerWidth >= Responsive.BREAKPOINTS.md ? bannerContent.content.LONG_COPY : bannerContent.content.SHORT_COPY);
  }, [bannerContent.content]);

  useResize({
    onResize: useCallback(() => {
      window.innerWidth >= Responsive.BREAKPOINTS.md ? setCustomCopy(bannerContent.content.LONG_COPY) : setCustomCopy(bannerContent.content.SHORT_COPY);
    }, [bannerContent.content]),
  });

  const covid19Link = (
    <Link
      css={styles.content}
      to={'/covid-19-response/'}
    >COVID-19
    </Link>);

  return (
    <div
      css={styles.banner}
      data-testid={'announcement_banner'}
    >
      <P1 css={styles.content}>
        {customCopy || (
          <>
            Learn about our response to {covid19Link}.
          </>
        )}
      </P1>
    </div>
  );
}

const styles = StyleSheet.create({
  banner: {
    backgroundColor: Colors.black(),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    height: 50,
    textAlign: 'center',
    width: '100vw',
  },
  content: {
    whiteSpace: 'nowrap',
    color: Colors.white(),
  },
  noBreakLg: {
    ...Responsive.lg({
      display: 'none',
    }),
  },
});

AnnouncementBanner.propTypes = {
  bannerContent: PropTypes.shape({
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  }),
};
