import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import PropTypes from '@root/vendor/prop-types';
import { H4 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function IconToutMini({
  body,
  icon,
  iconAltText,
  styleAsListItem,
  title,
}) {
  const styles = styleAsListItem ? listToutStyles : gridToutStyles;

  return (
    <div css={styles.iconToutMini}>
      <div css={[baseStyles.iconContainer, styles.iconContainer]}>
        <img
          alt={iconAltText ?? ''}
          css={baseStyles.icon}
          data-test-id={'tout-icon'}
          loading={'lazy'}
          src={icon}
        />
      </div>
      <div css={styles.textContainer}>
        {title && !styleAsListItem && (
          <H4
            css={styles.title}
            data-test-id={'tout-title'}
          >
            {title}
          </H4>
        )}
        {body &&
          <BodyCopy
            content={body}
            css={styles.body}
          />
        }
      </div>
    </div>
  );
}

const baseStyles = StyleSheet.create({
  iconContainer: {
    width: 48,
    height: 48,
    flexShrink: 0,
  },
  icon: {
    width: '100%',
  },
});

const listToutStyles = StyleSheet.create({
  iconToutMini: {
    display: 'flex',
    alignItems: 'center',
    '& + &': {
      marginTop: 50,
    },
  },
  body: {
    maxWidth: 270,
    marginLeft: 10,
    '@media screen and (min-width: 1024px)': {
      '.divider-left &, .divider-center &': {
        maxWidth: 'none',
      },
    },
  },
});

const gridToutStyles = StyleSheet.create({
  iconToutMini: {
    marginBottom: 50,
    '&:last-of-type': {
      marginBottom: 0,
    },
    '@media screen and (min-width: 768px)': {
      width: 240,
    },
  },
  iconContainer: {
    marginBottom: 10,
  },
  title: {
    marginBottom: 10,
  },
});

IconToutMini.propTypes = {
  body: PropTypes.node,
  icon: PropTypes.string.isRequired,
  iconAltText: PropTypes.string,
  styleAsListItem: PropTypes.bool,
  title: PropTypes.string,
};
