import AffiliateLandingInfoForm from '@root/joinroot.com/src/components/affiliates/affiliate-landing-info-form';
import FormHero from '@root/joinroot.com/src/components/sections/form-hero.js';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { H1Display } from '@root/gatsby-contentful-core/src/components/text';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

export const tertiaryTab = {
  title: 'A market of 230 million people',
  body: 'Based on the number of licensed American drivers in 2019.',
};
export default function AffiliatesLandingPageHero({ anchor, sectionSpacing }) {
  const { affiliatesHeroImg } = useStaticQuery(graphql`
    query {
      affiliatesHeroImg: file(relativePath: {eq: "affiliates/affiliates-hero-bg.jpg"}) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, quality: 70)
        }
      }
    }
  `);

  const image = {
    styles: styles.gatsbyImg,
    alt: '',
    gatsbyImage: getImage(affiliatesHeroImg.childImageSharp.gatsbyImageData),
  };

  return (
    <FormHero
      id={anchor}
      image={image}
      sectionId={'affiliates-hero'}
      sectionSpacing={sectionSpacing}
      tertiaryTab={tertiaryTab}
    >
      <H1Display css={styles.heading}>Meet the<br />biggest<br />opportunity<br />in insurance</H1Display>
      <AffiliateLandingInfoForm />
    </FormHero>
  );
}

AffiliatesLandingPageHero.propTypes = {
  anchor: PropTypes.string,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  gatsbyImg: {
    height: '50%',

    ...Responsive.lg({
      height: '100%',
    }),

    '& > picture > img': {
      objectPosition: '30% center !important',
    },
  },
  heading: {
    marginBottom: 160,
    color: Colors.nearWhite(),
    textAlign: 'left',

    ...Responsive.xsm({
      fontSize: '60px',
      lineHeight: '0.95',
    }),
    ...Responsive.sm({
      fontSize: '9vw',
    }),
    ...Responsive.lg({
      marginBottom: 0,
      alignSelf: 'flex-start',
      maxWidth: '50%',
    }),
    ...Responsive.xl({
      fontSize: '110px',
    }),
  },
});
