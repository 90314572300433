import AvailabilityMapSection, { TITLE_TYPES } from '@root/joinroot.com/src/components/sections/availability-map/availability-map-section';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export default function AvailabilityMapBlock({ content }) {
  return (
    <div data-testid={'availabilityMapBlock'}>
      <AvailabilityMapSection
        anchor={content.anchor?.anchorSlug}
        body={content.body}
        eyebrow={content.eyebrow}
        footerRichTextContent={content.modalFooterText}
        formInputText={content.modalExpandableTitle}
        title={content.headline}
        titleType={buildTitleType(content.titleType)}
      />
    </div>
  );
}

function buildTitleType(titleType) {
  return titleType.toLowerCase() === 'h1' ? TITLE_TYPES.H1 : TITLE_TYPES.H2;
}

AvailabilityMapBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.string,
    body: PropTypes.shape({
      json: PropTypes.object,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    modalExpandableTitle: PropTypes.string,
    modalFooterText: PropTypes.shape({
      json: PropTypes.object,
    }),
    titleType: PropTypes.string,
  }),
};
