import ExpandableGroup from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-group';
import ExpandableItem from '@root/gatsby-contentful-core/src/components/sections/expandable/expandable-item';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

const FaqBlock = React.forwardRef(({
  group,
  index,
  activeTopic,
  setActiveTopic,
  observerConfig,
}, ref) => {
  const isFirstBlock = index === 0;

  useEffect(() => {
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.target.id !== activeTopic.topic && entry.isIntersecting) {
          setActiveTopic({
            topic: entry.target.id,
            index: 0,
          });
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, observerConfig);
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [activeTopic, setActiveTopic, observerConfig, ref]);

  return (
    <div
      className={'faq-block'}
      css={[styles.faqBlock, isFirstBlock && styles.firstBlock]}
      data-testid={`faq-block-${index}`}
      id={group.title}
      ref={ref}
    >
      <div css={styles.groupWrap}>
        <ExpandableGroup
          anchor={group.anchor}
          groupStyles={styles.expandableGroup}
          groupTitle={group.title}
          link={group.link}
        >
          {group.items.map((item, j) => (
            <ExpandableItem
              anchor={item.anchor}
              itemTitle={item.title}
              key={j}
              openExpandableItem={false}
            >
              {item.content}
            </ExpandableItem>
          ))}
        </ExpandableGroup>
      </div>
    </div>
  );
});

export default FaqBlock;

FaqBlock.propTypes = {
  activeTopic: PropTypes.shape({
    topic: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  group: PropTypes.shape({
    anchor: PropTypes.string,
    title: PropTypes.string.isRequired,
    link: PropTypes.shape({
      to: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        anchor: PropTypes.string,
        title: PropTypes.string.isRequired,
        content: PropTypes.object.isRequired,
      }),
    ),
  }),
  index: PropTypes.number,
  observerConfig: PropTypes.shape({
    rootMargin: PropTypes.string.isRequired,
  }).isRequired,
  setActiveTopic: PropTypes.func.isRequired,
};

const styles = StyleSheet.create({
  faqBlock: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    padding: '80px 20px',

    ...Responsive.md({
      padding: '100px 30px',
    }),
  },

  firstBlock: {
    paddingTop: 50,

    ...Responsive.md({
      paddingTop: 50,
    }),
  },

  groupWrap: {
    width: '100%',

    ...Responsive.md({
      maxWidth: 1200,
      paddingLeft: 200,
    }),
  },

  expandableGroup: {
    maxWidth: '100%',
  },
});
