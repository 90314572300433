import BlogHeader from '@root/joinroot.com/src/components/blog/header';
import BlogPostPreviewTile from '@root/joinroot.com/src/components/blog/blog-post-preview-tile';
import CategorySectionStacked from '@root/joinroot.com/src/components/blog/category-section-stacked';
import CorePageTemplate from '@root/gatsby-contentful-core/src/components/pages/core-page-template';
import Footer from '@root/joinroot.com/src/components/footer/footer';
import NavWrapper from '@root/joinroot.com/src/components/nav/nav-wrapper';
import Pagination from '@root/joinroot.com/src/components/blog/pagination';
import PropTypes, { arrayOf } from '@root/vendor/prop-types';
import Spacer from '@root/gatsby-contentful-core/src/components/spacer';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import environment from '@root/core/src/utils/environment';
import { constantize } from '@root/core/src/utils/strings';
import { graphql } from 'gatsby';

export default function BlogCategoryPage({
  data: {
    allCategoryData,
    allPostData,
    categoryData: {
      categoryTitle,
      publishingMetadata,
    },
    topLevelOptionData: { nodes: [topLevelOptions] },
  },
  location,
  pageContext: {
    numberOfPages,
    currentPage,
  },
}) {
  const analyticsContext = `BLOG_CATEGORY_PAGE_${constantize(categoryTitle)}`;

  return (
    <CorePageTemplate
      analyticsContext={analyticsContext}
      ctaLink={topLevelOptions.navigationOptions?.ctaLink}
      ctaText={topLevelOptions.navigationOptions?.ctaText}
      deIndexPage={publishingMetadata.deIndexPage}
      footer={Footer}
      includeHotjar={publishingMetadata.includeHotjar}
      includeOptimizely={publishingMetadata.includeOptimizely}
      isCtaButtonDisabled={topLevelOptions.navigationOptions?.disableCtaButton}
      isStickyFooterDisabled={topLevelOptions.navigationOptions?.disableStickyFooter}
      location={location}
      nav={NavWrapper}
      pageCanonical={`${environment.websiteBaseUrl}/blog/tag${publishingMetadata.slug}`}
      pageDescription={publishingMetadata.pageDescription}
      pageThumbnail={publishingMetadata.pageThumbnail?.file?.url}
      pageTitle={publishingMetadata.pageTitle}
    >
      <BlogHeader
        activeNavItem={categoryTitle}
        logo={topLevelOptions.primaryHeaderLogo.file.url}
        logoAltText={'Root Insurance blog logo'}
        navItems={allCategoryData.nodes}
      />
      <CategorySectionStacked>
        {allPostData.nodes.map(({
          previewTitle,
          previewBody,
          previewImage: { gatsbyImageData },
          imageAltText,
          customDateCreated,
          publishingMetadata: { slug },
        }) => {
          return (
            <BlogPostPreviewTile
              dateCreated={customDateCreated}
              imageAltText={imageAltText}
              key={slug}
              layout={TileLayoutModel.STACKED}
              previewBody={previewBody}
              previewImage={gatsbyImageData}
              previewTitle={previewTitle}
              slug={slug}
            />
          );
        })}
      </CategorySectionStacked>
      <Spacer height={50} />
      <Pagination
        baseCategorySlug={`/blog/tag${publishingMetadata.slug}`}
        currentPage={currentPage}
        numberOfPages={numberOfPages}
      />
      {numberOfPages > 1 && <Spacer />}
    </CorePageTemplate>
  );
}

BlogCategoryPage.propTypes = {
  data: PropTypes.shape({
    categoryData: PropTypes.shape({
      categoryTitle: PropTypes.string.isRequired,
      publishingMetadata: PropTypes.shape({
        deIndexPage: PropTypes.bool,
        includeHotjar: PropTypes.bool,
        includeOptimizely: PropTypes.bool,
        pageTitle: PropTypes.string.isRequired,
        pageDescription: PropTypes.string.isRequired,
        pageThumbnail: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string,
          }),
        }),
        slug: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
    allCategoryData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        categoryTitle: PropTypes.string.isRequired,
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }),
      })).isRequired,
    }).isRequired,
    allPostData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        previewTitle: PropTypes.string.isRequired,
        previewBody: PropTypes.string.isRequired,
        previewImage: PropTypes.shape({
          gatsbyImageData: PropTypes.object,
        }).isRequired,
        imageAltText: PropTypes.string,
        customDateCreated: PropTypes.string,
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
    topLevelOptionData: PropTypes.shape({
      nodes: arrayOf(PropTypes.shape({
        navigationOptions: PropTypes.shape({
          ctaLink: PropTypes.string,
          ctaText: PropTypes.string,
          disableCtaButton: PropTypes.bool,
          disableStickyFooter: PropTypes.bool,
        }),
        primaryHeaderLogo: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    numberOfPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
  }).isRequired,
};

export const query = graphql`
  query ($blogPageIds: [String!]!, $categoryId: String!) {
    topLevelOptionData: allContentfulBlogTopLevelPageOptions(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        navigationOptions {
          ctaLink
          ctaText
          disableCtaButton
          disableStickyFooter
        }
        primaryHeaderLogo {
          file {
            url
          }
        }
      }
    }
    categoryData: contentfulBlogCategoryTag(id: {eq: $categoryId}) {
      categoryTitle
      publishingMetadata {
        ...ContentfulPublishingMetadata_fragment
      }
    }
    allCategoryData: allContentfulBlogCategoryTag(filter: {node_locale: {eq: "en-US"}}) {
      nodes {
        categoryTitle
        publishingMetadata {
          slug
        }
      }
    }
    allPostData: allContentfulBlogPostPage(
      filter: {
        id: { in: $blogPageIds }
        node_locale: {eq: "en-US"}
      }
      sort: {fields: customDateCreated, order: DESC}
      ) {
      nodes {
        previewTitle
        previewBody
        previewImage {
          gatsbyImageData(placeholder: BLURRED)
        }
        imageAltText
        customDateCreated(formatString: "MMMM DD, YYYY")
        publishingMetadata {
          slug
        }
      }
    }
  }
`;
