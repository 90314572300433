import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import environment from '@root/core/src/utils/environment';

export const CALIFORNIA_FAQ_URL = `${environment.websiteBaseUrl}/califaq/`;

export function CaliforniaFaqLink({ styles }) {
  return (
    <a
      css={styles}
      href={CALIFORNIA_FAQ_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      joinroot.com/califaq
    </a>
  );
}

CaliforniaFaqLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
