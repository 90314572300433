import Button from '@root/core/src/components/button';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import { AGENTS_TRIAGE_STATES } from '@root/agents/src/hooks/use-speak-to-agent-flow';
import { H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';
import { regular } from '@root/brand/src/assets/fonts/root-sans/emotion';

export default function AgentsUnsupportedMarketModal({
  isShowing,
  onDismiss,
  triageState,
}) {
  const triageTextHeader = () => {
    switch (triageState) {
    case AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT: return 'Root isn’t available in your state yet.';
    case AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT: return 'Licensed Root agents aren’t available in your state yet.';
    }
  };

  const triageTextSubHeader = () => {
    switch (triageState) {
    case AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT: return 'We’re working hard to get there. Please check back soon.';
    case AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT: return 'In the meantime, our online experience will help you find the right plan for your needs.';
    }
  };

  return (
    <Modal
      renderCloseButton
      isShowing={isShowing}
      name={'joinroot-agents-isa-not-supported-modal'}
      onClose={onDismiss}
    >
      <H3 css={styles.header}>{triageTextHeader()}</H3>
      <p css={styles.text}>{triageTextSubHeader()}</p>
      <Button
        css={styles.button}
        onClick={onDismiss}
      >
        Close
      </Button>
    </Modal>
  );
}

const styles = StyleSheet.create({
  header: {
    marginBottom: 10,
  },
  text: {
    fontSize: 18,
    ...regular(),
  },
  button: {
    marginTop: 30,
  },
  image: {
    maxWidth: '90%',
    marginBottom: 24,
  },
  imageContainer: {
    textAlign: 'center',
  },
});

AgentsUnsupportedMarketModal.propTypes = {
  isShowing: PropTypes.bool,
  market: PropTypes.string,
  onDismiss: PropTypes.func,
  triageState: PropTypes.oneOf([
    AGENTS_TRIAGE_STATES.QUOTE_SUPPORT,
    AGENTS_TRIAGE_STATES.NO_ROOT_SUPPORT,
    AGENTS_TRIAGE_STATES.NO_ISA_SUPPORT,
    null,
  ]),
};
