import TrackingService from '@root/attribution/src/services/tracking-service';
import { datadogRum } from '@root/vendor/@datadog/browser-rum';

export default class ConversionTrackingService {
  static CONVERSION_EVENTS = {
    ACCOUNT_CONVERSION: 'ACCOUNT_CONVERSION',
    AFFILIATES_APPLICATION_SUBMITTED: 'AFFILIATES_APPLICATION_SUBMITTED',
    AFFILIATES_INITIAL_SIGNUP: 'AFFILIATES_INITIAL_SIGNUP',
    BIND_CONVERSION: 'BIND_CONVERSION',
    BLOG_SUBSCRIPTION: 'BLOG_SUBSCRIPTION',
    GET_A_QUOTE_BUTTON_CLICKED: 'GET_A_QUOTE_BUTTON_CLICKED',
    GET_THE_APP_BUTTON_CLICKED: 'GET_THE_APP_BUTTON_CLICKED',
    WWW_SPEAK_TO_AN_AGENT_BUTTON_CLICKED: 'WWW_SPEAK_TO_AN_AGENT_BUTTON_CLICKED',
    PROFILE_CONVERSION: 'PROFILE_CONVERSION',
    QUOTE_VIEWED: 'QUOTE_VIEWED',
    RENTERS_BIND_CONVERSION: 'RENTERS_BIND_CONVERSION',
    RENTERS_PROFILE_CONVERSION: 'RENTERS_PROFILE_CONVERSION',
    RENTERS_QUOTE_VIEWED_CONVERSION: 'RENTERS_QUOTE_VIEWED_CONVERSION',
  };

  static trackConversion(event, data = {}) {
    datadogRum.addAction(event, data);
    TrackingService.fireEvent(event, data);
  }
}
