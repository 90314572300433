import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import IconTout from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout';
import IconToutSection, { ICON_SIZES } from '@root/gatsby-contentful-core/src/components/sections/icon-tout/icon-tout-section';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useInterfaceActions from '@root/gatsby-contentful-core/src/hooks/use-interface-actions';

export default function IconToutGridBlock({
  content,
  trackClick,
}) {
  const { buildCallToActionButton } = useInterfaceActions();

  return (
    <div data-testid={'iconToutGridBlock'}>
      <IconToutSection
        anchor={content.anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(content.backgroundColor)}
        body={renderRichText(content.body)}
        eyebrow={content.eyebrow}
        iconSize={content.iconSize ? content.iconSize.toLowerCase() : ICON_SIZES.SMALL}
        sectionSpacing={content.sectionSpacing}
        title={content.headline}
        withGrid={true}
      >
        {content.contentfulchildren.map((iconTout) => {
          return (
            <IconTout
              body={renderRichText(iconTout.body)}
              button={buildCallToActionButton({
                button: iconTout.callToActionButton,
                trackClick,
              })}
              eyebrow={iconTout.eyebrow}
              icon={iconTout.asset.file.url}
              iconAltText={iconTout.iconAltText}
              id={iconTout.id}
              key={iconTout.id}
              title={iconTout.headline}
            />
          );
        })}
      </IconToutSection>
    </div>
  );
}

IconToutGridBlock.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string.isRequired,
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
    body: PropTypes.shape({
      json: PropTypes.object.isRequired,
    }),
    eyebrow: PropTypes.string,
    headline: PropTypes.string,
    iconSize: PropTypes.string,
    contentfulchildren: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        eyebrow: PropTypes.string,
        headline: PropTypes.string,
        body: PropTypes.object,
        asset: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string.isRequired,
          }).isRequired,
        }).isRequired,
        iconAltText: PropTypes.string,
        callToActionButton: PropTypes.shape({
          id: PropTypes.string.isRequired,
          buttonType: PropTypes.string.isRequired,
          buttonText: PropTypes.string.isRequired,
          buttonLink: PropTypes.string.isRequired,
        }),
      }).isRequired
    ).isRequired,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
  }).isRequired,
  trackClick: PropTypes.func.isRequired,
};
