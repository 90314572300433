import Caret from '@root/gatsby-contentful-core/src/assets/components/brand-bling/root-caret';
import ContentfulCustomFormFactory from '@root/gatsby-contentful-core/test/support/factories/contentful-custom-form-factory';
import PropTypes from '@root/vendor/prop-types';
import { graphql } from 'gatsby';

export const graphQlFragment = graphql`
fragment ContentfulCustomForm_fragment on ContentfulCustomForm {
  sys
  id
  formId
}
`;

export default class ContentfulCustomFormComponent {
  constructor({
    formId = null, componentMap = defaultComponentMap, anchor,
  } = {}) {
    this.formId = formId;
    this.componentMap = componentMap;
    this.componentToRender = ContentfulCustomFormComponent._getComponentToRenderFromId(formId, componentMap);
    this.anchor = anchor;

    if (!this.componentToRender) {
      throw new Error(`No component found to render for id '${this.formId}'.`);
    }
  }

  static createFromGraphqlContent(content = {}, componentMap) {
    return new ContentfulCustomFormComponent({
      formId: content.formId,
      componentMap,
      anchor: content.anchor && content.anchor.anchorSlug,
    });
  }

  static graphQlDataFactory = ContentfulCustomFormFactory;

  static graphQlFragment = graphQlFragment;

  static propType() {
    return PropTypes.shape({
      id: PropTypes.string.isRequired,
      formId: PropTypes.string.isRequired,
      anchor: PropTypes.shape({
        id: PropTypes.string,
        anchorSlug: PropTypes.string,
      }),
    });
  }

  static EXAMPLE_FORM_ID = 'example-form-id';

  static _getComponentToRenderFromId(formId, componentMap) {
    return componentMap && componentMap.IDS && typeof componentMap.IDS === 'object'
      && Object.values(componentMap.IDS).includes(formId)
      && componentMap.ID_TO_COMPONENT_MAP && typeof componentMap.ID_TO_COMPONENT_MAP === 'object'
      && componentMap.ID_TO_COMPONENT_MAP[formId];
  }
}

const defaultComponentMap = {
  IDS: {
    EXAMPLE_FORM_ID: ContentfulCustomFormComponent.EXAMPLE_FORM_ID,
  },
  ID_TO_COMPONENT_MAP: {
    [ContentfulCustomFormComponent.EXAMPLE_FORM_ID]: Caret,
  },
};

