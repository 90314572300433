import ContentfulCustomReactComponent from '@root/gatsby-contentful-core/src/models/contentful/contentful-custom-react-component';
import PropTypes from '@root/vendor/prop-types';

export default function CoreCustomReactComponentBlock({ content, componentMap }) {
  const customReactComponent = ContentfulCustomReactComponent.createFromGraphqlContent(content, componentMap);

  const ComponentToRender = customReactComponent.componentToRender;

  return (
    <ComponentToRender
      anchor={customReactComponent.anchor}
      sectionSpacing={customReactComponent.sectionSpacing}
    />
  );
}

CoreCustomReactComponentBlock.propTypes = {
  componentMap: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  content: ContentfulCustomReactComponent.propType(),
};
