import dayjs from '@root/vendor/dayjs';

function isInt(string) {
  return !Number.isNaN(Number.parseInt(string));
}

function intOrDefault(string) {
  return isInt(string)
    ? string
    : '1';
}

function padField(field, length) {
  return isInt(field)
    ? field.padStart(length, '0')
    : field.padStart(length, '*');
}

export default class DobDate {
  static DEFAULT_MIN_AGE = 14;
  static DEFAULT_MAX_AGE = 110;

  constructor(dateString, currentDate = new Date()) {
    this.month = dateString ? dateString.split('-')[1] : '';
    this.year = dateString ? dateString.split('-')[0] : '';
    this.day = dateString ? dateString.split('-')[2] : '';
    this.currentDate = dayjs(currentDate);
  }

  toString() {
    const {
      year, month, day,
    } = this;

    return `${padField(year, 4)}-${padField(month, 2)}-${padField(day, 2)}`;
  }

  toDisplayString() {
    const {
      year, month, day,
    } = this;

    return `${padField(month, 2)}-${padField(day, 2)}-${padField(year, 4)}`;
  }

  toDisplayObfuscatedString() {
    const {
      year,
    } = this;
    const month = '*';
    const day = '*';

    return `${padField(month, 2)}-${padField(day, 2)}-${padField(year, 4)}`;
  }

  toStringUnobfuscate() {
    const {
      year, month, day,
    } = this;

    return `${padField(year, 4)}-${padField(intOrDefault(month), 2)}-${padField(intOrDefault(day), 2)}`;
  }

  toStringMDY() {
    const {
      year, month, day,
    } = this;

    return `${padField(month, 2)}/${padField(day, 2)}/${padField(year, 4)}`;
  }

  serializeForSubmission() {
    if (!this.dateExists()) {
      return null;
    }

    return this.toString();
  }

  age(currentDate = this.currentDate) {
    if (!this.dateExists()) { return null; }

    return dayjs(currentDate).diff(this.toStringUnobfuscate(), 'years');
  }

  isSameDate(otherDobDate) {
    return this.year === otherDobDate.year && this.month === otherDobDate.month && this.day === otherDobDate.day;
  }

  isValidDay() {
    return /^(\d{1,2}|\*{1,2})$/.test(this.day) && this.isInRange(this.day, 1, 31);
  }

  isValidMonth() {
    return /^(\d{1,2}|\*{1,2})$/.test(this.month) && this.isInRange(this.month, 1, 12);
  }

  isValidCombination() {
    return /^(\d{1,2})$/.test(this.day) && /^(\d{1,2})$/.test(this.month) || /^\*{1,2}$/.test(this.day) && /^\*{1,2}$/.test(this.month);
  }

  isValidYear(options = {}) {
    const maxYear = options.maxYear || this.currentDate.year();
    return /^\d{4}$/.test(this.year) && parseInt(this.year) <= parseInt(maxYear);
  }

  isInRange(testValue, minValue, maxValue) {
    return !isInt(testValue) || parseInt(testValue) <= maxValue && parseInt(testValue) >= minValue;
  }

  isYearComplete() {
    return /^\d{4}$/.test(this.year);
  }

  isObfuscated() {
    return /^\*{2}$/.test(this.day) || /^\*{2}$/.test(this.month);
  }

  isOldEnough({ currentDate = new Date(), minAge = DobDate.DEFAULT_MIN_AGE } = {}) {
    const minDate = dayjs(currentDate).subtract(minAge, 'years');

    return /^(\d|\*){4}$/.test(this.year) && !minDate.isBefore(this.toStringUnobfuscate(), 'day');
  }

  isYoungEnough({ currentDate = new Date(), maxAge = DobDate.DEFAULT_MAX_AGE } = {}) {
    const maxDate = dayjs(currentDate).subtract(maxAge + 1, 'years');

    return /^(\d|\*){4}$/.test(this.year) && maxDate.isBefore(this.toStringUnobfuscate(), 'day');
  }

  dateExists() {
    const dayjsDate = dayjs(this.toStringUnobfuscate(), 'YYYY-MM-DD');
    // dayjs will turn 2/29/19 into 3/1/19 -- to get around this, we check if the date got changed when it was passed in
    return dayjsDate.isValid() && dayjsDate.format('YYYY-MM-DD') === this.toStringUnobfuscate();
  }

  isValid(options = {}) {
    const maxAge = options.maxAge || DobDate.DEFAULT_MAX_AGE;
    const maxDate = options.maxDate || this.currentDate;
    const minDate = options.minDate || this.currentDate.year(dayjs().year() - maxAge - 2);

    const dateInputsValid = this.isValidDay() && this.isValidMonth() && this.isValidYear() && this.isValidCombination();

    if (dateInputsValid) {
      const dayjsDate = dayjs(this.toStringUnobfuscate(), 'YYYY-MM-DD');
      return this.dateExists() && dayjsDate.isBefore(maxDate) && dayjsDate.isAfter(minDate);
    } else {
      return false;
    }
  }

  set(key, value) {
    return Object.assign(
      new DobDate(),
      this,
      {
        [key]: value,
      },
    );
  }
}
