import Button, { ButtonType } from '@root/gatsby-contentful-core/src/components/button';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function ViewPrivacyPolicyCta() {
  return (
    <section css={styles.section}>
      <div css={styles.contentWrapper}>
        <p css={styles.heading}>Want to learn more about the data Root collects and how we use it?</p>
        <Button
          css={styles.button}
          href={'/privacy-policy'}
          type={ButtonType.LINK}
        >
          Learn more
        </Button>
      </div>
    </section>
  );
}

const styles = StyleSheet.create({
  section: {
    margin: '-60px auto 50px',
    borderRadius: 10,
    background: '#F4F7F9',
    display: 'flex',
    ...Responsive.sm({
      maxWidth: 550,
    }),
    ...Responsive.md({
      maxWidth: 750,
    }),
    ...Responsive.lg({
      maxWidth: 950,
    }),
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    maxWidth: 335,
    minHeight: 300,
    margin: 'auto',
    ...Responsive.sm({
      maxWidth: 350,
    }),
    ...Responsive.md({
      maxWidth: 550,
    }),
    ...Responsive.lg({
      maxWidth: 750,
    }),
  },
  heading: {
    ...Theme.subhead2Light(),
    fontSize: 36,
    lineHeight: '116%',
    color: Colors.black(),
    textAlign: 'center',
  },
  button: {
    ...Theme.bodyCopyBold(),
    background: Colors.black(),
    borderRadius: 8,
    paddingRight: 30,
    paddingLeft: 30,
  },
});
