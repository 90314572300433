import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export const AgentsAvailabilityStatuses = {
  HOLIDAY: 'holiday',
  IN_OFFICE: 'in_office',
  OUTSIDE_WORKING_HOURS: 'outside_working_hours',
};

export default function getAgentsAvailabilityConfiguration() {
  return new NetworkRequestConfiguration({
    endpoint: 'agents_availability',
    method: NetworkRequestConfiguration.Methods.GET,
    successCodes: [200],
  });
}
