import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

export const RECAPTCHA_TERMS_OF_SERVICE_URL = 'https://policies.google.com/terms';

export function RecaptchaTermsOfServiceLink({ styles }) {
  return (
    <a
      css={styles}
      href={RECAPTCHA_TERMS_OF_SERVICE_URL}
      rel={'noopener noreferrer'}
      target={'_blank'}
    >
      Terms of Service
    </a>
  );
}

RecaptchaTermsOfServiceLink.propTypes = {
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};
