import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { BlogNavigation } from '@root/joinroot.com/src/components/blog/navigation-elements';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function BlogHeader({
  logo,
  logoAltText,
  activeNavItem,
  navItems,
  sectionSpacing = {},
}) {
  const navContainsAll = navItems[0].categoryTitle === 'All';

  if (!navContainsAll) {
    navItems.unshift({
      categoryTitle: 'All',
      publishingMetadata: {
        slug: '/all',
      },
    });
  }

  const sectionSpacingStyles = {
    paddingTop: sectionSpacing.top / 1.25,
    paddingBottom: sectionSpacing.bottom / 1.25,
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <header css={[styles.header, sectionSpacingStyles]}>
      <Link to={'/blog'}>
        <img
          alt={logoAltText ?? ''}
          src={logo}
        />
      </Link>
      <nav
        aria-label={'blog categories'}
        css={styles.nav}
      >
        <ul css={styles.navUl}>
          {navItems.map(({ categoryTitle, publishingMetadata }) => {
            return (
              <BlogNavigation
                css={styles.navLink}
                isActive={categoryTitle === activeNavItem}
                key={categoryTitle}
                to={`/blog/tag${publishingMetadata.slug}`}
              >
                {categoryTitle}
              </BlogNavigation>
            );
          })}
        </ul>
      </nav>
    </header>
  );
}

BlogHeader.propTypes = {
  activeNavItem: PropTypes.string.isRequired,
  logo: PropTypes.node.isRequired,
  logoAltText: PropTypes.string.isRequired,
  navItems: PropTypes.arrayOf(PropTypes.shape({
    categoryTitle: PropTypes.string.isRequired,
    publishingMetadata: PropTypes.shape({
      slug: PropTypes.string.isRequired,
    }),
  })).isRequired,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: '100px 20px',
    backgroundColor: Colors.white(),
  },
  nav: {
    marginTop: 45,
    maxWidth: 690,
  },
  navUl: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    listStyle: 'none',
    ...Responsive.sm({
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
    }),
  },
  navLink: {
    margin: '10px 16px',
    whiteSpace: 'nowrap',
  },
});
