import AvailabilityMap from '@root/joinroot.com/src/components/sections/availability-map/availability-map';
import Market from '@root/core/src/models/market';
import MarketUnavailableForm from '@root/joinroot.com/src/components/sections/availability-map/availability-form';
import Modal from '@root/gatsby-contentful-core/src/components/modal';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useGetMarketSupport from '@root/joinroot.com/src/api/use-get-market-support';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Eyebrow, H1Display, H2 } from '@root/gatsby-contentful-core/src/components/text';
import { useState } from '@root/vendor/react';

const allMarkets = Object.keys(Market.MAPPING);

export const TITLE_TYPES = {
  H1: 'H1',
  H2: 'H2',
};
export default function AvailabilityMapSection({
  anchor,
  body,
  eyebrow,
  title,
  titleType = TITLE_TYPES.H1,
  formInputText,
  footerRichTextContent,
}) {
  const [selectedMarketData, setSelectedMarketData] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);

  const allMarketData = useGetMarketSupport(allMarkets);
  const closeModal = () => setIsModalVisible(false);

  const analytics = useAnalytics('AVAILABILITY_MAP');

  const headingContainerClass = titleType.toLowerCase() === 'h1' ? 'heading1' : 'heading2';

  const EyebrowComponent = eyebrow && <Eyebrow css={styles.eyebrow}>{eyebrow}</Eyebrow>;

  return (
    <section
      css={styles.availabilityMapSection}
      data-testid={'availabilityMapSection'}
      id={anchor}
    >
      <div
        className={headingContainerClass}
        css={styles.headingContainer}
      >
        {EyebrowComponent}
        <HeadlineComponent
          title={title}
          titleType={titleType}
        />
        <SubheadingComponent subheadingRichTextContent={body} />
      </div>
      <AvailabilityMap
        allMarketData={allMarketData}
        dataTestId={'availabilityMap'}
        selectedMarketData={selectedMarketData}
        setIsModalVisible={setIsModalVisible}
        setSelectedMarketData={setSelectedMarketData}
      />
      <Modal
        isShowing={isModalVisible}
        name={'availability-map-modal'}
        onClose={closeModal}
      >
        <MarketUnavailableForm
          analytics={analytics}
          footerRichTextContent={footerRichTextContent}
          formInputText={formInputText}
          onClose={closeModal}
          selectedMarketData={selectedMarketData}
        />
      </Modal>
    </section>
  );
}

AvailabilityMapSection.propTypes = {
  anchor: PropTypes.string,
  body: PropTypes.shape({
    json: PropTypes.object,
  }),
  eyebrow: PropTypes.node,
  footerRichTextContent: PropTypes.shape({
    json: PropTypes.object,
  }),
  formInputText: PropTypes.string,
  title: PropTypes.string,
  titleType: PropTypes.oneOf(Object.values(TITLE_TYPES)),
};

const HeadlineComponent = ({ titleType, title }) => {
  const TitleComponentType = titleType === TITLE_TYPES.H1 ? H1Display : H2;
  return (
    <TitleComponentType
      css={styles.title}
      data-testid={'map-heading'}
    >
      {title}
    </TitleComponentType>);
};

HeadlineComponent.propTypes = {
  title: PropTypes.string,
  titleType: PropTypes.oneOf(Object.values(TITLE_TYPES)),
};

const SubheadingComponent = ({ subheadingRichTextContent }) => {
  return (
    <div
      css={styles.body}
      data-testid={'map-subheading'}
    >
      {renderRichText(subheadingRichTextContent)}
    </div>);
};

SubheadingComponent.propTypes = {
  subheadingRichTextContent: PropTypes.shape({
    json: PropTypes.object,
  }),
};

const styles = StyleSheet.create({
  availabilityMapSection: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '80px 20px',
    textAlign: 'center',
    ...Responsive.md({
      padding: '100px 20px',
    }),
  },
  headingContainer: {
    '&.heading1': {
      maxWidth: 335,
    },
    '&.heading2': {
      maxWidth: 250,
    },
    marginBottom: 50,
    ...Responsive.md({
      '&.heading1': {
        maxWidth: 940,
      },
      '&.heading2': {
        maxWidth: 700,
      },
    }),
  },
  eyebrow: {
    marginBottom: 15,
  },
  title: {
    margin: '0 auto 15px',
  },
  subtitle: {
    color: Colors.gray50(),
    fontSize: 18,
  },
  body: {
    marginLeft: 'auto',
    marginRight: 'auto',
    ...Responsive.lessThanSm({
      maxWidth: 320,
    }),
  },
});
