import Button, { ButtonSize } from '@root/gatsby-contentful-core/src/components/button';
import Colors, { toRgba } from '@root/gatsby-contentful-core/src/utils/colors';
import InputError from '@root/gatsby-contentful-core/src/components/form/input-error';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import S from 'string';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import styled from '@root/vendor/@emotion/styled';
import uuid from '@root/vendor/uuid/v4';
import { IntentType } from '@root/gatsby-contentful-core/src/utils/theme';

export default class TextInput extends Component {
  static propTypes = {
    disabled: PropTypes.bool,
    displayErrorBelow: PropTypes.bool,
    errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    hideCount: PropTypes.bool,
    hint: PropTypes.string,
    icon: PropTypes.object,
    id: PropTypes.string,
    intent: PropTypes.string,
    isTouched: PropTypes.bool,
    label: PropTypes.string,
    maxLength: PropTypes.number,
    name: PropTypes.string.isRequired,
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onFocus: PropTypes.func,
    placeholder: PropTypes.string,
    readOnly: PropTypes.bool,
    required: PropTypes.bool,
    size: Button.propTypes.size,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  };

  static defaultProps = {
    disabled: false,
    intent: IntentType.PRIMARY,
    onChange: () => {},
    size: ButtonSize.NORMAL,
    displayErrorBelow: true,
    isTouched: false,
    value: '',
  };

  state = {
    id: this.props.id || uuid(),
    value: this.props.value,
  };

  get errors() {
    return typeof this.props.errors === 'string'
      ? [this.props.errors]
      : this.props.errors;
  }

  get isInvalid() {
    return this.props.isTouched && this.props.errors;
  }

  get isValid() {
    return !this.isInvalid;
  }

  get label() {
    return this.props.label || this.props.placeholder;
  }

  get inputId() {
    return S(this.state.id || this.props.name).slugify().s;
  }

  get labelId() {
    return `${this.inputId}-label`;
  }

  handleChange = (event) => {
    const { value } = event.target;
    this.setState({
      value,
    });
    this.props.onChange(event);
  };

  render() {
    const {
      icon, id, intent, isTouched, label, placeholder, size, errors, value, onChange, displayErrorBelow, ...rest // eslint-disable-line no-unused-vars
    } = this.props;

    return (
      <StyledTextInput
        className={'TextInput'}
        hasIcon={!!icon}
        hasValue={!!value}
        isDisabled={this.props.disabled}
        isInvalid={this.isInvalid}
        isReadOnly={this.props.readOnly}
        isValid={this.isValid}
        size={size}
      >
        <input
          {...rest}
          aria-invalid={!!this.isInvalid}
          aria-labelledby={this.labelId}
          aria-required={!!this.props.required}
          data-testid={this.props.name}
          defaultValue={this.props.value}
          id={this.inputId}
          onChange={this.handleChange}
        />
        {this.label && (
          <label
            aria-invalid={!!this.isInvalid}
            htmlFor={this.inputId}
            id={this.labelId}
          >{this.label} {this.props.required && !this.props.value || this.isInvalid ? <span className={'highlight'}> *</span> : ''}
          </label>
        )}
        <div className={'TextInput__meta'}>
          {displayErrorBelow && this.props.isTouched && this.errors?.map((error) =>
            <InputError key={error}>{error}</InputError>
          )}
          {this.props.hint && this.errors.length === 0 && (
            <small
              className={'meta__hint'}
            >
              {this.props.hint}
            </small>
          )}
          {this.props.maxLength && !this.props.hideCount && (
            <span
              className={'meta__count'}
            >
              {`${this.props.value.length}/${this.props.maxLength}`}
            </span>
          )}
        </div>
      </StyledTextInput>
    );
  }
}

const StyledTextInput = styled.div(({
  size, intent, hasValue, isDisabled,
}) => {
  const labelTransformDefault = 'translate3d(0, 0, 0) scale3d(1, 1, 1)';
  const labelTransformActive = 'translate3d(0, ' + (size === ButtonSize.SMALL ? '-50%' : '-100%') + ', 0) scale3d(0.8, 0.8, 1)';
  const labelTransform = () => hasValue ? labelTransformActive : labelTransformDefault;
  const inputCursor = isDisabled ? 'default' : 'auto';
  let labelTypography;
  let labelTop;
  let height;
  let backgroundColor;
  let color;

  switch (size) {
  case ButtonSize.SMALL:
    height = 48;
    labelTop = 14;
    labelTypography = Typography.formNormal();
    break;
  case ButtonSize.LARGE:
    height = 72;
    labelTop = 27;
    labelTypography = Typography.formLarge();
    break;
  case ButtonSize.NORMAL:
  default:
    height = 60;
    labelTop = 22;
    labelTypography = Typography.formNormal();
    break;
  }

  switch (intent) {
  case IntentType.PRIMARY:
  default:
    backgroundColor = Colors.white();
    color = Colors.nearBlack();
  }

  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',

    '> label': {
      boxSizing: 'border-box',
      flexOrder: 0,
      ...labelTypography,
      position: 'absolute',
      whiteSpace: 'nowrap',
      top: labelTop,
      left: Sizes.NORMAL,
      textOverflow: 'ellipsis',
      color: Colors.greyDark(),
      transformOrigin: '0 0',
      transform: labelTransform(),
      pointerEvents: 'none',
      transition: [
        'transform 0.3s cubic-bezier(0.86, 0, 0.07, 1);',
      ],

      '&[aria-invalid="true"]': {
        color: Colors.rootOrange(),
      },
    },

    '> input': {
      ...labelTypography,
      appearance: 'none',
      height,
      color,
      backgroundColor,
      boxSizing: 'border-box',
      cursor: inputCursor,
      minHeight: height,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: toRgba(Colors.black(), 0.1),
      paddingTop: 10,
      paddingBottom: 0,
      paddingRight: Sizes.NORMAL,
      paddingLeft: Sizes.NORMAL,
      transition: [
        'border-color 0.15s ease-out',
        'background-color 0.15s ease-out',
      ],

      '&:not([disabled]):not([readonly])': {
        caretColor: Colors.rootOrange(),

        '&:active, &:hover, &:focus': {
          borderColor: Colors.nearBlack(),
        },

        '&:active, &:focus': {
          '+ label': {
            transform: labelTransformActive,
          },
        },

        '&[aria-invalid="true"]': {
          borderColor: Colors.invalid(),
        },
      },

      '&[readonly]': {
        borderColor: Colors.greyMedium(),
        outline: 'none',
      },

      '&[disabled]': {
        backgroundColor: Colors.greyMedium(),
        borderColor: Colors.greyMedium(),
        outline: 'none',
      },
    },

    '.TextInput__meta': {
      display: 'flex',
      flexDirection: 'row',
      marginTop: Sizes.XXSMALL,
      marginBottom: Sizes.SMALL,

      '.meta__hint': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '1 0 auto',
      },

      '.meta__count': {
        ...Typography.caption(),
        color: Colors.greyDark(),
        flex: '0 1 0%',
        marginLeft: Sizes.XXSMALL,
      },
    },
  };
});
