import BackgroundColors from '@root/gatsby-contentful-core/src/utils/background-colors';
import ImageToutSideBySideSection from '@root/gatsby-contentful-core/src/components/sections/image-tout-side-by-side/image-tout-side-by-side-section';
import ImageToutSideBySideSectionFullBleed from '@root/gatsby-contentful-core/src/components/sections/image-tout-side-by-side/image-tout-side-by-side-section-full-bleed';
import PropTypes from '@root/vendor/prop-types';
import renderRichText from '@root/gatsby-contentful-core/src/utils/render-rich-text';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useInterfaceActions from '@root/gatsby-contentful-core/src/hooks/use-interface-actions';
import { IMAGE_POSITION_X, IMAGE_SIZE, TOUT_LAYOUT_X, TOUT_LAYOUT_Y } from '@root/gatsby-contentful-core/src/components/sections/image-tout-side-by-side/tout-options';

export default function ImageToutSideBySideSectionBlock({
  content: {
    id,
    toutLayoutHorizontal,
    toutLayoutVertical,
    backgroundColor,
    imageSize,
    imageAlignmentHorizontal,
    image,
    mobileImage,
    imageAltText,
    sectionSpacing,
    eyebrow,
    headline,
    body,
    ctaButton,
    anchor,
  },
}) {
  const { trackClick } = useAnalytics(`IMAGE_TOUT_SIDE_BY_SIDE_SECTION_${id}`, false);
  const { buildCallToActionButton } = useInterfaceActions();

  if (isImageFullBleed(imageSize)) {
    return (
      <ImageToutSideBySideSectionFullBleed
        anchor={anchor?.anchorSlug}
        backgroundColor={BackgroundColors.getDefaultedString(backgroundColor)}
        image={{
          desktopImg: image.gatsbyImageData,
          mobileImg: mobileImage?.gatsbyImageData,
          imageAltText: imageAltText ?? '',
          imagePositionX: IMAGE_POSITION_X[imageAlignmentHorizontal],
        }}
        sectionSpacing={sectionSpacing}
        toutContent={{
          body: renderRichText(body),
          button: buildCallToActionButton({
            button: ctaButton,
            trackClick,
          }),
          eyebrow,
          headline,
        }}
        toutLayoutX={TOUT_LAYOUT_X[toutLayoutHorizontal]}
      />
    );
  }

  return (
    <ImageToutSideBySideSection
      anchor={anchor?.anchorSlug}
      backgroundColor={BackgroundColors.getDefaultedString(backgroundColor)}
      image={{
        desktopImg: image.gatsbyImageData,
        mobileImg: mobileImage?.gatsbyImageData,
        imageAltText: imageAltText ?? '',
      }}
      imageSize={IMAGE_SIZE[imageSize]}
      sectionSpacing={sectionSpacing}
      toutContent={{
        body: renderRichText(body),
        button: buildCallToActionButton({
          button: ctaButton,
          trackClick,
        }),
        eyebrow,
        headline,
      }}
      toutLayoutX={TOUT_LAYOUT_X[toutLayoutHorizontal]}
      toutLayoutY={TOUT_LAYOUT_Y[toutLayoutVertical]}
    />
  );
}

ImageToutSideBySideSectionBlock.propTypes = {
  content: PropTypes.shape({
    anchor: PropTypes.shape({
      id: PropTypes.string,
      anchorSlug: PropTypes.string,
    }),
    eyebrow: PropTypes.string,
    id: PropTypes.string.isRequired,
    headline: PropTypes.string,
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    backgroundColor: PropTypes.string,
    toutLayoutHorizontal: PropTypes.string,
    toutLayoutVertical: PropTypes.string,
    imageSize: PropTypes.string,
    imageAlignmentHorizontal: PropTypes.oneOf(Object.keys(IMAGE_POSITION_X)),
    body: PropTypes.object,
    image: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }).isRequired,
    mobileImage: PropTypes.shape({
      gatsbyImageData: PropTypes.object.isRequired,
    }),
    imageAltText: PropTypes.string,
    ctaButton: PropTypes.shape({
      id: PropTypes.string.isRequired,
      buttonType: PropTypes.oneOf(['Primary', 'Secondary', 'Tertiary']).isRequired,
      buttonText: PropTypes.string.isRequired,
      buttonLink: PropTypes.string.isRequired,
    }),
  }).isRequired,
};

const isImageFullBleed = (imageSize) => IMAGE_SIZE[imageSize] === 'FULL_BLEED';

