import PropTypes from '@root/vendor/prop-types';
import React, { PureComponent } from '@root/vendor/react';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import styled from '@root/vendor/@emotion/styled';
import { AlignItems, FlexContainer, FlexDirection, FlexItem } from '@root/gatsby-contentful-core/src/components/flex';

export default class Content extends PureComponent {
  static propTypes = {
    align: FlexContainer.propTypes.align,
    children: PropTypes.node,
    flex: FlexItem.propTypes.flex,
    justify: FlexContainer.propTypes.justify,
  };

  static defaultProps = {};

  render() {
    const {
      children, flex, align, justify, ...rest
    } = this.props;
    return (
      <StyledContainer
        align={align || AlignItems.CENTER}
        flexDirection={FlexDirection.COLUMN}
        justify={justify}
        {...rest}
      >
        <FlexItem flex={flex}>{children}</FlexItem>
      </StyledContainer>
    );
  }
}

const StyledContainer = styled(FlexContainer)(() => {
  return {
    '&:not(:only-child)': {
      '&:first-of-type': {
        paddingTop: Sizes.NORMAL,
      },

      '&:last-child': {
        paddingBottom: Sizes.NORMAL,
      },
    },
    '> .FlexItem': {
      width: '100%',
      paddingLeft: Sizes.NORMAL,
      paddingRight: Sizes.NORMAL,
    },
  };
});

export const PageContent = styled(Content)(({ size = 960 }) => {
  return {
    '> .FlexItem': {
      maxWidth: size,
    },
  };
});
