import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getMarketSupportConfiguration(queryParameters) {
  return new NetworkRequestConfiguration({
    endpoint: 'supported_markets/support_in_all_markets',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      ...queryParameters,
      shouldIncludeHomeownersTieIn: true,
    },
  });
}
