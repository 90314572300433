import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Typography from '@root/gatsby-contentful-core/src/utils/typography';
import styled from '@root/vendor/@emotion/styled';

export class TitleTag {
  static H1 = 'h1';
  static H2 = 'h2';
  static H3 = 'h3';
  static H4 = 'h4';
  static H5 = 'h5';
  static H6 = 'h6';
}

export class TitleSize {
  static H1 = 'h1';
  static H2 = 'h2';
  static H3 = 'h3';
  static H4 = 'h4';
  static H5 = 'h5';
  static H6 = 'h6';
  static H7 = 'h7';
}

export class TextAlign {
  static LEFT = 'left';
  static CENTER = 'center';
  static RIGHT = 'right';
  static INHERIT = 'inherit';
}

export default class Title extends Component {
  static propTypes = {
    children: PropTypes.node,
    className: PropTypes.string,
    size: PropTypes.string,
    tag: PropTypes.string,
    textAlign: PropTypes.string,
  };

  static defaultProps = {
    tag: TitleTag.H1,
  };

  get tagName() {
    switch (this.props.tag) {
    case TitleTag.H1:
    case TitleTag.H2:
    case TitleTag.H3:
    case TitleTag.H4:
    case TitleTag.H5:
    case TitleTag.H6:
      return this.props.tag;
    default:
      return TitleTag.H1;
    }
  }

  render() {
    const {
      size, className, ...rest
    } = this.props;
    return (
      <StyledTitle
        as={this.tagName}
        size={size || this.tagName}
        {...rest}
        className={`Title${className ? ` ${className}` : ''}`}
      />
    );
  }
}

const StyledTitle = styled.h1(({ size, textAlign }) => {
  let typography;
  switch (size) {
  case TitleSize.H1:
    typography = Typography.heading1();
    break;
  case TitleSize.H2:
    typography = Typography.heading2();
    break;
  case TitleSize.H3:
    typography = Typography.heading3();
    break;
  case TitleSize.H4:
    typography = Typography.heading4();
    break;
  case TitleSize.H5:
    typography = Typography.heading5();
    break;
  case TitleSize.H6:
    typography = Typography.heading6();
    break;
  case TitleSize.H7:
    typography = Typography.heading7();
    break;
  }

  return (
    {
      ...typography,
      textAlign,
    }
  );
});
