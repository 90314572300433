import PropTypes from '@root/vendor/prop-types';
import ZipCodeForm, { FORM_FLOWS } from '@root/joinroot.com/src/components/forms/zip-code-form';
import { H2, P1 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function AutoZipCodeForm({
  analytics, formBody, formFlow, formTitle, namePrefix,
}) {
  return (
    <div css={styles.formContainer}>
      <H2 css={styles.formTitle}>{formTitle}</H2>
      <P1 css={styles.formBody}>{formBody}</P1>
      <ZipCodeForm
        analytics={analytics}
        formFlow={formFlow}
        namePrefix={namePrefix}
      />
    </div>
  );
}

const styles = StyleSheet.create({
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 50,
    maxWidth: 460,
    '@media screen and (min-width: 768px)': {
      marginTop: 100,
      maxWidth: 580,
    },
  },
  formTitle: {
    marginBottom: 15,
  },
  formBody: {
    marginBottom: 15,
    '@media screen and (min-width: 768px)': {
      marginBottom: 50,
    },
  },
});

AutoZipCodeForm.propTypes = {
  analytics: PropTypes.object,
  formBody: PropTypes.string,
  formFlow: PropTypes.oneOf(Object.values(FORM_FLOWS)),
  formTitle: PropTypes.string,
  namePrefix: PropTypes.string,
};
