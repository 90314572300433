export default (
  <svg
    display={'block'}
    fill={'none'}
    height={'20'}
    viewBox={'0 0 24 20'}
    width={'24'}
    xmlns={'http://www.w3.org/2000/svg'}
  >
    <path
      clipRule={'evenodd'}
      d={'M0 8.48865V18.8621C0 19.1972 0.244207 19.4689 0.545452 19.4689H3.02478C3.32603 19.4689 3.57023 19.1972 3.57023 18.8621V16.2772H20.4297V18.8621C20.4297 19.1972 20.6739 19.4689 20.9751 19.4689H23.4544C23.7557 19.4689 23.9999 19.1972 23.9999 18.8621L23.9999 8.48865C23.9999 7.94838 23.7842 7.43601 23.4111 7.09014L22.3635 6.11895H23.4545C23.7558 6.11895 24 5.84727 24 5.51214V4.2985C24 3.96337 23.7558 3.69168 23.4545 3.69168H21.0545L19.4086 0.640006C19.2115 0.274453 18.8564 0.0507812 18.4732 0.0507812H5.52673C5.14354 0.0507812 4.78844 0.274453 4.59129 0.640006L2.94544 3.69168H0.545452C0.244207 3.69168 0 3.96337 0 4.2985V5.51214C0 5.84727 0.244207 6.11895 0.545452 6.11895H1.63636L0.588787 7.09014C0.215709 7.43601 0 7.94838 0 8.48865ZM16.909 10.3667C16.909 10.0315 17.1532 9.75986 17.4545 9.75986H21.2726C21.5739 9.75986 21.8181 10.0315 21.8181 10.3667V12.1871C21.8181 12.5223 21.5739 12.7939 21.2726 12.7939H17.4545C17.1532 12.7939 16.909 12.5223 16.909 12.1871V10.3667ZM2.72726 9.75986C2.42602 9.75986 2.18181 10.0315 2.18181 10.3667V12.1871C2.18181 12.5223 2.42602 12.7939 2.72726 12.7939H6.54543C6.84667 12.7939 7.09088 12.5223 7.09088 12.1871V10.3667C7.09088 10.0315 6.84667 9.75986 6.54543 9.75986H2.72726ZM20.1817 6.72577H3.81817L5.9034 2.50075C6.09501 2.11251 6.46152 1.87123 6.85964 1.87123H17.1402C17.5384 1.87123 17.9049 2.11251 18.0965 2.50075L20.1817 6.72577Z'}
      fillRule={'evenodd'}
    />
  </svg>
);
