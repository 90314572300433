import PropTypes from '@root/vendor/prop-types';
import React, { PureComponent } from '@root/vendor/react';
import styled from '@root/vendor/@emotion/styled';

export class FlexDirection {
  static ROW = 'row';
  static COLUMN = 'column';
}

export class AlignItems {
  static STRETCH = 'stretch';
  static START = 'flex-start';
  static CENTER = 'center';
  static END = 'flex-end';
}

export class JustifyContent {
  static STRETCH = 'stretch';
  static CENTER = 'center';
  static SPACE_AROUND = 'space-around';
  static SPACE_BETWEEN = 'space-between';
  static START = 'flex-start';
  static END = 'flex-end';
}

export class FlexPreset {
  static NONE = '0 0 auto';
  static FLEX = '1 1 0%';
  static NOGROW = '0 1 auto';
  static GROW = '1 1 100%';
  static INITIAL = '0 1 auto';
  static AUTO = '1 1 auto';
  static NOSHRINK = '1 0 auto';
  static HALF = '1 1 50%';
  static ONE_QUARTER = '1 1 25%';
  static THREE_QUARTERS = '1 1 75%';
  static ONE_THIRD = '1 1 33.333%';
  static TWO_THIRDS = '1 1 66.666%';
}

export class FlexWrap {
  static NOWRAP = 'nowrap';
  static WRAP = 'wrap';
}

export const styles = {
  NONE: {
    flex: FlexPreset.NONE,
  },
  FLEX: {
    flex: FlexPreset.FLEX,
  },
  NOGROW: {
    flex: FlexPreset.NOGROW,
  },
  GROW: {
    flex: FlexPreset.GROW,
  },
  INITIAL: {
    flex: FlexPreset.INITIAL,
  },
  AUTO: {
    flex: FlexPreset.AUTO,
  },
  NOSHRINK: {
    flex: FlexPreset.NOSHRINK,
  },
  HALF: {
    flex: FlexPreset.HALF,
    maxWidth: '50%',
  },
  ONE_QUARTER: {
    flex: FlexPreset.ONE_QUARTER,
    maxWidth: '25%',
  },
  THREE_QUARTERS: {
    flex: FlexPreset.THREE_QUARTERS,
    maxWidth: '75%',
  },
  ONE_THIRD: {
    flex: FlexPreset.ONE_THIRD,
    maxWidth: '33.333%',
  },
  TWO_THIRDS: {
    flex: FlexPreset.TWO_THIRDS,
    maxWidth: '66.666%',
  },
};

export class FlexItem extends PureComponent {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    flex: PropTypes.string,
    justify: PropTypes.string,
  };

  static defaultProps = {};
  render() {
    const { className, ...rest } = this.props;

    return (
      <StyledFlexItem
        className={`FlexItem${className ? ` ${className}` : ''}`}
        {...rest}
      />
    );
  }
}

const StyledFlexItem = styled.div(({
  align,
  flex,
  justify,
}) => {
  let maxWidth = 'none';

  switch (flex) {
  case FlexPreset.HALF:
    maxWidth = '50%';
    break;
  case FlexPreset.ONE_QUARTER:
    maxWidth = '25%';
    break;
  case FlexPreset.THREE_QUARTERS:
    maxWidth = '75%';
    break;
  case FlexPreset.ONE_THIRD:
    maxWidth = '33.333%';
    break;
  case FlexPreset.TWO_THIRDS:
    maxWidth = '66.666%';
    break;
  }

  return {
    flex,
    alignSelf: align,
    justifySelf: justify,
    boxSizing: 'border-box',
    maxWidth,
  };
});

export class FlexContainer extends PureComponent {
  static propTypes = {
    align: PropTypes.string,
    children: PropTypes.node,
    className: PropTypes.string,
    fillParent: PropTypes.bool,
    flexDirection: PropTypes.string,
    flexWrap: PropTypes.string,
    gutter: PropTypes.number,
    justify: PropTypes.string,
    tag: PropTypes.string,
  };

  static defaultProps = {
    align: AlignItems.STRETCH,
    justify: JustifyContent.STRETCH,
    flexDirection: FlexDirection.ROW,
    fillParent: false,
    tag: 'div',
  };

  render() {
    const {
      className, tag, ...rest
    } = this.props;
    return (
      <StyledFlexContainer
        as={tag}
        className={`FlexContainer${className ? ` ${className}` : ''}`}
        {...rest}
      />
    );
  }
}

const StyledFlexContainer = styled.div(({
  align,
  justify,
  flexDirection,
  flexWrap,
  fillParent,
  gutter,
}) => {
  const defaults = {
    display: 'flex',
    flexDirection,
    alignItems: align,
    flexWrap,
    justifyContent: justify,
    boxSizing: 'border-box',
    width: fillParent ? '100%' : 'auto',
    height: fillParent ? '100%' : 'auto',

    '> *': {
      boxSizing: 'border-box',
    },
  };

  if (gutter) {
    const delta = 0.5 * gutter;
    if (flexDirection.match(/row/)) {
      return {
        ...defaults,
        marginLeft: -1 * delta,
        marginRight: -1 * delta,

        '> *': {
          ...defaults['> *'],
          paddingLeft: delta,
          paddingRight: delta,
        },
      };
    }
    if (flexDirection.match(/column/)) {
      return {
        ...defaults,
        marginTop: -1 * delta,
        marginBottom: -1 * delta,

        '> *': {
          ...defaults['> *'],
          paddingTop: delta,
          paddingBottom: delta,
        },
      };
    }
  }

  return defaults;
});
