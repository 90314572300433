import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import Responsive from '@root/core/src/utils/responsive';
import { regular, semiBold } from '@root/brand/src/assets/fonts/root-sans/emotion';

export const H1DisplayStyles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: 42,
  letterSpacing: '-0.02em',
  lineHeight: '110%',

  ...Responsive.md({
    fontSize: 66,
    lineHeight: '100%',
  }),
};

export const H2Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: 28,
  letterSpacing: '-0.01em',
  lineHeight: '110%',

  ...Responsive.md({
    fontSize: 32,
  }),
};

export const H3Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: 25,
  letterSpacing: '-0.01em',
  lineHeight: '110%',

  ...Responsive.md({
    fontSize: 28,
  }),
};

export const H4Styles = {
  ...semiBold(),
  color: Colors.nearBlack(),
  fontSize: 22,
  letterSpacing: '-0.01em',
  lineHeight: '130%',

  ...Responsive.md({
    fontSize: 25,
  }),
};

export const H5Styles = {
  ...regular(),
  color: Colors.nearBlack(),
  fontSize: 20,
  letterSpacing: '-0.015em',
  lineHeight: '130%',
};

export const SubheadStyles = {
  color: Colors.grayDark(),
};

export const P1Styles = {
  ...regular(),
  whiteSpace: 'pre-wrap',
  color: Colors.grayMedium(),
  fontSize: 18,
  letterSpacing: '-0.015em',
  lineHeight: '130%',
};

export const P2Styles = {
  ...regular(),
  whiteSpace: 'pre-wrap',
  color: Colors.grayMedium(),
  fontSize: 14,
  letterSpacing: '-0.015em',
  lineHeight: '130%',
};

const linkActionColors = {
  rootOrangePressed: () => '#F08E65',
  rootOrangeDisabled: () => '#DADADA',
};

export const LinkStyles = {
  ...regular(),
  color: Colors.nearBlack(),
  cursor: 'pointer',
  fontSize: 18,
  letterSpacing: '-0.015em',
  lineHeight: '130%',
  textDecoration: 'underline',
  transition: '150ms ease-in-out',

  '&:hover': {
    color: Colors.rootOrange(),
  },

  '&:focus': {},

  '&:active': {
    color: linkActionColors.rootOrangePressed(),
  },
};

export const ULStyles = {
  color: Colors.nearBlack(),
  paddingLeft: 28,
  textAlign: 'left',

  '& li p': {
    marginLeft: 4,
  },
};

export const OLStyles = {
  color: Colors.nearBlack(),
  paddingLeft: 28,
  textAlign: 'left',

  '& li p': {
    marginLeft: 10,
  },
};

export const LIStyles = {
  '& p': {
    margin: '10px 0',
  },
};

export const BlockquoteStyles = {
  margin: '50px 0',
  padding: '0 0 0 20px',
  borderLeft: `1px solid ${Colors.rootOrange()}`,

  '& p:first-of-type': {
    color: Colors.nearBlack(),
  },
};
