import BlogPostPreviewTile from '@root/joinroot.com/src/components/blog/blog-post-preview-tile';
import CategorySectionSliding from '@root/joinroot.com/src/components/blog/category-section-sliding';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import { getImage } from 'gatsby-plugin-image';
export default function BlogRelatedPostsSectionBlock({ content }) {
  return (
    <div data-testid={'blogRelatedPostsSectionBlock'}>
      <CategorySectionSliding
        sectionSpacing={content.sectionSpacing}
        sectionTitle={'Related Posts'}
      >
        {content.relatedPosts.map(({
          previewTitle,
          previewBody,
          previewImage: { gatsbyImageData },
          imageAltText,
          customDateCreated,
          publishingMetadata: { slug },
          blogCategory,
        }) => {
          return (
            <BlogPostPreviewTile
              categorySlug={blogCategory[0].publishingMetadata.slug}
              categoryTitle={blogCategory[0].categoryTitle}
              dateCreated={customDateCreated}
              imageAltText={imageAltText ?? ''}
              key={slug}
              layout={TileLayoutModel.SLIDING}
              previewBody={previewBody}
              previewImage={getImage(gatsbyImageData)}
              previewTitle={previewTitle}
              slug={slug}
            />
          );
        })}
      </CategorySectionSliding>
    </div>
  );
}

BlogRelatedPostsSectionBlock.propTypes = {
  content: PropTypes.shape({
    sectionSpacing: PropTypes.shape({
      bottom: PropTypes.number,
      top: PropTypes.number,
    }),
    relatedPosts: PropTypes.arrayOf(
      PropTypes.shape({
        publishingMetadata: PropTypes.shape({
          slug: PropTypes.string.isRequired,
        }).isRequired,
        blogCategory: PropTypes.arrayOf(
          PropTypes.shape({
            categoryTitle: PropTypes.string.isRequired,
            publishingMetadata: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }).isRequired,
          }).isRequired,
        ).isRequired,
        previewTitle: PropTypes.string.isRequired,
        previewBody: PropTypes.string.isRequired,
        previewImage: PropTypes.shape({
          gatsbyImageData: PropTypes.object.isRequired,
        }).isRequired,
        imageAltText: PropTypes.string,
        customDateCreated: PropTypes.string,
      }).isRequired,
    ).isRequired,
  }).isRequired,
};

