import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getMarketSupportByMarketConfiguration(queryParameters) {
  return new NetworkRequestConfiguration({
    endpoint: 'supported_markets/support_by_market',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      ...queryParameters,
      shouldIncludeHomeownersTieIn: true,
    },
  });
}
