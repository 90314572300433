import Button, { ButtonType } from '@root/gatsby-contentful-core/src/components/button';
import CaretLoader from '@root/core/src/components/caret-loader';
import OverlayModal from '@root/core/src/components/overlay-modal';
import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function PrivacyActRequestFormConfirmation({
  deleteData, onCancelPress, onConfirmationPress, submitting,
}) {
  const getBodyText = () => {
    if (deleteData) {
      return 'You are requesting that Root Insurance Company delete any personal information collected from you or from third parties about you with the understanding that certain information cannot be deleted for legal reasons.';
    }

    return 'You are opting out of Root Insurance Company’s practice of sharing personal information collected for legal or business purposes.';
  };

  const renderSpinner = () => {
    if (!submitting) { return null; }

    return (
      <div css={styles.spinnerContainer}>
        <div css={styles.spinner}>
          <CaretLoader />
        </div>
      </div>
    );
  };

  return (
    <OverlayModal
      onCancel={onCancelPress}
      visible={true}
    >
      <div css={styles.modalContent}>
        <h5 css={styles.modalHeading}>Are you sure you want to continue?</h5>
        <p css={styles.modalText}>{getBodyText()}</p>
        <div css={styles.buttonContainer} >
          <Button
            css={[styles.modalButton, styles.cancelButton]}
            onClick={onCancelPress}
            type={ButtonType.BUTTON}
          >
            Cancel
          </Button>
          <Button
            css={styles.modalButton}
            onClick={onConfirmationPress}
            type={ButtonType.BUTTON}
          >
            Submit
          </Button>
        </div>
        {renderSpinner()}
      </div>
    </OverlayModal>
  );
}

const styles = StyleSheet.create({
  modalContent: {
    margin: '0 5px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.white(),
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 300,
    minHeight: 400,
    maxWidth: 715,
    ...Responsive.md({
      minWidth: 600,
    }),
    ...Responsive.lg({
      minWidth: 715,
    }),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    width: '100%',
    marginTop: 35,
    marginBottom: 15,
  },
  modalHeading: {
    ...Theme.cardHeading(),
    width: '80%',
    textAlign: 'center',
    marginTop: 15,
    marginBottom: 15,
  },
  modalText: {
    ...Theme.bodyCopy(),
    width: '80%',
    textAlign: 'center',
  },
  cancelButton: {
    backgroundColor: Colors.white(),
    color: Colors.rootOrange(),
    borderColor: Colors.rootOrange(),
    borderWidth: 2,
  },
  modalButton: {
    paddingLeft: 75,
    paddingRight: 75,
    margin: 10,
  },
  spinnerContainer: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: Colors.DEPRECATED_transparentWhite(),
    width: '100%',
    height: '100%',
    borderRadius: 10,
  },
  spinner: {
    margin: 0,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
});

PrivacyActRequestFormConfirmation.propTypes = {
  deleteData: PropTypes.bool.isRequired,
  onCancelPress: PropTypes.func.isRequired,
  onConfirmationPress: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
};
