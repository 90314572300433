import BodyCopy from '@root/gatsby-contentful-core/src/components/body-copy';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import useVisibilityAnalytics from '@root/core/src/hooks/use-visibility-analytics';
import { Eyebrow, H3 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function IconTout({
  body,
  button: ButtonComponent,
  className,
  eyebrow,
  form,
  icon,
  iconAltText,
  title,
  id,
}) {
  const { nodeRef } = useVisibilityAnalytics(`ICON_TOUT_${id}`);

  return (
    <div
      className={className}
      css={[baseStyles.iconTout, gridStyles.iconTout]}
      ref={nodeRef}
    >
      {icon &&
        <div css={baseStyles.iconContainer}>
          <img
            alt={iconAltText ?? ''}
            css={baseStyles.icon}
            loading={'lazy'}
            src={icon}
          />
        </div>
      }
      <div css={baseStyles.textContainer}>
        {eyebrow && <Eyebrow css={baseStyles.eyebrow}>{eyebrow}</Eyebrow>}
        {title && <H3 css={baseStyles.title}>{title}</H3>}
        <BodyCopy
          content={body}
          css={baseStyles.body}
        />
        {ButtonComponent && <ButtonComponent />}
        {!ButtonComponent && form}
      </div>
    </div>
  );
}

const baseStyles = StyleSheet.create({
  iconTout: {
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 360,
    '& + &': {
      marginTop: 50,
    },
    '@media screen and (min-width: 768px)': {
      maxWidth: 480,
      '.two-touts &': {
        maxWidth: 470,
        marginTop: 0,
        paddingTop: 30,
        paddingBottom: 30,
      },
      '.two-touts &:first-of-type': {
        paddingRight: 65,
        borderRight: `2px solid ${Colors.grayMedium(.5)}`,
      },
      '.two-touts &:last-of-type': {
        paddingLeft: 65,
      },
      '.four-touts &': {
        maxWidth: 340,
        marginTop: 0,
      },
      '.four-touts &:nth-of-type(-n + 2)': {
        marginBottom: 50,
      },
      '.three-touts &, .five-touts &, .six-touts &': {
        width: '49%',
        marginTop: 0,
        padding: '0px 50px 80px',
      },
      '.three-touts &:nth-of-type(3), .five-touts &:nth-of-type(n + 4), .six-touts &:nth-of-type(n + 4)': {
        paddingBottom: 0,
      },
    },
    '@media screen and (min-width: 1280px)': {
      maxWidth: 700,
      '.two-touts &:first-of-type': {
        paddingRight: 130,
      },
      '.two-touts &:last-of-type': {
        paddingLeft: 130,
      },
      '.three-touts &': {
        paddingBottom: 0,
      },
      '.three-touts &, .five-touts &, .six-touts &': {
        width: '33.3%',
        maxWidth: 450,
      },
    },
  },
  iconContainer: {
    marginBottom: 25,
    '.icon-size-small &': {
      width: 48,
      height: 48,
    },
    '.icon-size-medium &': {
      width: 70,
      height: 70,
    },
    '.icon-size-large &': {
      width: 100,
      height: 100,
    },
  },
  icon: {
    width: '100%',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 300,
    '& > * + *:not(form)': { marginTop: 15 },
  },
  body: {
    marginBottom: 20,
  },
});

const gridStyles = StyleSheet.create({
  iconTout: {
    '@media screen and (min-width: 768px)': {
      '.six-touts.with-grid &': {
        padding: '40px 50px',
        alignSelf: 'stretch',
      },
      '.with-grid &:nth-of-type(2n-1)': {
        borderRight: `1px solid ${Colors.grayMedium()}`,
      },
      '.with-grid &:nth-of-type(-n+4)': {
        borderBottom: `1px solid ${Colors.grayMedium()}`,
      },
    },
    '@media screen and (min-width: 1280px)': {
      '.with-grid &:nth-of-type(2n-1), .with-grid &:nth-of-type(-n+4)': {
        border: 'none',
      },
      '.with-grid &:nth-of-type(n)': {
        borderRight: `1px solid ${Colors.grayMedium()}`,
      },
      '.with-grid &:nth-of-type(-n+3)': {
        borderBottom: `1px solid ${Colors.grayMedium()}`,
      },
      '.with-grid &:nth-of-type(3n)': {
        borderRight: 'none',
      },
    },
  },
});

IconTout.propTypes = {
  body: PropTypes.node,
  button: PropTypes.func,
  className: PropTypes.string,
  eyebrow: PropTypes.node,
  form: PropTypes.node,
  icon: PropTypes.string,
  iconAltText: PropTypes.string,
  id: PropTypes.string.isRequired,
  title: PropTypes.node,
};
