import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default class BackgroundColors {
  static backgroundStyles = StyleSheet.create({
    WHITE: {
      backgroundColor: Colors.white(),
    },
    OFF_WHITE: {
      backgroundColor: Colors.nearWhite(),
    },
  })

  static cmsValues = {
    WHITE: 'white',
    OFF_WHITE: 'offWhite',
  }

  static cmsValueList = Object.values(BackgroundColors.cmsValues);

  static getDefaultedString(colorString) {
    return colorString?.trim().toLowerCase().includes('off')
      ? BackgroundColors.cmsValues.OFF_WHITE
      : BackgroundColors.cmsValues.WHITE;
  }

  static getBackgroundStyle(colorString) {
    return colorString === BackgroundColors.cmsValues.OFF_WHITE
      ? BackgroundColors.backgroundStyles.OFF_WHITE
      : BackgroundColors.backgroundStyles.WHITE;
  }
}
