import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function FooterLinksColumn({ children, columnStyles }) {
  return (
    <div css={[styles.column, columnStyles]}>
      {children}
    </div>)
  ;
}

const styles = StyleSheet.create({
  column: {
    marginBottom: 30,
    ...Responsive.md({
      flexGrow: 1,
      marginBottom: 0,
    }),
  },
});

FooterLinksColumn.propTypes = {
  children: PropTypes.node.isRequired,
  columnStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};
