import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import Theme from '@root/gatsby-contentful-core/src/components/brand/theme';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';

export default function FooterLink({
  linkText,
  url,
  isDarkMode,
}) {
  return (
    <Link
      css={[
        styles.link,
        isDarkMode && linkWhenDarkMode,
      ]}
      to={url}
    >
      {linkText}
    </Link>
  );
}

FooterLink.propTypes = {
  isDarkMode: PropTypes.bool,
  linkText: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

const styles = StyleSheet.create({
  link: {
    ...Theme.bodyRegular(),
    display: 'block',
    transition: 'color 150ms cubic-bezier(0.455, 0.03, 0.515, 0.955);',
    lineHeight: '29px',
    textDecoration: 'none',
    color: Colors.rootOrange(),
    ':hover': {
      color: Colors.black(),
    },
    ':focus': {
      color: Colors.black(),
    },
    '.active': {
      color: Colors.black(),
    },
    ...Responsive.md({
      fontSize: 15,
    }),
  },
});

const linkWhenDarkMode = {
  color: Colors.white(),
  ':hover': {
    color: Colors.rootOrange(),
  },
  ':focus': {
    color: Colors.rootOrange(),
  },
  '.active': {
    color: Colors.rootOrange(),
  },
};
