import ArrowThinIcon from '@root/joinroot.com/src/components/icons/arrow-thin-icon';
import Link from '@root/gatsby-contentful-core/src/components/smart-link';
import PropTypes from '@root/vendor/prop-types';
import { Colors, StyleSheet, Theme } from '@root/core/src/utils/styles';

export default function NavMenuArrowLink({ link, title }) {
  return (
    <li css={styles.item}>
      <Link
        css={styles.container}
        to={link}
      >
        <p css={styles.text}>{title}</p>
        <ArrowThinIcon iconStyles={styles.arrowIcon} />
      </Link>
    </li>
  );
}

const styles = StyleSheet.create({
  item: {
    listStyle: 'none',
    borderBottom: `1px solid ${Colors.gray20()}`,
    margin: 0,
    overflow: 'hidden',
    width: '100%',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 0',
    cursor: 'pointer',
    '.is-open &': {
      color: Colors.gray50(),
    },
    '&:hover': {
      textDecoration: 'none',
    },
    '&:hover p': {
      color: Colors.rootOrange(),
    },
    '&:hover svg': {
      stroke: Colors.rootOrange(),
    },
  },
  text: {
    ...Theme.listHeader(),
    color: Colors.black(),
    fontWeight: 'normal',
    marginBottom: 0,
    transition: 'color 300ms cubic-bezier(.37, 0, .28, 1)',
  },
  arrowIcon: {
    stroke: Colors.gray20(),
    width: 20,
    strokeWidth: 10,
    transform: 'rotate(-90deg)',
    transition: 'all 300ms cubic-bezier(.37, 0, .28, 1)',
    '.is-open &': {
      stroke: Colors.gray50(),
      transform: 'rotate(0)',
    },
  },
});

NavMenuArrowLink.propTypes = {
  link: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};
