import LinkButton from '@root/gatsby-contentful-core/src/components/buttons/link-button';
import LinkButtonModel from '@root/gatsby-contentful-core/src/models/link-button';
import PropTypes from '@root/vendor/prop-types';
import React, { useEffect, useRef, useState } from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import TileLayoutModel from '@root/joinroot.com/src/models/tile-layout';
import { Colors, StyleSheet, styled } from '@root/core/src/utils/styles';
import { H2 } from '@root/gatsby-contentful-core/src/components/text';
import { IconChevron } from '@root/core/src/components/icons/chevron';
import { isDesktopBrowser } from '@root/core/src/utils/detect-mobile-browser';

const isBrowser = typeof window !== 'undefined';

export default function CategorySectionSliding({
  sectionTitle,
  viewAllButtonSlug,
  children,
  backgroundColor = Colors.white(),
  sectionSpacing = {},
}) {
  const tileLayoutRef = useRef();
  const tileCount = children.length;
  const [browserSupportsScrollBehaviorSmooth, setBrowserSupportsScrollBehaviorSmooth] = useState(false);
  const displaySliderButtons = isDesktopBrowser() && tileCount > 1 && browserSupportsScrollBehaviorSmooth;
  const [displayLeftButton, setDisplayLeftButton] = useState(false);
  const [displayRightButton, setDisplayRightButton] = useState(true);

  useEffect(() => {
    if (!tileLayoutRef.current) { return; }

    // Detect feature support for `scroll-behavior: smooth`. Currently supported in all major browsers except Safari.
    if (getComputedStyle(tileLayoutRef.current).scrollBehavior === 'smooth') {
      setBrowserSupportsScrollBehaviorSmooth(true);
    }
  }, []);

  useEffect(() => {
    if (!isBrowser) { return; }

    let isScrolling;

    const scrollEnd = () => {
      window.clearTimeout(isScrolling); // Clear our timeout throughout the scroll
      isScrolling = setTimeout(handleSliderButtonVisibility, 66); // Set a timeout to run after scrolling ends
    };

    tileLayoutRef.current.addEventListener('scroll', scrollEnd);

    const tileLayoutRefCurrent = tileLayoutRef.current;
    return () => tileLayoutRefCurrent.removeEventListener('scroll', scrollEnd);
  }, []);

  const handleSliderButtonVisibility = () => {
    const fullScrollableWidth = tileLayoutRef.current.scrollWidth;
    const leftEdgePosition = tileLayoutRef.current.scrollLeft;
    const rightEdgePosition = tileLayoutRef.current.scrollLeft + window.innerWidth;

    leftEdgePosition === 0 ? setDisplayLeftButton(false) : setDisplayLeftButton(true);
    rightEdgePosition === fullScrollableWidth ? setDisplayRightButton(false) : setDisplayRightButton(true);
  };

  const slideX = (direction) => {
    const fullScrollableWidth = tileLayoutRef.current.scrollWidth;
    const slideDistance = fullScrollableWidth / tileCount / 2;

    direction === 'left'
      ? tileLayoutRef.current.scrollLeft -= slideDistance
      : tileLayoutRef.current.scrollLeft += slideDistance;
  };

  return (
    <Section
      backgroundColor={backgroundColor}
      data-testid={'category-section-sliding'}
      sectionSpacing={sectionSpacing}
    >
      {sectionTitle && (
        <H2
          css={styles.sectionTitle}
          data-testid={'section-title'}
        >
          {sectionTitle}
        </H2>
      )}
      <div css={styles.tileLayoutContainer}>
        <TileLayout
          data-testid={'tile-layout'}
          ref={tileLayoutRef}
          tileCount={tileCount}
        >
          {children}
          {tileCount > 1 && <div css={styles.extraSliderMargin} />}
        </TileLayout>
        {displaySliderButtons && (
          <>
            <SliderButton
              aria-label={'button scrolls through related blog posts – left'}
              buttonDirection={'left'}
              displayButton={displayLeftButton}
              onClick={() => slideX('left')}
            >
              <IconChevron
                color={Colors.nearBlack()}
                direction={'left'}
                iconStyles={styles.iconChevron}
              />
            </SliderButton>
            <SliderButton
              aria-label={'button scrolls through related blog posts – right'}
              buttonDirection={'right'}
              displayButton={displayRightButton}
              onClick={() => slideX('right')}
            >
              <IconChevron
                color={Colors.nearBlack()}
                direction={'right'}
                iconStyles={styles.iconChevron}
              />
            </SliderButton>
          </>
        )}
      </div>
      {viewAllButtonSlug && (
        <LinkButton
          buttonStyles={styles.viewAllButtonContainer}
          to={viewAllButtonSlug}
          type={LinkButtonModel.types.SECONDARY}
        >
          View all
        </LinkButton>
      )}
    </Section>
  );
}

CategorySectionSliding.propTypes = {
  backgroundColor: PropTypes.oneOf([Colors.white(), Colors.nearWhite()]),
  children: PropTypes.node.isRequired,
  layout: PropTypes.oneOf(Object.values(TileLayoutModel)),
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
  sectionTitle: PropTypes.string,
  viewAllButtonSlug: PropTypes.string,
};

const Section = styled.section({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
}, ({ backgroundColor, sectionSpacing }) => {
  const sectionSpacingStyles = {
    ...Responsive.lessThanMd({
      paddingTop: sectionSpacing.top / 1.25,
      paddingBottom: sectionSpacing.bottom / 1.25,
    }),
    paddingTop: sectionSpacing.top,
    paddingBottom: sectionSpacing.bottom,
  };

  return {
    ...sectionSpacingStyles,
    backgroundColor,
  };
});

const TileLayout = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '& > .tile': {
    width: '100%',
    maxWidth: 460,
    padding: '0 10px',
  },
}, ({ tileCount }) => {
  if (tileCount <= 1) { return null; }

  return {
    padding: '0 24px',
    justifyContent: 'flex-start',
    scrollBehavior: 'smooth',
    scrollSnapType: 'x mandatory',
    overflowX: 'scroll',
    webkitOverflowScrolling: 'touch',
    // Next 3 properties hide the scrollbar: Every browser is different here...
    '&::-webkit-scrollbar': { display: 'none' },
    msOverflowStyle: 'none', // Not currently supported by the parser used in Emotion
    scrollbarWidth: 'none', // Current Firefox solution and likely the future web default solution
    ...Responsive.lg({
      justifyContent: 'center',
      padding: 0,
    }),
    '& > .tile': {
      padding: '0 6px',
      flexShrink: 0,
      userSelect: 'none',
      scrollSnapAlign: 'center',
      ...Responsive.lg({
        padding: '0 10px',
        flex: 1,
      }),
    },
  };
});

const SliderButton = styled.button({
  position: 'absolute',
  top: 'calc(50% - 25px)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 50,
  height: 50,
  padding: 0,
  appearance: 'none',
  backgroundColor: Colors.toRgba(Colors.white(), 0.9),
  border: 'none',
  borderRadius: 999,
  boxShadow: `0 8px 30px -4px ${Colors.toRgba(Colors.black(), 0.5)}`,
  cursor: 'pointer',
  transition: 'transform 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275)',
  ...Responsive.lg({
    display: 'none',
  }),
  '& > svg path': {
    strokeWidth: 2,
  },
}, ({ buttonDirection, displayButton }) => {
  return {
    ...buttonDirection === 'left'
      ? {
        left: 10,
        paddingRight: 3,
        transform: displayButton ? 'translateX(0)' : 'translateX(-70px)',
      } : {
        right: 10,
        paddingLeft: 3,
        transform: displayButton ? 'translateX(0)' : 'translateX(70px)',
      },
  };
});

const styles = StyleSheet.create({
  sectionTitle: {
    margin: '0 20px 40px',
    textAlign: 'center',
  },
  tileLayoutContainer: {
    position: 'relative',
    width: 'min(1440px, 100%)',
    overflowX: 'hidden',
  },
  extraSliderMargin: {
    width: '24px',
    flexShrink: 0,
    ...Responsive.lg({
      display: 'none',
    }),
  },
  viewAllButtonContainer: {
    margin: '60px 0 0',
  },
});
