export default function YahooSportsLogo() {
  return (
    <svg
      aria-labelledby={'yahooSportsTitle'}
      fill={'none'}
      height={'30'}
      role={'img'}
      viewBox={'0 0 204 30'}
      width={'204'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <title id={'yahooSportsTitle'}>Yahoo Sports logo</title>
      <path
        d={'M204.001 19.1805C202.691 22.8461 200.727 24.215 196.711 24.2478C193.966 24.2707 191.365 23.7232 188.91 22.2412C189.604 20.8199 190.282 19.4379 191.004 17.9642L191.745 18.3051C193.29 19.0281 194.892 19.5281 196.618 19.515C196.916 19.5302 197.214 19.4816 197.492 19.3723C197.603 19.3143 197.701 19.2333 197.779 19.1347C197.857 19.0361 197.914 18.9221 197.945 18.8002C197.968 18.6363 197.74 18.3314 197.56 18.2658C196.924 18.0346 196.251 17.8937 195.606 17.6937C194.593 17.3838 193.562 17.1166 192.583 16.7232C188.529 15.0937 189.08 9.94448 191.825 8.12645C193.542 6.98874 195.444 6.67071 197.439 6.75432C199.618 6.84612 201.668 7.43956 203.714 8.47727C203.105 9.90513 202.505 11.315 201.976 12.5592C200.308 12.1674 198.74 11.7871 197.164 11.4396C196.869 11.3747 196.563 11.3894 196.275 11.4822C196.03 11.5707 195.707 11.7953 195.67 12.0068C195.639 12.1805 195.932 12.5363 196.151 12.6117C197.074 12.9314 198.041 13.1314 198.961 13.4609C199.91 13.7932 200.835 14.1886 201.731 14.6445C203.019 15.3166 203.683 16.4838 204.001 17.8691V19.1805Z'}
        fill={'#ddd'}
      />
      <path
        d={'M177.731 12.0296H175.657V7.2017H177.806V2.05908H183.815V7.16072H187.743C187.743 8.72138 187.761 10.187 187.713 11.6509C187.713 11.7853 187.298 12.0066 187.059 12.0165C186.029 12.0558 184.996 12.0329 183.906 12.0329C183.906 13.8361 183.826 15.5788 183.936 17.305C184 18.3312 184.782 18.8394 185.928 18.8247C186.492 18.8247 187.055 18.6951 187.728 18.6099C187.728 20.1902 187.741 21.7607 187.707 23.3279C187.707 23.482 187.425 23.7115 187.235 23.7656C185.396 24.2804 183.533 24.446 181.671 23.8951C179.306 23.1968 177.888 21.1443 177.828 18.3214C177.783 16.3017 177.801 14.282 177.79 12.2558C177.776 12.1791 177.756 12.1035 177.731 12.0296V12.0296Z'}
        fill={'#ddd'}
      />
      <path
        d={'M162.731 7.23625C162.985 7.21822 163.173 7.19199 163.362 7.19199C165.077 7.19199 166.799 7.19199 168.614 7.19199V9.37232C169.954 7.4051 171.673 6.52642 174.027 6.86412V13.0297C173.581 12.992 173.161 12.9297 172.742 12.9231C170.562 12.8871 169.09 14.0559 168.814 16.2641C168.62 17.8526 168.679 19.474 168.65 21.0805C168.63 21.9461 168.65 22.8133 168.65 23.7281H162.731V7.23625Z'}
        fill={'#ddd'}
      />
      <path
        d={'M160.962 15.5489C160.943 20.544 157.046 24.2686 151.859 24.2506C146.705 24.2309 142.806 20.4375 142.819 15.4522C142.832 10.4408 146.759 6.70469 151.996 6.72272C157.16 6.73911 160.982 10.5014 160.962 15.5489ZM148.635 15.467C148.628 15.9051 148.71 16.3401 148.875 16.7461C149.039 17.1522 149.283 17.5211 149.593 17.831C149.902 18.1409 150.27 18.3856 150.675 18.5506C151.081 18.7156 151.515 18.7975 151.952 18.7916C152.607 18.7903 153.246 18.5949 153.789 18.23C154.333 17.8652 154.756 17.3473 155.006 16.7417C155.256 16.136 155.321 15.4698 155.193 14.8272C155.065 14.1845 154.75 13.5942 154.288 13.1307C153.825 12.6672 153.236 12.3514 152.594 12.223C151.953 12.0947 151.288 12.1596 150.683 12.4095C150.078 12.6595 149.561 13.0833 149.196 13.6274C148.832 14.1716 148.636 14.8117 148.635 15.467Z'}
        fill={'#ddd'}
      />
      <path
        d={'M122.655 29.9999C122.664 22.7605 122.672 15.5212 122.678 8.28183V7.25233H128.685V8.59823C129.767 8.0261 130.769 7.23429 131.896 6.95888C134.859 6.22282 137.406 7.14085 139.343 9.5097C142.095 12.872 141.99 18.349 139.18 21.654C137.252 23.9163 134.145 24.831 131.405 23.9146C130.916 23.7474 130.448 23.5206 130.014 23.2392C129.588 22.9654 129.222 22.5966 128.716 22.1736V29.9999H122.655ZM131.985 12.0884C130.047 12.0884 128.548 13.5638 128.533 15.4671C128.52 16.1499 128.711 16.8211 129.08 17.3952C129.449 17.9694 129.98 18.4206 130.606 18.6914C131.232 18.9622 131.925 19.0404 132.595 18.9161C133.266 18.7917 133.884 18.4705 134.372 17.9931C134.859 17.5158 135.194 16.904 135.334 16.2355C135.473 15.567 135.411 14.872 135.155 14.239C134.899 13.6059 134.461 13.0635 133.897 12.6805C133.332 12.2975 132.667 12.0914 131.985 12.0884V12.0884Z'}
        fill={'#ddd'}
      />
      <path
        d={'M120.759 8.52433L118.99 12.6768C117.782 12.2965 116.615 11.8768 115.419 11.5719C114.808 11.4339 114.178 11.4012 113.556 11.4752C113.247 11.5047 112.967 11.8309 112.672 12.0227C112.906 12.2473 113.103 12.5784 113.379 12.6784C114.361 13.0194 115.365 13.2571 116.348 13.5752C116.972 13.7707 117.581 14.009 118.172 14.2883C119.789 15.0768 120.923 16.2407 121.013 18.1604C121.114 20.2801 120.298 21.9424 118.481 23.0522C116.739 24.1145 114.795 24.3358 112.806 24.2342C110.41 24.1129 108.127 23.567 105.979 22.2342L107.994 18.1358C109.579 18.5948 111.047 19.0686 112.543 19.4276C113.132 19.5686 113.793 19.5112 114.404 19.4276C114.646 19.3932 114.95 19.0588 115.016 18.8063C115.055 18.6555 114.725 18.2866 114.495 18.2096C113.697 17.944 112.859 17.785 112.057 17.5407C111.193 17.2986 110.344 17.0074 109.513 16.6686C105.687 15.0129 106.24 10.1784 108.569 8.38007C110.206 7.11285 112.098 6.74072 114.099 6.74072C116.288 6.74892 118.99 7.47023 120.759 8.52433Z'}
        fill={'#ddd'}
      />
      <path
        d={'M96.5064 24.0817C96.0145 24.09 95.5259 23.9997 95.0694 23.8161C94.6128 23.6324 94.1976 23.3592 93.848 23.0125C93.4985 22.6657 93.2217 22.2525 93.034 21.797C92.8463 21.3416 92.7514 20.8531 92.755 20.3603C92.7614 19.3372 93.1688 18.3575 93.8894 17.6323C94.6101 16.9071 95.5863 16.4945 96.6078 16.4833C98.7356 16.4538 100.382 18.1128 100.372 20.2735C100.362 22.4341 98.6832 24.0833 96.5064 24.0817Z'}
        fill={'white'}
      />
      <path
        d={'M107.924 0C106.009 4.60492 104.068 9.2 102.195 13.8246C101.855 14.6689 101.459 14.9721 100.542 14.9377C98.7285 14.8541 96.9019 14.918 94.9297 14.918C96.9985 9.86066 99.0215 4.93115 101.049 0H107.924Z'}
        fill={'white'}
      />
      <path
        d={'M91.9301 15.6031C91.9301 20.5818 88.1804 24.2441 83.1098 24.2326C78.1112 24.2195 74.3926 20.5277 74.3975 15.5785C74.3975 10.6605 78.193 6.94737 83.1752 6.97032C88.1918 6.99327 91.9367 10.6834 91.9301 15.6031ZM83.2358 12.2506C81.3012 12.2326 79.8511 13.6375 79.8183 15.5621C79.8054 16.0079 79.8815 16.4518 80.042 16.8679C80.2026 17.2839 80.4444 17.6636 80.7533 17.9848C81.0622 18.3061 81.432 18.5623 81.841 18.7386C82.2501 18.9149 82.6901 19.0077 83.1354 19.0115C83.5807 19.0153 84.0222 18.9301 84.4342 18.7608C84.8462 18.5916 85.2203 18.3417 85.5347 18.0258C85.849 17.7099 86.0973 17.3344 86.2649 16.9212C86.4325 16.508 86.5162 16.0655 86.5109 15.6195C86.5211 15.1817 86.4441 14.7463 86.2842 14.3388C86.1244 13.9312 85.8849 13.5597 85.5799 13.246C85.2749 12.9322 84.9105 12.6826 84.508 12.5117C84.1055 12.3408 83.673 12.2521 83.2358 12.2506V12.2506Z'}
        fill={'white'}
      />
      <path
        d={'M64.4954 24.2331C59.4854 24.2331 55.7422 20.5412 55.7422 15.6134C55.7422 10.6855 59.4919 6.9855 64.4872 6.97075C69.4825 6.95599 73.3403 10.7412 73.301 15.6593C73.2617 20.5773 69.5071 24.238 64.4954 24.2331ZM64.5183 12.2511C62.5968 12.2511 61.1679 13.7019 61.1843 15.6314C61.2007 17.5609 62.6868 19.0396 64.556 19.0068C65.4393 18.9947 66.2819 18.6332 66.9001 18.0011C67.5182 17.3691 67.8617 16.5178 67.8556 15.633C67.8671 13.7101 66.4251 12.2494 64.5183 12.2511V12.2511Z'}
        fill={'white'}
      />
      <path
        d={'M44.419 0V8.84262C44.5343 8.78928 44.6439 8.72447 44.7463 8.64918C46.5074 6.88525 48.6106 6.5918 50.8628 7.34754C53.1149 8.10328 54.2017 9.97049 54.2999 12.1869C54.4636 15.9967 54.3457 19.818 54.3457 23.7115H48.735C48.7219 23.418 48.6974 23.1279 48.6957 22.8393C48.6957 20.2443 48.6957 17.6508 48.6957 15.059C48.6946 14.6234 48.643 14.1893 48.5419 13.7656C48.3275 12.8885 47.7235 12.3689 46.9052 12.2738C46.005 12.1689 45.1539 12.5525 44.8249 13.3869C44.5799 14.019 44.4475 14.6893 44.4337 15.3672C44.3895 17.8262 44.4173 20.2852 44.4157 22.7443V23.7131H38.6904V0H44.419Z'}
        fill={'white'}
      />
      <path
        d={'M36.622 23.7229H31.2208C31.17 23.2819 31.1258 22.895 31.0571 22.336C29.9114 23.5573 28.6216 24.1393 27.1289 24.218C24.083 24.3655 21.7277 23.1016 20.1303 20.5229C17.7652 16.7131 18.5967 11.4655 21.911 8.67865C24.4496 6.5475 28.6462 6.34095 30.8083 8.62455C30.8541 8.67373 30.9441 8.68029 31.0784 8.73111L31.2011 7.45406H36.6154L36.622 23.7229ZM31.2208 15.6475C31.2322 13.6803 29.7182 12.136 27.7836 12.1508C26.8746 12.1524 26.0027 12.5125 25.3567 13.1531C24.7107 13.7937 24.3425 14.6633 24.3318 15.5737C24.3249 16.0318 24.4099 16.4866 24.5816 16.9112C24.7534 17.3359 25.0084 17.7217 25.3316 18.0459C25.6548 18.37 26.0396 18.626 26.4633 18.7986C26.887 18.9712 27.341 19.057 27.7984 19.0508C28.2485 19.0603 28.696 18.979 29.1142 18.8117C29.5324 18.6444 29.9127 18.3945 30.2324 18.077C30.5522 17.7594 30.8049 17.3807 30.9756 16.9633C31.1462 16.546 31.2313 16.0985 31.2257 15.6475H31.2208Z'}
        fill={'white'}
      />
      <path
        d={'M4.48299 30.0001C5.23753 28.2164 6.00515 26.4443 6.71058 24.6427C6.86114 24.227 6.86114 23.7715 6.71058 23.3558C4.6303 18.3509 2.5091 13.3558 0.397725 8.37054C0.283154 8.09841 0.17513 7.82628 0 7.39677C1.22591 7.39677 2.32415 7.39677 3.42403 7.39677C4.34715 7.40661 5.47649 7.123 6.13445 7.5607C6.75313 7.95906 6.87425 9.12464 7.2016 9.9607C8.10507 12.264 9.002 14.5705 9.98403 17.0787C10.1837 16.641 10.3114 16.3886 10.4178 16.1246C11.4816 13.4427 12.5537 10.7607 13.5848 8.06398C13.7845 7.54103 14.0382 7.3771 14.5914 7.3771C16.3051 7.41644 18.0285 7.39185 19.9255 7.39185C16.7683 15.0017 13.6536 22.5017 10.5389 30.0001H4.48299Z'}
        fill={'white'}
      />
    </svg>
  );
}
