import AffiliateApplicationForm from '@root/joinroot.com/src/models/affiliate-application-form';
import AnalyticsService from '@root/core/src/services/analytics-service';
import AttributionController from '@root/joinroot.com/src/components/attribution-controller';
import Button, { ButtonType } from '@root/gatsby-contentful-core/src/components/button';
import Checkbox from '@root/gatsby-contentful-core/src/components/checkbox';
import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import ConversionTrackingService from '@root/attribution/src/services/conversion-tracking-service';
import ErrorReportService from '@root/core/src/services/error-report-service';
import Fieldset from '@root/core/src/components/fieldset';
import Icon, { IconSize } from '@root/gatsby-contentful-core/src/components/icon';
import Link from '@root/gatsby-contentful-core/src/components/text/link';
import PageTemplate from '@root/joinroot.com/src/components/pages/page-template';
import PropTypes from '@root/vendor/prop-types';
import React, { Component } from '@root/vendor/react';
import Select, { SelectOption } from '@root/gatsby-contentful-core/src/components/form/select';
import Sizes from '@root/gatsby-contentful-core/src/utils/sizes';
import States from '@root/gatsby-contentful-core/src/models/states';
import TextInput from '@root/gatsby-contentful-core/src/components/form/text-input';
import Title, { TitleSize, TitleTag } from '@root/gatsby-contentful-core/src/components/text/title';
import check from 'assets/icons/check.svg';
import styled from '@root/vendor/@emotion/styled';
import {
  AlignItems,
  FlexContainer,
  FlexDirection,
  FlexItem,
  FlexPreset,
  FlexWrap,
  JustifyContent,
} from '@root/gatsby-contentful-core/src/components/flex';
import { Formik } from 'formik/dist/index';
import { IntentType } from '@root/gatsby-contentful-core/src/utils/theme';
import { PageContent } from '@root/gatsby-contentful-core/src/components/layout/content';
import { postAffiliateApplicationForm } from '@root/joinroot.com/src/third-party-api/hubspot-api';

export default class AffiliateApplicationIndexPage extends Component {
  static MIXPANEL_CONTEXT = 'AFFILIATES_APPLICATION_INDEX_PAGE';
  static ANALYTICS_PROPS = {
    eventPrefix: 'martech',
  }

  state = {
    submitSuccess: false,
  };

  componentDidMount() {
    AnalyticsService.trackViewEvent(AffiliateApplicationIndexPage.MIXPANEL_CONTEXT, AffiliateApplicationIndexPage.ANALYTICS_PROPS);
  }

  handleSubmit = (values, actions) => {
    const partnerApplication = new AffiliateApplicationForm(values);
    postAffiliateApplicationForm(partnerApplication.serialize())
      .then(() => {
        actions.setSubmitting(false);
        this.setState({
          submitSuccess: true,
        });
        AnalyticsService.trackEvent(AffiliateApplicationIndexPage.MIXPANEL_CONTEXT, 'application_form_success', AffiliateApplicationIndexPage.ANALYTICS_PROPS);
        ConversionTrackingService.trackConversion(ConversionTrackingService.CONVERSION_EVENTS.AFFILIATES_APPLICATION_SUBMITTED);
      })
      .catch((error) => {
        actions.setSubmitting(false);
        AnalyticsService.trackEvent(AffiliateApplicationIndexPage.MIXPANEL_CONTEXT, 'application_form_error', AffiliateApplicationIndexPage.ANALYTICS_PROPS);

        ErrorReportService.reportError({
          error,
          caughtAt: 'affiliates application',
          additionalData: {
            methodThatThrewException: 'handleSubmit',
          },
        });
      });
  };

  render() {
    return (
      <PageTemplate
        analyticsContext={AffiliateApplicationIndexPage.MIXPANEL_CONTEXT}
        location={this.props.location}
        pageCanonical={'https://www.joinroot.com/affiliates/application/'}
        pageTitle={'Root Affiliate Program Application'}
      >
        <AttributionController location={this.props.location} />
        <StyledPageContent>
          <section>
            <PageContent>
              <Title
                size={TitleSize.H2}
                tag={TitleTag.H1}
              >
                Root Affiliate Program Application
              </Title>
              <FlexContainer flexDirection={FlexDirection.COLUMN}>
                <FlexItem>
                  {this.state.submitSuccess
                    ? (
                      <FlexContainer
                        align={AlignItems.CENTER}
                        className={'formSuccessContainer'}
                        fillParent={true}
                        flexDirection={FlexDirection.COLUMN}
                        justify={JustifyContent.CENTER}
                      >
                        <Icon
                          icon={check}
                          size={IconSize.LARGE}
                        />
                        <p>Thanks for your interest.</p>
                        <small>We’ll be in touch soon.</small>
                      </FlexContainer>
                    )
                    : (
                      <Formik
                        initialValues={{
                          firstName: '',
                          lastName: '',
                          email: '',
                          companyName: '',
                          website: '',
                          streetAddress: '',
                          city: '',
                          state: '',
                          country: '',
                          postalCode: '',
                          phoneNumber: '',
                          confirmation: false,
                        }}
                        onSubmit={this.handleSubmit}
                        validationSchema={AffiliateApplicationForm.schema}
                      >
                        {
                          ({
                            handleSubmit, handleChange, handleBlur, isSubmitting, isValid, values, errors, touched,
                          }) => (
                            <div className={'formWrap'}>
                              <form
                                onSubmit={handleSubmit}
                              >
                                <Fieldset
                                  title={
                                    <Title
                                      size={TitleSize.H5}
                                      tag={TitleTag.H2}
                                    >
                                      Contact Information
                                    </Title>
                                  }
                                >
                                  <FlexContainer flexDirection={FlexDirection.COLUMN}>
                                    <FlexContainer
                                      flexWrap={FlexWrap.WRAP}
                                      gutter={Sizes.XSMALL}
                                    >
                                      <FlexItem flex={FlexPreset.FLEX}>
                                        <TextInput
                                          autoComplete={'given-name'}
                                          errors={errors['firstName']}
                                          isTouched={touched['firstName']}
                                          label={'First name'}
                                          name={'firstName'}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.firstName}
                                        />
                                      </FlexItem>
                                      <FlexItem flex={FlexPreset.FLEX}>
                                        <TextInput
                                          autoComplete={'family-name'}
                                          errors={errors['lastName']}
                                          isTouched={touched['lastName']}
                                          label={'Last name'}
                                          name={'lastName'}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.lastName}
                                        />
                                      </FlexItem>
                                    </FlexContainer>
                                    <FlexContainer flexDirection={FlexDirection.COLUMN}>
                                      <FlexItem>
                                        <TextInput
                                          autoComplete={'email'}
                                          errors={errors['email']}
                                          isTouched={touched['email']}
                                          label={'Email address'}
                                          name={'email'}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.email}
                                        />
                                      </FlexItem>
                                      <FlexItem>
                                        <TextInput
                                          errors={errors['emailConfirm']}
                                          isTouched={touched['emailConfirm']}
                                          label={'Email address (confirm)'}
                                          name={'emailConfirm'}
                                          onBlur={handleBlur}
                                          onChange={handleChange}
                                          value={values.emailConfirm}
                                        />
                                      </FlexItem>
                                    </FlexContainer>
                                  </FlexContainer>
                                </Fieldset>
                                <Fieldset
                                  title={
                                    <Title
                                      size={TitleSize.H5}
                                      tag={TitleTag.H2}
                                    >
                                      Company Information
                                    </Title>
                                  }
                                >
                                  <FlexContainer flexDirection={FlexDirection.COLUMN}>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'organization'}
                                        errors={errors['companyName']}
                                        isTouched={touched['companyName']}
                                        label={'Company name'}
                                        name={'companyName'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.companyName}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'url'}
                                        errors={errors['website']}
                                        isTouched={touched['website']}
                                        label={'Company Website'}
                                        name={'website'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.website}
                                      />
                                    </FlexItem>
                                  </FlexContainer>
                                  <FlexContainer flexDirection={FlexDirection.COLUMN}>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'address-line1'}
                                        errors={errors['streetAddress']}
                                        isTouched={touched['streetAddress']}
                                        label={'Street address'}
                                        name={'streetAddress'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.streetAddress}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'address-line2'}
                                        errors={errors['streetAddress2']}
                                        isTouched={touched['streetAddress2']}
                                        label={'Street address line 2'}
                                        name={'streetAddress2'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.streetAddress2}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'address-level2'}
                                        errors={errors['city']}
                                        isTouched={touched['city']}
                                        label={'City'}
                                        name={'city'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.city}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <Select
                                        autoComplete={'address-level1'}
                                        errors={errors['state']}
                                        isTouched={touched['state']}
                                        label={'State'}
                                        name={'state'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.state}
                                      >
                                        <SelectOption
                                          disabled
                                          value={''}
                                        />
                                        {
                                          States.getKeys().map((k) =>
                                            (
                                              <SelectOption
                                                key={k}
                                                value={k}
                                              >
                                                {States.getMap()[k]}
                                              </SelectOption>
                                            )
                                          )
                                        }
                                      </Select>
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'country'}
                                        errors={errors['country']}
                                        isTouched={touched['country']}
                                        label={'Country'}
                                        name={'country'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.country}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'postal-code'}
                                        errors={errors['postalCode']}
                                        isTouched={touched['postalCode']}
                                        label={'ZIP code'}
                                        name={'postalCode'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        value={values.postalCode}
                                      />
                                    </FlexItem>
                                    <FlexItem>
                                      <TextInput
                                        autoComplete={'tel'}
                                        errors={errors['phoneNumber']}
                                        isTouched={touched['phoneNumber']}
                                        label={'Phone number'}
                                        name={'phoneNumber'}
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        type={'tel'}
                                        value={values.phoneNumber}
                                      />
                                    </FlexItem>
                                  </FlexContainer>
                                </Fieldset>
                                <Fieldset
                                  title={
                                    <Title
                                      size={TitleSize.H5}
                                      tag={TitleTag.H2}
                                    >
                                      Agreements
                                    </Title>
                                  }
                                >
                                  <iframe
                                    className={'embeddedDocument'}
                                    height={'auto'}
                                    src={'https://cdn.brandfolder.io/5S4BNCY2/as/qa6m23-dkma0-4jx3si/RPP_State_Pricing_TOS_OFFICIAL.pdf'}
                                    width={'100%'}
                                  />
                                  <Link
                                    href={'/affiliates/privacy-policy/'}
                                    target={'_blank'}
                                  >
                                    <small>Privacy Policy</small>
                                  </Link>
                                  <Checkbox
                                    checked={values.confirmation}
                                    errors={errors['confirmation']}
                                    isTouched={touched['confirmation']}
                                    label={'By checking this box I confirm I have read, understand, acknowledge and accept and I agree to be bound by its terms.'}
                                    name={'confirmation'}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                  />
                                </Fieldset>
                                <FlexContainer
                                  gutter={Sizes.XSMALL}
                                  justify={JustifyContent.SPACE_BETWEEN}
                                >
                                  <FlexItem flex={FlexPreset.NONE}>
                                    <Button
                                      href={'/partners/affiliates/'}
                                      intent={IntentType.TERTIARY}
                                      type={ButtonType.LINK}
                                    >
                                      Cancel
                                    </Button>
                                  </FlexItem>
                                  <FlexItem flex={FlexPreset.NONE}>
                                    <Button
                                      disabled={isSubmitting || !isValid}
                                      onClick={handleSubmit}
                                    >
                                      Submit application
                                    </Button>
                                  </FlexItem>
                                </FlexContainer>
                              </form>
                            </div>
                          )}
                      </Formik>
                    )
                  }
                </FlexItem>
              </FlexContainer>
            </PageContent>
          </section>
        </StyledPageContent>
      </PageTemplate>
    );
  }
}

AffiliateApplicationIndexPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string.isRequired,
    pathname: PropTypes.string.isRequired,
  }.isRequired),
};

const StyledPageContent = styled.div({
  section: {
    ':not(:first-of-type)': {
      marginTop: Sizes.XXLARGE,
      paddingTop: Sizes.XXLARGE,
      paddingBottom: Sizes.XXLARGE,
    },
    marginBottom: Sizes.XXLARGE,
  },
  '.embeddedDocument': {
    display: 'block',
    boxSizing: 'border-box',
    minHeight: '50vh',
    border: `1px solid ${Colors.greyDark()}`,

    '+ .Link': {
      display: 'inline-block',
      marginTop: Sizes.SMALL,
      marginBottom: Sizes.NORMAL,
    },
  },
  '.formSuccessContainer': {
    small: {
      color: Colors.greyDark(),
    },
  },
});
