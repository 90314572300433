export default function IconArrowRight() {
  return (
    <svg
      fill={'none'}
      height={'24'}
      viewBox={'0 0 24 24'}
      width={'24'}
      xmlns={'http://www.w3.org/2000/svg'}
    >
      <path
        clipRule={'evenodd'}
        d={'M22.9142 12.0002L14.7734 3.85938L13.3592 5.27359L19.0858 11.0002L1 11.0002V13.0002L19.0858 13.0002L13.3592 18.7267L14.7734 20.141L22.9142 12.0002Z'}
        fill={'white'}
        fillRule={'evenodd'}
      />
    </svg>
  );
}
