import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';

import { Colors, StyleSheet } from '@root/core/src/utils/styles';

function TwitterIcon({ iconStyles }) {
  return (
    <svg
      aria-label={'Twitter icon'}
      css={[styles.svg, iconStyles]}
      enableBackground={'new 0 0 50 50'}
      role={'img'}
      version={'1.1'}
      viewBox={'0 0 50 50'}
      x={'0px'}
      xmlns={'http://www.w3.org/2000/svg'}
      xmlSpace={'preserve'}
      y={'0px'}
    >
      <path
        d={'M50.061,10.438c-1.846,0.818-3.826,1.369-5.908,1.62c2.125-1.273,3.757-3.29,4.523-5.688  c-1.986,1.177-4.19,2.033-6.531,2.493c-1.874-2-4.547-3.247-7.504-3.247c-5.68,0-10.284,4.604-10.284,10.282  c0,0.805,0.092,1.589,0.269,2.343C16.08,17.812,8.502,13.718,3.429,7.497c-0.885,1.522-1.391,3.289-1.391,5.172  c0,3.567,1.812,6.713,4.574,8.56c-1.688-0.054-3.271-0.517-4.657-1.288c0,0.044,0,0.086,0,0.131c0,4.984,3.544,9.134,8.245,10.084  c-0.86,0.236-1.769,0.36-2.707,0.36c-0.664,0-1.309-0.064-1.938-0.186c1.313,4.081,5.108,7.06,9.607,7.143  c-3.517,2.757-7.951,4.399-12.77,4.399c-0.833,0-1.649-0.048-2.452-0.144c4.548,2.919,9.956,4.619,15.762,4.619  c18.915,0,29.26-15.668,29.26-29.252c0-0.448-0.011-0.894-0.03-1.332C46.94,14.313,48.684,12.5,50.061,10.438z'}
      />
    </svg>
  );
}

const styles = StyleSheet.create({
  svg: {
    fill: Colors.black(),
  },
});

TwitterIcon.propTypes = {
  iconStyles: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
};

export default TwitterIcon;
