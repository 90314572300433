import Colors from '@root/gatsby-contentful-core/src/utils/colors';
import PropTypes from '@root/vendor/prop-types';
import React from '@root/vendor/react';
import Responsive from '@root/core/src/utils/responsive';
import { H4 } from '@root/gatsby-contentful-core/src/components/text';
import { StyleSheet } from '@root/core/src/utils/styles';

export default function FaqTopicsNavDesktop({
  faqData,
  handleFaqNavClick,
  activeTopic,
  sectionSpacing = {},
}) {
  const sectionSpacingStylesTop = {
    ...Responsive.md({
      paddingTop: sectionSpacing.top,
      paddingBottom: sectionSpacing.bottom,
    }),
  };

  return (
    <>
      <div
        css={[styles.wrap, sectionSpacingStylesTop]}
        data-testid={'faq-topics-nav-desktop'}
      >
        <div css={styles.faqNav}>
          <H4 css={styles.title}>Help Topics</H4>
          <ul css={styles.list}>
            {faqData.map((group, i) => {
              const isActive = activeTopic.topic === group.title;
              return (
                <li
                  css={[styles.listItem, isActive && styles.activeListItem]}
                  key={i}
                  onClick={(e) => handleFaqNavClick(e.target.innerText)}
                >
                  {group.title}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}

FaqTopicsNavDesktop.propTypes = {
  activeTopic: PropTypes.shape({
    topic: PropTypes.string,
    index: PropTypes.number,
  }).isRequired,
  faqData: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
    })
  ),
  handleFaqNavClick: PropTypes.func,
  sectionSpacing: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number,
  }),
};

const styles = StyleSheet.create({
  wrap: {
    display: 'none',

    ...Responsive.md({
      display: 'block',
      position: 'absolute',
      width: '100%',
      height: '100%',
      maxWidth: 1260,
      padding: '92px 30px',
      pointerEvents: 'none',
    }),
  },

  faqNav: {
    position: 'sticky',
    top: 100,
    width: 170,
  },

  title: {
    marginBottom: 10,
    color: Colors.grayMedium(),
  },

  list: {
    listStyle: 'none',
    pointerEvents: 'auto',
  },

  listItem: {
    margin: 0,
    padding: '8px 0',
    color: Colors.grayMedium(),
    cursor: 'pointer',
    transition: '150ms ease-in-out',

    '&:hover': {
      color: Colors.nearBlack(),
    },
  },

  activeListItem: {
    color: Colors.rootOrange(),

    '&:hover': {
      color: Colors.rootOrange(),
    },
  },
});
