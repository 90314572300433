import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getRootAgentTriageConfiguration(market) {
  return new NetworkRequestConfiguration({
    endpoint: 'isa_eligibility',
    method: NetworkRequestConfiguration.Methods.GET,
    successCodes: [200],
    queryParameters: {
      market,
    },
  });
}
